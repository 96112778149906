import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import p1 from "../../assets/images/products/img-1.png";
import p2 from "../../assets/images/products/img-2.png";
import p3 from "../../assets/images/products/img-3.png";
import p4 from "../../assets/images/products/img-10.png";

function Product() {
  return (
    <Fragment>
      <Container style={{ marginTop: "200px" }}>
        <div className='text-center'>
          <h1 className='fs-1 fw-bold '>Product</h1>
          <h6 className='fs-6 text-muted'>
            Aptek innovates and re-innovates the most intelligent in the
            Internet of Things to prompt broader possibilities behind
            significant insights.
          </h6>
        </div>
        <div className='d-flex justify-content-center mt-5'>
          <Row>
            <Col sm={3}>
              <div className='zoom'>
                <img src={p1} alt='' height={200} />
                <h6 className='text-muted text-center'>IoT Controller</h6>
              </div>
            </Col>
            <Col sm={3}>
              <div className='zoom'>
                <img src={p2} alt='' height={200} />
                <h6 className='text-muted text-center'>IoT Controller</h6>
              </div>
            </Col>
            <Col sm={3}>
              <div className='zoom'>
                <img src={p3} alt='' height={200} />
                <h6 className='text-muted text-center'>IoT Controller</h6>
              </div>
            </Col>
            <Col sm={3}>
              <div className='zoom'>
                <img src={p4} alt='' height={200} />
                <h6 className='text-muted text-center'>IoT Controller</h6>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default Product;
