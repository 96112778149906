import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_DOCUMENT_DETAIL,
  DELETE_DOCUMENT_DETAIL,
  UPDATE_DOCUMENT_DETAIL,
  ADD_NEW_DOCUMENT_DETAIL,
} from "./actionType";

import {
  getDocumentDetailSuccess,
  getDocumentDetailFail,
  deleteDocumentDetailSuccess,
  deleteDocumentDetailFail,
  updateDocumentDetailSuccess,
  updateDocumentDetailFail,
  addDocumentDetailSuccess,
  addDocumentDetailFail,
} from "./action";

//Include Both Helper File with needed carriers
import {
  approveDocumentDetailApi,
  getDocumentDetailApi,
  updateDocumentDetailApi,
  deleteDocumentDetailApi,
  addNewDocumentDetailApi
} from "../../helpers/backend_helper";

function* getDocumentDetail({ payload: documentDetail }) {
  try {
    const response = yield call(getDocumentDetailApi, documentDetail);
    yield put(getDocumentDetailSuccess(GET_DOCUMENT_DETAIL, response.data));
  } catch (error) {
    yield put(getDocumentDetailFail(GET_DOCUMENT_DETAIL, error));
  }
}

function* onUpdateDocumentDetail({ payload: documentDetail }) {
  try {
    if (documentDetail.setApprove) {
      const response = yield call(approveDocumentDetailApi, documentDetail);
      yield put(updateDocumentDetailSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateDocumentDetailApi, documentDetail);
      yield put(updateDocumentDetailSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateDocumentDetailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteDocumentDetail({ payload: documentDetail }) {
  try {
    const response = yield call(deleteDocumentDetailApi, documentDetail);
    yield put(deleteDocumentDetailSuccess({ documentDetail, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteDocumentDetailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewDocumentDetail({ payload: documentDetail }) {
  try {
    const response = yield call(addNewDocumentDetailApi, documentDetail);
    yield put(addDocumentDetailSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addDocumentDetailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetDocumentDetail() {
  yield takeEvery(GET_DOCUMENT_DETAIL, getDocumentDetail);
}

export function* watchUpdateDocumentDetail() {
  yield takeEvery(UPDATE_DOCUMENT_DETAIL, onUpdateDocumentDetail);
}

export function* watchDeleteDocumentDetail() {
  yield takeEvery(DELETE_DOCUMENT_DETAIL, onDeleteDocumentDetail);
}

export function* watchAddNewDocumentDetail() {
  yield takeEvery(ADD_NEW_DOCUMENT_DETAIL, onAddNewDocumentDetail);
}

function* documentDetailSaga() {
  yield all([
    fork(watchGetDocumentDetail),
    fork(watchDeleteDocumentDetail),
    fork(watchUpdateDocumentDetail),
    fork(watchAddNewDocumentDetail)
  ]);
}

export default documentDetailSaga;
