export const SERVICE_ORDERHISTORY_LOADING = "SERVICE_ORDERHISTORY_LOADING";
export const SERVICE_ORDERHISTORY_RESET = "SERVICE_ORDERHISTORY_RESET";
export const GET_SERVICE_ORDERHISTORY = "GET_SERVICE_ORDERHISTORY";
export const GET_SERVICE_ORDERHISTORY_SUCCESS = "GET_SERVICE_ORDERHISTORY_SUCCESS";
export const GET_SERVICE_ORDERHISTORY_FAIL = "GET_SERVICE_ORDERHISTORY_FAIL";
export const DELETE_SERVICE_ORDERHISTORY = "DELETE_SERVICE_ORDERHISTORY"
export const DELETE_SERVICE_ORDERHISTORY_SUCCESS = "DELETE_SERVICE_ORDERHISTORY_SUCCESS"
export const DELETE_SERVICE_ORDERHISTORY_FAIL = "DELETE_SERVICE_ORDERHISTORY_FAIL"
export const UPDATE_SERVICE_ORDERHISTORY = "UPDATE_SERVICE_ORDERHISTORY"
export const UPDATE_SERVICE_ORDERHISTORY_SUCCESS = "UPDATE_SERVICE_ORDERHISTORY_SUCCESS"
export const UPDATE_SERVICE_ORDERHISTORY_FAIL = "UPDATE_SERVICE_ORDERHISTORY_FAIL"
export const ADD_NEW_SERVICE_ORDERHISTORY = "ADD_NEW_SERVICE_ORDERHISTORY"
export const ADD_SERVICE_ORDERHISTORY_SUCCESS = "ADD_SERVICE_ORDERHISTORY_SUCCESS"
export const ADD_SERVICE_ORDERHISTORY_FAIL = "ADD_SERVICE_ORDERHISTORY_FAIL"