import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GET_SERVICELIST, DELETE_SERVICELIST, UPDATE_SERVICELIST, ADD_NEW_SERVICELIST } from "./actionType";

import {
  getServiceListSuccess,
  getServiceListFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getServiceListApi,
} from "../../helpers/backend_helper";

function* getServiceList({ payload: serviceList }) {
  try {
    const response = yield call(getServiceListApi, serviceList);
    yield put(getServiceListSuccess(GET_SERVICELIST, response.data));
  } catch (error) {
    yield put(getServiceListFail(GET_SERVICELIST, error));
  }
}

export function* watchGetServiceList() {
  yield takeEvery(GET_SERVICELIST, getServiceList);
}

function* serviceListSaga() {
  yield all([
    fork(watchGetServiceList),
  ]);
}

export default serviceListSaga;
