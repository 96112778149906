import {
  USER_LOADING,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  USER_RESET
} from "./actionType";

const INIT_STATE = {
  users: [],
  error: {},
};

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_SUCCESS:
      switch (action.payload.actionType) {
        case GET_USER:
          return {
            ...state,
            users: action.payload.data,
            isUserCreated: false,
            isUserSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_USER_FAIL:
      switch (action.payload.actionType) {
        case GET_USER_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isUsersCreated: false,
            isUsersSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_USER: {
      return {
        ...state,
        isUsersCreated: false,
        loading: true,
      };
    }

    case USER_LOADING: {
      return {
        ...state,
        isUsersCreated: false,
        loading: true,
      };
    }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        isUsersCreated: true,
        loading: false,
        users: [...state.users, action.payload.data],
      };

    case ADD_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isUsersCreated: true,
        users: state.users.filter(
          users => users.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isUserCreated: true,
        users: state.users.map(user =>
          user.id.toString() === action.payload.data.id.toString() ? { ...user, ...action.payload.data } : user
        ),
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case USER_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Users;