export const SERVICE_ORDERAPPROVAL_LOADING = "SERVICE_ORDERAPPROVAL_LOADING";
export const SERVICE_ORDERAPPROVAL_RESET = "SERVICE_ORDERAPPROVAL_RESET";
export const GET_SERVICE_ORDERAPPROVAL = "GET_SERVICE_ORDERAPPROVAL";
export const GET_SERVICE_ORDERAPPROVAL_SUCCESS = "GET_SERVICE_ORDERAPPROVAL_SUCCESS";
export const GET_SERVICE_ORDERAPPROVAL_FAIL = "GET_SERVICE_ORDERAPPROVAL_FAIL";
export const DELETE_SERVICE_ORDERAPPROVAL = "DELETE_SERVICE_ORDERAPPROVAL"
export const DELETE_SERVICE_ORDERAPPROVAL_SUCCESS = "DELETE_SERVICE_ORDERAPPROVAL_SUCCESS"
export const DELETE_SERVICE_ORDERAPPROVAL_FAIL = "DELETE_SERVICE_ORDERAPPROVAL_FAIL"
export const UPDATE_SERVICE_ORDERAPPROVAL = "UPDATE_SERVICE_ORDERAPPROVAL"
export const UPDATE_SERVICE_ORDERAPPROVAL_SUCCESS = "UPDATE_SERVICE_ORDERAPPROVAL_SUCCESS"
export const UPDATE_SERVICE_ORDERAPPROVAL_FAIL = "UPDATE_SERVICE_ORDERAPPROVAL_FAIL"
export const ADD_NEW_SERVICE_ORDERAPPROVAL = "ADD_NEW_SERVICE_ORDERAPPROVAL"
export const ADD_SERVICE_ORDERAPPROVAL_SUCCESS = "ADD_SERVICE_ORDERAPPROVAL_SUCCESS"
export const ADD_SERVICE_ORDERAPPROVAL_FAIL = "ADD_SERVICE_ORDERAPPROVAL_FAIL"