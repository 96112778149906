import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_SERVICE_ORDERPAYMENT,
  DELETE_SERVICE_ORDERPAYMENT,
  UPDATE_SERVICE_ORDERPAYMENT,
  ADD_NEW_SERVICE_ORDERPAYMENT,
} from "./actionType";

import {
  getServiceOrderPaymentSuccess,
  getServiceOrderPaymentFail,
  deleteServiceOrderPaymentSuccess,
  deleteServiceOrderPaymentFail,
  updateServiceOrderPaymentSuccess,
  updateServiceOrderPaymentFail,
  addServiceOrderPaymentSuccess,
  addServiceOrderPaymentFail,
} from "./action";

//Include Both Helper File with needed carriers
import {
  approveServiceOrderPaymentApi,
  getServiceOrderPaymentApi,
  updateServiceOrderPaymentApi,
  deleteServiceOrderPaymentApi,
  addNewServiceOrderPaymentApi
} from "../../helpers/backend_helper";

function* getServiceOrderPayment({ payload: serviceOrderPayment }) {
  try {
    const response = yield call(getServiceOrderPaymentApi, serviceOrderPayment);
    yield put(getServiceOrderPaymentSuccess(GET_SERVICE_ORDERPAYMENT, response.data));
  } catch (error) {
    yield put(getServiceOrderPaymentFail(GET_SERVICE_ORDERPAYMENT, error));
  }
}

function* onUpdateServiceOrderPayment({ payload: serviceOrderPayment }) {
  try {
    if (serviceOrderPayment.setApprove) {
      const response = yield call(approveServiceOrderPaymentApi, serviceOrderPayment);
      yield put(updateServiceOrderPaymentSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateServiceOrderPaymentApi, serviceOrderPayment);
      yield put(updateServiceOrderPaymentSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateServiceOrderPaymentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteServiceOrderPayment({ payload: serviceOrderPayment }) {
  try {
    const response = yield call(deleteServiceOrderPaymentApi, serviceOrderPayment);
    yield put(deleteServiceOrderPaymentSuccess({ serviceOrderPayment, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteServiceOrderPaymentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewServiceOrderPayment({ payload: serviceOrderPayment }) {
  try {
    const response = yield call(addNewServiceOrderPaymentApi, serviceOrderPayment);
    yield put(addServiceOrderPaymentSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addServiceOrderPaymentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetServiceOrderPayment() {
  yield takeEvery(GET_SERVICE_ORDERPAYMENT, getServiceOrderPayment);
}

export function* watchUpdateServiceOrderPayment() {
  yield takeEvery(UPDATE_SERVICE_ORDERPAYMENT, onUpdateServiceOrderPayment);
}

export function* watchDeleteServiceOrderPayment() {
  yield takeEvery(DELETE_SERVICE_ORDERPAYMENT, onDeleteServiceOrderPayment);
}

export function* watchAddNewServiceOrderPayment() {
  yield takeEvery(ADD_NEW_SERVICE_ORDERPAYMENT, onAddNewServiceOrderPayment);
}

function* serviceOrderPaymentSaga() {
  yield all([
    fork(watchGetServiceOrderPayment),
    fork(watchDeleteServiceOrderPayment),
    fork(watchUpdateServiceOrderPayment),
    fork(watchAddNewServiceOrderPayment)
  ]);
}

export default serviceOrderPaymentSaga;
