import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_CONTACT_PERSON,
  DELETE_CONTACT_PERSON,
  UPDATE_CONTACT_PERSON,
  ADD_NEW_CONTACT_PERSON,
} from "./actionType";

import {
  getContactPersonSuccess,
  getContactPersonFail,
  deleteContactPersonSuccess,
  deleteContactPersonFail,
  updateContactPersonSuccess,
  updateContactPersonFail,
  addContactPersonSuccess,
  addContactPersonFail,
} from "./action";

//Include Both Helper File with needed carriers
import {
  approveContactPersonApi,
  getContactPersonApi,
  updateContactPersonApi,
  deleteContactPersonApi,
  addNewContactPersonApi
} from "../../helpers/backend_helper";

function* getContactPerson({ payload: contactPerson }) {
  try {
    const response = yield call(getContactPersonApi, contactPerson);
    yield put(getContactPersonSuccess(GET_CONTACT_PERSON, response.data));
  } catch (error) {
    yield put(getContactPersonFail(GET_CONTACT_PERSON, error));
  }
}

function* onUpdateContactPerson({ payload: contactPerson }) {
  try {
    if (contactPerson.setApprove) {
      const response = yield call(approveContactPersonApi, contactPerson);
      yield put(updateContactPersonSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateContactPersonApi, contactPerson);
      yield put(updateContactPersonSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateContactPersonFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteContactPerson({ payload: contactPerson }) {
  try {
    const response = yield call(deleteContactPersonApi, contactPerson);
    yield put(deleteContactPersonSuccess({ contactPerson, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteContactPersonFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewContactPerson({ payload: contactPerson }) {
  try {
    const response = yield call(addNewContactPersonApi, contactPerson);
    yield put(addContactPersonSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addContactPersonFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetContactPerson() {
  yield takeEvery(GET_CONTACT_PERSON, getContactPerson);
}

export function* watchUpdateContactPerson() {
  yield takeEvery(UPDATE_CONTACT_PERSON, onUpdateContactPerson);
}

export function* watchDeleteContactPerson() {
  yield takeEvery(DELETE_CONTACT_PERSON, onDeleteContactPerson);
}

export function* watchAddNewContactPerson() {
  yield takeEvery(ADD_NEW_CONTACT_PERSON, onAddNewContactPerson);
}

function* contactPersonSaga() {
  yield all([
    fork(watchGetContactPerson),
    fork(watchDeleteContactPerson),
    fork(watchUpdateContactPerson),
    fork(watchAddNewContactPerson)
  ]);
}

export default contactPersonSaga;
