import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_TASK,
  DELETE_TASK,
  UPDATE_TASK,
  ADD_NEW_TASK,
  GET_PUBLIC_TASK,
} from "./actionType";

import {
  getTaskSuccess,
  getTaskFail,
  deleteTaskSuccess,
  deleteTaskFail,
  updateTaskSuccess,
  updateTaskFail,
  addTaskSuccess,
  addTaskFail,
  getTaskPublicSuccess,
  getTaskPublicFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveTaskApi,
  getTaskApi,
  updateTaskApi,
  deleteTaskApi,
  addNewTaskApi,
  getPublicTaskApi,
} from "../../helpers/backend_helper";

function* getTask({ payload: task }) {
  try {
    const response = yield call(getTaskApi, task);
    yield put(getTaskSuccess(GET_TASK, response.data));
  } catch (error) {
    yield put(getTaskFail(GET_TASK, error));
  }
}

function* getPublicTask({ payload: task }) {
  try {
    const response = yield call(getPublicTaskApi, task);
    yield put(getTaskPublicSuccess(GET_PUBLIC_TASK, response.data));
  } catch (error) {
    yield put(getTaskPublicFail(GET_PUBLIC_TASK, error));
  }
}

function* onUpdateTask({ payload: task }) {
  try {
    if (task.setApprove) {
      const response = yield call(approveTaskApi, task);
      yield put(updateTaskSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateTaskApi, task);
      yield put(updateTaskSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateTaskFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteTask({ payload: task }) {
  try {
    const response = yield call(deleteTaskApi, task);
    yield put(deleteTaskSuccess({ task, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteTaskFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewTask({ payload: task }) {
  try {
    const response = yield call(addNewTaskApi, task);
    yield put(addTaskSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addTaskFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetTask() {
  yield takeEvery(GET_TASK, getTask);
}

export function* watchGetPublicTask() {
  yield takeEvery(GET_PUBLIC_TASK, getPublicTask);
}

export function* watchUpdateTask() {
  yield takeEvery(UPDATE_TASK, onUpdateTask);
}

export function* watchDeleteTask() {
  yield takeEvery(DELETE_TASK, onDeleteTask);
}

export function* watchAddNewTask() {
  yield takeEvery(ADD_NEW_TASK, onAddNewTask);
}

function* taskSaga() {
  yield all([
    fork(watchGetTask),
    fork(watchDeleteTask),
    fork(watchUpdateTask),
    fork(watchAddNewTask),
    fork(watchGetPublicTask),
  ]);
}

export default taskSaga;
