export const TICKETCATEGORY_LOADING = "TICKETCATEGORY_LOADING";
export const TICKETCATEGORY_RESET = "TICKETCATEGORY_RESET";
export const GET_TICKETCATEGORY = "GET_TICKETCATEGORY";
export const GET_TICKETCATEGORY_SUCCESS = "GET_TICKETCATEGORY_SUCCESS";
export const GET_TICKETCATEGORY_FAIL = "GET_TICKETCATEGORY_FAIL";
export const DELETE_TICKETCATEGORY = "DELETE_TICKETCATEGORY";
export const DELETE_TICKETCATEGORY_SUCCESS = "DELETE_TICKETCATEGORY_SUCCESS";
export const DELETE_TICKETCATEGORY_FAIL = "DELETE_TICKETCATEGORY_FAIL";
export const UPDATE_TICKETCATEGORY = "UPDATE_TICKETCATEGORY";
export const UPDATE_TICKETCATEGORY_SUCCESS = "UPDATE_TICKETCATEGORY_SUCCESS";
export const UPDATE_TICKETCATEGORY_FAIL = "UPDATE_TICKETCATEGORY_FAIL";
export const ADD_NEW_TICKETCATEGORY = "ADD_NEW_TICKETCATEGORY";
export const ADD_TICKETCATEGORY_SUCCESS = "ADD_TICKETCATEGORY_SUCCESS";
export const ADD_TICKETCATEGORY_FAIL = "ADD_TICKETCATEGORY_FAIL";
