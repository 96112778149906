import {
  GET_DOCUMENT_DETAIL,
  GET_DOCUMENT_DETAIL_SUCCESS,
  GET_DOCUMENT_DETAIL_FAIL,
  DELETE_DOCUMENT_DETAIL,
  DELETE_DOCUMENT_DETAIL_SUCCESS,
  DELETE_DOCUMENT_DETAIL_FAIL,
  UPDATE_DOCUMENT_DETAIL,
  UPDATE_DOCUMENT_DETAIL_SUCCESS,
  UPDATE_DOCUMENT_DETAIL_FAIL,
  ADD_NEW_DOCUMENT_DETAIL,
  ADD_DOCUMENT_DETAIL_SUCCESS,
  ADD_DOCUMENT_DETAIL_FAIL,
  DOCUMENT_DETAIL_LOADING,
  DOCUMENT_DETAIL_RESET,
} from "./actionType";

export const resetDocumentDetailState = (actionType) => ({
  type: DOCUMENT_DETAIL_RESET,
  payload: { actionType },
});

export const getDocumentDetailSuccess = (actionType, data) => ({
  type: GET_DOCUMENT_DETAIL_SUCCESS,
  payload: { actionType, data },
});

export const getDocumentDetailFail = (actionType, error) => ({
  type: GET_DOCUMENT_DETAIL_FAIL,
  payload: { actionType, error },
});

export const getDocumentDetail = data => ({
  type: GET_DOCUMENT_DETAIL,
  payload: data,
});

export const getDocumentDetailLoading = () => ({
  type: DOCUMENT_DETAIL_LOADING
});

export const deleteDocumentDetail = data => ({
  type: DELETE_DOCUMENT_DETAIL,
  payload: data,
});

export const deleteDocumentDetailSuccess = data => ({
  type: DELETE_DOCUMENT_DETAIL_SUCCESS,
  payload: data,
});

export const deleteDocumentDetailFail = error => ({
  type: DELETE_DOCUMENT_DETAIL_FAIL,
  payload: error,
});

export const updateDocumentDetail = data => ({
  type: UPDATE_DOCUMENT_DETAIL,
  payload: data,
});

export const updateDocumentDetailFail = error => ({
  type: UPDATE_DOCUMENT_DETAIL_FAIL,
  payload: error,
});

export const updateDocumentDetailSuccess = data => ({
  type: UPDATE_DOCUMENT_DETAIL_SUCCESS,
  payload: data,
});

export const addNewDocumentDetail = data => ({
  type: ADD_NEW_DOCUMENT_DETAIL,
  payload: data,
});

export const addDocumentDetailSuccess = data => ({
  type: ADD_DOCUMENT_DETAIL_SUCCESS,
  payload: data,
});

export const addDocumentDetailFail = error => ({
  type: ADD_DOCUMENT_DETAIL_FAIL,
  payload: error,
});