import {
  GET_SERVICE_ORDERDETAIL,
  GET_SERVICE_ORDERDETAIL_SUCCESS,
  GET_SERVICE_ORDERDETAIL_FAIL,
  DELETE_SERVICE_ORDERDETAIL,
  DELETE_SERVICE_ORDERDETAIL_SUCCESS,
  DELETE_SERVICE_ORDERDETAIL_FAIL,
  UPDATE_SERVICE_ORDERDETAIL,
  UPDATE_SERVICE_ORDERDETAIL_SUCCESS,
  UPDATE_SERVICE_ORDERDETAIL_FAIL,
  ADD_NEW_SERVICE_ORDERDETAIL,
  ADD_SERVICE_ORDERDETAIL_SUCCESS,
  ADD_SERVICE_ORDERDETAIL_FAIL,
  SERVICE_ORDERDETAIL_LOADING,
  SERVICE_ORDERDETAIL_RESET,
} from "./actionType";

export const resetServiceOrderDetailState = (actionType) => ({
  type: SERVICE_ORDERDETAIL_RESET,
  payload: { actionType },
});

export const getServiceOrderDetailSuccess = (actionType, data) => ({
  type: GET_SERVICE_ORDERDETAIL_SUCCESS,
  payload: { actionType, data },
});

export const getServiceOrderDetailFail = (actionType, error) => ({
  type: GET_SERVICE_ORDERDETAIL_FAIL,
  payload: { actionType, error },
});

export const getServiceOrderDetail = data => ({
  type: GET_SERVICE_ORDERDETAIL,
  payload: data,
});

export const getServiceOrderDetailLoading = () => ({
  type: SERVICE_ORDERDETAIL_LOADING
});

export const deleteServiceOrderDetail = data => ({
  type: DELETE_SERVICE_ORDERDETAIL,
  payload: data,
});

export const deleteServiceOrderDetailSuccess = data => ({
  type: DELETE_SERVICE_ORDERDETAIL_SUCCESS,
  payload: data,
});

export const deleteServiceOrderDetailFail = error => ({
  type: DELETE_SERVICE_ORDERDETAIL_FAIL,
  payload: error,
});

export const updateServiceOrderDetail = data => ({
  type: UPDATE_SERVICE_ORDERDETAIL,
  payload: data,
});

export const updateServiceOrderDetailFail = error => ({
  type: UPDATE_SERVICE_ORDERDETAIL_FAIL,
  payload: error,
});

export const updateServiceOrderDetailSuccess = data => ({
  type: UPDATE_SERVICE_ORDERDETAIL_SUCCESS,
  payload: data,
});

export const addNewServiceOrderDetail = data => ({
  type: ADD_NEW_SERVICE_ORDERDETAIL,
  payload: data,
});

export const addServiceOrderDetailSuccess = data => ({
  type: ADD_SERVICE_ORDERDETAIL_SUCCESS,
  payload: data,
});

export const addServiceOrderDetailFail = error => ({
  type: ADD_SERVICE_ORDERDETAIL_FAIL,
  payload: error,
});