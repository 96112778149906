import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GET_APP, DELETE_APP, UPDATE_APP, ADD_NEW_APP } from "./actionType";

import {
  getAppSuccess,
  getAppFail,
  deleteAppSuccess,
  deleteAppFail,
  updateAppSuccess,
  updateAppFail,
  addAppSuccess,
  addAppFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveAppApi,
  getAppApi,
  updateAppApi,
  deleteAppApi,
  addNewAppApi,
} from "../../helpers/backend_helper";

function* getApp({ payload: app }) {
  try {
    const response = yield call(getAppApi, app);
    yield put(getAppSuccess(GET_APP, response.data));
  } catch (error) {
    yield put(getAppFail(GET_APP, error));
  }
}

function* onUpdateApp({ payload: app }) {
  try {
    if (app.setApprove) {
      const response = yield call(approveAppApi, app);
      yield put(updateAppSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateAppApi, app);
      yield put(updateAppSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateAppFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteApp({ payload: app }) {
  try {
    const response = yield call(deleteAppApi, app);
    yield put(deleteAppSuccess({ app, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteAppFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewApp({ payload: app }) {
  try {
    const response = yield call(addNewAppApi, app);
    yield put(addAppSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addAppFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetApp() {
  yield takeEvery(GET_APP, getApp);
}

export function* watchUpdateApp() {
  yield takeEvery(UPDATE_APP, onUpdateApp);
}

export function* watchDeleteApp() {
  yield takeEvery(DELETE_APP, onDeleteApp);
}

export function* watchAddNewApp() {
  yield takeEvery(ADD_NEW_APP, onAddNewApp);
}

function* appSaga() {
  yield all([
    fork(watchGetApp),
    fork(watchDeleteApp),
    fork(watchUpdateApp),
    fork(watchAddNewApp),
  ]);
}

export default appSaga;
