import {
  GET_STORAGE_FILE,
  GET_STORAGE_FILE_ONE,
  GET_STORAGE_FILE_SUCCESS,
  GET_STORAGE_FILE_FAIL,
  DELETE_STORAGE_FILE,
  DELETE_STORAGE_FILE_SUCCESS,
  DELETE_STORAGE_FILE_FAIL,
  UPDATE_STORAGE_FILE,
  UPDATE_STORAGE_FILE_SUCCESS,
  UPDATE_STORAGE_FILE_FAIL,
  ADD_NEW_STORAGE_FILE,
  ADD_STORAGE_FILE_SUCCESS,
  ADD_STORAGE_FILE_FAIL,
  STORAGE_FILE_LOADING,
  STORAGE_FILE_RESET,
} from "./actionType";

export const resetStorageFileState = (actionType) => ({
  type: STORAGE_FILE_RESET,
  payload: { actionType },
});

export const getStorageFileSuccess = (actionType, data) => ({
  type: GET_STORAGE_FILE_SUCCESS,
  payload: { actionType, data },
});

export const getStorageFileFail = (actionType, error) => ({
  type: GET_STORAGE_FILE_FAIL,
  payload: { actionType, error },
});

export const getStorageFile = (data) => ({
  type: GET_STORAGE_FILE,
  payload: data,
});

export const getStorageFileOne = (data) => ({
  type: GET_STORAGE_FILE_ONE,
  payload: data,
});

export const getStorageFileLoading = () => ({
  type: STORAGE_FILE_LOADING,
});

export const deleteStorageFile = (data) => ({
  type: DELETE_STORAGE_FILE,
  payload: data,
});

export const deleteStorageFileSuccess = (data) => ({
  type: DELETE_STORAGE_FILE_SUCCESS,
  payload: data,
});

export const deleteStorageFileFail = (error) => ({
  type: DELETE_STORAGE_FILE_FAIL,
  payload: error,
});

export const updateStorageFile = (data) => ({
  type: UPDATE_STORAGE_FILE,
  payload: data,
});

export const updateStorageFileFail = (error) => ({
  type: UPDATE_STORAGE_FILE_FAIL,
  payload: error,
});

export const updateStorageFileSuccess = (data) => ({
  type: UPDATE_STORAGE_FILE_SUCCESS,
  payload: data,
});

export const addNewStorageFile = (data) => ({
  type: ADD_NEW_STORAGE_FILE,
  payload: data,
});

export const addStorageFileSuccess = (data) => ({
  type: ADD_STORAGE_FILE_SUCCESS,
  payload: data,
});

export const addStorageFileFail = (error) => ({
  type: ADD_STORAGE_FILE_FAIL,
  payload: error,
});
