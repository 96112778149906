import {
  SERVICE_ORDERTOTAL_LOADING,
  GET_SERVICE_ORDERTOTAL,
  GET_SERVICE_ORDERTOTAL_SUCCESS,
  GET_SERVICE_ORDERTOTAL_FAIL,
  ADD_SERVICE_ORDERTOTAL_SUCCESS,
  ADD_SERVICE_ORDERTOTAL_FAIL,
  DELETE_SERVICE_ORDERTOTAL_SUCCESS,
  DELETE_SERVICE_ORDERTOTAL_FAIL,
  UPDATE_SERVICE_ORDERTOTAL_SUCCESS,
  UPDATE_SERVICE_ORDERTOTAL_FAIL,
  SERVICE_ORDERTOTAL_RESET
} from "./actionType";

const INIT_STATE = {
  serviceOrderTotals: [],
  error: {},
};

const ServiceOrderTotal = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_ORDERTOTAL_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDERTOTAL:
          return {
            ...state,
            serviceOrderTotals: action.payload.data,
            isServiceOrderTotalCreated: false,
            isServiceOrderTotalSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SERVICE_ORDERTOTAL_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDERTOTAL_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isServiceOrderTotalCreated: false,
            isServiceOrderTotalSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SERVICE_ORDERTOTAL: {
      return {
        ...state,
        isServiceOrderTotalCreated: false,
        loading: true,
      };
    }

    case SERVICE_ORDERTOTAL_LOADING: {
      return {
        ...state,
        isServiceOrderTotalCreated: false,
        loading: true,
      };
    }

    case ADD_SERVICE_ORDERTOTAL_SUCCESS:
      return {
        ...state,
        isServiceOrderTotalCreated: true,
        loading: false,
        serviceOrderTotals: [...state.serviceOrderTotals, action.payload.data],
      };

    case ADD_SERVICE_ORDERTOTAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_SERVICE_ORDERTOTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderTotalCreated: true,
        serviceOrderTotals: state.serviceOrderTotals.filter(
          serviceOrderTotal => serviceOrderTotal.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_SERVICE_ORDERTOTAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_SERVICE_ORDERTOTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderTotalCreated: true,
        serviceOrderTotals: state.serviceOrderTotals.map(serviceOrderTotal =>
          serviceOrderTotal.id.toString() === action.payload.data.id.toString() ? { ...serviceOrderTotal, ...action.payload.data } : serviceOrderTotal
        ),
      };

    case UPDATE_SERVICE_ORDERTOTAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SERVICE_ORDERTOTAL_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ServiceOrderTotal;