import {
  GET_CONTACT_PERSON,
  GET_CONTACT_PERSON_SUCCESS,
  GET_CONTACT_PERSON_FAIL,
  DELETE_CONTACT_PERSON,
  DELETE_CONTACT_PERSON_SUCCESS,
  DELETE_CONTACT_PERSON_FAIL,
  UPDATE_CONTACT_PERSON,
  UPDATE_CONTACT_PERSON_SUCCESS,
  UPDATE_CONTACT_PERSON_FAIL,
  ADD_NEW_CONTACT_PERSON,
  ADD_CONTACT_PERSON_SUCCESS,
  ADD_CONTACT_PERSON_FAIL,
  CONTACT_PERSON_LOADING,
  CONTACT_PERSON_RESET,
} from "./actionType";

export const resetContactPersonState = (actionType) => ({
  type: CONTACT_PERSON_RESET,
  payload: { actionType },
});

export const getContactPersonSuccess = (actionType, data) => ({
  type: GET_CONTACT_PERSON_SUCCESS,
  payload: { actionType, data },
});

export const getContactPersonFail = (actionType, error) => ({
  type: GET_CONTACT_PERSON_FAIL,
  payload: { actionType, error },
});

export const getContactPerson = data => ({
  type: GET_CONTACT_PERSON,
  payload: data,
});

export const getContactPersonLoading = () => ({
  type: CONTACT_PERSON_LOADING
});

export const deleteContactPerson = data => ({
  type: DELETE_CONTACT_PERSON,
  payload: data,
});

export const deleteContactPersonSuccess = data => ({
  type: DELETE_CONTACT_PERSON_SUCCESS,
  payload: data,
});

export const deleteContactPersonFail = error => ({
  type: DELETE_CONTACT_PERSON_FAIL,
  payload: error,
});

export const updateContactPerson = data => ({
  type: UPDATE_CONTACT_PERSON,
  payload: data,
});

export const updateContactPersonFail = error => ({
  type: UPDATE_CONTACT_PERSON_FAIL,
  payload: error,
});

export const updateContactPersonSuccess = data => ({
  type: UPDATE_CONTACT_PERSON_SUCCESS,
  payload: data,
});

export const addNewContactPerson = data => ({
  type: ADD_NEW_CONTACT_PERSON,
  payload: data,
});

export const addContactPersonSuccess = data => ({
  type: ADD_CONTACT_PERSON_SUCCESS,
  payload: data,
});

export const addContactPersonFail = error => ({
  type: ADD_CONTACT_PERSON_FAIL,
  payload: error,
});