import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GET_SERVICEPRICECATEGORY, DELETE_SERVICEPRICECATEGORY, UPDATE_SERVICEPRICECATEGORY, ADD_NEW_SERVICEPRICECATEGORY } from "./actionType";

import {
  getServicePriceCategorySuccess,
  getServicePriceCategoryFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getServicePriceCategoryApi,
} from "../../helpers/backend_helper";

function* getServicePriceCategory({ payload: servicePriceCategory }) {
  try {
    const response = yield call(getServicePriceCategoryApi, servicePriceCategory);
    yield put(getServicePriceCategorySuccess(GET_SERVICEPRICECATEGORY, response.data));
  } catch (error) {
    yield put(getServicePriceCategoryFail(GET_SERVICEPRICECATEGORY, error));
  }
}

export function* watchGetServicePriceCategory() {
  yield takeEvery(GET_SERVICEPRICECATEGORY, getServicePriceCategory);
}

function* servicePriceCategorySaga() {
  yield all([
    fork(watchGetServicePriceCategory),
  ]);
}

export default servicePriceCategorySaga;
