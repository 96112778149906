import {
  SERVICE_ORDER_LOADING,
  GET_SERVICE_ORDER,
  GET_SERVICE_ORDER_SUCCESS,
  GET_SERVICE_ORDER_FAIL,
  ADD_SERVICE_ORDER_SUCCESS,
  ADD_SERVICE_ORDER_FAIL,
  DELETE_SERVICE_ORDER_SUCCESS,
  DELETE_SERVICE_ORDER_FAIL,
  UPDATE_SERVICE_ORDER_SUCCESS,
  UPDATE_SERVICE_ORDER_FAIL,
  SERVICE_ORDER_RESET
} from "./actionType";

const INIT_STATE = {
  serviceOrders: [],
  error: {},
};

const ServiceOrder = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_ORDER_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDER:
          return {
            ...state,
            serviceOrders: action.payload.data,
            isServiceOrderCreated: false,
            isServiceOrderSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SERVICE_ORDER_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDER_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isServiceOrderCreated: false,
            isServiceOrderSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SERVICE_ORDER: {
      return {
        ...state,
        isServiceOrderCreated: false,
        loading: true,
      };
    }

    case SERVICE_ORDER_LOADING: {
      return {
        ...state,
        isServiceOrderCreated: false,
        loading: true,
      };
    }

    case ADD_SERVICE_ORDER_SUCCESS:
      return {
        ...state,
        isServiceOrderCreated: true,
        loading: false,
        serviceOrders: [...state.serviceOrders, action.payload.data],
      };

    case ADD_SERVICE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_SERVICE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderCreated: true,
        serviceOrders: state.serviceOrders.filter(
          serviceOrder => serviceOrder.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_SERVICE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_SERVICE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderCreated: true,
        serviceOrders: state.serviceOrders.map(serviceOrder =>
          serviceOrder.id.toString() === action.payload.data.id.toString() ? { ...serviceOrder, ...action.payload.data } : serviceOrder
        ),
      };

    case UPDATE_SERVICE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SERVICE_ORDER_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ServiceOrder;