import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Dashboard from "../pages/Dashboard";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import Landing from "../pages/Home";
import QRLanding from "../pages/Landing/qr";

//USers
import Users from "../pages/Users";
import Clients from "../pages/Clients";
import Service from "../pages/Service";
import Billing from "../pages/Billing";
import Apps from "../pages/App";
import Units from "../pages/Units";
import ServiceOrder from "../pages/ServiceOrder";
import Branch from "../pages/Branch";
import Company from "../pages/Company";
import PaymentMethod from "../pages/Master/PaymentMethod";
import PaymentTerm from "../pages/Master/PaymentTerm";
import TransactionTax from "../pages/Master/TransactionTax";
import BillingCategory from "../pages/Master/CategoryBilling";
import Waranty from "../pages/Master/Waranty";
import Promosion from "../pages/Master/Promosion";
import ContactPerson from "../pages/Master/ContactPerson";
import Wiki from "../pages/Master/Wiki";
import Ticket from "../pages/Ticket";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const authProtectedRoutes = [
  { path: "/index", component: <Dashboard /> },
  { path: "/dashboard", component: <Dashboard /> },

  { path: "/users", component: <Users /> },
  { path: "/service-order", component: <Users /> },
  { path: "/support-ticket", component: <Users /> },
  { path: "/report", component: <Users /> },
  { path: "/client", component: <Clients /> },
  { path: "/company", component: <Company /> },
  { path: "/branch", component: <Branch /> },
  { path: "/unit", component: <Units /> },
  { path: "/service", component: <Service /> },
  { path: "/service-orders", component: <ServiceOrder /> },
  { path: "/billings", component: <Billing /> },
  { path: "/payment-method", component: <PaymentMethod /> },
  { path: "/payment-term", component: <PaymentTerm /> },
  { path: "/transaction-tax", component: <TransactionTax /> },
  { path: "/category-billing", component: <BillingCategory /> },
  { path: "/waranty", component: <Waranty /> },
  { path: "/promotion", component: <Promosion /> },
  { path: "/contact-person", component: <ContactPerson /> },
  { path: "/document", component: <Wiki /> },
  { path: "/apps", component: <Apps /> },
  { path: "/ticket", component: <Ticket /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/home", component: <Landing /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/qr/:taskId", component: <QRLanding /> },
];

export { authProtectedRoutes, publicRoutes };
