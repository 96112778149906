import {
  GET_BILLING,
  GET_BILLING_SUCCESS,
  GET_BILLING_FAIL,
  DELETE_BILLING,
  DELETE_BILLING_SUCCESS,
  DELETE_BILLING_FAIL,
  UPDATE_BILLING,
  UPDATE_BILLING_SUCCESS,
  UPDATE_BILLING_FAIL,
  ADD_NEW_BILLING,
  ADD_BILLING_SUCCESS,
  ADD_BILLING_FAIL,
  BILLING_LOADING,
  BILLING_RESET,
} from "./actionType";

export const resetBillingState = (actionType) => ({
  type: BILLING_RESET,
  payload: { actionType },
});

export const getBillingSuccess = (actionType, data) => ({
  type: GET_BILLING_SUCCESS,
  payload: { actionType, data },
});

export const getBillingFail = (actionType, error) => ({
  type: GET_BILLING_FAIL,
  payload: { actionType, error },
});

export const getBilling = (data) => ({
  type: GET_BILLING,
  payload: data,
});

export const getBillingLoading = () => ({
  type: BILLING_LOADING,
});

export const deleteBilling = (data) => ({
  type: DELETE_BILLING,
  payload: data,
});

export const deleteBillingSuccess = (data) => ({
  type: DELETE_BILLING_SUCCESS,
  payload: data,
});

export const deleteBillingFail = (error) => ({
  type: DELETE_BILLING_FAIL,
  payload: error,
});

export const updateBilling = (data) => ({
  type: UPDATE_BILLING,
  payload: data,
});

export const updateBillingFail = (error) => ({
  type: UPDATE_BILLING_FAIL,
  payload: error,
});

export const updateBillingSuccess = (data) => ({
  type: UPDATE_BILLING_SUCCESS,
  payload: data,
});

export const addNewBilling = (data) => ({
  type: ADD_NEW_BILLING,
  payload: data,
});

export const addBillingSuccess = (data) => ({
  type: ADD_BILLING_SUCCESS,
  payload: data,
});

export const addBillingFail = (error) => ({
  type: ADD_BILLING_FAIL,
  payload: error,
});
