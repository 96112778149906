import {
  GET_SERVICE_ORDERTOTAL,
  GET_SERVICE_ORDERTOTAL_SUCCESS,
  GET_SERVICE_ORDERTOTAL_FAIL,
  DELETE_SERVICE_ORDERTOTAL,
  DELETE_SERVICE_ORDERTOTAL_SUCCESS,
  DELETE_SERVICE_ORDERTOTAL_FAIL,
  UPDATE_SERVICE_ORDERTOTAL,
  UPDATE_SERVICE_ORDERTOTAL_SUCCESS,
  UPDATE_SERVICE_ORDERTOTAL_FAIL,
  ADD_NEW_SERVICE_ORDERTOTAL,
  ADD_SERVICE_ORDERTOTAL_SUCCESS,
  ADD_SERVICE_ORDERTOTAL_FAIL,
  SERVICE_ORDERTOTAL_LOADING,
  SERVICE_ORDERTOTAL_RESET,
} from "./actionType";

export const resetServiceOrderTotalState = (actionType) => ({
  type: SERVICE_ORDERTOTAL_RESET,
  payload: { actionType },
});

export const getServiceOrderTotalSuccess = (actionType, data) => ({
  type: GET_SERVICE_ORDERTOTAL_SUCCESS,
  payload: { actionType, data },
});

export const getServiceOrderTotalFail = (actionType, error) => ({
  type: GET_SERVICE_ORDERTOTAL_FAIL,
  payload: { actionType, error },
});

export const getServiceOrderTotal = data => ({
  type: GET_SERVICE_ORDERTOTAL,
  payload: data,
});

export const getServiceOrderTotalLoading = () => ({
  type: SERVICE_ORDERTOTAL_LOADING
});

export const deleteServiceOrderTotal = data => ({
  type: DELETE_SERVICE_ORDERTOTAL,
  payload: data,
});

export const deleteServiceOrderTotalSuccess = data => ({
  type: DELETE_SERVICE_ORDERTOTAL_SUCCESS,
  payload: data,
});

export const deleteServiceOrderTotalFail = error => ({
  type: DELETE_SERVICE_ORDERTOTAL_FAIL,
  payload: error,
});

export const updateServiceOrderTotal = data => ({
  type: UPDATE_SERVICE_ORDERTOTAL,
  payload: data,
});

export const updateServiceOrderTotalFail = error => ({
  type: UPDATE_SERVICE_ORDERTOTAL_FAIL,
  payload: error,
});

export const updateServiceOrderTotalSuccess = data => ({
  type: UPDATE_SERVICE_ORDERTOTAL_SUCCESS,
  payload: data,
});

export const addNewServiceOrderTotal = data => ({
  type: ADD_NEW_SERVICE_ORDERTOTAL,
  payload: data,
});

export const addServiceOrderTotalSuccess = data => ({
  type: ADD_SERVICE_ORDERTOTAL_SUCCESS,
  payload: data,
});

export const addServiceOrderTotalFail = error => ({
  type: ADD_SERVICE_ORDERTOTAL_FAIL,
  payload: error,
});