export const WARANTY_LOADING = "WARANTY_LOADING";
export const WARANTY_RESET = "WARANTY_RESET";
export const GET_WARANTY = "GET_WARANTY";
export const GET_WARANTY_SUCCESS = "GET_WARANTY_SUCCESS";
export const GET_WARANTY_FAIL = "GET_WARANTY_FAIL";
export const DELETE_WARANTY = "DELETE_WARANTY";
export const DELETE_WARANTY_SUCCESS = "DELETE_WARANTY_SUCCESS";
export const DELETE_WARANTY_FAIL = "DELETE_WARANTY_FAIL";
export const UPDATE_WARANTY = "UPDATE_WARANTY";
export const UPDATE_WARANTY_SUCCESS = "UPDATE_WARANTY_SUCCESS";
export const UPDATE_WARANTY_FAIL = "UPDATE_WARANTY_FAIL";
export const ADD_NEW_WARANTY = "ADD_NEW_WARANTY";
export const ADD_WARANTY_SUCCESS = "ADD_WARANTY_SUCCESS";
export const ADD_WARANTY_FAIL = "ADD_WARANTY_FAIL";
