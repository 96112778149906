import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_SERVICE_ORDER,
  DELETE_SERVICE_ORDER,
  UPDATE_SERVICE_ORDER,
  ADD_NEW_SERVICE_ORDER,
} from "./actionType";

import {
  getServiceOrderSuccess,
  getServiceOrderFail,
  deleteServiceOrderSuccess,
  deleteServiceOrderFail,
  updateServiceOrderSuccess,
  updateServiceOrderFail,
  addServiceOrderSuccess,
  addServiceOrderFail,
} from "./action";

//Include Both Helper File with needed carriers
import {
  approveServiceOrderApi,
  getServiceOrderApi,
  updateServiceOrderApi,
  deleteServiceOrderApi,
  addNewServiceOrderApi
} from "../../helpers/backend_helper";

function* getServiceOrder({ payload: serviceOrder }) {
  try {
    const response = yield call(getServiceOrderApi, serviceOrder);
    yield put(getServiceOrderSuccess(GET_SERVICE_ORDER, response.data));
  } catch (error) {
    yield put(getServiceOrderFail(GET_SERVICE_ORDER, error));
  }
}

function* onUpdateServiceOrder({ payload: serviceOrder }) {
  try {
    if (serviceOrder.setApprove) {
      const response = yield call(approveServiceOrderApi, serviceOrder);
      yield put(updateServiceOrderSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateServiceOrderApi, serviceOrder);
      yield put(updateServiceOrderSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateServiceOrderFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteServiceOrder({ payload: serviceOrder }) {
  try {
    const response = yield call(deleteServiceOrderApi, serviceOrder);
    yield put(deleteServiceOrderSuccess({ serviceOrder, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteServiceOrderFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewServiceOrder({ payload: serviceOrder }) {
  try {
    const response = yield call(addNewServiceOrderApi, serviceOrder);
    yield put(addServiceOrderSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addServiceOrderFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetServiceOrder() {
  yield takeEvery(GET_SERVICE_ORDER, getServiceOrder);
}

export function* watchUpdateServiceOrder() {
  yield takeEvery(UPDATE_SERVICE_ORDER, onUpdateServiceOrder);
}

export function* watchDeleteServiceOrder() {
  yield takeEvery(DELETE_SERVICE_ORDER, onDeleteServiceOrder);
}

export function* watchAddNewServiceOrder() {
  yield takeEvery(ADD_NEW_SERVICE_ORDER, onAddNewServiceOrder);
}

function* serviceOrderSaga() {
  yield all([
    fork(watchGetServiceOrder),
    fork(watchDeleteServiceOrder),
    fork(watchUpdateServiceOrder),
    fork(watchAddNewServiceOrder)
  ]);
}

export default serviceOrderSaga;
