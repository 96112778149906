import {
  GET_SERVICELIST,
  GET_SERVICELIST_SUCCESS,
  GET_SERVICELIST_FAIL,
  SERVICELIST_LOADING,
  SERVICELIST_RESET,
} from "./actionType";

export const resetServiceListState = (actionType) => ({
  type: SERVICELIST_RESET,
  payload: { actionType },
});

export const getServiceListSuccess = (actionType, data) => ({
  type: GET_SERVICELIST_SUCCESS,
  payload: { actionType, data },
});

export const getServiceListFail = (actionType, error) => ({
  type: GET_SERVICELIST_FAIL,
  payload: { actionType, error },
});

export const getServiceList = data => ({
  type: GET_SERVICELIST,
  payload: data,
});

export const getServiceListLoading = () => ({
  type: SERVICELIST_LOADING
});