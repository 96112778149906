import {
  GET_DOCUMENT,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAIL,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAIL,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAIL,
  ADD_NEW_DOCUMENT,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_FAIL,
  DOCUMENT_LOADING,
  DOCUMENT_RESET,
} from "./actionType";

export const resetDocumentState = (actionType) => ({
  type: DOCUMENT_RESET,
  payload: { actionType },
});

export const getDocumentSuccess = (actionType, data) => ({
  type: GET_DOCUMENT_SUCCESS,
  payload: { actionType, data },
});

export const getDocumentFail = (actionType, error) => ({
  type: GET_DOCUMENT_FAIL,
  payload: { actionType, error },
});

export const getDocument = data => ({
  type: GET_DOCUMENT,
  payload: data,
});

export const getDocumentLoading = () => ({
  type: DOCUMENT_LOADING
});

export const deleteDocument = data => ({
  type: DELETE_DOCUMENT,
  payload: data,
});

export const deleteDocumentSuccess = data => ({
  type: DELETE_DOCUMENT_SUCCESS,
  payload: data,
});

export const deleteDocumentFail = error => ({
  type: DELETE_DOCUMENT_FAIL,
  payload: error,
});

export const updateDocument = data => ({
  type: UPDATE_DOCUMENT,
  payload: data,
});

export const updateDocumentFail = error => ({
  type: UPDATE_DOCUMENT_FAIL,
  payload: error,
});

export const updateDocumentSuccess = data => ({
  type: UPDATE_DOCUMENT_SUCCESS,
  payload: data,
});

export const addNewDocument = data => ({
  type: ADD_NEW_DOCUMENT,
  payload: data,
});

export const addDocumentSuccess = data => ({
  type: ADD_DOCUMENT_SUCCESS,
  payload: data,
});

export const addDocumentFail = error => ({
  type: ADD_DOCUMENT_FAIL,
  payload: error,
});