import {
  GET_ROLELIST,
  GET_ROLELIST_SUCCESS,
  GET_ROLELIST_FAIL,
  DELETE_ROLELIST,
  DELETE_ROLELIST_SUCCESS,
  DELETE_ROLELIST_FAIL,
  UPDATE_ROLELIST,
  UPDATE_ROLELIST_SUCCESS,
  UPDATE_ROLELIST_FAIL,
  ADD_NEW_ROLELIST,
  ADD_ROLELIST_SUCCESS,
  ADD_ROLELIST_FAIL,
  ROLELIST_LOADING,
  ROLELIST_RESET
} from "./actionType";

export const resetRoleListState = (actionType) => ({
  type: ROLELIST_RESET,
  payload: { actionType },
});

export const getRoleListSuccess = (actionType, data) => ({
  type: GET_ROLELIST_SUCCESS,
  payload: { actionType, data },
});

export const getRoleListFail = (actionType, error) => ({
  type: GET_ROLELIST_FAIL,
  payload: { actionType, error },
});

export const getRoleList = (data, path) => ({
  type: GET_ROLELIST,
  payload: data, path
});

export const getRoleListLoading = () => ({
  type: ROLELIST_LOADING
});

export const deleteRoleList = data => ({
  type: DELETE_ROLELIST,
  payload: data,
});

export const deleteRoleListSuccess = data => ({
  type: DELETE_ROLELIST_SUCCESS,
  payload: data,
});

export const deleteRoleListFail = error => ({
  type: DELETE_ROLELIST_FAIL,
  payload: error,
});

export const updateRoleList = data => ({
  type: UPDATE_ROLELIST,
  payload: data,
});

export const updateRoleListFail = error => ({
  type: UPDATE_ROLELIST_FAIL,
  payload: error,
});

export const updateRoleListSuccess = data => ({
  type: UPDATE_ROLELIST_SUCCESS,
  payload: data,
});

export const addNewRoleList = data => ({
  type: ADD_NEW_ROLELIST,
  payload: data,
});

export const addRoleListSuccess = data => ({
  type: ADD_ROLELIST_SUCCESS,
  payload: data,
});

export const addRoleListFail = error => ({
  type: ADD_ROLELIST_FAIL,
  payload: error,
});