export const TICKET_LOADING = "TICKET_LOADING";
export const TICKET_RESET = "TICKET_RESET";
export const GET_TICKET = "GET_TICKET";
export const GET_TICKET_SUCCESS = "GET_TICKET_SUCCESS";
export const GET_TICKET_FAIL = "GET_TICKET_FAIL";
export const DELETE_TICKET = "DELETE_TICKET"
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS"
export const DELETE_TICKET_FAIL = "DELETE_TICKET_FAIL"
export const UPDATE_TICKET = "UPDATE_TICKET"
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS"
export const UPDATE_TICKET_FAIL = "UPDATE_TICKET_FAIL"
export const ADD_NEW_TICKET = "ADD_NEW_TICKET"
export const ADD_TICKET_SUCCESS = "ADD_TICKET_SUCCESS"
export const ADD_TICKET_FAIL = "ADD_TICKET_FAIL"