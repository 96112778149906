import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GET_SERVICEPRICE, DELETE_SERVICEPRICE, UPDATE_SERVICEPRICE, ADD_NEW_SERVICEPRICE } from "./actionType";

import {
  getServicePriceSuccess,
  getServicePriceFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getServicePriceApi,
} from "../../helpers/backend_helper";

function* getServicePrice({ payload: servicePrice }) {
  try {
    const response = yield call(getServicePriceApi, servicePrice);
    yield put(getServicePriceSuccess(GET_SERVICEPRICE, response.data));
  } catch (error) {
    yield put(getServicePriceFail(GET_SERVICEPRICE, error));
  }
}

export function* watchGetServicePrice() {
  yield takeEvery(GET_SERVICEPRICE, getServicePrice);
}

function* servicePriceSaga() {
  yield all([
    fork(watchGetServicePrice),
  ]);
}

export default servicePriceSaga;
