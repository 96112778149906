import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Table, Button, Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, Modal, ModalHeader, Form, ModalBody, Label, Input, FormFeedback, Spinner, Collapse } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import ApproveModal from "../../Components/Common/ApproveModal";
import { isEmpty } from "lodash";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Dropzone from "react-dropzone";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Formik
import * as Yup from "yup";
import { useFormik, Field, FieldArray } from "formik";
import FormData from "form-data";
//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import { getClient, resetClientState } from "../../store/clients/action";
import { getBranch, resetBranchState } from "../../store/branch/action";
import { resetCompanyState, getCompany } from "../../store/company/action";
import { resetUnitState, getUnit as onGetUnits, addNewUnit as onAddNewUnit, updateUnit as onUpdateUnit, deleteUnit as onDeleteUnit, getUnitLoading, getUnit } from "../../store/units/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import defaultImg from "../../assets/images/upload.png";
import ModalInputFile from "../../Components/ModalInputFile";
import { api } from "../../config";

import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const authUser = JSON.parse(sessionStorage.getItem("authUser"));

const Units = (props) => {
  const { t } = props;
  const [logo, setLogo] = useState(defaultImg);
  const [unitImg, setUnitImg] = useState("");
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();

  const { companyLoading, companies, branchLoading, branches, clientLoading, clients, unitLoading, units, isUnitCreated, isUnitSuccess, loading, error } = useSelector((state) => ({
    branchLoading: state.Branch.loading,
    branches: state.Branch.branches,
    unitLoading: state.Units.loading,
    units: state.Units.units,
    isUnitCreated: state.Units.isUnitCreated,
    isUnitSuccess: state.Units.isUnitSuccess,
    loading: state.Units.loading,
    error: state.Units.error,
    clients: state.Clients.clients,
    clientLoading: state.Clients.loading,
    companies: state.Company.companies,
    companyLoading: state.Company.loading,
  }));

  const unitTypes = [
    {
      options: [
        { label: "Unit", value: "unit" },
        { label: "Executive", value: "executive" },
      ],
    },
  ];

  const defaultdate = () => {
    return moment(new Date()).tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };

  const [unitList, setUnitList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [parentUnitList, setParentUnitList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [unit, setUnit] = useState([]);
  const [info, setInfo] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);
  const [filterDate, setFilterDate] = useState(defaultdate());
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [isExecutive, setIsExecutive] = useState(false);

  const [open, setOpen] = useState("2");
  const toggleAccordion = (id) => {
    if (open === id) {
      setOpen(open);
    } else {
      setOpen(id);
    }
  };

  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: "1" },
        { label: "Activated", value: "2" },
      ],
    },
  ];
  useEffect(() => {
    dispatch(resetUnitState());
    dispatch(getUnit());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDelete = (unit) => {
    setUnit(unit);
    setDeleteModal(true);
  };

  const onClickApprove = (unit) => {
    setUnit(unit);
    setApproveModal(true);
  };

  const handleDeleteUnit = () => {
    if (unit) {
      dispatch(onDeleteUnit(unit));
      setDeleteModal(false);
    }
  };

  const handleApproveUnit = () => {
    if (unit) {
      unit.setApprove = true;
      dispatch(onUpdateUnit(unit));
      setApproveModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setUnitList(units);
  }, [units]);

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetUnitState());
      dispatch(onGetUnits());
      setIsSearch(true);
    }
  }, [dispatch, units, isSearch]);

  useEffect(() => {
    if (!isEmpty(units)) {
      setUnit(units);
      setUnitList(units);
      setInfo(units[units.length - 1]);
      setIsEdit(false);
    }
  }, [units]);

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredUnits = units;
      if (type !== "all") {
        filteredUnits = units.filter((unit) => unit.unit_status.name === type);
      }
      setUnitList(filteredUnits);
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setUnit(null);
      resetList();
      validation.resetForm();
      validation.setErrors({});
    } else {
      dispatch(getClient());
      setModal(true);
    }
    if (!isExecutive) {
      //validation.setva
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, isExecutive]);

  const toggleMedia = useCallback(() => {
    if (modalMedia) {
      setModalMedia(false);
    } else {
      setModalMedia(true);
    }
  }, [modalMedia]);

  const handleUnitClick = useCallback(
    (arg) => {
      const unit = arg;
      let address_unit = {};
      for (const address of unit.unit_address) {
        if (address.isPrimary) {
          address_unit = address;
        }
      }

      setUnitImg(unit.image ?? "");

      setUnit({
        id: unit.id,
        code: unit.code ?? "",
        zip: address_unit.code ?? "",
        name: unit.name ?? "",
        village: address_unit.village ?? "",
        district: address_unit.district ?? "",
        city: address_unit.city ?? "",
        province: address_unit.province ?? "",
        country: address_unit.country ?? "",
        parent:
          unit.unit_parent === null
            ? ""
            : {
                label: unit.unit_parent ? unit.unit_parent.name : "",
                value: unit.unit_parent ? unit.unit_parent.id : "",
              },
        company:
          unit.unit_company === null
            ? ""
            : {
                label: unit.unit_company ? unit.unit_company.name : "",
                value: unit.unit_company ? unit.unit_company.id : "",
              },
        client: {
          label: unit.unit_client ? unit.unit_client.name : "",
          value: unit.unit_client ? unit.unit_client.id : "",
        },
        branch: {
          label: unit.unit_branch ? unit.unit_branch.name : "",
          value: unit.unit_branch ? unit.unit_branch.id : "",
        },
        status:
          unit.unit_status === null
            ? ""
            : {
                label: unit.unit_status.name,
                value: unit.unit_status.id,
              },
        description: unit.description ?? "",
        address:
          unit.unit_address.length < 1
            ? ""
            : {
              label: `${address_unit.village} ${address_unit.district} ${address_unit.city} ${address_unit.province} ${address_unit.country}`,
              value: {
                description: `${address_unit.village} ${address_unit.district} ${address_unit.city} ${address_unit.province} ${address_unit.country}`,
                matched_substrings: [],
                place_id: "",
                reference: "",
                structured_formatting: {},
                terms: [],
                types: [],
              },
            },
        street: address_unit.description ?? "",
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="dataCheckbox form-check-input" value={cellProps.row.original.id} />;
        },
        id: "#",
      },
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleUnitClick(data);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </Link>
              {cellProps.row.original.statusActiveId === 1 ? (
                <button
                  className="btn-label btn btn-sm btn-soft-success fs-12 me-0"
                  onClick={() => {
                    const data = cellProps.row.original;
                    onClickApprove(data);
                  }}
                >
                  <i className="ri-checkbox-circle-line label-icon align-middle fs-16 me-1"></i>Approve
                </button>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        Header: `${t("Name")}`,
        filterable: true,
        accessor: "name",
      },
      {
        Header: `${t("Company")}`,
        accessor: "unit_company.name",
        filterable: true,
      },
      {
        Header: `${t("Client")}`,
        accessor: "unit_client.name",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "unit_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.row.original.statusId) {
            case 1:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
            case 2:
              return <span className="badge text-uppercase badge-soft-info">Activated</span>;
            default:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
          }
        },
      },
    ],
    [handleUnitClick, t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: unitList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable ? (column.isSorted ? (column.isSortedDesc ? " sorting_desc" : " sorting_asc") : " sort") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleFilter() {
    let startat = "";
    let endat = "";
    let datearr = filterDate.split(" to ");
    if (datearr[1]) {
      startat = datearr[0];
      endat = datearr[1];
    }

    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetUnits(param));
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      code: (unit && unit.code) || "",
      name: (unit && unit.name) || "",
      status: (unit && unit.status) || "",
      parent: (unit && unit.parent) || "",
      company: (unit && unit.company) || "",
      client: (unit && unit.client) || "",
      branch: (unit && unit.branch) || "",
      description: (unit && unit.description) || "",
      address: (unit && unit.address) || "",
      zip: (unit && unit.zip) || "",
      street: (unit && unit.street) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      code: Yup.string().required("Please Enter Code"),
      // zip: Yup.string().required("Please Enter Code Zip"),
      status: Yup.object().required("Please Select Status"),
      client: Yup.object().required("Please Select Client"),
      company: Yup.object().required("Please Select Company"),
      branch: Yup.object().required("Please Select Branch"),
      // address: Yup.object().required("Please Enter Address"),
      // street: Yup.string().required("Please Enter Street"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        let editDataSubmit = {};

        if (values.address.value && values.address.value.terms.length > 0) {
          editDataSubmit = {
            id: unit.id,
            name: values.name,
            statusId: values.status.value,
            parentId: values.parent.value,
            companyId: values.company.value,
            clientId: values.client.value,
            branchId: values.branch.value,
            description: values.description,
            code: values.code,
            zip: values.zip,
            village: values.address.value.terms[0].value,
            district: values.address.value.terms[values.address.value.terms.length - 4].value,
            city: values.address.value.terms[values.address.value.terms.length - 3].value,
            province: values.address.value.terms[values.address.value.terms.length - 2].value,
            country: values.address.value.terms[values.address.value.terms.length - 1].value,
            street: values.street,
          };

          if (values.address.value) {
            editDataSubmit = {
              ...editDataSubmit,
              village: values.address.value.terms[0].value,
              district: values.address.value.terms[values.address.value.terms.length - 4].value,
              city: values.address.value.terms[values.address.value.terms.length - 3].value,
              province: values.address.value.terms[values.address.value.terms.length - 2].value,
              country: values.address.value.terms[values.address.value.terms.length - 1].value,
            };
          }
        } else {
          editDataSubmit = {
            id: unit.id,
            name: values.name,
            statusId: values.status.value,
            parentId: values.parent.value,
            companyId: values.company.value,
            clientId: values.client.value,
            branchId: values.branch.value,
            description: values.description,
            code: values.code,
            zip: values.zip,
            street: values.street,
          };

          if (values.address.value) {
            editDataSubmit = {
              ...editDataSubmit,
              village: unit.village,
              district: unit.district,
              city: unit.city,
              province: unit.province,
              country: unit.country,
            };
          }
        }
        dispatch(onUpdateUnit(editDataSubmit));
      } else {
        var addDataSubmit = {
          name: values.name,
          statusId: values.status.value,
          parentId: values.parent.value,
          companyId: values.company.value,
          clientId: values.client.value,
          branchId: values.branch.value,
          description: values.description,
          code: values.code,
          zip: values.zip,
          street: values.street,
        };

        if (values.address.value) {
          addDataSubmit = {
            ...addDataSubmit,
            village: values.address.value.terms[0].value,
            district: values.address.value.terms[values.address.value.terms.length - 4].value,
            city: values.address.value.terms[values.address.value.terms.length - 3].value,
            province: values.address.value.terms[values.address.value.terms.length - 2].value,
            country: values.address.value.terms[values.address.value.terms.length - 1].value,
          };
        }

        dispatch(onAddNewUnit(addDataSubmit));
      }
      toggle();
      validation.resetForm();
    },
  });
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteUnit(element.value));
    });
    checkall.checked = false;
  };

  const handleSelectFile = (file) => {
    setUnitImg(file.filename);
    toggleMedia();
  };

  useEffect(() => {
    var data = [];
    var opt = [];
    companies.forEach((item, key) => {
      const name = item.name;
      const id = item.id;
      var obj = { label: name, value: id };
      opt.push(obj);
    });
    var option = { options: opt };
    data.push(option);

    if (companies.length > 0) {
      setCompanyList(data);
    }
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(clients)) {
      var data = [];
      var opt = [];
      clients.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setClientList(data);
    }
  }, [clients]);

  useEffect(() => {
    var data = [];
    var opt = [];
    branches.forEach((item, key) => {
      const name = item.name;
      const id = item.id;
      var obj = { label: name, value: id };
      opt.push(obj);
    });
    var option = { options: opt };
    data.push(option);

    if (branches.length > 0) {
      setBranchList(data);
    }
  }, [branches]);

  const handleGetCompany = (id) => {
    dispatch(resetCompanyState());
    dispatch(getCompany({ clientId: id }));
  };

  const handleGetBranch = (id) => {
    dispatch(resetBranchState());
    dispatch(getBranch({ companyId: id }));
  };

  const handleGetParentUnit = (id) => {
    var data = [];
    var opt = [];
    units.forEach((item, key) => {
      if (item.branchId === id) {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      }
    });
    var option = { options: opt };
    data.push(option);

    if (opt.length > 0) {
      setParentUnitList(data);
    } else {
      setParentUnitList([]);
    }
  };

  const resetList = () => {
    setCompanyList([]);
    setBranchList([]);
    setParentUnitList([]);
    dispatch(resetClientState());
    dispatch(resetCompanyState());
    dispatch(resetBranchState());
  };

  useEffect(() => {
    resetList();
    return () => {
      dispatch(resetUnitState());
      resetList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = `${t("Unit")} | Aptek`;

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={unitList} />
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteUnit} onCloseClick={() => setDeleteModal(false)} />
      <ApproveModal data={unit && unit.unitname ? unit.unitname : ""} show={approveModal} onApproveClick={handleApproveUnit} onCloseClick={() => setApproveModal(false)} />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={t("Unit")} pageTitle={t("Unit")} />
        <Row>
          <Col xxl={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    {t("Unit History")} &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color="primary" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className={"btn btn-primary add-btn"}
                      id="create-btn"
                      onClick={() => {
                        setIsEdit(false);
                        toggle();
                      }}
                    >
                      <i className="ri-building-line me-1 align-bottom fs-14"></i> {t("Add")}
                    </button>
                    {/* <button type="button" className="btn btn-success" onClick={() => setIsExportCSV(true)}>
                      <i className="ri-file-download-line me-1 align-bottom fs-14"></i> Export
                    </button>{" "}
                    <button className={userType === "executive" ? "btn btn-soft-danger" : roles && roles.split(",").indexOf("deleteUnit") !== -1 ? "btn btn-soft-danger" : "btn btn-soft-danger d-none"} onClick={() => setDeleteModalMulti(true)}>
                      <i className="ri-delete-bin-5-line me-1 align-bottom fs-15"></i> Hapus
                    </button> */}
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className=" ri-group-line me-1 align-bottom fs-14"></i> All Units
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("2", "Draft");
                        }}
                        href="#"
                      >
                        <i className="ri-information-line me-1 align-bottom fs-14"></i> Draft
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("3", "Activated");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Activated
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className="mb-3">
                    <CardBody className="border border-top-0 border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={1} className="col-xxl-1">
                            <div className="me-2 mb-3 col-12">
                              <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="search-box me-2 mb-3 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${count} Search...`}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col sm={6} className="col-xxl-3">
                            <div className="search-box me-2 mb-3 col-12">
                              <Flatpickr
                                className="form-control"
                                id="datepicker-publish-input"
                                placeholder="Select join date"
                                options={{
                                  mode: "range",
                                  dateFormat: "Y/m/d",
                                }}
                                onChange={(selectedDates, dateStr) => {
                                  setFilterDate(dateStr);
                                }}
                              />
                              <i className="bx bx-calendar-alt search-icon"></i>
                            </div>
                          </Col>

                          <Col className="col-xxl-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-light w-100"
                                onClick={() => {
                                  handleFilter();
                                }}
                              >
                                <i className="ri-search-line me-1 align-bottom fs-14"></i> {t("Search")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card mb-1">
                    <Table hover {...getTableProps()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroups.map((headerGroup) => (
                          <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={previousPage} disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      {" "}
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
                    </Col>
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={nextPage} disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal id="showModal" isOpen={modal} centered fullscreen={false} size="lg">
                  <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                      toggle();
                      validation.resetForm();
                      setUnitImg("");
                      validation.setErrors({});
                    }}
                  >
                    {!!isEdit ? `${t("Edit")} ${t("Unit")}` : `${t("Add")} ${t("Unit")}`}
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="client-field" className="form-label">
                            Client <span className="text-danger">*</span>&nbsp;
                            {clientLoading ? (
                              <Spinner color="primary" size="sm">
                                Loading...
                              </Spinner>
                            ) : (
                              ""
                            )}
                          </Label>
                          <Select
                            name="client"
                            value={validation.values.client}
                            placeholder="Select Client"
                            onChange={(e) => {
                              setCompanyList([]);
                              validation.setFieldValue("company", "");
                              setBranchList([]);
                              validation.setFieldValue("branch", "");
                              setParentUnitList([]);
                              validation.setFieldValue("parent", "");
                              validation.setFieldValue("client", e);
                              handleGetCompany(e.value);
                            }}
                            options={clientList}
                          ></Select>
                          {validation.touched.client && validation.errors.client ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.client}
                            </p>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="company-field" className="form-label">
                            Company <span className="text-danger">*</span>&nbsp;
                            {companyLoading ? (
                              <Spinner color="primary" size="sm">
                                Loading...
                              </Spinner>
                            ) : (
                              ""
                            )}
                          </Label>
                          <Select
                            name="company"
                            value={validation.values.company}
                            placeholder="Select Company"
                            isDisabled={companyList.length > 0 ? false : true}
                            onChange={(e) => {
                              setBranchList([]);
                              validation.setFieldValue("branch", "");
                              setParentUnitList([]);
                              validation.setFieldValue("parent", "");
                              validation.setFieldValue("company", e);
                              handleGetBranch(e.value);
                            }}
                            options={companyList}
                          ></Select>
                          {validation.touched.company && validation.errors.company ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.company}
                            </p>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="branch-field" className="form-label">
                            Branch <span className="text-danger">*</span>&nbsp;
                            {branchLoading ? (
                              <Spinner color="primary" size="sm">
                                Loading...
                              </Spinner>
                            ) : (
                              ""
                            )}
                          </Label>
                          <Select
                            name="branch"
                            value={validation.values.branch}
                            placeholder="Select Branch"
                            isDisabled={branchList.length > 0 ? false : true}
                            onChange={(e) => {
                              setParentUnitList([]);
                              validation.setFieldValue("parent", "");
                              validation.setFieldValue("branch", e);
                              handleGetParentUnit(e.value);
                            }}
                            options={branchList}
                          ></Select>
                          {validation.touched.branch && validation.errors.branch ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.branch}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="parent-field" className="form-label">
                            Parent (if any)&nbsp;
                            {unitLoading ? (
                              <Spinner color="primary" size="sm">
                                Loading...
                              </Spinner>
                            ) : (
                              ""
                            )}
                          </Label>
                          <Select
                            name="parent"
                            value={validation.values.parent}
                            placeholder="Select Parent"
                            isDisabled={parentUnitList.length > 0 ? false : true}
                            onChange={(e) => {
                              validation.setFieldValue("parent", e);
                            }}
                            options={parentUnitList}
                          ></Select>
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="name-field" className="form-label">
                            {t("Name")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="name" id="name-field" className="form-control" placeholder="Enter Name" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.name || ""} invalid={validation.touched.name && validation.errors.name ? true : false} />
                          {validation.touched.name && validation.errors.name ? <FormFeedback type="invalid">{validation.errors.name}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="code-field" className="form-label">
                            {t("Code")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="code" id="code-field" className="form-control" placeholder="Enter Code" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.code || ""} invalid={validation.touched.code && validation.errors.code ? true : false} />
                          {validation.touched.code && validation.errors.code ? <FormFeedback type="invalid">{validation.errors.code}</FormFeedback> : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="address-field" className="form-label">
                            {t("Address")}
                          </Label>
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyD0auKcsMNas3qbqi-BgF_s3UxwtQNP74Q"
                            apiOptions={{
                              region: "IDN",
                            }}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["idn"],
                              },
                              types: ["route"],
                            }}
                            selectProps={{
                              name: "address",
                              value: undefined,
                              defaultValue: validation.values.address,
                              onChange: (value) => {
                                validation.setFieldValue("address", value);
                              },
                            }}
                          />
                          {validation.touched.address && validation.errors.address ? <div className="invalid-feedback d-block">{validation.errors.address}</div> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="zip-field" className="form-label">
                            {t("Zip Code")}
                          </Label>
                          <Input name="zip" id="zip-field" className="form-control" placeholder="Enter Zip Code" type="number" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.zip || ""} invalid={validation.touched.zip && validation.errors.zip ? true : false} />
                          {validation.touched.zip && validation.errors.zip ? <FormFeedback type="invalid">{validation.errors.zip}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="street-field" className="form-label">
                            {t("Street")}
                          </Label>
                          <Input name="street" id="street-field" className="form-control" placeholder="Enter Street Name" type="textarea" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.street || ""} invalid={validation.touched.street && validation.errors.street ? true : false} />
                          {validation.touched.street && validation.errors.street ? <FormFeedback type="invalid">{validation.errors.street}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="active-status" className="form-label">
                            Status <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="status"
                            value={validation.values.status}
                            validate={{ required: { value: true } }}
                            placeholder="Select Status"
                            onChange={(e) => {
                              validation.setFieldValue("status", e);
                            }}
                            onInputChange={(e) => {
                              // handleUnit(e);
                            }}
                            options={statusActiveData}
                            invalid={validation.touched.status && validation.errors.status ? true : false}
                          ></Select>
                          {validation.touched.status && validation.errors.status ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.status}
                            </p>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="description-field" className="form-label">
                            {t("Description")}
                          </Label>
                          <Input name="description" id="description-field" className="form-control" placeholder="Enter Description" type="textarea" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.description || ""} invalid={validation.touched.description && validation.errors.description ? true : false} />
                          {validation.touched.description && validation.errors.description ? <FormFeedback type="invalid">{validation.errors.description}</FormFeedback> : null}
                        </div>
                      </Col>
                    </Row>
                  </ModalBody>
                  <div className="modal-footer mb3">
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          setModal(false);
                          validation.resetForm();
                          validation.setErrors({});
                          setUnitImg("");
                        }}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        onClick={() => {
                          validation.handleSubmit();
                        }}
                        type="submit"
                        className="btn btn-success"
                      >
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">{t("Save")}</span>
                          {loading ? (
                            <Spinner size="sm" className="flex-shrink-0" role="status">
                              Loading...
                            </Spinner>
                          ) : (
                            ""
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </Modal>
                <ModalInputFile onSelect={handleSelectFile} loading={loading} modal={modalMedia} toggle={toggleMedia} />
                {/* <Modal id="showModalMedia" isOpen={modalMedia} centered size="xl">
                  <ModalHeader className="bg-light p-3" toggle={toggleMedia}>
                    Media File
                  </ModalHeader>
                  <ModalBody>
                    <div className="row">
                      <div className="col-lg-8">
                        <di>
                          <div className="search-box me-2 mb-3 d-inline-block col-12">
                            <input
                              onChange={(e) => {
                                // setSearchValue(e.target.value);
                                // onChange(e.target.value);
                              }}
                              id="search-bar-0"
                              type="text"
                              className="form-control search"
                              placeholder={`${199} Search...`}
                              value={""}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </di>
                        <div style={{ maxHeight: "500px", overflowY: "scroll", overflowX: "hidden" }}>
                          <Card className="mt-1 mb-0 me-2 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="py-2 px-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <div className="me-2">
                                    <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" />
                                  </div>
                                  <div>
                                    <p className="mb-0">
                                      <strong>{"f.formattedSize"}</strong>
                                    </p>
                                    <p className="text-muted font-weight-bold">{"f.name"}</p>
                                  </div>
                                </div>
                                <div>
                                  <Link
                                    to="#"
                                    className={"btn-icon btn btn-sm btn-soft-danger remove-item-btn"}
                                    onClick={() => {
                                      // const unitData = cellProps.row.original;
                                      // onClickDelete(unitData);
                                    }}
                                  >
                                    <i className=" ri-delete-bin-line fs-15"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-3">
                          <Dropzone
                            multiple={true}
                            onDrop={(acceptedFiles) => {
                              // handleAcceptedFiles(acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone dz-clickable" style={{ cursor: "pointer", height: "140px" }} {...getRootProps({ className: "dropzone" })}>
                                <div className="dz-message needsclick">
                                  <div>
                                    <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                    <input {...getInputProps()} />
                                  </div>
                                  <h5>Drop employee document here.</h5>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <div className="modal-footer">
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          toggleMedia();
                        }}
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-success" disabled={loading}>
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">{"Tambah Karyawan"}</span>
                          {loading && (
                            <Spinner size="sm" className="flex-shrink-0" role="status">
                              Loading...
                            </Spinner>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </Modal> */}
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Units.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Units));
// export default Units;
