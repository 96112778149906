export const APP_LOADING = "APP_LOADING";
export const APP_RESET = "APP_RESET";
export const GET_APP = "GET_APP";
export const GET_APP_SUCCESS = "GET_APP_SUCCESS";
export const GET_APP_FAIL = "GET_APP_FAIL";
export const DELETE_APP = "DELETE_APP"
export const DELETE_APP_SUCCESS = "DELETE_APP_SUCCESS"
export const DELETE_APP_FAIL = "DELETE_APP_FAIL"
export const UPDATE_APP = "UPDATE_APP"
export const UPDATE_APP_SUCCESS = "UPDATE_APP_SUCCESS"
export const UPDATE_APP_FAIL = "UPDATE_APP_FAIL"
export const ADD_NEW_APP = "ADD_NEW_APP"
export const ADD_APP_SUCCESS = "ADD_APP_SUCCESS"
export const ADD_APP_FAIL = "ADD_APP_FAIL"