import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";

function PrivacyPolicy() {
  return (
    <div>
      <div className="w-100 bg-primary" style={{ height: "80px" }}>
        <div className="d-flex justify-content-center align-items-center pt-4">
          <h3 className="text-white">Kebijakan Privasi</h3>
        </div>
      </div>
      <div className="container mt-3">
        <p>Kami menyadari dan memahami bahwa data pribadi sangat rahasia, dengan itu kami berusaha sebaik mungkin untuk menjaga kenyamanan dan rasa aman Anda sebagai pengguna layanan kami.</p>
        <p>Dalam Kebijakan Privasi ini kami menggambarkan praktek kami terkait dengan data pribadi yang terkumpul melalui layanan kami. </p>
        <p>Informasi mengenai data pribadi seperti nama, alamat surat menyurat, telepon dan alamat email akan dipakai sebagai syarat registrasi dan menerima layanan yang kami sediakan, kami juga akan memberikan informasi mengenai produk yang terkait dengan layanan yang kami miliki. Informasi lainnya adalah terkait layanan yang dipakai. </p>
        <p>Kebijakan privasi ini hanya berlaku pada situs ketoko.co.id dan seluruh subdomain dari situs ketoko.co.id </p>
        <p className="fw-bold fs-5">Pengidentifikasi Alamat IP, Cookies & Mobile</p>
        <p>Kami dapat mengumpulkan informasi tertentu melalui teknologi seperti cookies, pixels dan local storage (seperti pada browser atau perangkat Anda). Cookies adalah data teks kecil yang menyimpan informasi pada komputer Anda, telepon seluler atau perangkat lainnya secara lokal. Pixels adalah gambar kecil yang merupakan bagian dari kode dalam halaman web yang melakukan hal-hal seperti mengizinkan server lain untuk mengukur pengunjung dari suatu halaman web dan seringkali digunakan sehubungan dengan suatu kode untuk melacak jika dan saat (dan pada halaman mana) suatu pixel dimuat untuk menunjukkan bahwa pengguna telah menggunakan layar khusus atau bagian dari suatu layar. </p>
        <p>Dengan bantuan cookies, server web dapat menyimpan, sebagai contoh, preferensi dan pengaturan pada komputer, telepon seluler atau perangkat lain pengguna yang kemudian secara otomatis akan terbuka kembali pada kunjungan selanjutnya. Atau sebaliknya, cookies digunakan, antara lain, untuk membuat penggunaan dari situs menjadi lebih mudah digunakan pengguna sehingga, sebagai contoh, Anda tidak harus mengulangi proses log-in pada saat Anda berkunjung kembali. Persistent Cookies berada pada komputer Anda untuk waktu yang lebih lama, sedangkan Session cookies akan secara otomatis terhapus pada saat jendela browser Anda ditutup. </p>
        <p className="fw-bold fs-5">Kontrol Pengguna </p>
        <p>Apabila Anda telah membuat akun atau mendaftar kepada kami maka anda akan memiliki akun. Anda dapat mengakses, memperbaiki atau menghapus informasi yang Anda sediakan, anda dapat menghubungi kami untuk pemblokiran dan penghapusan akun jika dalam keadaan darurat. Anda bertanggung jawab untuk menjaga data yang Anda sediakan agar tetap akurat dan rahasia. </p>
        <p>Contoh pada Cookies: Kebanyakan dari web browser desktop dan mobile yang utama (contoh : Safari, Firefox, Internet Explorer, Chrome, Opera, Edge) menyediakan kendali yang memungkinkan Anda untuk membatasi atau memblokir pengaturan dari cookies dalam sistem Anda. Sebagaimana telah kami jelaskan di atas, Anda dapat menghilangkan cookies dalam browser Anda baik untuk domain pihak pertama (situs yang secara aktif Anda kunjungi) atau domain pihak ketiga (perusahaan selain dengan yang Anda secara langsung kunjungi dalam web) dapat menyebabkan penurunan fungsi pada keadaan tertentu. </p>
        <p className="fw-bold fs-5">Keamanan </p>
        <p>Seluruh informasi yang kami kumpulkan terlindungi dengan cara teknis dan prosedur keamanan yang wajar untuk mencegah akses atau penggunaan data yang tidak sah. </p>
        <p className="fw-bold fs-5">Perubahan Atas Kebijakan Ini </p>
        <p>Kami akan selalu memperbaharui kebijakan privasi ini. Anda harus memeriksa halaman ini dari waktu ke waktu untuk mengetahui setiap perubahan. Kami dapat, namun tidak berkewajiban untuk, memberitahu Anda mengenai perubahan kebijakan privasi ini. </p>
        <p className="fw-bold fs-5">Perubahan Atas Kebijakan Ini </p>
        <p>Dengan menggunakan layanan kami, menegaskan bahwa Anda setuju atas pengumpulan dan pemakaian informasi yang tercantum pada Kebijakan Privasi ini. </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
