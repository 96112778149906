/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { Collapse, Container, NavbarToggler, NavLink, Modal, ModalBody, Button, Input, FormFeedback, Form, Alert, Spinner } from "reactstrap";
import Scrollspy from "react-scrollspy";
import { Link, useNavigate, useRoutes } from "react-router-dom";

// Import Images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser, apiError, resetLoginFlag } from "../../store/actions";
import { registerUser, resetRegisterFlag } from "../../store/auth/register/actions";
import { resetServiceState, getService } from "../../store/service/action";
import { resetCompanyState, getCompany } from "../../store/company/action";
// import { resetProductState, getProduct } from "../../store/product/action";
// import { resetClientState, getClient } from "../../store/client/action";
import { useSelector, useDispatch } from "react-redux";
import withRouter from "../../Components/Common/withRouter";
import { MegaMenu } from "primereact/megamenu";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import LanguageDropdown from "../../Components/Common/LanguageDropdown";

const Navbar = (props) => {
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [navClass, setnavClass] = useState("");
  const history = useNavigate();
  const toggle = () => setisOpenMenu(!isOpenMenu);
  const [isSearch, setIsSearch] = useState(false);
  const [megaMenuItem, setMegaMenuItem] = useState([]);

  // const router = useRoutes();

  const services = [
    {
      id: 2,
      name: "River Monitoring System",
      code: "RMS-01",
      description: "",
      createdat: "2023-11-17T02:54:57.268Z",
      updatedat: "2023-11-17T02:54:57.268Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 3,
      name: "Smart Water Monitoring",
      code: "SWM-01",
      description: "",
      createdat: "2023-11-17T02:56:01.830Z",
      updatedat: "2023-11-17T02:56:01.830Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 4,
      name: "Energy Monitoring System",
      code: "EMS-01",
      description: "",
      createdat: "2023-11-17T02:58:41.452Z",
      updatedat: "2023-11-17T02:58:41.452Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 5,
      name: "Smart Weighing",
      code: "SW-01",
      description: "",
      createdat: "2023-11-17T03:01:45.309Z",
      updatedat: "2023-11-17T03:01:45.309Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 1,
      name: "Fuel Management System",
      code: "FMS-01",
      description: "",
      createdat: "2023-11-17T02:30:12.437Z",
      updatedat: "2023-11-17T03:04:05.676Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 6,
      name: "Smart Kereta",
      code: "SK-01",
      description: "",
      createdat: "2023-11-17T03:04:19.474Z",
      updatedat: "2023-11-17T03:04:19.474Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 7,
      name: "Temperature Monitoring",
      code: "TM-01",
      description: "",
      createdat: "2023-11-17T03:04:54.645Z",
      updatedat: "2023-11-17T03:04:54.645Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 8,
      name: "GPS Tracking",
      code: "GT-01",
      description: "",
      createdat: "2023-11-17T03:09:35.856Z",
      updatedat: "2023-11-17T03:09:35.856Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 9,
      name: "CEMS",
      code: "CEMS-01",
      description: "",
      createdat: "2023-11-17T03:09:55.306Z",
      updatedat: "2023-11-17T03:09:55.306Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 10,
      name: "Power Monitoring",
      code: "PM-01",
      description: "",
      createdat: "2023-11-17T03:10:32.983Z",
      updatedat: "2023-11-17T03:10:32.983Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 12,
      name: "ERP Product Management",
      code: "PRM",
      description: "",
      createdat: "2023-11-27T07:20:32.326Z",
      updatedat: "2023-11-27T07:20:44.009Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 11,
      name: "ERP Human Resources Management",
      code: "HRM",
      description: "",
      createdat: "2023-11-27T07:19:30.666Z",
      updatedat: "2023-11-27T07:21:03.944Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 13,
      name: "ERP Inventory Management",
      code: "INV",
      description: "",
      createdat: "2023-11-27T07:23:35.073Z",
      updatedat: "2023-11-27T07:23:35.073Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 14,
      name: "ERP Asset Management",
      code: "AST",
      description: "",
      createdat: "2023-11-27T08:28:13.447Z",
      updatedat: "2023-12-05T07:20:03.126Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 15,
      name: "ERP Procurement",
      code: "PRC",
      description: "",
      createdat: "2023-11-27T08:31:10.256Z",
      updatedat: "2023-12-05T07:20:50.285Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 16,
      name: "Engine Monitoring",
      code: "EMG-01",
      description: "",
      createdat: "2023-12-15T02:40:49.641Z",
      updatedat: "2023-12-15T02:40:49.641Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 17,
      name: "Vessel Monitoring System",
      code: "VMS",
      description: "Monitoring Transportasi Laut",
      createdat: "2024-02-15T12:36:29.113Z",
      updatedat: "2024-02-15T12:36:29.113Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
    {
      id: 18,
      name: "INTANK (Intelegent Tank Monitoring System)",
      code: "intank",
      description: "Intelegent Tank Monitoring",
      createdat: "2024-03-01T03:57:18.628Z",
      updatedat: "2024-03-01T03:57:18.628Z",
      statusId: 2,
      service_status: { id: 2, name: "Activated" },
    },
  ];

  const items = [
    {
      id: "product",
      label: "Product",
    },
    {
      id: "service",
      label: "Service",
      items: [
        [
          {
            label: "Service 1",
            items: [{ label: "Service 1.1" }, { label: "Service 1.2" }],
          },
          {
            label: "Service 2",
            items: [{ label: "Service 2.1" }, { label: "Service 2.2" }],
          },
        ],
        [
          {
            label: "Service 3",
            items: [{ label: "Service 3.1" }, { label: "Service 3.2" }],
          },
          {
            label: "Service 4",
            items: [{ label: "Service 4.1" }, { label: "Service 4.2" }],
          },
        ],
        [
          {
            label: "Service 5",
            items: [{ label: "Service 5.1" }, { label: "Service 5.2" }],
          },
          {
            label: "Service 6",
            items: [{ label: "Service 6.1" }, { label: "Service 6.2" }],
          },
        ],
      ],
    },
    {
      id: "company",
      label: "Company",
      items: [
        [
          {
            label: "Company 1",
            items: [{ label: "Company 1.1" }, { label: "Company 1.2" }],
          },
          {
            label: "Company 2",
            items: [{ label: "Company 2.1" }, { label: "Company 2.2" }],
          },
        ],
        [
          {
            label: "Company 3",
            items: [{ label: "Company 3.1" }, { label: "Company 3.2" }],
          },
          {
            label: "Company 4",
            items: [{ label: "Company 4.1" }, { label: "Company 4.2" }],
          },
        ],
      ],
    },
    {
      id: "partners",
      label: "Partners",
      items: [
        [
          {
            label: "Partner 1",
            items: [{ label: "Partner 1.1" }, { label: "Partner 1.2" }],
          },
          {
            label: "Partner 2",
            items: [{ label: "Partner 2.1" }, { label: "Partner 2.2" }],
          },
          {
            label: "Partner 3",
            items: [{ label: "Partner 3.1" }, { label: "Partner 3.2" }],
          },
        ],
        [
          {
            label: "Technology 4",
            items: [{ label: "Partner 4.1" }, { label: "Partner 4.2" }],
          },
        ],
      ],
    },
    {
      id: "privacy-policy",
      label: "Privacy Policy",
      url: "/privacy-policy",
      // command: (e) => {
      //   console.log("okkkkkkkkkkkkkkkkkkkk");
      // },
    },
  ];

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass("is-sticky");
    } else {
      setnavClass("");
    }
  };

  const dispatch = useDispatch();

  const { user, loading, success } = useSelector((state) => ({
    user: state.Account.user,
    loading: state.Login.loading,
    success: state.Register.success,
  }));
  const [userLogin, setUserLogin] = useState([]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      clientAppId: 3,
      username: userLogin.usename || "",
      password: userLogin.password || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });

  const validation1 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      username: "",
      password: "",
      phone: "",
      retype_password: "",
      client_name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
      email: Yup.string().required("Please Enter Your Email"),
      phone: Yup.string().required("Please Enter Your Phone"),
      client_name: Yup.string().required("Please Enter Client Name"),
      retype_password: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords does not match")
        .required("Please Enter Your Retype Password"),
    }),
    onSubmit: (values) => {
      const dataSumbit = {
        name: values.name,
        username: values.username,
        password: values.password,
        email: values.email,
        phone: values.phone,
        client_name: values.client_name,
      };
      dispatch(registerUser(dataSumbit));
    },
  });

  const { error } = useSelector((state) => ({
    error: state.Login.error,
  }));

  const [modal_loginModals, setmodal_loginModals] = useState(false);
  function tog_loginModals() {
    setmodal_loginModals(!modal_loginModals);
  }

  const [modal_regisModals, setmodal_regisModals] = useState(false);
  function tog_regisModals() {
    setmodal_regisModals(!modal_regisModals);
  }

  useEffect(() => {
    return () => {
      dispatch(resetRegisterFlag());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      history("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  // useEffect(() => {
  //   if (!isSearch) {
  //     dispatch(resetServiceState());
  //     dispatch(getService());
  //   }
  // }, [isSearch, dispatch]);

  // useEffect(() => {
  //   if (!isSearch && services) {
  //     let item = [];
  //     let items = [];
  //     let itemss = [];
  //     services.forEach((service) => {
  //       items.push([{ label: service.name }]);
  //     });
  //     items.push([{ items: [itemss] }]);
  //     // item.push({ label: "Services", items: items });
  //     console.log(item);
  //     setMegaMenuItem(item);
  //     setIsSearch(true);
  //   }
  // }, [services]);

  const start = <img alt="logo" src={logodark} height="37" className="ms-5 me-5"></img>;
  const end = (
    <Fragment>
      <div className="d-flex flex-row ">
        <div>
          <LanguageDropdown />
        </div>
        <div>
          <Button color="secondary" onClick={() => tog_loginModals()} data-bs-toggle="modal" data-bs-target="#loginModals" className="rounded-pill me-2 ms-2">
            Contact
          </Button>
          <Button outline color="primary" onClick={() => tog_loginModals()} data-bs-toggle="modal" data-bs-target="#loginModals" className="rounded-pill me-2">
            <div className="d-flex flex-row">
              <div>
                <i className="ri-play-circle-line text-warning"></i>
              </div>
              <div className="ms-1 ">Online Demo</div>
            </div>
          </Button>
          {/* <Button
            color='none'
            onClick={() => tog_loginModals()}
            data-bs-toggle='modal'
            data-bs-target='#loginModals'
            className='rounded-pill me-2'>
            Sign In
          </Button>
          <Button
            color='primary'
            onClick={() => tog_regisModals()}
            data-bs-toggle='modal'
            data-bs-target='#regisModals'
            className='rounded-pill me-2'>
            Sign Up
          </Button> */}
        </div>
      </div>
      <Modal
        id="loginModals"
        tabIndex="-1"
        isOpen={modal_loginModals}
        toggle={() => {
          tog_loginModals();
        }}
        centered
      >
        <div className="modal-content border-0 overflow-hidden">
          <ModalBody className="login-modal p-5">
            <h5 className="text-white fs-20">Login</h5>
            {/* <p className="text-white-50 mb-4">Don't have an account? <Link to="/#" className="text-white">Sign Up.</Link></p> */}
            <div className="vstack gap-2 justify-content-center">
              <button className="btn btn-light">
                <i className="ri-google-fill align-bottom text-danger"></i> Google
              </button>
              <button className="btn btn-info">
                <i className="ri-facebook-fill align-bottom"></i> Facebook
              </button>
            </div>
          </ModalBody>
          <ModalBody className="p-5">
            <h5 className="mb-3">Login with Email</h5>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              action="#"
            >
              <div className="mb-2">
                <Input name="username" className="form-control" placeholder="Enter Username" type="text" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.username || ""} invalid={validation.touched.username && validation.errors.username ? true : false} />
                {validation.touched.username && validation.errors.username ? <FormFeedback type="invalid">{validation.errors.username}</FormFeedback> : null}
              </div>
              <div className="mb-3">
                <Input name="password" value={validation.values.password || ""} type="password" className="form-control pe-5" placeholder="Enter Password" onChange={validation.handleChange} onBlur={validation.handleBlur} invalid={validation.touched.password && validation.errors.password ? true : false} />
                {validation.touched.password && validation.errors.password ? <FormFeedback type="invalid">{validation.errors.password}</FormFeedback> : null}
                <div className="mt-1 text-end">
                  <Link to="/auth-pass-reset-basic">Forgot password ?</Link>
                </div>
              </div>
              <button type="submit" className="btn btn-primary w-100">
                Submit
              </button>
              {loading ? <Spinner size="sm" className="flex-shrink-0 ms-2" role="status"></Spinner> : ""}
            </Form>
          </ModalBody>
        </div>
      </Modal>
      <Modal
        id="regisModals"
        tabIndex="-1"
        isOpen={modal_regisModals}
        toggle={() => {
          tog_regisModals();
        }}
        centered
      >
        <div className="modal-content border-0 overflow-hidden">
          <ModalBody className="login-modal p-5">
            <h5 className="text-white fs-20">Register</h5>
            {/* <p className="text-white-50 mb-4">Have an account? <Link to="/#" className="text-white">Sign Up.</Link></p> */}
            <div className="vstack gap-2 justify-content-center">
              <button className="btn btn-light">
                <i className="ri-google-fill align-bottom text-danger"></i> Google
              </button>
              <button className="btn btn-info">
                <i className="ri-facebook-fill align-bottom"></i> Facebook
              </button>
            </div>
          </ModalBody>
          <ModalBody className="p-5">
            <h5 className="mb-3">Register with Email</h5>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation1.handleSubmit();
                return false;
              }}
              action="#"
            >
              <div className="mb-2">
                <Input name="client_name" id="clientclient_name-field" className="form-control" placeholder="Enter Client Name" type="text" validate={{ required: { value: true } }} onChange={validation1.handleChange} onBlur={validation1.handleBlur} value={validation1.values.client_name || ""} invalid={validation1.touched.client_name && validation1.errors.client_name ? true : false} />
                {validation1.touched.client_name && validation1.errors.client_name ? <FormFeedback type="invalid">{validation1.errors.client_name}</FormFeedback> : null}
              </div>
              <div className="mb-2">
                <Input name="name" type="text" placeholder="Enter name" onChange={validation1.handleChange} onBlur={validation1.handleBlur} value={validation1.values.name || ""} invalid={validation1.touched.name && validation1.errors.name ? true : false} />
                {validation1.touched.name && validation1.errors.name ? (
                  <FormFeedback type="invalid">
                    <div>{validation1.errors.name}</div>
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Input id="email" name="email" className="form-control" placeholder="Enter email address" type="email" onChange={validation1.handleChange} onBlur={validation1.handleBlur} value={validation1.values.email || ""} invalid={validation1.touched.email && validation1.errors.email ? true : false} />
                {validation1.touched.email && validation1.errors.email ? (
                  <FormFeedback type="invalid">
                    <div>{validation1.errors.email}</div>
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-2">
                <Input name="phone" type="text" placeholder="Phone" onChange={validation1.handleChange} onBlur={validation1.handleBlur} value={validation1.values.phone || ""} invalid={validation1.touched.phone && validation1.errors.phone ? true : false} />
                {validation1.touched.phone && validation1.errors.phone ? (
                  <FormFeedback type="invalid">
                    <div>{validation1.errors.phone}</div>
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-2">
                <Input name="username" type="text" placeholder="Enter username" onChange={validation1.handleChange} onBlur={validation1.handleBlur} value={validation1.values.username || ""} invalid={validation1.touched.username && validation1.errors.username ? true : false} />
                {validation1.touched.username && validation1.errors.username ? (
                  <FormFeedback type="invalid">
                    <div>{validation1.errors.username}</div>
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Input name="password" type="password" placeholder="Enter Password" onChange={validation1.handleChange} onBlur={validation1.handleBlur} value={validation1.values.password || ""} invalid={validation1.touched.password && validation1.errors.password ? true : false} />
                {validation1.touched.password && validation1.errors.password ? (
                  <FormFeedback type="invalid">
                    <div>{validation1.errors.password}</div>
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Input name="retype_password" type="password" placeholder="Enter Retype Password" onChange={validation1.handleChange} onBlur={validation1.handleBlur} invalid={validation1.touched.retype_password && validation1.errors.retype_password ? true : false} />
                {validation1.touched.retype_password && validation1.errors.retype_password ? (
                  <FormFeedback type="invalid">
                    <div>{validation1.errors.retype_password}</div>
                  </FormFeedback>
                ) : null}
              </div>
              <button type="submit" className="btn btn-primary w-100">
                Submit
              </button>
            </Form>
          </ModalBody>
        </div>
      </Modal>
    </Fragment>
  );

  return (
    <React.Fragment>
      <div>
        <div className="card fixed-top">
          <MegaMenu model={items} orientation="horizontal" start={start} end={end} className="p-2" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Navbar);
