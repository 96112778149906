import {
  GET_WARANTY,
  GET_WARANTY_SUCCESS,
  GET_WARANTY_FAIL,
  DELETE_WARANTY,
  DELETE_WARANTY_SUCCESS,
  DELETE_WARANTY_FAIL,
  UPDATE_WARANTY,
  UPDATE_WARANTY_SUCCESS,
  UPDATE_WARANTY_FAIL,
  ADD_NEW_WARANTY,
  ADD_WARANTY_SUCCESS,
  ADD_WARANTY_FAIL,
  WARANTY_LOADING,
  WARANTY_RESET,
} from "./actionType";

export const resetWarantyState = (actionType) => ({
  type: WARANTY_RESET,
  payload: { actionType },
});

export const getWarantySuccess = (actionType, data) => ({
  type: GET_WARANTY_SUCCESS,
  payload: { actionType, data },
});

export const getWarantyFail = (actionType, error) => ({
  type: GET_WARANTY_FAIL,
  payload: { actionType, error },
});

export const getWaranty = (data) => ({
  type: GET_WARANTY,
  payload: data,
});

export const getWarantyLoading = () => ({
  type: WARANTY_LOADING,
});

export const deleteWaranty = (data) => ({
  type: DELETE_WARANTY,
  payload: data,
});

export const deleteWarantySuccess = (data) => ({
  type: DELETE_WARANTY_SUCCESS,
  payload: data,
});

export const deleteWarantyFail = (error) => ({
  type: DELETE_WARANTY_FAIL,
  payload: error,
});

export const updateWaranty = (data) => ({
  type: UPDATE_WARANTY,
  payload: data,
});

export const updateWarantyFail = (error) => ({
  type: UPDATE_WARANTY_FAIL,
  payload: error,
});

export const updateWarantySuccess = (data) => ({
  type: UPDATE_WARANTY_SUCCESS,
  payload: data,
});

export const addNewWaranty = (data) => ({
  type: ADD_NEW_WARANTY,
  payload: data,
});

export const addWarantySuccess = (data) => ({
  type: ADD_WARANTY_SUCCESS,
  payload: data,
});

export const addWarantyFail = (error) => ({
  type: ADD_WARANTY_FAIL,
  payload: error,
});
