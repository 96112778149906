import {
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  ADD_NEW_CLIENT,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAIL,
  CLIENT_LOADING,
  CLIENT_RESET,
} from "./actionType";

export const resetClientState = (actionType) => ({
  type: CLIENT_RESET,
  payload: { actionType },
});

export const getClientSuccess = (actionType, data) => ({
  type: GET_CLIENT_SUCCESS,
  payload: { actionType, data },
});

export const getClientFail = (actionType, error) => ({
  type: GET_CLIENT_FAIL,
  payload: { actionType, error },
});

export const getClient = data => ({
  type: GET_CLIENT,
  payload: data,
});

export const getClientLoading = () => ({
  type: CLIENT_LOADING
});

export const deleteClient = data => ({
  type: DELETE_CLIENT,
  payload: data,
});

export const deleteClientSuccess = data => ({
  type: DELETE_CLIENT_SUCCESS,
  payload: data,
});

export const deleteClientFail = error => ({
  type: DELETE_CLIENT_FAIL,
  payload: error,
});

export const updateClient = data => ({
  type: UPDATE_CLIENT,
  payload: data,
});

export const updateClientFail = error => ({
  type: UPDATE_CLIENT_FAIL,
  payload: error,
});

export const updateClientSuccess = data => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: data,
});

export const addNewClient = data => ({
  type: ADD_NEW_CLIENT,
  payload: data,
});

export const addClientSuccess = data => ({
  type: ADD_CLIENT_SUCCESS,
  payload: data,
});

export const addClientFail = error => ({
  type: ADD_CLIENT_FAIL,
  payload: error,
});