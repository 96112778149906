import {
  GET_SERVICE_ORDER_PRICE,
  GET_SERVICE_ORDER_PRICE_SUCCESS,
  GET_SERVICE_ORDER_PRICE_FAIL,
  DELETE_SERVICE_ORDER_PRICE,
  DELETE_SERVICE_ORDER_PRICE_SUCCESS,
  DELETE_SERVICE_ORDER_PRICE_FAIL,
  UPDATE_SERVICE_ORDER_PRICE,
  UPDATE_SERVICE_ORDER_PRICE_SUCCESS,
  UPDATE_SERVICE_ORDER_PRICE_FAIL,
  ADD_NEW_SERVICE_ORDER_PRICE,
  ADD_SERVICE_ORDER_PRICE_SUCCESS,
  ADD_SERVICE_ORDER_PRICE_FAIL,
  SERVICE_ORDER_PRICE_LOADING,
  SERVICE_ORDER_PRICE_RESET,
  GET_SERVICE_ORDER_PRICE_LIST,
  GET_SERVICE_ORDER_PRICE_LIST_SUCCESS,
  GET_SERVICE_ORDER_PRICE_LIST_FAIL,
} from "./actionType";

export const resetServiceOrderPriceState = (actionType) => ({
  type: SERVICE_ORDER_PRICE_RESET,
  payload: { actionType },
});

export const getServiceOrderPriceSuccess = (actionType, data) => ({
  type: GET_SERVICE_ORDER_PRICE_SUCCESS,
  payload: { actionType, data },
});

export const getServiceOrderPriceFail = (actionType, error) => ({
  type: GET_SERVICE_ORDER_PRICE_FAIL,
  payload: { actionType, error },
});

export const getServiceOrderPriceList = data => ({
  type: GET_SERVICE_ORDER_PRICE_LIST,
  payload: data,
});

export const getServiceOrderPriceListSuccess = (actionType, data) => ({
  type: GET_SERVICE_ORDER_PRICE_LIST_SUCCESS,
  payload: { actionType, data },
});

export const getServiceOrderPriceListFail = (actionType, error) => ({
  type: GET_SERVICE_ORDER_PRICE_LIST_FAIL,
  payload: { actionType, error },
});

export const getServiceOrderPrice = data => ({
  type: GET_SERVICE_ORDER_PRICE,
  payload: data,
});

export const getServiceOrderPriceLoading = () => ({
  type: SERVICE_ORDER_PRICE_LOADING
});

export const deleteServiceOrderPrice = data => ({
  type: DELETE_SERVICE_ORDER_PRICE,
  payload: data,
});

export const deleteServiceOrderPriceSuccess = data => ({
  type: DELETE_SERVICE_ORDER_PRICE_SUCCESS,
  payload: data,
});

export const deleteServiceOrderPriceFail = error => ({
  type: DELETE_SERVICE_ORDER_PRICE_FAIL,
  payload: error,
});

export const updateServiceOrderPrice = data => ({
  type: UPDATE_SERVICE_ORDER_PRICE,
  payload: data,
});

export const updateServiceOrderPriceFail = error => ({
  type: UPDATE_SERVICE_ORDER_PRICE_FAIL,
  payload: error,
});

export const updateServiceOrderPriceSuccess = data => ({
  type: UPDATE_SERVICE_ORDER_PRICE_SUCCESS,
  payload: data,
});

export const addNewServiceOrderPrice = data => ({
  type: ADD_NEW_SERVICE_ORDER_PRICE,
  payload: data,
});

export const addServiceOrderPriceSuccess = data => ({
  type: ADD_SERVICE_ORDER_PRICE_SUCCESS,
  payload: data,
});

export const addServiceOrderPriceFail = error => ({
  type: ADD_SERVICE_ORDER_PRICE_FAIL,
  payload: error,
});