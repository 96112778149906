export const BILLING_CATEGORY_LOADING = "BILLING_CATEGORY_LOADING";
export const BILLING_CATEGORY_RESET = "BILLING_CATEGORY_RESET";
export const GET_BILLING_CATEGORY = "GET_BILLING_CATEGORY";
export const GET_BILLING_CATEGORY_SUCCESS = "GET_BILLING_CATEGORY_SUCCESS";
export const GET_BILLING_CATEGORY_FAIL = "GET_BILLING_CATEGORY_FAIL";
export const DELETE_BILLING_CATEGORY = "DELETE_BILLING_CATEGORY";
export const DELETE_BILLING_CATEGORY_SUCCESS = "DELETE_BILLING_CATEGORY_SUCCESS";
export const DELETE_BILLING_CATEGORY_FAIL = "DELETE_BILLING_CATEGORY_FAIL";
export const UPDATE_BILLING_CATEGORY = "UPDATE_BILLING_CATEGORY";
export const UPDATE_BILLING_CATEGORY_SUCCESS = "UPDATE_BILLING_CATEGORY_SUCCESS";
export const UPDATE_BILLING_CATEGORY_FAIL = "UPDATE_BILLING_CATEGORY_FAIL";
export const ADD_NEW_BILLING_CATEGORY = "ADD_NEW_BILLING_CATEGORY";
export const ADD_BILLING_CATEGORY_SUCCESS = "ADD_BILLING_CATEGORY_SUCCESS";
export const ADD_BILLING_CATEGORY_FAIL = "ADD_BILLING_CATEGORY_FAIL";
