import {
  SERVICE_LOADING,
  GET_SERVICE,
  GET_SERVICE_SUCCESS,
  GET_SERVICE_FAIL,
  ADD_SERVICE_SUCCESS,
  ADD_SERVICE_FAIL,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAIL,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAIL,
  SERVICE_RESET
} from "./actionType";

const INIT_STATE = {
  services: [],
  error: {},
  loading: false
};

const Services = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICE:
          return {
            ...state,
            services: action.payload.data,
            isServiceCreated: false,
            isServiceSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SERVICE_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isServicesCreated: false,
            isServicesSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SERVICE: {
      return {
        ...state,
        isServicesCreated: false,
        loading: true,
      };
    }

    case SERVICE_LOADING: {
      return {
        ...state,
        isServicesCreated: false,
        loading: true,
      };
    }

    case ADD_SERVICE_SUCCESS:
      return {
        ...state,
        isServicesCreated: true,
        loading: false,
        services: [...state.services, action.payload.data],
      };

    case ADD_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        isServicesCreated: true,
        services: state.services.filter(
          services => services.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceCreated: true,
        services: state.services.map(service =>
          service.id.toString() === action.payload.data.id.toString() ? { ...service, ...action.payload.data } : service
        ),
      };

    case UPDATE_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SERVICE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Services;