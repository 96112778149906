export const SERVICE_ORDERDETAIL_LOADING = "SERVICE_ORDERDETAIL_LOADING";
export const SERVICE_ORDERDETAIL_RESET = "SERVICE_ORDERDETAIL_RESET";
export const GET_SERVICE_ORDERDETAIL = "GET_SERVICE_ORDERDETAIL";
export const GET_SERVICE_ORDERDETAIL_SUCCESS = "GET_SERVICE_ORDERDETAIL_SUCCESS";
export const GET_SERVICE_ORDERDETAIL_FAIL = "GET_SERVICE_ORDERDETAIL_FAIL";
export const DELETE_SERVICE_ORDERDETAIL = "DELETE_SERVICE_ORDERDETAIL"
export const DELETE_SERVICE_ORDERDETAIL_SUCCESS = "DELETE_SERVICE_ORDERDETAIL_SUCCESS"
export const DELETE_SERVICE_ORDERDETAIL_FAIL = "DELETE_SERVICE_ORDERDETAIL_FAIL"
export const UPDATE_SERVICE_ORDERDETAIL = "UPDATE_SERVICE_ORDERDETAIL"
export const UPDATE_SERVICE_ORDERDETAIL_SUCCESS = "UPDATE_SERVICE_ORDERDETAIL_SUCCESS"
export const UPDATE_SERVICE_ORDERDETAIL_FAIL = "UPDATE_SERVICE_ORDERDETAIL_FAIL"
export const ADD_NEW_SERVICE_ORDERDETAIL = "ADD_NEW_SERVICE_ORDERDETAIL"
export const ADD_SERVICE_ORDERDETAIL_SUCCESS = "ADD_SERVICE_ORDERDETAIL_SUCCESS"
export const ADD_SERVICE_ORDERDETAIL_FAIL = "ADD_SERVICE_ORDERDETAIL_FAIL"