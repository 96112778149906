import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  getServicePrice,
  resetServicePriceState,
} from "../../store/servicePrice/action";
import {
  getServicePriceCategory,
  resetServicePriceCategoryState,
} from "../../store/servicePriceCategory/action";
import {
  getServicePriceDetail,
  resetServicePriceDetailState,
} from "../../store/servicePriceDetail/action";

const Plans = () => {
  const dispatch = useDispatch();
  const [plan, setPlan] = useState(true);
  const [priceId, setPriceId] = useState();
  const [price, setPrice] = useState([]);
  const [total, setTotal] = useState([]);

  const {
    servicePriceLoading,
    servicePrices,
    servicePriceCategorys,
    servicePriceCategorysLoading,
    servicePriceDetails,
    servicePriceDetailsLoading,
  } = useSelector((state) => ({
    servicePriceLoading: state.ServicePrice.loading,
    servicePrices: state.ServicePrice.servicePrices,
    servicePriceCategorysLoading: state.ServicePriceCategory.loading,
    servicePriceCategorys: state.ServicePriceCategory.servicePriceCategorys,
    servicePriceDetailsLoading: state.ServicePriceDetail.loading,
    servicePriceDetails: state.ServicePriceDetail.servicePriceDetails,
  }));

  useEffect(() => {
    dispatch(resetServicePriceState());
    dispatch(getServicePrice());
  }, [dispatch]);

  useEffect(() => {
    if (servicePrices || priceId) {
      dispatch(resetServicePriceCategoryState());
      dispatch(getServicePriceCategory({ billingId: priceId ? priceId : 1 }));
      dispatch(resetServicePriceDetailState());
      dispatch(getServicePriceDetail({ billingId: priceId ? priceId : 1 }));
    }
  }, [dispatch, priceId, servicePrices]);

  const handlePriceClick = (id) => {
    // dispatch(resetServicePriceCategoryState());
    // dispatch(getServicePriceCategory({ billingId: id }));
    // dispatch(resetServicePriceDetailState());
    // dispatch(getServicePriceDetail({ billingId: id }));
  };

  useEffect(() => {
    if (servicePriceDetails) {
      let data = [];
      servicePriceCategorys.forEach((cat) => {
        servicePriceDetails.forEach((det) => {
          if (det.billing_component_category.id === cat.id) {
            data.push(Math.round(det.price));
          }
        });
      });
      const chunkIntoN = (arr, n) => {
        const size = Math.ceil(arr.length / n);
        return Array.from({ length: n }, (v, i) =>
          arr.slice(i * size, i * size + size)
        );
      };

      chunkIntoN(data, 4);
      chunkIntoN(data, 4);
      setPrice(chunkIntoN(data, 4));
    }
  }, [servicePriceDetails, servicePriceCategorys]);

  useEffect(() => {
    if (price) {
      let data = [];
      price.forEach((pr) => {
        let total = 0;
        let numbers = pr;

        for (var i = 0; i < numbers.length; i++) {
          total += numbers[i];
        }
        data.push(total.toLocaleString("en"));
      });
      setTotal(data);
    }
  }, [price]);

  return (
    <React.Fragment>
      <section
        className='section bg-light'
        id='plans'
        // style={{ height: "80vh" }}
        >
        <div className='bg-overlay bg-overlay-pattern'></div>
        <Container>
          <Row className='justify-content-center'>
            <Col lg={8}>
              <div className='text-center mb-5'>
                <h3 className='mb-3 fw-semibold'>
                  Choose the plan that's right for you
                </h3>
                <p className='text-muted mb-4'>
                  Simple pricing. No hidden fees. Advanced features for you
                  business.
                </p>

                <div className='d-flex justify-content-center align-items-center'>
                  {servicePrices.map((prices, pricesIdx) => {
                    return (
                      <div key={pricesIdx}>
                        <button
                          className='mb-0 me-2 btn btn-success'
                          onClick={() => {
                            handlePriceClick(prices.id);
                            setPriceId(prices.id);
                          }}>
                          {prices.name}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>

          {servicePriceCategorysLoading ? (
            <div className='text-center m-2'>
              <Spinner color='primary' type='grow' size={"sm"}></Spinner>
              &nbsp;
              <Spinner color='success' type='grow' size={"sm"}>
                Loading...
              </Spinner>
              &nbsp;
            </div>
          ) : servicePriceDetailsLoading ? (
            <div className='text-center m-2'>
              <Spinner color='primary' type='grow' size={"sm"}></Spinner>
              &nbsp;
              <Spinner color='success' type='grow' size={"sm"}>
                Loading...
              </Spinner>
              &nbsp;
            </div>
          ) : servicePriceLoading ? (
            <div className='text-center m-2'>
              <Spinner color='primary' type='grow' size={"sm"}></Spinner>
              &nbsp;
              <Spinner color='success' type='grow' size={"sm"}>
                Loading...
              </Spinner>
              &nbsp;
            </div>
          ) : null}

          <Row className='gy-4'>
            {(servicePriceCategorys || []).map((category, categoryIdx) => {
              return (
                <Col lg={3} key={categoryIdx}>
                  <Card className='plan-box mb-0'>
                    <CardBody className='p-4 m-2'>
                      <div className='d-flex align-items-center'>
                        <div className='flex-grow-1'>
                          <h5 className='mb-1 fw-semibold'>{category.name}</h5>
                          <p className='text-muted mb-0'>For Startup</p>
                        </div>
                        <div className='avatar-sm'>
                          <div className='avatar-title bg-light rounded-circle text-primary'>
                            <i className='ri-book-mark-line fs-20'></i>
                          </div>
                        </div>
                      </div>
                      <div className='py-4 text-center'>
                        <h2 className='month'>
                          <sup>
                            <small className='fw-semibold me-1'>Rp</small>
                          </sup>
                          <span className='ff-secondary fw-bold'>
                            {total[categoryIdx]}
                          </span>
                        </h2>
                      </div>

                      <div>
                        {(servicePriceDetails || []).map((det, detId) => {
                          return (
                            <div key={detId}>
                              {det.billing_component_category.id ===
                              category.id ? (
                                <ul className='list-unstyled text-muted vstack gap-3 ff-secondary'>
                                  <li>
                                    <div className='d-flex'>
                                      <div className='flex-shrink-0 text-success me-1'>
                                        <i className='ri-checkbox-circle-fill fs-15 align-middle'></i>
                                      </div>
                                      <div className='flex-grow-1'>
                                        {det.name +
                                          " - " +
                                          Math.round(det.amount) +
                                          "GB"}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })}
                        <div className='mt-4'>
                          <Link to='#' className='btn btn-soft-success w-100'>
                            Get Started
                          </Link>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Plans;
