import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  USER_LOADING,
  USER_RESET,
} from "./actionType";

export const resetUserState = (actionType) => ({
  type: USER_RESET,
  payload: { actionType },
});

export const getUserSuccess = (actionType, data) => ({
  type: GET_USER_SUCCESS,
  payload: { actionType, data },
});

export const getUserFail = (actionType, error) => ({
  type: GET_USER_FAIL,
  payload: { actionType, error },
});

export const getUser = data => ({
  type: GET_USER,
  payload: data,
});

export const getUserLoading = () => ({
  type: USER_LOADING
});

export const deleteUser = data => ({
  type: DELETE_USER,
  payload: data,
});

export const deleteUserSuccess = data => ({
  type: DELETE_USER_SUCCESS,
  payload: data,
});

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
});

export const updateUser = data => ({
  type: UPDATE_USER,
  payload: data,
});

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
});

export const updateUserSuccess = data => ({
  type: UPDATE_USER_SUCCESS,
  payload: data,
});

export const addNewUser = data => ({
  type: ADD_NEW_USER,
  payload: data,
});

export const addUserSuccess = data => ({
  type: ADD_USER_SUCCESS,
  payload: data,
});

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
});