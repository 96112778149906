import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
// profile
// import ProfileSaga from "./profile/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";

import RoleSaga from "./role/saga";
import RoleListSaga from "./roleList/saga";

//USers
import UserSaga from "./users/saga";
import ClientSaga from "./clients/saga";
import ServiceSaga from "./service/saga";
import BillingSaga from "./billing/saga";
import BillingComponentSaga from "./billingComponent/saga";
import BillingCategorySaga from "./billingCategory/saga";
import AppSaga from "./app/saga";

import StorageFiles from "./storageFiles/saga";
import UnitSaga from "./units/saga";

import ServiceOrderSaga from "./serviceOrder/saga";
import ServiceOrderDetailSaga from "./serviceOrderDetail/saga";
import ServiceOrderTotalSaga from "./serviceOrderTotal/saga";
import ServiceOrderHistorySaga from "./serviceOrderHistory/saga";
import ServiceOrderPaymentSaga from "./serviceOrderPayment/saga";
import ServiceOrderApprovalSaga from "./serviceOrderApproval/saga";
import ServiceOrderPriceSaga from "./serviceOrderPrice/saga";

import CompanySaga from "./company/saga";
import BranchSaga from "./branch/saga";
import PaymentMethodSaga from "./paymentMethod/saga";
import PaymentTermSaga from "./paymentTerm/saga";
import TransactionTaxSaga from "./transactionTax/saga";
import WarantySaga from "./waranty/saga";
import PromosionSaga from "./promosion/saga";
import ContactPersonSaga from "./contactPerson/saga";
import DocumentSaga from "./document/saga";
import DocumentDetailSaga from "./documentDetail/saga";

import TicketSaga from "./ticket/saga";
import TicketCategorySaga from "./ticketCategory/saga";

import ServicePriceSaga from "./servicePrice/saga";
import ServicePriceDetailSaga from "./servicePriceDetail/saga";
import ServicePriceCategorySaga from "./servicePriceCategory/saga";
import ServiceListSaga from "./serviceList/saga";

import TaskSaga from "./task/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(RoleSaga),
    fork(RoleListSaga),
    fork(UserSaga),
    fork(ClientSaga),
    fork(ServiceSaga),
    fork(BillingSaga),
    fork(BillingComponentSaga),
    fork(BillingCategorySaga),
    fork(AppSaga),
    fork(StorageFiles),
    fork(UnitSaga),

    fork(ServiceOrderSaga),
    fork(ServiceOrderDetailSaga),
    fork(ServiceOrderTotalSaga),
    fork(ServiceOrderHistorySaga),
    fork(ServiceOrderPaymentSaga),
    fork(ServiceOrderApprovalSaga),
    fork(ServiceOrderPriceSaga),

    fork(CompanySaga),
    fork(BranchSaga),
    fork(PaymentMethodSaga),
    fork(PaymentTermSaga),
    fork(TransactionTaxSaga),
    fork(WarantySaga),
    fork(PromosionSaga),
    fork(ContactPersonSaga),
    fork(DocumentSaga),
    fork(DocumentDetailSaga),

    fork(TicketSaga),
    fork(TicketCategorySaga),

    fork(ServicePriceSaga),
    fork(ServicePriceDetailSaga),
    fork(ServicePriceCategorySaga),
    fork(ServiceListSaga),

    fork(TaskSaga),
  ]);
}
