import {
  SERVICELIST_LOADING,
  GET_SERVICELIST,
  GET_SERVICELIST_SUCCESS,
  GET_SERVICELIST_FAIL,
  SERVICELIST_RESET
} from "./actionType";

const INIT_STATE = {
  serviceLists: [],
  error: {},
  loading: false
};

const ServiceLists = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICELIST_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICELIST:
          return {
            ...state,
            serviceLists: action.payload.data,
            isServiceListCreated: false,
            isServiceListSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SERVICELIST_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICELIST_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isServiceListsCreated: false,
            isServiceListsSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SERVICELIST: {
      return {
        ...state,
        isServiceListsCreated: false,
        loading: true,
      };
    }

    case SERVICELIST_LOADING: {
      return {
        ...state,
        isServiceListsCreated: false,
        loading: true,
      };
    }

    case SERVICELIST_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ServiceLists;