import React, { useEffect, useState } from "react";
import { Card, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { addNewStorageFile, deleteStorageFile, getStorageFile } from "../../store/actions";
import { api } from "../../config";
const authUser = JSON.parse(sessionStorage.getItem("authUser"));

const ModalInputFile = ({ modal, toggle, loading, onSelect }) => {
  const { storageFiles, storageFile, isStorageFileCreated, isStorageFileSuccess, loadingFile, errorFile } = useSelector((state) => ({
    storageFiles: state.StorageFile.storageFiles,
    storageFile: state.StorageFile.storageFile,
    isStorageFileCreated: state.StorageFile.isStorageFileCreated,
    isStorageFileSuccess: state.StorageFile.isStorageFileSuccess,
    loadingFile: state.StorageFile.loading,
    errorFile: state.StorageFile.error,
  }));
  const [storageFileList, setStorageFileList] = useState([]);
  const dispatch = useDispatch();
  const handleUpload = (files) => {
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    formData.append("clientId", authUser.data.user_client.id ?? "");
    dispatch(addNewStorageFile(formData));
  };

  const handleDeleteFile = (id) => {
    dispatch(deleteStorageFile(id));
  };

  const handleSearchFiles = (value) => {
    const filteredBooks = storageFiles.filter(({ originalname }) => originalname.toLowerCase().includes(value.toLowerCase()));
    setStorageFileList(filteredBooks);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    dispatch(getStorageFile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStorageFileList(storageFiles);
  }, [storageFiles]);

  return (
    <Modal id="showModalMedia" isOpen={modal} centered size="xl">
      <ModalHeader
        className="bg-light p-3"
        toggle={() => {
          toggle();
          handleSearchFiles("");
        }}
      >
        Media File
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-lg-8">
            <div>
              <div className="search-box me-2 mb-3 d-inline-block col-12">
                <input
                  onChange={(e) => {
                    handleSearchFiles(e.target.value);
                    // setSearchValue(e.target.value);
                    // onChange(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control search"
                  placeholder={`${storageFileList.length} Search...`}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
            <div style={{ maxHeight: "700px", height: "700px", overflowY: "scroll", overflowX: "hidden" }}>
              {storageFileList.toReversed().map((sFile, i) => {
                return (
                  <Card
                    style={{
                      cursor: "pointer",
                    }}
                    key={i}
                    onClick={() => {
                      onSelect(sFile);
                    }}
                    className="mt-1 mb-0 me-2 shadow-none border dz-processing dz-image-preview dz-success dz-complete pe-auto"
                  >
                    <div className="py-2 px-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="me-2">
                            <img src={api.MEDIA_URL + "/media/" + sFile.filename} alt="" data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" />
                          </div>
                          <div>
                            <p className="mb-0">
                              <strong>{sFile.originalname}</strong>
                            </p>
                            <p className="text-muted font-weight-bold">{formatBytes(sFile.size)}</p>
                          </div>
                        </div>
                        <div>
                          <Link
                            to="#"
                            className={"btn-icon btn btn-sm btn-soft-danger remove-item-btn"}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteFile(sFile.id);
                            }}
                          >
                            <i className=" ri-delete-bin-line fs-15"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Card>
                );
              })}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-3">
              <Dropzone
                multiple={true}
                onDrop={(acceptedFiles) => {
                  handleUpload(acceptedFiles);
                  // handleAcceptedFiles(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone dz-clickable" style={{ cursor: "pointer", height: "140px" }} {...getRootProps({ className: "dropzone" })}>
                    <div className="dz-message needsclick">
                      <div>
                        <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        <input {...getInputProps()} />
                      </div>
                      <h5>Drop document here.</h5>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
      </ModalBody>
      {/* <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>
          <button type="submit" className="btn btn-success" disabled={loading}>
            <span className="d-flex align-items-center">
              <span className="flex-grow-1 me-2">{"Tambah"}</span>
              {loadingFile && (
                <Spinner size="sm" className="flex-shrink-0" role="status">
                  Loading...
                </Spinner>
              )}
            </span>
          </button>
        </div>
      </div> */}
    </Modal>
  );
};

export default ModalInputFile;
