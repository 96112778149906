import {
  GET_TICKET,
  GET_TICKET_SUCCESS,
  GET_TICKET_FAIL,
  DELETE_TICKET,
  DELETE_TICKET_SUCCESS,
  DELETE_TICKET_FAIL,
  UPDATE_TICKET,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_FAIL,
  ADD_NEW_TICKET,
  ADD_TICKET_SUCCESS,
  ADD_TICKET_FAIL,
  TICKET_LOADING,
  TICKET_RESET,
} from "./actionType";

export const resetTicketState = (actionType) => ({
  type: TICKET_RESET,
  payload: { actionType },
});

export const getTicketSuccess = (actionType, data) => ({
  type: GET_TICKET_SUCCESS,
  payload: { actionType, data },
});

export const getTicketFail = (actionType, error) => ({
  type: GET_TICKET_FAIL,
  payload: { actionType, error },
});

export const getTicket = data => ({
  type: GET_TICKET,
  payload: data,
});

export const getTicketLoading = () => ({
  type: TICKET_LOADING
});

export const deleteTicket = data => ({
  type: DELETE_TICKET,
  payload: data,
});

export const deleteTicketSuccess = data => ({
  type: DELETE_TICKET_SUCCESS,
  payload: data,
});

export const deleteTicketFail = error => ({
  type: DELETE_TICKET_FAIL,
  payload: error,
});

export const updateTicket = data => ({
  type: UPDATE_TICKET,
  payload: data,
});

export const updateTicketFail = error => ({
  type: UPDATE_TICKET_FAIL,
  payload: error,
});

export const updateTicketSuccess = data => ({
  type: UPDATE_TICKET_SUCCESS,
  payload: data,
});

export const addNewTicket = data => ({
  type: ADD_NEW_TICKET,
  payload: data,
});

export const addTicketSuccess = data => ({
  type: ADD_TICKET_SUCCESS,
  payload: data,
});

export const addTicketFail = error => ({
  type: ADD_TICKET_FAIL,
  payload: error,
});