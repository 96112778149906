import React, { useEffect, useState, useLayoutEffect } from "react";
import { Table, CardBody, Col, CardHeader, Row, Card, } from "reactstrap";
import logoDark from "../../assets/images/logo-dark.png";
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
// import logoLight from "../../assets/images/logo-light.png";
import moment from "moment";
// import { QRCodeSVG } from "qrcode.react";
// import CryptoJS from "crypto-js";

const PrintDoc = ({ innerRef, item }) => {
//   const [authUser, setAuthUser] = useState();
//   const [logo, setLogo] = useState();
//   const [signed, setSigned] = useState();

//   useEffect(() => {
//     const obj = JSON.parse(localStorage.getItem("authUser"));
//     setAuthUser(obj);
//   }, []);

//   useEffect(() => {
//     if (authUser) {
//       (authUser);
//       const lg = authUser.data.user_client ? process.env.REACT_APP_API_URL + "/media/" + authUser.data.user_client.client_image?.file_folder.name + "/" + authUser.data.user_client.client_image?.filename : null;
//       setLogo(lg);
//       let signed = authUser.data.user_image_signed ? process.env.REACT_APP_API_URL + "/media/" + authUser.data.user_image_signed.file_folder.name + "/" + authUser.data.user_image_signed.filename : null;
//       setSigned(signed);
//     }
//   }, [authUser]);

const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY");
  };
  function currencyFormat(num) {
    return "Rp " + parseFloat(num).toLocaleString(["ban", "id"]);
  }
  return (
    <div
      className="d-none"
      style={{
        overflow: "scroll",
        //backgroundColor: "blue"
      }}
    >
      <div ref={innerRef} className="bg-white" style={{ padding: "40px 80px 40px 80px" }}>


      <Card>
      <Col lg={12}>
                        <CardHeader className="border-bottom-dashed p-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="40" />
                              {/* <img src={logoLight} className="card-logo card-logo-light" alt="logo light" height="40" /> */}
                            </div>
                              <div>
                                <h6 className="text-uppercase fw-semibold">{item.client && item.client.label}</h6>
                                <p className="mb-1">{item.company && item.company.label}</p>
                              </div>
                          </div>
                        </CardHeader>
                        <CardBody className="p-4">
                          <Row className="g-3">
                            <Col lg={3} xs={3}>
                              <p className="text-muted mb-2 text-uppercase fw-semibold">Invoice No</p>
                              <h5 className="fs-14 mb-0">
                                #<span id="invoice-no">{item.transactionNo}</span>
                              </h5>
                            </Col>
                            <Col lg={3} xs={3}>
                              <p className="text-muted mb-2 text-uppercase fw-semibold">Date</p>
                              <h5 className="fs-14 mb-0">
                                <span id="invoice-date">{handleValidDate(item.transactionAt)}</span>
                              </h5>
                            </Col>
                            <Col lg={3} xs={3}>
                              <p className="text-muted mb-2 text-uppercase fw-semibold">Due Date</p>
                              <h5 className="fs-14 mb-0">
                                <span id="invoice-date">{handleValidDate(item.dueAt)}</span>
                              </h5>
                            </Col>
                            <Col lg={3} xs={3}>
                              <p className="text-muted mb-2 text-uppercase fw-semibold">Total Amount</p>
                              <h5 className="fs-14 mb-0">
                                <span id="total-amount">{currencyFormat(item.totalAmount)}</span>
                              </h5>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardBody className="p-4">
                          <div className="table-responsive">
                            <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                              <thead>
                                <tr className="table-active">
                                  <th scope="col" style={{ width: "50px" }}>
                                    #
                                  </th>
                                  <th scope="col">Service</th>
                                  <th scope="col">Branch</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Billing</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Discount</th>
                                  <th scope="col">Price</th>
                                </tr>
                              </thead>
                              <tbody id="products-list">
                                {(item.items ? item.items : []).map((o, i) => {
                                  return (
                                    <tr key={i} id={i} className="product">
                                      <th scope="row" className="product-id">
                                        {i + 1}
                                      </th>
                                      <td>{o.service.label}</td>
                                      <td>{o.branch.label}</td>
                                      <td>{o.unit.label}</td>
                                      <td>{o.billing.label}</td>
                                      <td>{o.billingComponent.label}</td>
                                      <td className="text-end">{currencyFormat(o.discount)}</td>
                                      <td className="text-end">{currencyFormat(o.price)}</td>
                                    </tr>
                                  );
                                })}
                                <tr className="border-top">
                                  <td colSpan={6}></td>
                                  <td className="text-end fw-semibold">Sub Total</td>
                                  <td className="text-end">{currencyFormat(item.subTotal)}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td colSpan={6}></td>
                                  <td className="text-end fw-semibold">Sub Discount</td>
                                  <td className="text-end">{currencyFormat(item.subDiscount)}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td colSpan={6}></td>
                                  <td className="text-end fw-semibold">Tax</td>
                                  <td className="text-end">{currencyFormat(item.tax ? item.tax.amount : 0)}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td colSpan={6}></td>
                                  <td className="text-end fw-semibold">Total Amount</td>
                                  <td className="text-end">{currencyFormat(item.totalAmount)}</td>
                                </tr>
                              </tbody>
                            </Table>
                            {/* <Table>
                                  <tbody>
                                    <tr></tr>
                                    <tr>
                                      <td>Sub Total</td>
                                      <td className="text-end">{currencyFormat(1000000)}</td>
                                    </tr>
                                    <tr>
                                      <td>Discount</td>
                                      <td className="text-end">{currencyFormat(dis)}</td>
                                    </tr>
                                    <tr>
                                      <td>Shipping Charge</td>
                                      <td className="text-end">{currencyFormat(ship)}</td>
                                    </tr>
                                    <tr>
                                      <td>Estimated Tax ({tax}%)</td>
                                      <td className="text-end">{currencyFormat((tax / 100) * (subTotal + ship - dis))}</td>
                                    </tr>
                                    <tr className="border-top border-top-dashed fs-15">
                                      <th scope="row">Total Amount</th>
                                      <th className="text-end">{currencyFormat(subTotal + (tax / 100) * (subTotal + ship - dis) + ship - dis)}</th>
                                    </tr>
                                  </tbody>
                                </Table> */}
                          </div>
                          <div className="mt-4">
                            <div className="alert alert-info">
                              <p className="mb-0">
                                <span className="fw-semibold">NOTES:</span>
                                <span id="note"> {item.description}</span>
                              </p>
                            </div>
                          </div>
                        </CardBody>
                      </Col>
      </Card>



      </div>
    </div>
  );
};

export default PrintDoc;
