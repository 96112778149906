import {
  GET_SERVICEPRICECATEGORY,
  GET_SERVICEPRICECATEGORY_SUCCESS,
  GET_SERVICEPRICECATEGORY_FAIL,
  SERVICEPRICECATEGORY_LOADING,
  SERVICEPRICECATEGORY_RESET,
} from "./actionType";

export const resetServicePriceCategoryState = (actionType) => ({
  type: SERVICEPRICECATEGORY_RESET,
  payload: { actionType },
});

export const getServicePriceCategorySuccess = (actionType, data) => ({
  type: GET_SERVICEPRICECATEGORY_SUCCESS,
  payload: { actionType, data },
});

export const getServicePriceCategoryFail = (actionType, error) => ({
  type: GET_SERVICEPRICECATEGORY_FAIL,
  payload: { actionType, error },
});

export const getServicePriceCategory = data => ({
  type: GET_SERVICEPRICECATEGORY,
  payload: data,
});

export const getServicePriceCategoryLoading = () => ({
  type: SERVICEPRICECATEGORY_LOADING
});