import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
//import { GoogleLogin } from "react-google-login";
import { GoogleLogin } from "@react-oauth/google";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// actions
import { loginUser, socialLogin, resetLoginFlag } from "../../store/actions";

import logoLight from "../../assets/images/logo-light.png";
//Import config
import { facebook, google } from "../../config";

import withRouter from '../../Components/Common/withRouter';

const Login = (props) => {
  const dispatch = useDispatch();
  const { user, errorMsg, loading, error } = useSelector(state => ({
    user: state.Account.user,
    errorMsg: state.Login.errorMsg,
    loading: state.Login.loading,
    error: state.Login.error,
  }));

  const [userLogin, setUserLogin] = useState([]);
  const [passwordShow, setPasswordShow] = useState(false);

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        email: user.user.email,
        password: user.user.confirm_password
      });
    }
  }, [user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      clientAppId: 3,
      username: userLogin.usename || "",
      password: userLogin.password || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        clientAppId: 3,
        clientId: res.clientId,
        token: res.credential,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = response => {
    console.log ("googleResponse", response);
    signIn(response, "google");
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook");
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
    }
  }, [dispatch, error]);


  document.title = "Basic SignIn | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="60" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium"></p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">Sign in to continue.</p>
                    </div>
                    {errorMsg && errorMsg ? (<Alert color="danger"> {errorMsg} </Alert>) : null}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        {error && error.response ? (
                          <Alert color="danger">
                            <div>{error.response.data.message}</div>
                          </Alert>
                        ) : null}

                        {error === "error_client" ? (
                          <Alert color="danger">
                            <div>{"Can only be accessed by admin type"}</div>
                          </Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label htmlFor="username" className="form-label">
                            Username
                          </Label>
                          <Input name="username" className="form-control" placeholder="Enter Username" type="text" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.username || ""} invalid={validation.touched.username && validation.errors.username ? true : false} />
                          {validation.touched.username && validation.errors.username ? <FormFeedback type="invalid">{validation.errors.username}</FormFeedback> : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label" htmlFor="password-input">
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input name="password" value={validation.values.password || ""} type="password" className="form-control pe-5" placeholder="Enter Password" onChange={validation.handleChange} onBlur={validation.handleBlur} invalid={validation.touched.password && validation.errors.password ? true : false} />
                            {validation.touched.password && validation.errors.password ? <FormFeedback type="invalid">{validation.errors.password}</FormFeedback> : null}
                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon">
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div className="form-check">
                          <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                          <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                        </div>

                        <div className="mt-4">
                          <Button disabled={error ? null : loading ? true : false} color="success" className="btn btn-success w-100" type="submit">
                            {error ? null : loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                            Sign In
                          </Button>
                        </div>

                        <div className="mt-4 text-center">
                          <div className="signin-other-title">
                            <h5 className="fs-13 mb-4 title">Sign In with</h5>
                          </div>
                          <div>
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Button color="primary"
                                  className="btn-icon me-1"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="ri-facebook-fill fs-16" />
                                </Button>
                              )}
                            />
                            <GoogleLogin
                              clientId={google.CLIENT_ID ? google.CLIENT_ID : ""}
                              render={renderProps => (
                                <Button color="danger"
                                  to="#"
                                  className="btn-icon me-1"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="ri-google-fill fs-16" />
                                </Button>
                              )}
                              onSuccess={googleResponse}
                              onFailure={(error) => {
                                console.log("ERROR LOGIN GOOGLE", error);
                              }} />

                            <Button color="dark" className="btn-icon"><i className="ri-github-fill fs-16"></i></Button>{" "}
                            <Button color="info" className="btn-icon"><i className="ri-twitter-fill fs-16"></i></Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">Don't have an account ? <Link to="/register" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p>
                </div>

              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);