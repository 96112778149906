import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_SERVICE_ORDERHISTORY,
  DELETE_SERVICE_ORDERHISTORY,
  UPDATE_SERVICE_ORDERHISTORY,
  ADD_NEW_SERVICE_ORDERHISTORY,
} from "./actionType";

import {
  getServiceOrderHistorySuccess,
  getServiceOrderHistoryFail,
  deleteServiceOrderHistorySuccess,
  deleteServiceOrderHistoryFail,
  updateServiceOrderHistorySuccess,
  updateServiceOrderHistoryFail,
  addServiceOrderHistorySuccess,
  addServiceOrderHistoryFail,
} from "./action";

//Include Both Helper File with needed carriers
import {
  approveServiceOrderHistoryApi,
  getServiceOrderHistoryApi,
  updateServiceOrderHistoryApi,
  deleteServiceOrderHistoryApi,
  addNewServiceOrderHistoryApi
} from "../../helpers/backend_helper";

function* getServiceOrderHistory({ payload: serviceOrderHistory }) {
  try {
    const response = yield call(getServiceOrderHistoryApi, serviceOrderHistory);
    yield put(getServiceOrderHistorySuccess(GET_SERVICE_ORDERHISTORY, response.data));
  } catch (error) {
    yield put(getServiceOrderHistoryFail(GET_SERVICE_ORDERHISTORY, error));
  }
}

function* onUpdateServiceOrderHistory({ payload: serviceOrderHistory }) {
  try {
    if (serviceOrderHistory.setApprove) {
      const response = yield call(approveServiceOrderHistoryApi, serviceOrderHistory);
      yield put(updateServiceOrderHistorySuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateServiceOrderHistoryApi, serviceOrderHistory);
      yield put(updateServiceOrderHistorySuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateServiceOrderHistoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteServiceOrderHistory({ payload: serviceOrderHistory }) {
  try {
    const response = yield call(deleteServiceOrderHistoryApi, serviceOrderHistory);
    yield put(deleteServiceOrderHistorySuccess({ serviceOrderHistory, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteServiceOrderHistoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewServiceOrderHistory({ payload: serviceOrderHistory }) {
  try {
    const response = yield call(addNewServiceOrderHistoryApi, serviceOrderHistory);
    yield put(addServiceOrderHistorySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addServiceOrderHistoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetServiceOrderHistory() {
  yield takeEvery(GET_SERVICE_ORDERHISTORY, getServiceOrderHistory);
}

export function* watchUpdateServiceOrderHistory() {
  yield takeEvery(UPDATE_SERVICE_ORDERHISTORY, onUpdateServiceOrderHistory);
}

export function* watchDeleteServiceOrderHistory() {
  yield takeEvery(DELETE_SERVICE_ORDERHISTORY, onDeleteServiceOrderHistory);
}

export function* watchAddNewServiceOrderHistory() {
  yield takeEvery(ADD_NEW_SERVICE_ORDERHISTORY, onAddNewServiceOrderHistory);
}

function* serviceOrderHistorySaga() {
  yield all([
    fork(watchGetServiceOrderHistory),
    fork(watchDeleteServiceOrderHistory),
    fork(watchUpdateServiceOrderHistory),
    fork(watchAddNewServiceOrderHistory)
  ]);
}

export default serviceOrderHistorySaga;
