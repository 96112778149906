import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, Modal, ModalHeader, Form, ModalBody, Label, Input, FormFeedback, Spinner, UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import { isEmpty, clone } from "lodash";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";
import Dropzone from "react-dropzone";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import ModalInputFile from "../../Components/ModalInputFile";
import { api } from "../../config";
import defaultImg from "../../assets/images/upload.png";

import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

import mapValues from "lodash/mapValues";

// Formik
import * as Yup from "yup";
import { useFormik, Field, FieldArray } from "formik";
import FormData from "form-data";
import { useSelector, useDispatch } from "react-redux";
import PrintDoc from "./PrintDoc";

//Import actions
// import { getEmployee, resetEmployeeState } from "../../store/employee/action";
// import { resetCompanyState, getCompany } from "../../store/company/action";
// import { resetVendorState, getVendor } from "../../store/vendor/action";
// import { resetPaymentMethodState, getPaymentMethod } from "../../store/paymentMethod/action";
// import { resetPaymentTermState, getPaymentTerm, addNewPaymentTerm } from "../../store/paymentTerm/action";
// import { resetShipmentMethodState, getShipmentMethod, addNewShipmentMethod } from "../../store/shipmentMethod/action";
// import { resetShipmentCarrierState, getShipmentCarrier, addNewShipmentCarrier } from "../../store/shipmentCarrier/action";
// import { resetProductVarianState, getProductVarian } from "../../store/productVarian/action";
// import { resetAssetState, getAsset } from "../../store/asset/action";
// import { resetBranchState, getBranch } from "../../store/branch/action";
// import { resetDepartmentState, getDepartment } from "../../store/department/action";
// import { getAccAccount, resetAccAccountState } from "../../store/accAccount/action";
// import { deleteFile } from "../../store/file/action";
// import { resetTransactionTaxState, getTransactionTax } from "../../store/transactionTax/action";
import { resetServiceOrderState, getServiceOrder as onGetServiceOrders, addNewServiceOrder as onAddNewServiceOrder, updateServiceOrder as onUpdateServiceOrder, deleteServiceOrder as onDeleteServiceOrder, getServiceOrderLoading, resetServiceOrderCreateState } from "../../store/serviceOrder/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";

import ExportCSVModal from "../../Components/Common/ExportCSVModal";

import { getPaymentMethod, resetPaymentMethodState } from "../../store/paymentMethod/action";
import { getPaymentTerm, resetPaymentTermState } from "../../store/paymentTerm/action";
import { getTransactionTax, resetTransactionTaxState } from "../../store/transactionTax/action";
import { getServiceOrderPrice, resetServiceOrderPriceState, getServiceOrderPriceList } from "../../store/serviceOrderPrice/action";
import { getClient, resetClientState } from "../../store/clients/action";
import { resetCompanyState, getCompany } from "../../store/company/action";
import { getService, resetServiceState } from "../../store/service/action";
import { resetBranchState, getBranch } from "../../store/branch/action";
import { resetUnitState, getUnit } from "../../store/units/action";
import { getBilling, resetBillingState } from "../../store/billing/action";
import { getBillingComponent, resetBillingComponentState } from "../../store/billingComponent/action";
import imgNotFound from "../../assets/images/users/img-remove.png";

const ServiceOrder = (props) => {
  const printRef = useRef(null);
  const [logo, setLogo] = useState(defaultImg);
  const [modal, setModal] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [modalHistory, setModalHistory] = useState(false);
  const [modalApprove, setModalApprove] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [dataListHelper, setDataListHelper] = useState([]);
  const [indexItem, setIndexItem] = useState(null);
  const [otherDocsImg, setOtherDocsImg] = useState([]);
  const [modalMedia, setModalMedia] = useState(false);
  const [serviceOrderData, setServiceOrderData] = useState(null);

  const printInvoice = () => {
    setModalDetail(false);
    var printContents = document.getElementById("PrintArea").outerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    return false;
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };
  const dispatch = useDispatch();

  const { paymentMethodLoading, paymentMethods, paymentTermLoading, paymentTerms, transactionTaxLoading, transactionTaxs, serviceOrderPricesList, serviceOrderPrices, serviceOrderPriceLoading, billingComponentLoading, billingComponents, billingLoading, billings, unitLoading, units, brancheLoading, branches, serviceLoading, services, companyLoading, companies, clientLoading, clients, serviceOrders, error, isServiceOrderCreated, isServiceOrderSuccess, loading, serviceOrderAdded } = useSelector((state) => ({
    serviceOrderAdded: state.ServiceOrder.serviceOrderAdded,
    serviceOrders: state.ServiceOrder.serviceOrders,
    isServiceOrderCreated: state.ServiceOrder.isServiceOrderCreated,
    isServiceOrderSuccess: state.ServiceOrder.isServiceOrderSuccess,
    loading: state.ServiceOrder.loading,
    clients: state.Clients.clients,
    clientLoading: state.Clients.loading,
    companies: state.Company.companies,
    companyLoading: state.Company.loading,
    serviceLoading: state.Services.loading,
    services: state.Services.services,
    brancheLoading: state.Branch.loading,
    branches: state.Branch.branches,
    unitLoading: state.Units.loading,
    units: state.Units.units,
    billingLoading: state.Billing.loading,
    billings: state.Billing.billings,
    billingComponents: state.BillingComponent.billingComponents,
    billingComponentLoading: state.BillingComponent.loading,
    serviceOrderPrices: state.ServiceOrderPrice.serviceOrderPrices,
    serviceOrderPricesList: state.ServiceOrderPrice.serviceOrderPricesList,
    serviceOrderPriceLoading: state.ServiceOrderPrice.loading,
    transactionTaxs: state.TransactionTax.transactionTaxs,
    transactionTaxLoading: state.TransactionTax.loading,
    paymentMethods: state.PaymentMethod.paymentMethods,
    paymentMethodLoading: state.PaymentMethod.loading,
    paymentTerms: state.PaymentTerm.paymentTerms,
    paymentTermLoading: state.PaymentTerm.loading,
    error: state.ServiceOrder.error,
  }));

  const defaultdate = () => {
    return moment(new Date()).tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };

  const [filterDate, setFilterDate] = useState(defaultdate());
  const [serviceOrderList, setServiceOrderList] = useState([]);
  const [serviceOrder, setServiceOrder] = useState({});
  const [info, setInfo] = useState([]);
  const [serviceOrderType, setServiceOrderType] = useState("");
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusServiceOrder, setStatusServiceOrder] = useState([]);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [paymentTermList, setPaymentTermList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [serviceOrderDetailItem, setServiceOrderDetailItem] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [branchList, setBranchList] = useState([]);

  const [isSearch, setIsSearch] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const onBeforeGetContentResolve = useRef(null);

  const statusServiceOrderData = [
    {
      options: [
        { label: "Draft", value: "1" },
        { label: "Pending", value: "2" },
        { label: "Confirmed", value: "3" },
        { label: "Processed", value: "4" },
        { label: "Completed", value: "5" },
        { label: "Canceled", value: "8" },
        { label: "Returned", value: "9" },
      ],
    },
  ];

  const serviceOrderTypeData = [
    {
      options: [
        { label: "Asset", value: "Asset" },
        { label: "Product", value: "Product" },
      ],
    },
  ];

  const initialStatusApproveList = [
    {
      options: [
        { label: "Set as Paid", value: 3 },
        { label: "Set as Processed", value: 4 },
        { label: "Set as Cancelled", value: 8 },
      ],
    },
  ];

  const [statusApproveList, setStatusApproveList] = useState(initialStatusApproveList);

  useEffect(() => {
    switch (serviceOrder.statusId) {
      case 3:
        setStatusApproveList([
          {
            options: [
              { label: "Set as Processed", value: 4 },
              { label: "Set as Cancelled", value: 8 },
            ],
          },
        ]);
        break;
      case 4:
        setStatusApproveList([
          {
            options: [
              { label: "Set as Paid", value: 3 },
              { label: "Set as Cancelled", value: 8 },
            ],
          },
        ]);
        break;
      case 8:
        setStatusApproveList([
          {
            options: [
              { label: "Set as Processed", value: 4 },
              { label: "Set as Paid", value: 3 },
            ],
          },
        ]);
        break;

      default:
        setStatusApproveList(initialStatusApproveList);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceOrder]);

  const reactToPrintContent = useCallback(() => {
    return printRef.current;
  }, [printRef]);

  const handleAfterPrint = useCallback(() => {
    // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = useCallback(() => {
    // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = useCallback(() => {
    setLoadingPrint(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoadingPrint(false);
        resolve();
      }, 2000);
    });
  }, [setLoadingPrint]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Task",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredServiceOrders = serviceOrders;

      if (type !== "all") {
        filteredServiceOrders = serviceOrders.filter((serviceOrder) => serviceOrder.so_status.name === type);
      }
      setServiceOrderList(filteredServiceOrders);
    }
  };

  const handleSelectFile = (file) => {
    setOtherDocsImg((val) => [...val, { filename: file.filename }]);

    toggleMedia();
  };

  const handleDeleteFile = (file) => {
    if (isEdit) {
      const newState = otherDocsImg.map((obj) => {
        if (obj.id === file.id) {
          return { id: file.id, filename: file.filename, isDeleted: true };
        }
        return obj;
      });
      setOtherDocsImg(newState);
    } else {
      const index = otherDocsImg.findIndex((fl) => fl.filename === file.filename);
      setOtherDocsImg((val) => [...val.slice(0, index), ...val.slice(index + 1)]);
    }
  };

  const toggleMedia = useCallback(() => {
    if (modalMedia) {
      setModalMedia(false);
    } else {
      setModalMedia(true);
    }
  }, [modalMedia]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setServiceOrderList(serviceOrders);
  }, [serviceOrders]);

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetServiceOrderState());
      dispatch(onGetServiceOrders());
      setIsSearch(true);
      setIsEdit(false);
    }
  }, [dispatch, serviceOrders, isSearch]);

  useEffect(() => {
    setServiceOrder(serviceOrderAdded);
  }, [serviceOrderAdded]);

  useEffect(() => {
    if (!isEmpty(serviceOrderAdded)) {
      setServiceOrder(serviceOrderAdded);
    }
  }, [serviceOrderAdded]);

  useEffect(() => {
    if (!isEmpty(serviceOrders)) {
      setServiceOrderList(serviceOrders);
      setInfo(serviceOrders[serviceOrders.length - 1]);
    }
  }, [serviceOrders]);

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  // useEffect(() => {
  //   if (companies && !companies.length) {
  //     dispatch(resetCompanyState());
  //     dispatch(getCompany());
  //   }
  // }, [dispatch, companies]);

  useEffect(() => {
    if (!isEmpty(clients)) {
      var data = [];
      var opt = [];
      clients.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setClientList(data);
    }
  }, [clients]);

  useEffect(() => {
    if (!isEmpty(transactionTaxs)) {
      var data = [];
      var opt = [];
      transactionTaxs.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        const amount = item.amount;
        var obj = { label: name, value: id, amount: amount };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setTaxList(data);
    }
  }, [transactionTaxs]);

  useEffect(() => {
    if (!isEmpty(paymentMethods)) {
      var data = [];
      var opt = [];
      paymentMethods.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setPaymentMethodList(data);
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (!isEmpty(paymentTerms)) {
      var data = [];
      var opt = [];
      paymentTerms.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setPaymentTermList(data);
    }
  }, [paymentTerms]);

  useEffect(() => {
    var data = [];
    var opt = [];
    companies.forEach((item, key) => {
      const name = item.name;
      const id = item.id;
      var obj = { label: name, value: id };
      opt.push(obj);
    });
    var option = { options: opt };
    data.push(option);

    if (companies.length > 0) {
      setCompanyList(data);
    }
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(services)) {
      var data = [];
      var opt = [];
      services.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setServiceList(data);
    }
  }, [services]);

  useEffect(() => {
    var data = [];
    var opt = [];
    branches.forEach((item, key) => {
      const name = item.name;
      const id = item.id;
      var obj = { label: name, value: id };
      opt.push(obj);
    });
    var option = { options: opt };
    data.push(option);

    if (branches.length > 0) {
      setBranchList(data);
    }
  }, [branches]);

  // useEffect(() => {
  //   var data = [];
  //   var opt = [];
  //   units.forEach((item, key) => {
  //     const name = item.name;
  //     const id = item.id;
  //     var obj = { label: name, value: id };
  //     opt.push(obj);
  //   });
  //   var option = { options: opt };
  //   data.push(option);

  //   if (units.length > 0) {
  //     setUnitList(data);
  //   }
  // }, [units]);

  // useEffect(() => {
  //   var data = [];
  //   var opt = [];
  //   billings.forEach((item, key) => {
  //     const name = item.name;
  //     const id = item.id;
  //     var obj = { label: name, value: id };
  //     opt.push(obj);
  //   });
  //   var option = { options: opt };
  //   data.push(option);

  //   if (billings.length > 0) {
  //     setBillingList(data);
  //   }
  // }, [billings]);

  // const promiseProductOptions = (e) => {
  //   if (!productVarianLoading) {
  //     dispatch(resetProductVarianState());
  //     setProductList([]);
  //     if (e.length > 1) {
  //       dispatch(getProductVarian({ "type": "referral", "title": e }));
  //     }
  //     return new Promise((resolve, reject) => {
  //       while (productVarianLoading);
  //       resolve(productList);
  //     });
  //   }
  // }

  // useEffect(() => {
  //   if (!isEmpty(productVarians)) {
  //     var productVarianData = [];
  //     var opt = [];
  //     productVarians.forEach((item, key) => {
  //       const varian_name = item.pvar_product.product_name;
  //       const id = item.id;
  //       const barcode = item.barcode;
  //       const sku = item.sku;
  //       const price = item.price;
  //       const brandId = item.brandId;
  //       const productId = item.productId;
  //       let option_name = "";
  //       item.pvar_options &&
  //         item.pvar_options.forEach((i, k) => {
  //           option_name += i.detail_name + "/";
  //         });

  //       var obj = { label: varian_name + " " + option_name.slice(0, -1) + " - " + barcode, name: varian_name + " " + option_name.slice(0, -1), barcode: barcode, sku: sku, value: id, price: price, brandId: brandId, productId: productId };
  //       opt.push(obj);
  //     });
  //     var option = { options: opt };
  //     productVarianData.push(option);
  //     setProductList(productVarianData);
  //   }
  // }, [productVarians]);

  // const promiseAssetOptions = (e) => {
  //   if (!assetLoading) {
  //     dispatch(resetAssetState());
  //     setAssetList([]);
  //     if (e.length > 1) {
  //       dispatch(getAsset({ "type": "referral", "title": e }));
  //     }
  //     return new Promise((resolve, reject) => {
  //       while (assetLoading);
  //       resolve(assetList);
  //     });
  //   }
  // }

  // useEffect(() => {
  //   if (!isEmpty(assets)) {
  //     var assetData = [];
  //     var opt = [];
  //     assets.forEach((item, key) => {
  //       const name = item.asset_name;
  //       const id = item.id;
  //       const barcode = item.barcode;
  //       const sku = item.sku;
  //       const price = item.price;
  //       const brandId = item.brandId;
  //       var obj = { label: name + " - " + barcode, name: name, barcode: barcode, sku: sku, value: id, price: price, brandId: brandId };
  //       opt.push(obj);
  //     });
  //     var option = { options: opt };
  //     assetData.push(option);
  //     setAssetList(assetData);
  //   }
  // }, [assets]);

  // useEffect(() => {
  //   if (paymentTerms && !paymentTerms.length && !isSearch) {
  //     dispatch(getPaymentTerm());
  //   }
  // }, [dispatch, paymentTerms, isSearch]);

  // useEffect(() => {
  //   if (!isEmpty(paymentTerms)) {
  //     var data = [];
  //     var opt = [];
  //     paymentTerms.forEach((item, key) => {
  //       const name = item.name;
  //       const id = item.id;
  //       var obj = { label: name, value: id };
  //       opt.push(obj);
  //     });
  //     var option = { options: opt };
  //     data.push(option);
  //     setPaymentTermList(data);
  //   }
  // }, [paymentTerms]);

  // useEffect(() => {
  //   if (transactionTaxs && !transactionTaxs.length && !isSearch) {
  //     dispatch(getTransactionTax());
  //   }
  // }, [dispatch, transactionTaxs, isSearch]);

  // useEffect(() => {
  //   if (!isEmpty(transactionTaxs)) {
  //     var data = [];
  //     var opt = [];
  //     transactionTaxs.forEach((item, key) => {
  //       const name = item.name;
  //       const id = item.id;
  //       const amount = item.amount;
  //       var obj = { label: name, value: id, amount: amount };
  //       opt.push(obj);
  //     });
  //     var option = { options: opt };
  //     data.push(option);
  //     setTaxList(data);
  //   }
  // }, [transactionTaxs]);

  // useEffect(() => {
  //   if (shipmentCarriers && !shipmentCarriers.length && !isSearch) {
  //     dispatch(getShipmentCarrier());
  //   }
  // }, [dispatch, shipmentCarriers, isSearch]);

  // useEffect(() => {
  //   if (!isEmpty(shipmentCarriers)) {
  //     var data = [];
  //     var opt = [];
  //     shipmentCarriers.forEach((item, key) => {
  //       const name = item.name;
  //       const id = item.id;
  //       var obj = { label: name, value: id };
  //       opt.push(obj);
  //     });
  //     var option = { options: opt };
  //     data.push(option);
  //     setShipmentCarrierList(data);
  //   }
  // }, [shipmentCarriers]);

  // useEffect(() => {
  //   if (shipmentMethods && !shipmentMethods.length && !isSearch) {
  //     dispatch(getShipmentMethod());
  //   }
  // }, [dispatch, shipmentMethods, isSearch]);

  // useEffect(() => {
  //   if (!isEmpty(shipmentMethods)) {
  //     var data = [];
  //     var opt = [];
  //     shipmentMethods.forEach((item, key) => {
  //       const name = item.name;
  //       const id = item.id;
  //       var obj = { label: name, value: id };
  //       opt.push(obj);
  //     });
  //     var option = { options: opt };
  //     data.push(option);
  //     setShipmentMethodList(data);
  //   }
  // }, [shipmentMethods]);

  // useEffect(() => {
  //   if (paymentMethods && !paymentMethods.length && !isSearch) {
  //     dispatch(resetPaymentMethodState());
  //     dispatch(getPaymentMethod());
  //   }
  // }, [dispatch, paymentMethods, isSearch]);

  // useEffect(() => {
  //   if (!isEmpty(paymentMethods)) {
  //     var data = [];
  //     var opt = [];
  //     paymentMethods.forEach((item, key) => {
  //       const name = item.name;
  //       const id = item.id;
  //       var obj = { label: name, value: id };
  //       opt.push(obj);
  //     });
  //     var option = { options: opt };
  //     data.push(option);
  //     setPaymentMethodList(data);
  //   }
  // }, [paymentMethods]);

  // useEffect(() => {
  //   if (employees && !employees.length && !isSearch) {
  //     dispatch(resetEmployeeState());
  //     dispatch(getEmployee({ type: "referral", status: 2 }));
  //   }
  // }, [employees, isSearch, dispatch]);

  // useEffect(() => {
  //   if (!isEmpty(employees)) {
  //     var data = [];
  //     var opt = [];
  //     employees.forEach((item, key) => {
  //       const employee_name = item.employee_name;
  //       const id = item.id;
  //       const taskListId = item.taskListId;
  //       const positionId = item.positionId;
  //       const position_name = item.employee_position && item.employee_position.position_name ? item.employee_position.position_name : "Not set";
  //       var obj = { label: employee_name + " - " + position_name, value: id, taskListId: taskListId, positionId: positionId };
  //       opt.push(obj);
  //     });
  //     var option = { options: opt };
  //     data.push(option);
  //     setEmployeeList(data);
  //   }
  // }, [employees]);

  // useEffect(() => {
  //   if (branches && !branches.length && !isSearch) {
  //     dispatch(resetBranchState());
  //     dispatch(getBranch({ status: 2, warehouse: "true" }));
  //   }
  // }, [branches, isSearch, dispatch]);

  // useEffect(() => {
  //   if (!isEmpty(branches)) {
  //     var branchData = [];
  //     var opt = [];
  //     branches.forEach((item, key) => {
  //       const branch_name = item.branch_name;
  //       const id = item.id;
  //       var obj = { label: branch_name, value: id };
  //       opt.push(obj);
  //     });
  //     var option = { options: opt };
  //     branchData.push(option);
  //     setBranchList(branchData);
  //   }
  // }, [branches]);

  // function handleCompanyClick(companyId) {
  //   dispatch(resetDepartmentState());
  //   dispatch(getDepartment({ companyId: companyId, status: 2 }));
  //   return false;
  // }

  // useEffect(() => {
  //   if (!isEmpty(departments)) {
  //     var departmentData = [];
  //     var opt = [];
  //     departments.forEach((item, key) => {
  //       const department_name = item.department_name;
  //       const id = item.id;
  //       var obj = { label: department_name, value: id };
  //       opt.push(obj);
  //     });
  //     var option = { options: opt };
  //     departmentData.push(option);
  //     setDepartmentList(departmentData);
  //   }
  // }, [departments]);

  // useEffect(() => {
  //   if (!isEmpty(vendors)) {
  //     var vendorData = [];
  //     var opt = [];
  //     vendors.forEach((item, key) => {
  //       const name = item.name;
  //       const id = item.id;
  //       var obj = { label: name, value: id };
  //       opt.push(obj);
  //     });
  //     var option = { options: opt };
  //     vendorData.push(option);
  //     setVendorList(vendorData);
  //   }
  // }, [vendors]);

  // useEffect(() => {
  //   if (!isEmpty(accAccounts)) {
  //     var data = [];
  //     var opt = [];
  //     accAccounts.forEach((item, key) => {
  //       const account_name = item.account_name;
  //       const account_code = item.account_code;
  //       const id = item.id;
  //       var obj = { label: account_code + " " + account_name, value: id };
  //       opt.push(obj);
  //     });
  //     var option = { options: opt };
  //     data.push(option);
  //     setAccountingList(data);
  //   }
  // }, [accAccounts]);

  const onClickDelete = (serviceOrder) => {
    setServiceOrder(serviceOrder);
    setDeleteModal(true);
  };

  const handleDeleteServiceOrder = () => {
    if (serviceOrder) {
      dispatch(onDeleteServiceOrder(serviceOrder));
      setDeleteModal(false);
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      resetList();
    } else {
      dispatch(getClient());
      dispatch(getTransactionTax());
      dispatch(getService());
      dispatch(getPaymentMethod());
      dispatch(getPaymentTerm());
      dispatch(getUnit());
      dispatch(getBilling());
      dispatch(getBillingComponent());
      setModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const toggleApprove = useCallback(() => {
    if (modalApprove) {
      setServiceOrder({});
      validationApprove.resetForm();
      validationApprove.setErrors({});
      setModalApprove(false);
    } else {
      setModalApprove(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalApprove]);

  const toggleHistory = useCallback(() => {
    if (modalHistory) {
      setServiceOrder({});
      setModalHistory(false);
    } else {
      setModalHistory(true);
    }
  }, [modalHistory]);

  const toggleDetail = useCallback(() => {
    if (modalDetail) {
      setModalDetail(false);
      setServiceOrder({});
      setSelectedFiles([]);
      setOriginalFiles([]);
      setServiceOrderDetailItem([]);
      dispatch(resetServiceOrderPriceState());
      validationApprove.resetForm();
      validationApprove.setErrors({});
    } else {
      setModalDetail(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalDetail]);

  const handleInvoiceClick = (arg) => {
    const serviceOrder = arg;
    // let serviceOrderDetail = [];

    // setSubTotal(parseInt(serviceOrder.sub_amount || 0));
    // setShip(parseInt(serviceOrder.shipment_amount || 0));
    // setTax(parseInt(serviceOrder.serviceOrder_tax ? serviceOrder.serviceOrder_tax.amount : 0));
    // setDis(parseInt(serviceOrder.disc_amount || 0));

    // setServiceOrderType(serviceOrder.serviceOrder_type);
    // if (serviceOrder.serviceOrder_detail) {
    //   serviceOrder.serviceOrder_detail.forEach((element) => {
    //     serviceOrderDetail.push({
    //       detailId: element.id,
    //       itemId: serviceOrder.serviceOrder_type === "Product" ? element.productVarianId : element.assetId,
    //       item: serviceOrder.serviceOrder_type === "Product" ? { label: element.item_name, value: element.productVarianId, name: element.item_name, sku: element.item_sku, barcode: element.item_barcode, price: element.amount, brandId: element.brandId, productId: element.productId } : { label: element.item_name, value: element.assetId, name: element.item_name, sku: element.item_sku, barcode: element.item_barcode, price: element.amount, brandId: element.brandExtId },
    //       amount: Math.round(element.amount, 0),
    //       quantity: Math.round(element.quantity, 0),
    //       total: Math.round(element.amount_total, 0),
    //       name: element.item_name,
    //       sku: element.item_sku,
    //       barcode: element.item_barcode,
    //       brandId: serviceOrder.serviceOrder_type === "Product" ? element.brandId : element.brandExtId,
    //       accepted: element.accepted ? Math.round(element.accepted, 0) : 0,
    //       rejected: element.rejected ? Math.round(element.rejected, 0) : 0,
    //     });
    //   });
    //   setServiceOrderDetailItem(serviceOrderDetail);
    // }

    // if (serviceOrder.serviceOrder_file && serviceOrder.serviceOrder_file.length) setOriginalFiles(serviceOrder.serviceOrder_file);
  };

  const handleServiceOrderClick = useCallback(
    (arg, type) => {
      dispatch(getBillingComponent());
      const serviceOrder = arg;
      if (serviceOrder.so_company) {
        handleGetBranch(serviceOrder.so_company.id);
      }

      let itemDetail = [];
      for (const el of serviceOrder.so_detail) {
        itemDetail.push({
          billing: {
            label: el.sod_billing.name,
            value: el.sod_billing.id,
          },
          billingComponent: {
            label: el.sod_billing_category.name,
            value: el.sod_billing_category.id,
            billingComponentId: el.billingComponentId,
            promotionId: el.promotionId,
            warrantyId: el.warrantyId,
          },
          branch: {
            label: el.sod_branch.name,
            value: el.sod_branch.id,
          },
          service: {
            label: el.sod_service.name,
            value: el.sod_service.id,
          },
          unit: {
            label: el.sod_unit.name,
            value: el.sod_unit.id,
          },
          discount: 0,
          price: 0,
          priceNet: 0,
        });
      }

      const itemDetailCopy = [...new Map(itemDetail.map((item) => [item.billingComponent["value"], item])).values()];

      let dataListHelperArr = [];
      for (const itm of itemDetailCopy) {
        dispatch(getServiceOrderPriceList({ categoryId: itm.billingComponent.value, billingId: itm.billing.value }));
        // setDataListHelper((val) => [...val, { unitList: [], billingList: [], billingComponentList: [], billingComponent: [] }]);
        dataListHelperArr.push({ unitList: [], billingList: [], billingComponentList: [], billingComponent: [] });
      }

      // for (const item of itemDetail) {
      //   for (const [i, itm] of itemDetailCopy.entries())
      //     // if (item.billingComponent.value == itm.billingComponent.value) {
      //       dataListHelperArr[i].billingComponent.push(item.billingComponent);
      //     // }
      // }

      // (billingComponents, "billcomponent");

      setDataListHelper(dataListHelperArr);

      let files = [];

      if (serviceOrder.so_file.length > 0) {
        serviceOrder.so_file.forEach((element) => {
          files.push({
            id: element.id,
            filename: element.filename,
          });
        });
      }

      setOtherDocsImg(files);

      setServiceOrder({
        id: serviceOrder.id,
        transactionNo: serviceOrder.transaction_no,
        transactionReff: serviceOrder.transaction_reff,
        transactionAt: serviceOrder.transactionat,
        dueAt: serviceOrder.dueat,
        client:
          serviceOrder.so_client === null
            ? ""
            : {
                label: serviceOrder.so_client ? serviceOrder.so_client.name : "",
                value: serviceOrder.so_client ? serviceOrder.so_client.id : "",
              },
        company:
          serviceOrder.so_company === null
            ? ""
            : {
                label: serviceOrder.so_company ? serviceOrder.so_company.name : "",
                value: serviceOrder.so_company ? serviceOrder.so_company.id : "",
              },
        tax:
          serviceOrder.so_tax === null
            ? ""
            : {
                label: serviceOrder.so_tax ? serviceOrder.so_tax.name : "",
                value: serviceOrder.so_tax ? serviceOrder.so_tax.id : "",
                amount: serviceOrder.so_tax ? parseFloat(serviceOrder.so_tax.amount) : 0,
              },
        paymentMethod:
          serviceOrder.so_payment_method === null
            ? ""
            : {
                label: serviceOrder.so_payment_method ? serviceOrder.so_payment_method.name : "",
                value: serviceOrder.so_payment_method ? serviceOrder.so_payment_method.id : "",
              },
        paymentTerm:
          serviceOrder.so_payment_term === null
            ? ""
            : {
                label: serviceOrder.so_payment_term ? serviceOrder.so_payment_term.name : "",
                value: serviceOrder.so_payment_term ? serviceOrder.so_payment_term.id : "",
              },
        items: itemDetailCopy ? itemDetailCopy : [],
        subTotal: 0,
        subDiscount: 0,
        description: serviceOrder.description,
        statusId: serviceOrder.statusId,
      });

      const historyList = serviceOrder.so_history.sort((a, b) => a.createdat.split("/").reverse().join().localeCompare(b.createdat.split("/").reverse().join()));

      switch (type) {
        case "detail":
          setServiceOrder((val) => ({
            ...val,
            totalAmount: parseFloat(serviceOrder.total_amount),
          }));
          toggleDetail();
          break;

        case "history":
          setServiceOrder((val) => ({
            ...val,
            histories: historyList,
          }));
          toggleHistory();
          break;

        default:
          setIsEdit(true);
          toggle();
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggle, toggleDetail, toggleHistory]
  );

  useEffect(() => {
    if (isEdit) {
      for (const [i, itm] of validation.values.items.entries()) {
        for (const billcomponent of billingComponents) {
          if (itm.billing.value === billcomponent.billingId) {
            dataListHelper[i].billingComponent.push({
              label: billcomponent.billing_component_category.name,
              value: billcomponent.billing_component_category.id,
              billingComponentId: billcomponent.id,
              promotionId: billcomponent.promotionId,
              warrantyId: billcomponent.warrantyId,
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, billingComponents]);

  useEffect(() => {
    // (serviceOrder, "service order");
    if (serviceOrder) {
      if (Object.keys(serviceOrder).length > 0) {
        if (serviceOrderPricesList.length === serviceOrder.items.length) {
          let subTotalData = 0;
          let subDiscountData = 0;
          for (const [i, e] of serviceOrder.items.entries()) {
            subTotalData += serviceOrderPricesList[i].price;
            subDiscountData += serviceOrderPricesList[i].discount;
            e.discount = serviceOrderPricesList[i].discount;
            e.price = serviceOrderPricesList[i].price;
            e.priceNet = serviceOrderPricesList[i].price_net;
          }

          serviceOrder.subTotal = subTotalData;
          serviceOrder.subDiscount = subDiscountData;
        }
      }
    }
  }, [serviceOrderPricesList, serviceOrder]);

  function currencyFormat(num) {
    return "Rp " + parseFloat(num).toLocaleString(["ban", "id"]);
  }

  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="dataCheckbox form-check-input" value={cellProps.row.original.id} />;
        },
        id: "#",
      },
      {
        Header: "Inv No",
        accessor: "transaction_no",
        filterable: true,
        Cell: (cellProps) => (
          <Link
            to="#"
            className="fw-semibold"
            onClick={() => {
              const data = cellProps.row.original;
              handleInvoiceClick(data);
              setInfo(data);
              setModalDetail(true);
            }}
          >
            {cellProps.row.original.transaction_no}
          </Link>
        ),
      },
      // {
      //   Header: "Type",
      //   accessor: "serviceOrder_type",
      //   filterable: true,
      //   Cell: (cell) => {
      //     switch (cell.value) {
      //       case "Asset":
      //         return (
      //           <Link
      //             to="#"
      //             className="badge text-uppercase badge-soft-info"
      //             onClick={() => {
      //               const data = cell.row.original;
      //               handleInvoiceClick(data);
      //               setInfo(data);
      //               setModalDetail(true);
      //             }}
      //           >
      //             {cell.value}
      //           </Link>
      //         );
      //       default:
      //         return (
      //           <Link
      //             to="#"
      //             className="badge text-uppercase badge-soft-success"
      //             onClick={() => {
      //               const data = cell.row.original;
      //               handleInvoiceClick(data);
      //               setInfo(data);
      //               setModalDetail(true);
      //             }}
      //           >
      //             {cell.value}
      //           </Link>
      //         );
      //     }
      //   },
      // },
      {
        Header: "Inv Reff",
        accessor: "transaction_reff",
        filterable: true,
      },
      {
        Header: "Date",
        accessor: "transactionat",
        filterable: false,
        Cell: (data) => {
          return handleValidDate(data.row.original.transactionat);
        },
      },
      {
        Header: "Due Date",
        accessor: "dueat",
        filterable: false,
        Cell: (data) => {
          return handleValidDate(data.row.original.dueat);
        },
      },
      {
        Header: "Total",
        accessor: "total_amount",
        filterable: true,
        Cell: (data) => {
          return <span>{currencyFormat(data.row.original.total_amount)}</span>;
        },
      },
      // {
      //   Header: "Quantity",
      //   accessor: "sub_amount",
      //   filterable: true,
      //   Cell: (data) => {
      //     let qty = 0;
      //     data.row.original.serviceOrder_detail.forEach((element) => {
      //       qty += parseInt(element.quantity);
      //     });
      //     return <span>{qty}</span>;
      //   },
      // },
      // {
      //   Header: "Cost Center",
      //   accessor: "serviceOrder_department.department_name",
      //   filterable: true,
      // },
      {
        Header: "Status",
        accessor: "so_status.name",
        filterable: true,
        Cell: (data) => {
          switch (data.row.original.so_status.name) {
            case "Pending":
              return <span className="badge text-uppercase badge-soft-secondary">{data.row.original.so_status.name}</span>;
            case "Confirmed":
              return <span className="badge text-uppercase badge-soft-info">{data.row.original.so_status.name}</span>;
            case "Paid":
              return <span className="badge text-uppercase badge-soft-info">{data.row.original.so_status.name}</span>;
            case "Processed":
              return <span className="badge text-uppercase badge-soft-primary">{data.row.original.so_status.name}</span>;
            case "Completed":
              return <span className="badge text-uppercase badge-soft-success">{data.row.original.so_status.name}</span>;
            default:
              return <span className="badge text-uppercase badge-soft-danger">{data.row.original.so_status.name}</span>;
          }
        },
      },
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-primary"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleServiceOrderClick(data, "detail");
                  // handleInvoiceClick(data);
                  // setInfo(data);
                  // setModalDetail(true);
                }}
              >
                <i className="ri-eye-line fs-15"></i>
              </Link>
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-primary"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleServiceOrderClick(data, "history");
                  toggleHistory();
                }}
              >
                <i className=" ri-history-line fs-15"></i>
              </Link>
              {parseInt(cellProps.row.original.statusId) < 2 && (
                <>
                  <Link
                    to="#"
                    className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                    onClick={() => {
                      const data = cellProps.row.original;
                      handleServiceOrderClick(data);
                    }}
                  >
                    <i className="ri-pencil-line fs-16"></i>
                  </Link>
                  {/* <Link
                    to="#"
                    className="btn-icon btn btn-sm btn-soft-danger remove-item-btn"
                    onClick={() => {
                      const data = cellProps.row.original;
                      onClickDelete(data);
                    }}
                  >
                    <i className=" ri-delete-bin-line fs-15"></i>
                  </Link> */}
                </>
              )}
              {cellProps.row.original.statusId === 1 && (
                <button
                  className="btn-label btn btn-sm btn-soft-success fs-12 me-0"
                  onClick={() => {
                    const data = cellProps.row.original;
                    data.total_amount = parseFloat(data.total_amount);
                    validationApprove.setFieldValue("statusId", 2);
                    setServiceOrderData(data);
                    toggleApprove();
                  }}
                >
                  {" "}
                  <i className="ri-checkbox-circle-line label-icon align-middle fs-16 me-1"></i> Approve{" "}
                </button>
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleServiceOrderClick, toggleApprove, toggleHistory]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: serviceOrderList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable ? (column.isSorted ? (column.isSortedDesc ? " sorting_desc" : " sorting_asc") : " sort") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const countSearch = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const [subTotal, setSubTotal] = useState(0);
  const [ship, setShip] = useState(0);
  const [tax, setTax] = useState(0);
  const [dis, setDis] = useState(0);

  function handleFilter() {
    let startat = "";
    let endat = "";
    let datearr = filterDate.split(" to ");
    if (datearr[1]) {
      startat = datearr[0];
      endat = datearr[1];
    }

    var status = statusServiceOrder.value ? statusServiceOrder.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
      type: "requested",
    };
    dispatch(onGetServiceOrders(param));
  }

  const validationApprove = useFormik({
    enableReinitialize: true,
    initialValues: {
      approval_note: "",
      setApprove: true,
      statusId: 0,
    },
    onSubmit: (values) => {
      let dataApprove = {};

      if (serviceOrderData) {
        dataApprove = {
          ...serviceOrderData,
          statusId: values.statusId,
          approval_note: values.approval_note,
          setApprove: values.setApprove,
        };
      } else {
        dataApprove = {
          ...serviceOrder,
          statusId: values.statusId,
          approval_note: values.approval_note,
          setApprove: values.setApprove,
        };
      }
      dispatch(onUpdateServiceOrder(dataApprove));
      validationApprove.resetForm();
      toggleApprove();
      if (modalDetail) {
        toggleDetail();
      }
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (serviceOrder && serviceOrder.id) || 0,
      transactionNo: (serviceOrder && serviceOrder.transactionNo) || "",
      client: (serviceOrder && serviceOrder.client) || "",
      company: (serviceOrder && serviceOrder.company) || "",
      items: (serviceOrder && serviceOrder.items) || [],
      subTotal: (serviceOrder && serviceOrder.subTotal) || 0,
      subDiscount: (serviceOrder && serviceOrder.subDiscount) || 0,
      tax: (serviceOrder && serviceOrder.tax) || "",
      totalAmount: (serviceOrder && serviceOrder.totalAmount) || 0,
      transactionReff: (serviceOrder && serviceOrder.transactionReff) || "",
      transactionAt: (serviceOrder && serviceOrder.transactionAt) || "",
      dueAt: (serviceOrder && serviceOrder.dueAt) || "",
      paymentMethod: (serviceOrder && serviceOrder.paymentMethod) || "",
      paymentTerm: (serviceOrder && serviceOrder.paymentTerm) || "",
      description: (serviceOrder && serviceOrder.description) || "",
      file: (serviceOrder && serviceOrder.file) || [],
    },
    validationSchema: Yup.object({
      // company: Yup.object().required("Please enter company").nullable(true),
      // serviceOrder_type: Yup.object().required("Please enter serviceOrder type").nullable(true),
      // dueAt: Yup.string().required("Please enter serviceOrder name").nullable(true),
      // branch: Yup.object().required("Please enter branch name").nullable(true),
      // class: Yup.object().required("Please enter class").nullable(true),
      // price: Yup.number().required("Please enter price number").nullable(true),
      // transactionNo: Yup.string().required("Please enter transactionNo").nullable(true),
      // transactionReff: Yup.string().required("Please enter transactionReff").nullable(true),
      // company: Yup.object().required("Please enter company").nullable(true),
      // discount: Yup.number().required("Please enter discount number").nullable(true),
      // serviceOrder_type: Yup.object().required("Please enter serviceOrder type").nullable(true),
      // vendor: Yup.object().required("Please enter vendor").nullable(true),
      items: Yup.array().min(1),
    }),
    onSubmit: (values) => {
      let serviceOrderDetails = [];
      for (const [i, e] of values.items.entries()) {
        // if (e.service !== "" && e.branch !== "") {
        for (const bc of dataListHelper[i].billingComponent) {
          if (e.billingComponent.value === bc.value) {
            serviceOrderDetails.push({
              unitId: e.unit.value,
              branchId: e.branch.value,
              serviceId: e.service.value,
              billingId: e.billing.value,
              billingCategoryId: bc.value,
              billingComponentId: bc.billingComponentId,
              promotionId: bc.promotionId,
              warrantyId: bc.warrantyId,
            });
          }
          // }
        }
      }
      if (isEdit) {
        const dataEditSubmit = {
          id: values.id,
          statusId: 1,
          transaction_no: values.transactionNo,
          transaction_reff: values.transactionReff,
          transactionat: values.transactionAt,
          dueat: values.dueAt,
          description: values.description,
          clientId: values.client.value,
          companyId: values.company.value,
          paymentMethodId: values.paymentMethod.value,
          paymentTermId: values.paymentTerm.value,
          sub_amount: values.subTotal,
          disc_amount: values.subDiscount,
          total_amount: values.totalAmount,
          taxId: values.tax.value,
          so_detail: serviceOrderDetails.length > 0 ? serviceOrderDetails : [],
          files: otherDocsImg.length > 0 ? otherDocsImg : [],
        };

        dispatch(onUpdateServiceOrder(dataEditSubmit));
      } else {
        const dataAddSubmit = {
          statusId: 1,
          transaction_no: values.transactionNo,
          transaction_reff: values.transactionReff,
          transactionat: values.transactionAt,
          dueat: values.dueAt,
          description: values.description,
          clientId: values.client.value,
          companyId: values.company.value,
          paymentMethodId: values.paymentMethod.value,
          paymentTermId: values.paymentTerm.value,
          sub_amount: values.subTotal,
          disc_amount: values.subDiscount,
          total_amount: values.totalAmount,
          taxId: values.tax.value,
          so_detail: serviceOrderDetails.length > 0 ? serviceOrderDetails : [],
          files: otherDocsImg.length > 0 ? otherDocsImg : [],
        };

        dispatch(onAddNewServiceOrder(dataAddSubmit));
      }
      validation.resetForm();
      toggle();
    },
  });

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteServiceOrder(element.value));
    });
    checkall.checked = false;
  };

  function handleTotalServiceOrder() {
    let sub_total = 0;
    for (const item of serviceOrderDetailItem) {
      sub_total += parseInt(item.quantity * item.amount);
    }
    setSubTotal(sub_total);
  }

  function handleServiceOrderDetailAdd() {
    validation.setFieldValue("items", [...validation.values.items, { service: "", billing: "", branch: "", unit: "", billingComponent: "", discount: 0, price: 0, priceNet: 0 }]);

    // let resetDiscount = 0;
    // // validation.values.items.forEach((e)=>{
    // //   resetDiscount +=  e.discount;
    // // });

    // for (const e of validation.values.items) {
    //   resetDiscount += e.discount;
    // }

    // validation.setFieldValue("subDiscount", resetDiscount);
    setDataListHelper((val) => [...val, { unitList: [], billingList: [], billingComponentList: [], billingComponent: [] }]);
  }

  useEffect(() => {
    // (dataListHelper);
  }, [dataListHelper]);

  const onClickDeleteDetailItem = (index) => {
    const arrayCopy = [...validation.values.items];
    validation.setFieldValue("subTotal", validation.values.subTotal - arrayCopy[index].price);
    arrayCopy.splice(index, 1);

    const arrayDataListHelperCopy = [...dataListHelper];

    arrayDataListHelperCopy.splice(index, 1);
    validation.setFieldValue("items", arrayCopy);

    setDataListHelper(arrayDataListHelperCopy);
    // (newArr);
    // validation.setValues((val)=> val.items.filter((e, i)=> (i !== index)));
    // const object = serviceOrderDetailItem.findIndex((obj) => obj.detailId === detail.detailId);
    // if (object > -1) {
    //   serviceOrderDetailItem.splice(object, 1);
    // }
  };

  useEffect(() => {
    let resetDiscount = 0;
    for (const e of validation.values.items) {
      resetDiscount += e.discount;
    }

    validation.setFieldValue("subDiscount", resetDiscount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation.values.items, serviceOrderPricesList]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);
    validation.setFieldValue("files", files);
  }

  // function handleDeleteFiles(filesId) {
  //   const object = originalFiles.findIndex((obj) => obj.id === filesId);
  //   if (object > -1) {
  //     dispatch(deleteFile({ id: filesId }));
  //     originalFiles.splice(object, 1);
  //   }
  // }

  function handleDeleteUploadedFiles(filesId) {
    const object = selectedFiles.findIndex((obj, idx) => idx === filesId);
    if (object > -1) selectedFiles.splice(object, 1);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // useEffect(() => {
  //   if (clients && !clients.length) {
  //     dispatch(resetClientState());
  //     dispatch(getClient());
  //   }
  // }, [dispatch, clients]);

  // useEffect(() => {
  //   if (services && !services.length) {
  //     dispatch(resetServiceState());
  //     dispatch(getService());
  //   }
  // }, [dispatch, services]);

  const resetList = () => {
    dispatch(resetClientState());
    dispatch(resetServiceState());
    dispatch(resetTransactionTaxState());
    dispatch(resetPaymentMethodState());
    dispatch(resetPaymentTermState());
    dispatch(resetServiceOrderPriceState());
    setPaymentMethodList([]);
    setPaymentTermList([]);
    setTax([]);
    setClientList([]);
    setServiceList([]);
    setCompanyList([]);
    setBranchList([]);
    setDataListHelper([]);
    setIndexItem(null);
    setServiceOrder({});
    setOtherDocsImg([]);
    validation.resetForm();
    validation.setErrors({});
  };

  const handleGetCompany = (id) => {
    dispatch(resetCompanyState());
    dispatch(getCompany({ clientId: id }));
  };

  const handleGetBranch = (id) => {
    dispatch(resetBranchState());
    dispatch(getBranch({ companyId: id }));
  };

  const handleGetUnit = (id, index) => {
    var data = [];
    var opt = [];
    units.forEach((item, key) => {
      if (item.branchId === id) {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      }
    });
    var option = { options: opt };
    data.push(option);

    let dataListHelperCopy = [...dataListHelper];
    if (opt.length > 0) {
      dataListHelperCopy[index].unitList = data;
      setDataListHelper(dataListHelperCopy);
    } else {
      dataListHelperCopy[index].unitList = [];
      setDataListHelper(dataListHelperCopy);
    }
  };

  const handleGetBilling = (id, index) => {
    var data = [];
    var opt = [];
    billings.forEach((item, key) => {
      if (item.serviceId === id) {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      }
    });
    var option = { options: opt };
    data.push(option);

    let dataListHelperCopy = [...dataListHelper];
    if (opt.length > 0) {
      dataListHelperCopy[index].billingList = data;
      setDataListHelper(dataListHelperCopy);
    } else {
      dataListHelperCopy[index].billingList = [];
      setDataListHelper(dataListHelperCopy);
    }
  };

  const handleGetBillingComponent = (id, index) => {
    var data = [];
    var opt = [];

    billingComponents.forEach((item, key) => {
      if (item.billingId === id) {
        if (item.billing_component_category) {
          // (item);
          const name = item.billing_component_category.name;
          const id = item.billing_component_category.id;
          const billingComponentId = item.id;
          const promotionId = item.promotionId;
          const warrantyId = item.warrantyId;
          var obj = { label: name, value: id, billingComponentId: billingComponentId, warrantyId: warrantyId, promotionId: promotionId };
          opt.push(obj);
        }
      }
    });

    const optCopy = [...new Map(opt.map((item) => [item["value"], item])).values()];
    var option = { options: optCopy };
    data.push(option);

    let dataListHelperCopy = [...dataListHelper];
    if (opt.length > 0) {
      dataListHelperCopy[index].billingComponentList = data;
      dataListHelperCopy[index].billingComponent = opt;
      setDataListHelper(dataListHelperCopy);
    } else {
      dataListHelperCopy[index].billingComponentList = [];
      dataListHelperCopy[index].billingComponent = [];
      setDataListHelper(dataListHelperCopy);
    }
  };

  const handleGetServiceOrderPrice = (categoryId, billingId) => {
    dispatch(resetServiceOrderPriceState());
    dispatch(getServiceOrderPrice({ categoryId: categoryId, billingId: billingId }));
  };

  const renderTitleApprove = (status) => {
    switch (status) {
      case 3:
        return "Paid";
      case 4:
        return "Processed";
      case 8:
        return "Cancelled";

      default:
        return "Approve";
    }
  };

  useEffect(() => {
    resetList();
    return () => {
      dispatch(resetServiceOrderState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(serviceOrderPrices).length > 0 && indexItem !== null) {
      // ("jalan");
      let itemsCopy = [...validation.values.items];
      itemsCopy[indexItem].discount = serviceOrderPrices.discount;
      itemsCopy[indexItem].price = serviceOrderPrices.price;
      itemsCopy[indexItem].priceNet = serviceOrderPrices.price_net;
      validation.setFieldValue("items", itemsCopy);
      validation.setFieldValue("subTotal", validation.values.subTotal + serviceOrderPrices.price);
      validation.setFieldValue("subDiscount", validation.values.subDiscount + serviceOrderPrices.discount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceOrderPrices, indexItem]);

  useEffect(() => {
    // (validation.values);
    let totAmount = 0;
    if (validation.values.tax === "") {
      totAmount = validation.values.subTotal - validation.values.subDiscount;
    } else {
      totAmount = validation.values.subTotal - validation.values.subDiscount + parseFloat(validation.values.tax.amount);
    }
    validation.setFieldValue("totalAmount", totAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation.values.subTotal, validation.values.subDiscount, validation.values.tax]);

  useEffect(() => {
    if (validation.values.items.length < 1) {
      validation.setFieldValue("tax", "");
      validation.setFieldValue("totalAmount", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation.values.items]);

  useEffect(() => {
    // (dataListHelper, "dataHel");
    // (validation.values, "valll");
  }, [validation.values]);

  document.title = "Service Order Request | Aptek";

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={serviceOrderList} />
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteServiceOrder} onCloseClick={() => setDeleteModal(false)} />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title="Service Order Request" pageTitle="Service Order" />
        <Row>
          <Col xxl={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Service Orders &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color="primary" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      id="create-btn"
                      onClick={() => {
                        setServiceOrder("");
                        setIsEdit(false);
                        toggle();
                      }}
                    >
                      <i className="ri-archive-line me-1 align-bottom fs-14"></i> Add New
                    </button>{" "}
                    <button type="button" className="btn btn-success" onClick={() => setIsExportCSV(true)}>
                      <i className="ri-file-download-line me-1 align-bottom fs-14"></i> Export
                    </button>{" "}
                    <button className="btn btn-soft-danger" onClick={() => setDeleteModalMulti(true)}>
                      <i className="ri-delete-bin-5-line me-1 align-bottom fs-15"></i> Delete
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className=" ri-group-line me-1 align-bottom fs-14"></i> All ServiceOrders
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("2", "Pending");
                        }}
                        href="#"
                      >
                        <i className="ri-information-line me-1 align-bottom fs-14"></i> Pending
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("3", "Confirmed");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Confirmed{" "}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("4", "Paid");
                        }}
                        href="#"
                      >
                        <i className="ri-information-line me-1 align-bottom fs-14"></i> Paid
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "5" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("5", "Processed");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Processed{" "}
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "6" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("6", "Completed");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Completed
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "7" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("7", "Cancelled");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Cancelled
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className="mb-3">
                    <CardBody className="border border-top-0 border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={1} className="col-xxl-2">
                            <div className="me-2 mb-3 col-12">
                              <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                {" "}
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="search-box me-2 mb-3 d-inline-block col-12">
                              <input
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                placeholder={`${countSearch} Search...`}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col sm={6} className="col-xxl-4">
                            <div className="search-box me-2 mb-3 col-12">
                              <Flatpickr
                                className="form-control"
                                id="datepicker-publish-input"
                                placeholder="Select publish date"
                                options={{
                                  mode: "range",
                                  dateFormat: "Y/m/d",
                                }}
                                onChange={(selectedDates, dateStr) => {
                                  setFilterDate(dateStr);
                                }}
                              />
                              <i className="bx bx-calendar-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col className="col-xxl-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-light w-100"
                                onClick={() => {
                                  handleFilter();
                                }}
                              >
                                {" "}
                                <i className="ri-search-line me-1 align-bottom fs-14"></i> Search
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card mb-1">
                    <Table hover {...getTableProps()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroups.map((headerGroup) => (
                          <tr className={""} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={previousPage} disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
                    </Col>
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={nextPage} disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>

                <Modal id="showModal" isOpen={modal} centered className="modal-fullscreen">
                  <ModalHeader className="bg-light p-3" toggle={toggle}>
                    {!!isEdit ? "Edit ServiceOrder Request" : "Add ServiceOrder Request"}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody className="bg-white">
                      <CardBody className="border-bottom border-bottom-dashed p-4">
                        <Row>
                          <Col lg={5} className="d-flex justify-content-center align-items-center">
                            <div className="profile-user mx-auto mb-3">
                              <Input id="profile-img-file-input" type="file" className="profile-img-file-input" />
                              <Label for="profile-img-file-input" className="d-block">
                                <span className="overflow-hidden border border-dashed d-flex align-items-center justify-content-center rounded" style={{ height: "60px", width: "256px" }}>
                                  <img src={logoDark} className="card-logo card-logo-dark user-profile-image img-fluid" alt="logo dark" />
                                  <img src={logoLight} className="card-logo card-logo-light user-profile-image img-fluid" alt="logo light" />
                                </span>
                              </Label>
                            </div>
                            <div>
                              {/* <div>
                                <Label for="companyAddress">Ship To</Label>
                              </div>
                              <div className="mb-3">
                                <Select
                                  name="branch"
                                  placeholder="Select Warehouse"
                                  value={validation.values.branch}
                                  onChange={(e) => {
                                    validation.setFieldValue("branch", e);
                                  }}
                                  options={branchList}
                                ></Select>
                                {validation.touched.branch && validation.errors.branch && (
                                  <small className="text-danger" type="invalid">
                                    {validation.errors.branch}
                                  </small>
                                )}
                              </div> */}
                              {/* <Row className="mb-3">
                                <Col lg={7} sm={7}>
                                  <Select
                                    name="shipment_carrier"
                                    placeholder="Shipping Carrier"
                                    value={validation.values.shipment_carrier}
                                    onChange={(e) => {
                                      validation.setFieldValue("shipment_carrier", e);
                                    }}
                                    options={shipmentCarrierList}
                                  ></Select>
                                  {validation.touched.shipment_carrier && validation.errors.shipment_carrier && (
                                    <small className="text-danger" type="invalid">
                                      {validation.errors.shipment_carrier}
                                    </small>
                                  )}
                                </Col>
                                <Col lg={5} sm={5}>
                                  <Select
                                    name="shipment_method"
                                    placeholder="Method"
                                    value={validation.values.shipment_method}
                                    onChange={(e) => {
                                      validation.setFieldValue("shipment_method", e);
                                    }}
                                    options={shipmentMethodList}
                                  ></Select>
                                  {validation.touched.shipment_method && validation.errors.shipment_method && (
                                    <small className="text-danger" type="invalid">
                                      {validation.errors.shipment_method}
                                    </small>
                                  )}
                                </Col>
                              </Row> */}
                            </div>
                          </Col>
                          <Col lg={4} className="ms-auto">
                            <div className="mb-3">
                              <div className="position-relative">
                                <Select
                                  name="client"
                                  value={validation.values.client}
                                  placeholder="Select Client"
                                  onChange={(e) => {
                                    setCompanyList([]);
                                    validation.setFieldValue("company", "");
                                    let dataListHelperCopy = [...dataListHelper];
                                    dataListHelperCopy.forEach((_, i) => {
                                      dataListHelperCopy[i].branchList = [];
                                      dataListHelperCopy[i].unitList = [];
                                    });

                                    setDataListHelper(dataListHelperCopy);
                                    let itemsCopy = [...validation.values.items];
                                    itemsCopy.forEach((_, i) => {
                                      itemsCopy[i].unit = "";
                                      itemsCopy[i].branch = "";
                                    });

                                    validation.setFieldValue("items", itemsCopy);
                                    validation.setFieldValue("client", e);
                                    handleGetCompany(e.value);
                                  }}
                                  options={clientList}
                                ></Select>
                                <div
                                  className="position-absolute"
                                  style={{
                                    top: "12px",
                                    left: "-24px",
                                  }}
                                >
                                  {clientLoading ? (
                                    <Spinner color="primary" size="sm">
                                      Loading...
                                    </Spinner>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              {validation.touched.client && validation.errors.client ? (
                                <p className="invalid-feedback d-block" type="invalid">
                                  {validation.errors.client}
                                </p>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <div className="position-relative">
                                <Select
                                  name="company"
                                  value={validation.values.company}
                                  placeholder="Select Company"
                                  isDisabled={companyList.length > 0 ? false : true}
                                  onChange={(e) => {
                                    let dataListHelperCopy = [...dataListHelper];
                                    dataListHelperCopy.forEach((_, i) => {
                                      dataListHelperCopy[i].branchList = [];
                                      dataListHelperCopy[i].unitList = [];
                                    });
                                    setDataListHelper(dataListHelperCopy);
                                    let itemsCopy = [...validation.values.items];
                                    itemsCopy.forEach((_, i) => {
                                      itemsCopy[i].unit = "";
                                      itemsCopy[i].branch = "";
                                    });

                                    validation.setFieldValue("items", itemsCopy);

                                    validation.setFieldValue("company", e);
                                    handleGetBranch(e.value);
                                  }}
                                  options={companyList}
                                ></Select>
                                <div
                                  className="position-absolute"
                                  style={{
                                    top: "12px",
                                    left: "-24px",
                                  }}
                                >
                                  {companyLoading ? (
                                    <Spinner color="primary" size="sm">
                                      Loading...
                                    </Spinner>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              {validation.touched.company && validation.errors.company ? (
                                <p className="invalid-feedback d-block" type="invalid">
                                  {validation.errors.company}
                                </p>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Input name="transactionNo" id="transactionNo" className="form-control" placeholder="Enter Transaction No" type="text" value={validation.values.transactionNo} onChange={validation.handleChange} onBlur={validation.handleBlur} invalid={validation.touched.transactionNo && validation.errors.transactionNo ? true : false} />
                              {validation.touched.transactionNo && validation.errors.transactionNo && (
                                <small className="text-danger" type="invalid">
                                  {validation.errors.transactionNo}
                                </small>
                              )}
                              <small className="text-success" type="invalid">
                                * automatic numbering if left blank
                              </small>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardBody className="p-4">
                        <Row className="g-3">
                          <Col lg={4} sm={12}>
                            <div className="mb-3">
                              <label className="form-label" htmlFor="transactionReff">
                                {" "}
                                Transaction Reff{" "}
                              </label>
                              <Input name="transactionReff" id="transactionReff" className="form-control" placeholder="Enter Transaction Reff" type="text" value={validation.values.transactionReff} onChange={validation.handleChange} onBlur={validation.handleBlur} invalid={validation.touched.transactionReff && validation.errors.transactionReff ? true : false} />
                              {validation.touched.transactionReff && validation.errors.transactionReff && (
                                <small className="text-danger" type="invalid">
                                  {validation.errors.transactionReff}
                                </small>
                              )}
                            </div>
                          </Col>
                          <Col lg={4} sm={12}>
                            <div className="mb-3">
                              <label htmlFor="datepicker-publish-input" className="form-label">
                                Transaction Date
                              </label>
                              <Flatpickr
                                name="transactionAt"
                                className="form-control"
                                placeholder="Transaction date"
                                options={{ enableTime: false, time_24hr: false, dateFormat: "Y/m/d" }}
                                value={validation.values.transactionAt || ""}
                                onChange={(obj, str) => {
                                  validation.setFieldValue("transactionAt", str);
                                }}
                              />
                              {validation.touched.transactionAt && validation.errors.transactionAt && (
                                <small className="text-danger" type="invalid">
                                  {validation.errors.transactionAt}
                                </small>
                              )}
                            </div>
                          </Col>
                          <Col lg={4} sm={12}>
                            <div className="mb-3">
                              <label htmlFor="datepicker-publish-input" className="form-label">
                                Due Date
                              </label>
                              <Flatpickr
                                name="dueAt"
                                className="form-control"
                                placeholder="Due date"
                                options={{ enableTime: false, time_24hr: false, dateFormat: "Y/m/d" }}
                                value={validation.values.dueAt || ""}
                                onChange={(obj, str) => {
                                  validation.setFieldValue("dueAt", str);
                                }}
                              />
                              {validation.touched.dueAt && validation.errors.dueAt && (
                                <small className="text-danger" type="invalid">
                                  {validation.errors.dueAt}
                                </small>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardBody className="p-4 border-top border-top-dashed position-relative">
                        {clientLoading || serviceLoading || transactionTaxLoading || companyLoading || brancheLoading || unitLoading || billingLoading || billingComponentLoading || serviceOrderPriceLoading ? (
                          <div className="position-absolute top-0">
                            <Spinner color="primary" type="grow" size={"sm"}>
                              Loading...
                            </Spinner>
                            &nbsp;
                            <Spinner color="success" type="grow" size={"sm"}>
                              Loading...
                            </Spinner>
                            &nbsp;
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="table-responsive">
                          <Table className="invoice-table table-borderless table-nowrap mb-0">
                            <thead className="align-middle">
                              <tr className="table-active">
                                <th scope="col" style={{ width: "5%" }}>
                                  #
                                </th>
                                <th scope="col" style={{ width: "11.25%" }}>
                                  {props.t("Service")}
                                </th>
                                <th scope="col" style={{ width: "11.25%" }}>
                                  {props.t("Branch")}
                                </th>
                                <th scope="col" style={{ width: "11.25%" }}>
                                  {props.t("Unit")}
                                </th>
                                <th scope="col" style={{ width: "11.25%" }}>
                                  {props.t("Billing")}
                                </th>
                                <th scope="col" style={{ width: "11.25%" }}>
                                  {props.t("Type")}
                                </th>
                                <th scope="col" style={{ width: "11.25%" }}>
                                  {props.t("Discount")}
                                </th>
                                <th scope="col" style={{ width: "11.25%" }}>
                                  {props.t("Price")}
                                </th>
                                <th scope="col" style={{ width: "11.25%" }}>
                                  {props.t("Price Net")}
                                </th>
                                <th scope="col" style={{ width: "5%" }}></th>
                              </tr>
                            </thead>
                            <tbody id="newlink">
                              {validation.values.items.map((o, i) => {
                                return (
                                  <tr key={i} id={i} className="product">
                                    <th scope="row" className="product-id">
                                      <p className="order-text">{i + 1}</p>
                                    </th>
                                    <td>
                                      <Select
                                        name="service"
                                        value={validation.values.items[i].service}
                                        placeholder="Select Service"
                                        onChange={(e) => {
                                          let dataListHelperCopy = [...dataListHelper];
                                          dataListHelperCopy[i].billingList = [];
                                          dataListHelperCopy[i].billingComponentList = [];
                                          dataListHelperCopy[i].billingComponent = [];
                                          setDataListHelper(dataListHelperCopy);
                                          let itemsCopy = [...validation.values.items];
                                          validation.setFieldValue("subTotal", validation.values.subTotal - itemsCopy[i].price);
                                          validation.setFieldValue("subDiscount", validation.values.subDiscount - itemsCopy[i].discount);
                                          itemsCopy[i].billing = "";
                                          itemsCopy[i].billingComponent = "";
                                          itemsCopy[i].price = 0;
                                          itemsCopy[i].discount = 0;
                                          itemsCopy[i].priceNet = 0;
                                          itemsCopy[i].service = e;

                                          validation.setFieldValue("items", itemsCopy);
                                          handleGetBilling(e.value, i);
                                        }}
                                        options={serviceList}
                                        validate={{ required: { value: true } }}
                                        invalid={validation.touched.service && validation.errors.service ? true : false}
                                      ></Select>
                                      {validation.touched.service && validation.errors.service ? (
                                        <p className="invalid-feedback d-block" type="invalid">
                                          {validation.errors.service}
                                        </p>
                                      ) : null}
                                    </td>
                                    <td>
                                      <Select
                                        name="branch"
                                        value={validation.values.items[i].branch}
                                        placeholder="Select Branch"
                                        isDisabled={branchList.length > 0 ? false : true}
                                        onChange={(e) => {
                                          let dataListHelperCopy = [...dataListHelper];
                                          dataListHelperCopy[i].unitList = [];
                                          setDataListHelper(dataListHelperCopy);
                                          let itemsCopy = [...validation.values.items];
                                          itemsCopy[i].unit = "";
                                          itemsCopy[i].branch = e;

                                          validation.setFieldValue("items", itemsCopy);
                                          handleGetUnit(e.value, i);
                                        }}
                                        options={branchList}
                                      ></Select>
                                      {validation.touched.branch && validation.errors.branch ? (
                                        <p className="invalid-feedback d-block" type="invalid">
                                          {validation.errors.branch}
                                        </p>
                                      ) : null}
                                    </td>
                                    <td>
                                      <Select
                                        name="unit"
                                        value={validation.values.items[i].unit}
                                        placeholder="Select Unit"
                                        isDisabled={dataListHelper[i] && dataListHelper[i].unitList.length > 0 ? false : true}
                                        onChange={(e) => {
                                          let itemsCopy = [...validation.values.items];
                                          itemsCopy[i].unit = e;

                                          validation.setFieldValue("items", itemsCopy);
                                        }}
                                        options={dataListHelper[i] ? dataListHelper[i].unitList : []}
                                      ></Select>
                                      {validation.touched.unit && validation.errors.unit ? (
                                        <p className="invalid-feedback d-block" type="invalid">
                                          {validation.errors.unit}
                                        </p>
                                      ) : null}
                                    </td>
                                    <td>
                                      <Select
                                        name="billing"
                                        value={validation.values.items[i].billing}
                                        placeholder="Select Billing"
                                        isDisabled={dataListHelper[i] && dataListHelper[i].billingList.length > 0 ? false : true}
                                        onChange={(e) => {
                                          let dataListHelperCopy = [...dataListHelper];
                                          dataListHelperCopy[i].billingComponentList = [];
                                          dataListHelperCopy[i].billingComponent = [];
                                          setDataListHelper(dataListHelperCopy);
                                          let itemsCopy = [...validation.values.items];
                                          validation.setFieldValue("subTotal", validation.values.subTotal - itemsCopy[i].price);
                                          validation.setFieldValue("subDiscount", validation.values.subDiscount - itemsCopy[i].discount);
                                          itemsCopy[i].billingComponent = "";
                                          itemsCopy[i].price = 0;
                                          itemsCopy[i].discount = 0;
                                          itemsCopy[i].priceNet = 0;
                                          itemsCopy[i].billing = e;

                                          validation.setFieldValue("items", itemsCopy);
                                          handleGetBillingComponent(e.value, i);
                                        }}
                                        options={dataListHelper[i] ? dataListHelper[i].billingList : []}
                                      ></Select>
                                      {validation.touched.billing && validation.errors.billing ? (
                                        <p className="invalid-feedback d-block" type="invalid">
                                          {validation.errors.billing}
                                        </p>
                                      ) : null}
                                    </td>
                                    <td>
                                      <Select
                                        name="billingComponent"
                                        value={validation.values.items[i].billingComponent}
                                        placeholder="Select Billing Component"
                                        isDisabled={dataListHelper[i] && dataListHelper[i].billingComponentList.length > 0 ? false : true}
                                        onChange={(e) => {
                                          let itemsCopy = [...validation.values.items];
                                          validation.setFieldValue("subTotal", validation.values.subTotal - itemsCopy[i].price);
                                          validation.setFieldValue("subDiscount", validation.values.subDiscount - itemsCopy[i].discount);
                                          itemsCopy[i].price = 0;
                                          itemsCopy[i].discount = 0;
                                          itemsCopy[i].priceNet = 0;
                                          itemsCopy[i].billingComponent = e;

                                          validation.setFieldValue("items", itemsCopy);

                                          setIndexItem(i);
                                          handleGetServiceOrderPrice(e.value, validation.values.items[i].billing.value);
                                        }}
                                        options={dataListHelper[i] ? dataListHelper[i].billingComponentList : []}
                                      ></Select>
                                      {validation.touched.billingComponent && validation.errors.billingComponent ? (
                                        <p className="invalid-feedback d-block" type="invalid">
                                          {validation.errors.billingComponent}
                                        </p>
                                      ) : null}
                                    </td>
                                    <td>
                                      <p className="text-end order-text">{validation.values.items[i].discount ? currencyFormat(validation.values.items[i].discount) : currencyFormat(0)}</p>
                                    </td>
                                    <td>
                                      <p className="text-end order-text">{validation.values.items[i].price ? currencyFormat(validation.values.items[i].price) : currencyFormat(0)}</p>
                                    </td>
                                    <td>
                                      <p className="text-end order-text">{validation.values.items[i].priceNet ? currencyFormat(validation.values.items[i].priceNet) : currencyFormat(0)}</p>
                                    </td>
                                    <td className="product-removal">
                                      <Link
                                        to="#"
                                        className="text-danger btn-icon btn btn-soft-danger flex-shrink-0"
                                        onClick={() => {
                                          // const data = validation.values.items[i];
                                          // ("ok");
                                          onClickDeleteDetailItem(i);
                                        }}
                                      >
                                        <i className=" ri-delete-bin-line fs-15"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <tbody>
                              <tr>
                                <td className="10">
                                  {validation.touched.items && validation.errors.items && (
                                    <small className="text-danger" type="invalid">
                                      {validation.errors.items}
                                    </small>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                            <tbody>
                              <tr id="newForm" style={{ display: "none" }}>
                                <td className="d-none" colSpan="5">
                                  <p>Add New Form</p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="5">
                                  <Link
                                    to="#"
                                    className="btn btn-soft-success fw-medium"
                                    id="add-item"
                                    onClick={() => {
                                      handleServiceOrderDetailAdd();
                                    }}
                                  >
                                    <i className="ri-add-fill me-1 align-bottom"></i>Add Item
                                  </Link>
                                </td>
                              </tr>
                              <tr className="border-top border-top-dashed mt-2">
                                <td colSpan="2">
                                  <div className="mb-3">
                                    <Select
                                      name="paymentMethod"
                                      value={validation.values.paymentMethod}
                                      placeholder="Select Payment Method"
                                      onChange={(e) => {
                                        validation.setFieldValue("paymentMethod", e);
                                      }}
                                      options={paymentMethodList}
                                    ></Select>
                                    {validation.touched.paymentMethod && validation.errors.paymentMethod && (
                                      <small className="text-danger" type="invalid">
                                        {validation.errors.paymentMethod}
                                      </small>
                                    )}
                                  </div>
                                  <div className="mb-3">
                                    <Select
                                      name="paymentTerm"
                                      value={validation.values.paymentTerm}
                                      placeholder="Select Payment Term"
                                      onChange={(e) => {
                                        validation.setFieldValue("paymentTerm", e);
                                      }}
                                      options={paymentTermList}
                                    ></Select>
                                    {validation.touched.paymentTerm && validation.errors.paymentTerm && (
                                      <small className="text-danger" type="invalid">
                                        {validation.errors.paymentTerm}
                                      </small>
                                    )}
                                  </div>
                                  {/* <div className="mb-3">
                                    <Select
                                      name="account"
                                      value={validation.values.account}
                                      placeholder="Select Account"
                                      onChange={(e) => {
                                        validation.setFieldValue("account", e);
                                      }}
                                      options={accountingList}
                                    ></Select>
                                    {validation.touched.account && validation.errors.account && (
                                      <small className="text-danger" type="invalid">
                                        {validation.errors.account}
                                      </small>
                                    )}
                                  </div> */}
                                  <div className="mb-3">
                                    <Input type="textarea" className="form-control" id="description" placeholder="Enter Description " name="description" rows={6} value={validation.values.description || ""} onBlur={validation.handleBlur} onChange={validation.handleChange} invalid={validation.touched.description && validation.errors.description ? true : false} />
                                    {validation.touched.description && validation.errors.description && (
                                      <small className="text-danger" type="invalid">
                                        {validation.errors.description}
                                      </small>
                                    )}
                                  </div>
                                  <div className="mb-3 d-flex justify-content-between">
                                    <Label htmlFor="education" className="form-label">
                                      Other Document
                                      {/* {educationLevelLoading && (
                                <Spinner color="primary" size="sm">
                                  Loading...
                                </Spinner>
                              )} */}
                                    </Label>
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-label btn-soft-success"
                                      onClick={() => {
                                        // setMediaType("other");
                                        toggleMedia();
                                      }}
                                    >
                                      <i className="ri-file-cloud-fill label-icon align-middle fs-16 me-1"></i> Media
                                    </button>
                                  </div>
                                  {otherDocsImg.map((otherDocFile, i) => {
                                    if (otherDocFile.isDeleted) {
                                      return null;
                                    } else {
                                      return (
                                        <div key={i} className="border-bottom mb-3">
                                          <div className="d-flex justify-content-between mb-3 align-items-center">
                                            <div className="me-2">
                                              <img
                                                src={otherDocFile.filename === "" ? logo : `${api.MEDIA_URL}/media/${otherDocFile.filename}`}
                                                alt=""
                                                data-dz-thumbnail=""
                                                height="30"
                                                className="avatar-sm rounded bg-light"
                                                onError={({ currentTarget }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src = imgNotFound;
                                                }}
                                              />
                                            </div>

                                            <div className="text-truncate">{otherDocFile.filename}</div>
                                            <div>
                                              <Link
                                                to="#"
                                                className={"btn-icon btn btn-sm btn-soft-danger remove-item-btn mx-2"}
                                                onClick={(e) => {
                                                  // e.stopPropagation();
                                                  handleDeleteFile(otherDocFile);
                                                }}
                                              >
                                                <i className=" ri-delete-bin-line fs-15"></i>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td colSpan="2" className="p-0">
                                  <Table className="table-borderless table-sm table-nowrap align-middle mb-0">
                                    <tbody>
                                      <tr>
                                        <th scope="row">Sub Total</th>
                                        <td className="text-end">{currencyFormat(validation.values.subTotal)}</td>
                                        {/* <td style={{ width: "150px" }}>
                                          <Input name="sub_total_desc" value={currencyFormat(validation.values.subTotal)} type="text" className="form-control bg-light border-0 text-end" id="cart-subtotal" style={{ width: "180px" }} placeholder="0.00" disabled />
                                        </td> */}
                                      </tr>
                                      <tr>
                                        <th scope="row">Discount</th>
                                        {/* <td scope="row" className="text-end">
                                          {currencyFormat(validation.values.subTotal)}
                                        </td> */}
                                        <td>
                                          <Input
                                            name="discount"
                                            id="discount"
                                            className="form-control text-end"
                                            placeholder="Discount"
                                            type="number"
                                            value={validation.values.subDiscount}
                                            onChange={(e) => {
                                              validation.setFieldValue("subDiscount", e.target.value);
                                              // handleTotalServiceOrder();
                                            }}
                                            onBlur={validation.handleBlur}
                                          />
                                        </td>
                                      </tr>

                                      <tr>
                                        <th scope="row">
                                          <Select
                                            name="tax"
                                            value={validation.values.tax}
                                            isDisabled={validation.values.subTotal !== 0 ? false : true}
                                            placeholder="Select Tax"
                                            onChange={(e) => {
                                              validation.setFieldValue("tax", e);
                                              // handleTotalServiceOrder();
                                            }}
                                            options={taxList}
                                          ></Select>
                                          {validation.touched.tax && validation.errors.tax && (
                                            <small className="text-danger" type="invalid">
                                              {validation.errors.tax}
                                            </small>
                                          )}
                                        </th>
                                        <td className="text-end">{validation.values.tax === "" ? currencyFormat(0) : currencyFormat(validation.values.tax.amount)}</td>
                                      </tr>
                                      <tr className="border-top border-top-dashed">
                                        <th scope="row">Total Amount</th>
                                        <td className="text-end">{validation.values.totalAmount ? currencyFormat(validation.values.totalAmount) : currencyFormat(0)}</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </ModalBody>
                    <div className="modal-footer bg-white">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            toggle();
                          }}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-success" disabled={loading}>
                          <span className="d-flex align-items-center">
                            {!!isEdit ? "Update" : "Add ServiceOrder Request"}
                            {loading ? (
                              <Spinner size="sm" className="flex-shrink-0" role="status">
                                Loading...
                              </Spinner>
                            ) : (
                              ""
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <Modal id="showModalHistory" isOpen={modalHistory} centered size="F">
                  <ModalHeader className="bg-light p-3" toggle={toggleHistory}>
                    ServiceOrder Histories
                  </ModalHeader>
                  <ModalBody>
                    <div className="profile-timeline">
                      <div></div>
                      <div className="accordion accordion-flush" id="todayExample">
                        {(serviceOrder.histories ? serviceOrder.histories : []).map((item, i) => {
                          return (
                            <div key={i} className="accordion-item border-0">
                              <div className="accordion-header">
                                <button className="accordion-button p-2 shadow-none" type="button" id="headingOne">
                                  <div className="d-flex">
                                    <div className="flex-shrink-0">
                                      {item.so_history_createdby.image !== null && item.so_history_createdby.image !== "" ? (
                                        <img
                                          src={api.MEDIA_URL + "/media/" + item.so_history_createdby.image}
                                          alt=""
                                          className="avatar-xs rounded-circle me-1"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = imgNotFound;
                                          }}
                                        />
                                      ) : (
                                        <div className="flex-shrink-0 avatar-xs me-1">
                                          <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">{item.so_history_createdby.name !== null ? item.so_history_createdby.name.charAt(0).toUpperCase() : "-"}</div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                      <h6 className="fs-14 mb-1">{item.so_history_createdby.name}</h6>
                                      <p className="fw-normal text-muted mb-1">
                                        Change serviceOrder status to <span className="fw-semibold text-info">{item.so_history_status.name}</span> with note:
                                      </p>
                                      <p className="fw-normal mb-1">{item.notes || "-"}</p>
                                      <small className="text-muted">{moment(item.createdat).tz("Asia/Jakarta").fromNow()}</small>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </ModalBody>
                  <div className="modal-footer">
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          setModalHistory(false);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Modal>
                <Modal id="showModalApprove" isOpen={modalApprove} centered size="md">
                  <ModalHeader className="bg-light p-3" toggle={toggleApprove}>
                    {`Add Product ${renderTitleApprove(validationApprove.values.statusId)}`}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validationApprove.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody>
                      <div className="mt-2 text-center">
                        <lord-icon src="https://cdn.lordicon.com/puvaffet.json" trigger="loop" colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                          <h4>Are you sure ?</h4>
                          <p className="text-muted mx-4 mb-0">
                            {`Are you sure you want to ${renderTitleApprove(validationApprove.values.statusId).toLocaleLowerCase()} serviceOrder request with total`} <span className="fw-semibold text-primary">{currencyFormat(serviceOrderData ? (serviceOrderData.total_amount ? serviceOrderData.total_amount : 0) : serviceOrder.totalAmount)}</span>
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="mt-3 col-lg-12">
                          <Input name="approval_note" id="approval_note-field" className="form-control" placeholder="Enter Notes If Any" rows={4} type="textarea" onChange={validationApprove.handleChange} onBlur={validationApprove.handleBlur} />
                        </div>
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            toggleApprove();
                          }}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-success">
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">Approve</span>
                            {loading ? (
                              <Spinner size="sm" className="flex-shrink-0" role="status">
                                Loading...
                              </Spinner>
                            ) : (
                              ""
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <Modal id="showModalDetail" isOpen={modalDetail} centered size="xl">
                  <ModalHeader className="bg-light p-3" toggle={toggleDetail}>
                    Invoice Detail
                  </ModalHeader>
                  <ModalBody>
                    <Row id="printThis">
                      <Col lg={12}>
                        <CardHeader className="border-bottom-dashed p-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="40" />
                              <img src={logoLight} className="card-logo card-logo-light" alt="logo light" height="40" />
                            </div>
                            <div>
                              <h6 className="text-uppercase fw-semibold">{serviceOrder.client && serviceOrder.client.label}</h6>
                              <p className="mb-1">{serviceOrder.company && serviceOrder.company.label}</p>
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody className="p-4">
                          <Row className="g-3">
                            <Col lg={3} xs={3}>
                              <p className="text-muted mb-2 text-uppercase fw-semibold">Invoice No</p>
                              <h5 className="fs-14 mb-0">
                                #<span id="invoice-no">{serviceOrder.transactionNo}</span>
                              </h5>
                            </Col>
                            <Col lg={3} xs={3}>
                              <p className="text-muted mb-2 text-uppercase fw-semibold">Date</p>
                              <h5 className="fs-14 mb-0">
                                <span id="invoice-date">{handleValidDate(serviceOrder.transactionAt)}</span>
                              </h5>
                            </Col>
                            <Col lg={3} xs={3}>
                              <p className="text-muted mb-2 text-uppercase fw-semibold">Due Date</p>
                              <h5 className="fs-14 mb-0">
                                <span id="invoice-date">{handleValidDate(serviceOrder.dueAt)}</span>
                              </h5>
                            </Col>
                            <Col lg={3} xs={3}>
                              <p className="text-muted mb-2 text-uppercase fw-semibold">Total Amount</p>
                              <h5 className="fs-14 mb-0">
                                <span id="total-amount">{currencyFormat(serviceOrder.totalAmount)}</span>
                              </h5>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardBody className="p-4">
                          <div className="table-responsive">
                            <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                              <thead>
                                <tr className="table-active">
                                  <th scope="col" style={{ width: "50px" }}>
                                    #
                                  </th>
                                  <th scope="col">Service</th>
                                  <th scope="col">Branch</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Billing</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Discount</th>
                                  <th scope="col">Price</th>
                                  <th scope="col">Price Net</th>
                                </tr>
                              </thead>
                              <tbody id="products-list">
                                {(serviceOrder.items ? serviceOrder.items : []).map((o, i) => {
                                  return (
                                    <tr key={i} id={i} className="product">
                                      <th scope="row" className="product-id">
                                        {i + 1}
                                      </th>
                                      <td>{o.service.label}</td>
                                      <td>{o.branch.label}</td>
                                      <td>{o.unit.label}</td>
                                      <td>{o.billing.label}</td>
                                      <td>{o.billingComponent.label}</td>
                                      <td className="text-end">{currencyFormat(o.discount)}</td>
                                      <td className="text-end">{currencyFormat(o.price)}</td>
                                      <td className="text-end">{currencyFormat(o.priceNet)}</td>
                                    </tr>
                                  );
                                })}
                                <tr className="border-top">
                                  <td colSpan={6}></td>
                                  <td className="text-end fw-semibold">Sub Total</td>
                                  <td className="text-end">{currencyFormat(serviceOrder.subTotal)}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td colSpan={6}></td>
                                  <td className="text-end fw-semibold">Sub Discount</td>
                                  <td className="text-end">{currencyFormat(serviceOrder.subDiscount)}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td colSpan={6}></td>
                                  <td className="text-end fw-semibold">Tax</td>
                                  <td className="text-end">{currencyFormat(serviceOrder.tax ? serviceOrder.tax.amount : 0)}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td colSpan={6}></td>
                                  <td className="text-end fw-semibold">Total Amount</td>
                                  <td className="text-end">{currencyFormat(serviceOrder.totalAmount)}</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </Table>
                            {/* <Table>
                                  <tbody>
                                    <tr></tr>
                                    <tr>
                                      <td>Sub Total</td>
                                      <td className="text-end">{currencyFormat(1000000)}</td>
                                    </tr>
                                    <tr>
                                      <td>Discount</td>
                                      <td className="text-end">{currencyFormat(dis)}</td>
                                    </tr>
                                    <tr>
                                      <td>Shipping Charge</td>
                                      <td className="text-end">{currencyFormat(ship)}</td>
                                    </tr>
                                    <tr>
                                      <td>Estimated Tax ({tax}%)</td>
                                      <td className="text-end">{currencyFormat((tax / 100) * (subTotal + ship - dis))}</td>
                                    </tr>
                                    <tr className="border-top border-top-dashed fs-15">
                                      <th scope="row">Total Amount</th>
                                      <th className="text-end">{currencyFormat(subTotal + (tax / 100) * (subTotal + ship - dis) + ship - dis)}</th>
                                    </tr>
                                  </tbody>
                                </Table> */}
                          </div>
                          <div className="mt-4">
                            <div className="alert alert-info">
                              <p className="mb-0">
                                <span className="fw-semibold">NOTES:</span>
                                <span id="note"> {serviceOrder.description}</span>
                              </p>
                            </div>
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </ModalBody>
                  <div className="modal-footer">
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          setModalDetail(false);
                        }}
                      >
                        {" "}
                        Close{" "}
                      </button>

                      <button
                        type="submit"
                        className="btn btn-info"
                        disabled={loadingPrint}
                        onClick={() => {
                          handlePrint();
                        }}
                      >
                        Print Document&nbsp;{loadingPrint ? <Spinner size="sm" className="flex-shrink-0 ms-2" role="status"></Spinner> : ""}
                      </button>

                      <Select
                        name="statusId"
                        placeholder="Select Status"
                        onChange={(e) => {
                          validationApprove.setFieldValue("statusId", e.value);
                        }}
                        options={statusApproveList}
                      ></Select>

                      {validationApprove.values.statusId < 3 ? null : (
                        <button
                          type="submit"
                          className={`btn ${validationApprove.values.statusId === 8 ? "btn-warning" : "btn-success"}`}
                          onClick={() => {
                            // validationApprove.setFieldValue("statusId", 3);
                            toggleApprove();
                          }}
                        >
                          {renderTitleApprove(validationApprove.values.statusId)}
                        </button>
                      )}
                      {/* <button
                        type="submit"
                        className="btn btn-success"
                        onClick={() => {
                          validationApprove.setFieldValue("statusId", 4);
                          toggleApprove();
                        }}
                      >
                        Set as Processed
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success"
                        onClick={() => {
                          validationApprove.setFieldValue("statusId", 8);
                          toggleApprove();
                        }}
                      >
                        Set Cancelled
                      </button> */}
                    </div>
                  </div>
                </Modal>
                <ModalInputFile onSelect={handleSelectFile} loading={loading} modal={modalMedia} toggle={toggleMedia} />
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <PrintDoc innerRef={printRef} item={serviceOrder} />
      </Container>
    </div>
  );
};

ServiceOrder.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ServiceOrder));
