import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_BILLING_COMPONENT,
  DELETE_BILLING_COMPONENT,
  UPDATE_BILLING_COMPONENT,
  ADD_NEW_BILLING_COMPONENT,
} from "./actionType";

import {
  getBillingComponentSuccess,
  getBillingComponentFail,
  deleteBillingComponentSuccess,
  deleteBillingComponentFail,
  updateBillingComponentSuccess,
  updateBillingComponentFail,
  addBillingComponentSuccess,
  addBillingComponentFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveBillingComponentApi,
  getBillingComponentApi,
  updateBillingComponentApi,
  deleteBillingComponentApi,
  addNewBillingComponentApi,
} from "../../helpers/backend_helper";

function* getBillingComponent({ payload: billingComponent }) {
  try {
    const response = yield call(getBillingComponentApi, billingComponent);
    yield put(getBillingComponentSuccess(GET_BILLING_COMPONENT, response.data));
  } catch (error) {
    yield put(getBillingComponentFail(GET_BILLING_COMPONENT, error));
  }
}

function* onUpdateBillingComponent({ payload: billingComponent }) {
  try {
    if (billingComponent.setApprove) {
      const response = yield call(approveBillingComponentApi, billingComponent);
      yield put(updateBillingComponentSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateBillingComponentApi, billingComponent);
      yield put(updateBillingComponentSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateBillingComponentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteBillingComponent({ payload: billingComponent }) {
  try {
    const response = yield call(deleteBillingComponentApi, billingComponent);
    yield put(deleteBillingComponentSuccess({ billingComponent, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteBillingComponentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewBillingComponent({ payload: billingComponent }) {
  try {
    const response = yield call(addNewBillingComponentApi, billingComponent);
    yield put(addBillingComponentSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addBillingComponentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetBillingComponent() {
  yield takeEvery(GET_BILLING_COMPONENT, getBillingComponent);
}

export function* watchUpdateBillingComponent() {
  yield takeEvery(UPDATE_BILLING_COMPONENT, onUpdateBillingComponent);
}

export function* watchDeleteBillingComponent() {
  yield takeEvery(DELETE_BILLING_COMPONENT, onDeleteBillingComponent);
}

export function* watchAddNewBillingComponent() {
  yield takeEvery(ADD_NEW_BILLING_COMPONENT, onAddNewBillingComponent);
}

function* billingComponentSaga() {
  yield all([
    fork(watchGetBillingComponent),
    fork(watchDeleteBillingComponent),
    fork(watchUpdateBillingComponent),
    fork(watchAddNewBillingComponent),
  ]);
}

export default billingComponentSaga;
