export const DOCUMENT_DETAIL_LOADING = "DOCUMENT_DETAIL_LOADING";
export const DOCUMENT_DETAIL_RESET = "DOCUMENT_DETAIL_RESET";
export const GET_DOCUMENT_DETAIL = "GET_DOCUMENT_DETAIL";
export const GET_DOCUMENT_DETAIL_SUCCESS = "GET_DOCUMENT_DETAIL_SUCCESS";
export const GET_DOCUMENT_DETAIL_FAIL = "GET_DOCUMENT_DETAIL_FAIL";
export const DELETE_DOCUMENT_DETAIL = "DELETE_DOCUMENT_DETAIL"
export const DELETE_DOCUMENT_DETAIL_SUCCESS = "DELETE_DOCUMENT_DETAIL_SUCCESS"
export const DELETE_DOCUMENT_DETAIL_FAIL = "DELETE_DOCUMENT_DETAIL_FAIL"
export const UPDATE_DOCUMENT_DETAIL = "UPDATE_DOCUMENT_DETAIL"
export const UPDATE_DOCUMENT_DETAIL_SUCCESS = "UPDATE_DOCUMENT_DETAIL_SUCCESS"
export const UPDATE_DOCUMENT_DETAIL_FAIL = "UPDATE_DOCUMENT_DETAIL_FAIL"
export const ADD_NEW_DOCUMENT_DETAIL = "ADD_NEW_DOCUMENT_DETAIL"
export const ADD_DOCUMENT_DETAIL_SUCCESS = "ADD_DOCUMENT_DETAIL_SUCCESS"
export const ADD_DOCUMENT_DETAIL_FAIL = "ADD_DOCUMENT_DETAIL_FAIL"