import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { Table, Button, Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, Modal, ModalHeader, Form, ModalBody, Label, Input, FormFeedback, Spinner } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";

import Flatpickr from "react-flatpickr";
import Select from "react-select";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import { getPaymentTerm as onGetPaymentTerms, addNewPaymentTerm as onAddNewPaymentTerm, updatePaymentTerm as onUpdatePaymentTerm, deletePaymentTerm as onDeletePaymentTerm, getPaymentTermLoading } from "../../../store/paymentTerm/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../Components/Common/ExportCSVModal";

import PropTypes from "prop-types";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const PaymentTerm = (props) => {
  const { t } = props;
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();

  const { paymentTerms, isPaymentTermCreated, isPaymentTermSuccess, loading, error } = useSelector((state) => ({
    paymentTerms: state.PaymentTerm.paymentTerms,
    isPaymentTermCreated: state.PaymentTerm.isPaymentTermCreated,
    isPaymentTermSuccess: state.PaymentTerm.isPaymentTermSuccess,
    loading: state.PaymentTerm.loading,
    error: state.PaymentTerm.error,
  }));

  const [paymentTermList, setPaymentTermList] = useState([]);
  const [paymentTerm, setPaymentTerm] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);

  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: "1" },
        { label: "Activated", value: "2" },
      ],
    },
  ];

  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const onClickDelete = (paymentTerm) => {
    setPaymentTerm(paymentTerm);
    setDeleteModal(true);
  };

  const handleDeletePaymentTerm = () => {
    if (paymentTerm) {
      dispatch(onDeletePaymentTerm(paymentTerm));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setPaymentTermList(paymentTerms);
  }, [paymentTerms]);

  useEffect(() => {
    if (!isEmpty(paymentTerms)) setPaymentTermList(paymentTerms);
  }, [paymentTerms]);

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredPaymentTerms = paymentTerms;
      if (type !== "all") {
        filteredPaymentTerms = paymentTerms.filter((paymentTerm) => paymentTerm.payment_term_status.status_name === type);
      }
      setPaymentTermList(filteredPaymentTerms);
    }
  };

  useEffect(() => {
    if (!isSearch) {
      dispatch(onGetPaymentTerms());
    }
    setIsSearch(true);
  }, [dispatch, paymentTerms, isSearch]);

  useEffect(() => {
    setPaymentTerm(paymentTerms);
  }, [paymentTerms]);

  useEffect(() => {
    if (!isEmpty(paymentTerms)) {
      setPaymentTerm(paymentTerms);
      setIsEdit(false);
    }
  }, [paymentTerms]);

  useEffect(() => {
    if (!isEmpty(paymentTerms)) {
      setPaymentTermList(paymentTerms);
    }
  }, [paymentTerms]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setPaymentTerm(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handlePaymentTermClick = useCallback(
    (arg) => {
      const paymentTerm = arg;

      setPaymentTerm({
        id: paymentTerm.id,
        name: paymentTerm.name,
        amount: paymentTerm.amount,
        description: paymentTerm.description,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (paymentTerm && paymentTerm.name) || "",
      amount: (paymentTerm && paymentTerm.amount) || 0,
      description: (paymentTerm && paymentTerm.description) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter payment name"),
      amount: Yup.string().required("Please enter amount"),
    }),
    onSubmit: (values) => {
      dispatch(getPaymentTermLoading());
      if (isEdit) {
        const updatePaymentTerm = {
          id: paymentTerm ? paymentTerm.id : 0,
          clientId: 1,
          name: values.name,
          amount: values.amount,
          description: values.description,
        };
        dispatch(onUpdatePaymentTerm(updatePaymentTerm));
        validation.resetForm();
      } else {
        const newPaymentTerm = {
          clientId: 1,
          name: values.name,
          amount: values.amount,
          description: values.description,
        };
        dispatch(onAddNewPaymentTerm(newPaymentTerm));
        validation.resetForm();
      }
      toggle();
    },
  });

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeletePaymentTerm(element.value));
    });
    checkall.checked = false;
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="dataCheckbox form-check-input" value={cellProps.row.original.id} />;
        },
        id: "#",
      },
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                onClick={() => {
                  const paymentTermData = cellProps.row.original;
                  handlePaymentTermClick(paymentTermData);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </Link>
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-danger remove-item-btn"
                onClick={() => {
                  const paymentTermData = cellProps.row.original;
                  onClickDelete(paymentTermData);
                }}
              >
                <i className=" ri-delete-bin-line fs-15"></i>
              </Link>
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "Payment Lenght",
        accessor: "amount",
        filterable: true,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "payment_term_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.value) {
            case "Draft":
              return <span className="badge text-uppercase badge-soft-warning"> {cell.value} </span>;
            case "Activated":
              return <span className="badge text-uppercase badge-soft-info"> {cell.value} </span>;
            case "Deactivated":
              return <span className="badge text-uppercase badge-soft-danger"> {cell.value} </span>;
            default:
              return <span className="badge text-uppercase badge-soft-warning"> {cell.value} </span>;
          }
        },
      },
    ],
    [handlePaymentTermClick]
  );

  const defaultdate = () => {
    return moment(new Date()).tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };

  const [filterDate, setFilterDate] = useState(defaultdate());

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  const handleValidTime = (time) => {
    return moment(time, "YYYY/MM/DD").tz("Asia/Jakarta").format("HH:mm");
  };

  //TABLE
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: paymentTermList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable ? (column.isSorted ? (column.isSortedDesc ? " sorting_desc" : " sorting_asc") : " sort") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState([]);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handlePaymentTermStatus(data) {
    setStatusActive(data);
  }

  function handleFilter() {
    let startat = "";
    let endat = "";
    let datearr = filterDate.split(" to ");
    if (datearr[1]) {
      startat = datearr[0];
      endat = datearr[1];
    }
    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetPaymentTerms(param));
  }

  document.title = "Payment Terms | Aptek";
  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={paymentTermList} />
      <DeleteModal show={deleteModal} onDeleteClick={handleDeletePaymentTerm} onCloseClick={() => setDeleteModal(false)} />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title="Payment Terms" pageTitle="Procurements" />
        <Row>
          <Col lg={12}>
            <Card id="paymentTermList">
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Payment Term Data &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color="primary" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      id="create-btn"
                      onClick={() => {
                        setPaymentTerm("");
                        setIsEdit(false);
                        toggle();
                      }}
                    >
                      <i className="ri-booklet-line me-1 align-bottom fs-14"></i> Add New
                    </button>{" "}
                    <button type="button" className="btn btn-success" onClick={() => setIsExportCSV(true)}>
                      <i className="ri-file-download-line me-1 align-bottom fs-14"></i> Export
                    </button>{" "}
                    <button className="btn btn-soft-danger" onClick={() => setDeleteModalMulti(true)}>
                      <i className="ri-delete-bin-5-line me-1 align-bottom fs-15"></i> Delete
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className="ri-booklet-line me-1 align-bottom fs-14"></i> All Payments
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("2", "Draft");
                        }}
                        href="#"
                      >
                        <i className="ri-information-line me-1 align-bottom fs-14"></i> Draft
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("3", "Activated");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Activated{" "}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("4", "Deactivated");
                        }}
                        href="#"
                      >
                        <i className="ri-indeterminate-circle-line me-1 align-bottom fs-14"></i> Deactivated
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className="mb-3">
                    <CardBody className="border border-top-0 border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={1} className="col-xxl-1">
                            <div className="me-2 mb-2 col-12">
                              <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                {" "}
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="search-box me-2 mb-2 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${count} Search...`}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col sm={6} className="col-xxl-3">
                            <div className="search-box me-2 mb-2 col-12">
                              <Flatpickr
                                className="form-control"
                                id="datepicker-publish-input"
                                placeholder="Select created date"
                                options={{
                                  mode: "range",
                                  dateFormat: "Y/m/d",
                                }}
                                onChange={(selectedDates, dateStr) => {
                                  setFilterDate(dateStr);
                                }}
                              />
                              <i className="bx bx-calendar-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col sm={4} className="col-xxl-2">
                            <div className="me-2 mb-2 col-12">
                              <Select
                                value={statusActive}
                                placeholder="Select Status"
                                onChange={(e) => {
                                  handlePaymentTermStatus(e);
                                }}
                                options={statusActiveData}
                                name="choices-single-default"
                              ></Select>
                            </div>
                          </Col>

                          <Col className="col-xxl-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-light w-100"
                                onClick={() => {
                                  handleFilter();
                                }}
                              >
                                {" "}
                                <i className="ri-search-line me-1 align-bottom fs-14"></i> Search
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card mb-1">
                    <Table hover {...getTableProps()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroups.map((headerGroup) => (
                          <tr className={""} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button color="light" onClick={previousPage} disabled={!canPreviousPage}>
                          {" "}
                          {"<"}{" "}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      {" "}
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
                    </Col>
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={nextPage} disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal id="showModal" isOpen={modal} centered size="md">
                  <ModalHeader className="bg-light p-3" toggle={toggle}>
                    {!!isEdit ? "Edit Payment Term" : "Add Payment Term"}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <Label htmlFor="name-field" className="form-label">
                              {" "}
                              Payment Name{" "}
                            </Label>
                            <Input name="name" id="name-field" className="form-control" placeholder="Enter name" type="text" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.name} invalid={validation.touched.name && validation.errors.name ? true : false} />
                            {validation.touched.name && validation.errors.name ? <FormFeedback type="invalid">{validation.errors.name}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="amount-field" className="form-label">
                              {" "}
                              Payment Day Length{" "}
                            </Label>
                            <Input name="amount" id="amount-field" className="form-control" placeholder="Enter name" type="text" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.amount} invalid={validation.touched.amount && validation.errors.amount ? true : false} />
                            {validation.touched.amount && validation.errors.amount ? <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="des-field" className="form-label">
                              Description
                            </Label>
                            <Input name="description" id="des-field" className="form-control" placeholder="Enter Description" type="textarea" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.description || ""} />
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            setModal(false);
                          }}
                        >
                          {" "}
                          Close{" "}
                        </button>
                        <button type="submit" className="btn btn-success">
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">{!!isEdit ? "Update" : "Add Payment Term"}</span>
                            {loading ? (
                              <Spinner size="sm" className="flex-shrink-0" role="status">
                                Loading...
                              </Spinner>
                            ) : (
                              ""
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};


PaymentTerm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(PaymentTerm));
