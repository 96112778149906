import {
  BILLING_CATEGORY_LOADING,
  GET_BILLING_CATEGORY,
  GET_BILLING_CATEGORY_SUCCESS,
  GET_BILLING_CATEGORY_FAIL,
  ADD_BILLING_CATEGORY_SUCCESS,
  ADD_BILLING_CATEGORY_FAIL,
  DELETE_BILLING_CATEGORY_SUCCESS,
  DELETE_BILLING_CATEGORY_FAIL,
  UPDATE_BILLING_CATEGORY_SUCCESS,
  UPDATE_BILLING_CATEGORY_FAIL,
  BILLING_CATEGORY_RESET,
} from "./actionType";

const INIT_STATE = {
  billingCategories: [],
  error: {},
};

const BillingCategory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BILLING_CATEGORY_SUCCESS:
      switch (action.payload.actionType) {
        case GET_BILLING_CATEGORY:
          return {
            ...state,
            billingCategories: action.payload.data,
            isBillingCategoryCreated: false,
            isBillingCategorySuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_BILLING_CATEGORY_FAIL:
      switch (action.payload.actionType) {
        case GET_BILLING_CATEGORY_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isBillingCategoryCreated: false,
            isBillingCategorySuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_BILLING_CATEGORY: {
      return {
        ...state,
        isBillingCategoryCreated: false,
        loading: true,
      };
    }

    case BILLING_CATEGORY_LOADING: {
      return {
        ...state,
        isBillingCategoryCreated: false,
        loading: true,
      };
    }

    case ADD_BILLING_CATEGORY_SUCCESS:
      return {
        ...state,
        isBillingCategoryCreated: true,
        loading: false,
        billingCategories: [...state.billingCategories, action.payload.data],
      };

    case ADD_BILLING_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_BILLING_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        billingCategories: state.billingCategories.filter(
          (billingCategory) => billingCategory.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_BILLING_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_BILLING_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        billingCategories: state.billingCategories.map((billingCategory) =>
          billingCategory.id.toString() === action.payload.data.id.toString()
            ? { ...billingCategory, ...action.payload.data }
            : billingCategory
        ),
      };

    case UPDATE_BILLING_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BILLING_CATEGORY_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default BillingCategory;
