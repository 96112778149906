export const CONTACT_PERSON_LOADING = "CONTACT_PERSON_LOADING";
export const CONTACT_PERSON_RESET = "CONTACT_PERSON_RESET";
export const GET_CONTACT_PERSON = "GET_CONTACT_PERSON";
export const GET_CONTACT_PERSON_SUCCESS = "GET_CONTACT_PERSON_SUCCESS";
export const GET_CONTACT_PERSON_FAIL = "GET_CONTACT_PERSON_FAIL";
export const DELETE_CONTACT_PERSON = "DELETE_CONTACT_PERSON"
export const DELETE_CONTACT_PERSON_SUCCESS = "DELETE_CONTACT_PERSON_SUCCESS"
export const DELETE_CONTACT_PERSON_FAIL = "DELETE_CONTACT_PERSON_FAIL"
export const UPDATE_CONTACT_PERSON = "UPDATE_CONTACT_PERSON"
export const UPDATE_CONTACT_PERSON_SUCCESS = "UPDATE_CONTACT_PERSON_SUCCESS"
export const UPDATE_CONTACT_PERSON_FAIL = "UPDATE_CONTACT_PERSON_FAIL"
export const ADD_NEW_CONTACT_PERSON = "ADD_NEW_CONTACT_PERSON"
export const ADD_CONTACT_PERSON_SUCCESS = "ADD_CONTACT_PERSON_SUCCESS"
export const ADD_CONTACT_PERSON_FAIL = "ADD_CONTACT_PERSON_FAIL"