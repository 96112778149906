import {
  SERVICE_ORDERPAYMENT_LOADING,
  GET_SERVICE_ORDERPAYMENT,
  GET_SERVICE_ORDERPAYMENT_SUCCESS,
  GET_SERVICE_ORDERPAYMENT_FAIL,
  ADD_SERVICE_ORDERPAYMENT_SUCCESS,
  ADD_SERVICE_ORDERPAYMENT_FAIL,
  DELETE_SERVICE_ORDERPAYMENT_SUCCESS,
  DELETE_SERVICE_ORDERPAYMENT_FAIL,
  UPDATE_SERVICE_ORDERPAYMENT_SUCCESS,
  UPDATE_SERVICE_ORDERPAYMENT_FAIL,
  SERVICE_ORDERPAYMENT_RESET
} from "./actionType";

const INIT_STATE = {
  serviceOrderPayments: [],
  error: {},
};

const ServiceOrderPayment = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_ORDERPAYMENT_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDERPAYMENT:
          return {
            ...state,
            serviceOrderPayments: action.payload.data,
            isServiceOrderPaymentCreated: false,
            isServiceOrderPaymentSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SERVICE_ORDERPAYMENT_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDERPAYMENT_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isServiceOrderPaymentCreated: false,
            isServiceOrderPaymentSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SERVICE_ORDERPAYMENT: {
      return {
        ...state,
        isServiceOrderPaymentCreated: false,
        loading: true,
      };
    }

    case SERVICE_ORDERPAYMENT_LOADING: {
      return {
        ...state,
        isServiceOrderPaymentCreated: false,
        loading: true,
      };
    }

    case ADD_SERVICE_ORDERPAYMENT_SUCCESS:
      return {
        ...state,
        isServiceOrderPaymentCreated: true,
        loading: false,
        serviceOrderPayments: [...state.serviceOrderPayments, action.payload.data],
      };

    case ADD_SERVICE_ORDERPAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_SERVICE_ORDERPAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderPaymentCreated: true,
        serviceOrderPayments: state.serviceOrderPayments.filter(
          serviceOrderPayment => serviceOrderPayment.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_SERVICE_ORDERPAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_SERVICE_ORDERPAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderPaymentCreated: true,
        serviceOrderPayments: state.serviceOrderPayments.map(serviceOrderPayment =>
          serviceOrderPayment.id.toString() === action.payload.data.id.toString() ? { ...serviceOrderPayment, ...action.payload.data } : serviceOrderPayment
        ),
      };

    case UPDATE_SERVICE_ORDERPAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SERVICE_ORDERPAYMENT_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ServiceOrderPayment;