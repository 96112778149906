export const ROLELIST_LOADING = "ROLELIST_LOADING";
export const ROLELIST_RESET = "ROLELIST_RESET";
export const GET_STRUCURE = "GET_ROLELIST";
export const GET_ROLELIST = "GET_ROLELIST";
export const GET_ROLELIST_SUCCESS = "GET_ROLELIST_SUCCESS";
export const GET_ROLELIST_FAIL = "GET_ROLELIST_FAIL";
export const DELETE_ROLELIST = "DELETE_ROLELIST"
export const DELETE_ROLELIST_SUCCESS = "DELETE_ROLELIST_SUCCESS"
export const DELETE_ROLELIST_FAIL = "DELETE_ROLELIST_FAIL"
export const UPDATE_ROLELIST = "UPDATE_ROLELIST"
export const UPDATE_ROLELIST_SUCCESS = "UPDATE_ROLELIST_SUCCESS"
export const UPDATE_ROLELIST_FAIL = "UPDATE_ROLELIST_FAIL"
export const ADD_NEW_ROLELIST = "ADD_NEW_ROLELIST"
export const ADD_ROLELIST_SUCCESS = "ADD_ROLELIST_SUCCESS"
export const ADD_ROLELIST_FAIL = "ADD_ROLELIST_FAIL"