import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Table, Button, Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, Modal, ModalHeader, Form, ModalBody, Label, Input, FormFeedback, Spinner, Collapse } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import ApproveModal from "../../Components/Common/ApproveModal";
import { isEmpty } from "lodash";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Dropzone from "react-dropzone";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Formik
import * as Yup from "yup";
import { useFormik, Field, FieldArray } from "formik";
import FormData from "form-data";
//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import { resetClientState, getClient as onGetClients, addNewClient as onAddNewClient, updateClient as onUpdateClient, deleteClient as onDeleteClient, getClientLoading } from "../../store/clients/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import defaultImg from "../../assets/images/upload.png";
import ModalInputFile from "../../Components/ModalInputFile";
import { api } from "../../config";

import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import imgNotFound from "../../assets/images/users/img-remove.png";

const authUser = JSON.parse(sessionStorage.getItem("authUser"));

const Clients = (props) => {
  const { t } = props;
  const [logo, setLogo] = useState(defaultImg);
  const [clientImg, setClientImg] = useState("");
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();

  const { clients, isClientCreated, isClientSuccess, loading, error } = useSelector((state) => ({
    clients: state.Clients.clients,
    isClientCreated: state.Clients.isClientCreated,
    isClientSuccess: state.Clients.isClientSuccess,
    loading: state.Clients.loading,
    error: state.Clients.error,
  }));

  const clientTypes = [
    {
      options: [
        { label: "Client", value: "client" },
        { label: "Executive", value: "executive" },
      ],
    },
  ];

  const defaultdate = () => {
    return moment(new Date()).tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };

  const [placeDefaultVal, setPlaceDefaultVal] = useState("");
  const [clientList, setClientList] = useState([]);
  const [client, setClient] = useState([]);
  const [info, setInfo] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);
  const [filterDate, setFilterDate] = useState(defaultdate);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [isExecutive, setIsExecutive] = useState(false);

  const [open, setOpen] = useState("2");
  const toggleAccordion = (id) => {
    if (open === id) {
      setOpen(open);
    } else {
      setOpen(id);
    }
  };

  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: "1" },
        { label: "Activated", value: "2" },
      ],
    },
  ];

  const onClickDelete = (client) => {
    setClient(client);
    setDeleteModal(true);
  };

  const onClickApprove = (client) => {
    setClient(client);
    setApproveModal(true);
  };

  const handleDeleteClient = () => {
    if (client) {
      dispatch(onDeleteClient(client));
      setDeleteModal(false);
    }
  };

  const handleApproveClient = () => {
    if (client) {
      client.setApprove = true;
      dispatch(onUpdateClient(client));
      setApproveModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setClientList(clients);
  }, [clients]);

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetClientState());
      dispatch(onGetClients());
      setIsSearch(true);
    }
  }, [dispatch, clients, isSearch]);

  useEffect(() => {
    if (!isEmpty(clients)) {
      setClient(clients);
      setClientList(clients);
      setInfo(clients[clients.length - 1]);
      setIsEdit(false);
    }
  }, [clients]);

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredClients = clients;
      if (type !== "all") {
        filteredClients = clients.filter((client) => client.client_status.name === type);
      }
      setClientList(filteredClients);
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setClient(null);
      resetList();
    } else {
      setModal(true);
    }
    if (!isExecutive) {
      //validation.setva
    }
  }, [modal, isExecutive]);

  const toggleMedia = useCallback(() => {
    if (modalMedia) {
      setModalMedia(false);
    } else {
      setModalMedia(true);
    }
  }, [modalMedia]);

  const handleClientClick = useCallback(
    (arg) => {
      const client = arg;
      let address_client = {};
      for (const address of client.client_address) {
        if (address.isPrimary) {
          address_client = address;
        }
      }

      setClientImg(client.image ?? "");

      setPlaceDefaultVal({
        label: `${address_client.village} ${address_client.district} ${address_client.city} ${address_client.province} ${address_client.country}`,
        value: `${address_client.village} ${address_client.district} ${address_client.city} ${address_client.province} ${address_client.country}`,
      });

      setClient({
        id: client.id,
        domain: client.domain ?? "",
        code: address_client.code ?? "",
        village: address_client.village ?? "",
        district: address_client.district ?? "",
        city: address_client.city ?? "",
        province: address_client.province ?? "",
        country: address_client.country ?? "",
        name: client.name ?? "",
        subscribedat: client.subscribedat ?? "",
        expiredat: client.expiredat ?? "",
        status:
          client.client_status === null
            ? ""
            : {
                label: client.client_status.name,
                value: client.client_status.id,
              },
        description: client.description ?? "",
        address:
          client.client_address.length < 1
            ? ""
            : {
                label: `${address_client.village} ${address_client.district} ${address_client.city} ${address_client.province} ${address_client.country}`,
                value: {
                  description: `${address_client.village} ${address_client.district} ${address_client.city} ${address_client.province} ${address_client.country}`,
                  matched_substrings: [],
                  place_id: "",
                  reference: "",
                  structured_formatting: {},
                  terms: [],
                  types: [],
                },
              },
        street: address_client.description ?? "",
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="dataCheckbox form-check-input" value={cellProps.row.original.id} />;
        },
        id: "#",
      },
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleClientClick(data);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </Link>
              {cellProps.row.original.statusActiveId === 1 ? (
                <button
                  className="btn-label btn btn-sm btn-soft-success fs-12 me-0"
                  onClick={() => {
                    const data = cellProps.row.original;
                    onClickApprove(data);
                  }}
                >
                  <i className="ri-checkbox-circle-line label-icon align-middle fs-16 me-1"></i>Approve
                </button>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        Header: `${t("Name")}`,
        filterable: true,
        accessor: "name",
        Cell: (client) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {client.row.original.image !== null && client.row.original.image !== "" ? (
                  <img
                    src={api.MEDIA_URL + "/media/" + client.row.original.image}
                    alt=""
                    className="avatar-xs rounded-circle me-1"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = imgNotFound;
                    }}
                  />
                ) : (
                  <div className="flex-shrink-0 avatar-xs me-1">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">{client.row.original.name.charAt(0).toUpperCase()}</div>
                  </div>
                )}
              </div>
              <div className="flex-grow-1 ms-2 name">{client.row.original.name}</div>
            </div>
          </>
        ),
      },
      {
        Header: `${t("Domain")}`,
        accessor: "domain",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "client_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.row.original.statusId) {
            case 1:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
            case 2:
              return <span className="badge text-uppercase badge-soft-info">Activated</span>;
            default:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
          }
        },
      },
    ],
    [handleClientClick, t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: clientList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable ? (column.isSorted ? (column.isSortedDesc ? " sorting_desc" : " sorting_asc") : " sort") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleFilter() {
    let startat = "";
    let endat = "";
    let datearr = filterDate.split(" to ");
    if (datearr[1]) {
      startat = datearr[0];
      endat = datearr[1];
    }

    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetClients(param));
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      code: (client && client.code) || "",
      domain: (client && client.domain) || "",
      name: (client && client.name) || "",
      image: clientImg === null ? "" : clientImg,
      subscribedat: (client && client.subscribedat) || "",
      expiredat: (client && client.expiredat) || "",
      status: (client && client.status) || "",
      description: (client && client.description) || "",
      address: (client && client.address) || "",
      street: (client && client.street) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      domain: Yup.string().required("Please Enter Domain"),
      subscribedat: Yup.string().required("Please Enter Subscribed At"),
      expiredat: Yup.string().required("Please Enter Expired At"),
      status: Yup.object().required("Please Enter Status"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        let editDataSubmit = {};

        if (values.address.value && values.address.value.terms.length > 0) {
          editDataSubmit = {
            id: client.id,
            name: values.name,
            domain: values.domain,
            image: values.image,
            subscribedat: values.subscribedat,
            expiredat: values.expiredat,
            statusId: values.status.value,
            description: values.description,
            zip: values.code,
            street: values.street,
          };
          if (values.address.value) {
            editDataSubmit = {
              ...editDataSubmit,
              village: values.address.value.terms[0].value,
              district: values.address.value.terms[values.address.value.terms.length - 4].value,
              city: values.address.value.terms[values.address.value.terms.length - 3].value,
              province: values.address.value.terms[values.address.value.terms.length - 2].value,
              country: values.address.value.terms[values.address.value.terms.length - 1].value,
            };
          }
        } else {
          editDataSubmit = {
            id: client.id,
            name: values.name,
            domain: values.domain,
            image: values.image,
            subscribedat: values.subscribedat,
            expiredat: values.expiredat,
            statusId: values.status.value,
            description: values.description,
            zip: values.code,
            street: values.street,
          };
          if (values.address.value) {
            editDataSubmit = {
              ...editDataSubmit,
              village: client.village,
              district: client.district,
              city: client.city,
              province: client.province,
              country: client.country,
            };
          }
        }

        dispatch(onUpdateClient(editDataSubmit));
      } else {
        var addDataSubmit = {
          name: values.name,
          domain: values.domain,
          image: values.image,
          subscribedat: values.subscribedat,
          expiredat: values.expiredat,
          statusId: values.status.value,
          description: values.description,
          zip: values.code,
          street: values.street,
        };
        if (values.address.value) {
          addDataSubmit = {
            ...addDataSubmit,
            village: values.address.value.terms[0].value,
            district: values.address.value.terms[values.address.value.terms.length - 4].value,
            city: values.address.value.terms[values.address.value.terms.length - 3].value,
            province: values.address.value.terms[values.address.value.terms.length - 2].value,
            country: values.address.value.terms[values.address.value.terms.length - 1].value,
          };
        }

        dispatch(onAddNewClient(addDataSubmit));
      }
      toggle();
      validation.resetForm();
    },
  });
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteClient(element.value));
    });
    checkall.checked = false;
  };

  const handleSelectFile = (file) => {
    setClientImg(file.filename);
    toggleMedia();
  };

  const resetList = () => {};

  useEffect(() => {
    return () => {
      dispatch(resetClientState());
      resetList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = `${t("Client")} | Aptek`;

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={clientList} />
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteClient} onCloseClick={() => setDeleteModal(false)} />
      <ApproveModal data={client && client.clientname ? client.clientname : ""} show={approveModal} onApproveClick={handleApproveClient} onCloseClick={() => setApproveModal(false)} />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={t("Client")} pageTitle={t("Client")} />
        <Row>
          <Col xxl={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    {t("Client History")} &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color="primary" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className={"btn btn-primary add-btn"}
                      id="create-btn"
                      onClick={() => {
                        setIsEdit(false);
                        toggle();
                      }}
                    >
                      <i className="ri-building-line me-1 align-bottom fs-14"></i> {t("Add")}
                    </button>
                    {/* <button type="button" className="btn btn-success" onClick={() => setIsExportCSV(true)}>
                      <i className="ri-file-download-line me-1 align-bottom fs-14"></i> Export
                    </button>{" "}
                    <button className={userType === "executive" ? "btn btn-soft-danger" : roles && roles.split(",").indexOf("deleteClient") !== -1 ? "btn btn-soft-danger" : "btn btn-soft-danger d-none"} onClick={() => setDeleteModalMulti(true)}>
                      <i className="ri-delete-bin-5-line me-1 align-bottom fs-15"></i> Hapus
                    </button> */}
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className=" ri-group-line me-1 align-bottom fs-14"></i> All Clients
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("2", "Draft");
                        }}
                        href="#"
                      >
                        <i className="ri-information-line me-1 align-bottom fs-14"></i> Draft
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("3", "Activated");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Activated{" "}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className="mb-3">
                    <CardBody className="border border-top-0 border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={1} className="col-xxl-1">
                            <div className="me-2 mb-3 col-12">
                              <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="search-box me-2 mb-3 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${count} Search...`}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col sm={6} className="col-xxl-3">
                            <div className="search-box me-2 mb-3 col-12">
                              <Flatpickr
                                className="form-control"
                                id="datepicker-publish-input"
                                placeholder="Select join date"
                                options={{
                                  mode: "range",
                                  dateFormat: "Y/m/d",
                                }}
                                onChange={(selectedDates, dateStr) => {
                                  setFilterDate(dateStr);
                                }}
                              />
                              <i className="bx bx-calendar-alt search-icon"></i>
                            </div>
                          </Col>

                          <Col className="col-xxl-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-light w-100"
                                onClick={() => {
                                  handleFilter();
                                }}
                              >
                                <i className="ri-search-line me-1 align-bottom fs-14"></i> {t("Search")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card mb-1">
                    <Table hover {...getTableProps()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroups.map((headerGroup) => (
                          <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={previousPage} disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      {" "}
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
                    </Col>
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={nextPage} disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal id="showModal" isOpen={modal} centered fullscreen={false} size="lg">
                  <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                      toggle();
                      validation.resetForm();
                      setClientImg("");
                      validation.setErrors({});
                    }}
                  >
                    {!!isEdit ? `${t("Edit")} ${t("Client")}` : `${t("Add")} ${t("Client")}`}
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col lg={6}>
                        <div className="col-lg-12 mb-3">
                          <div className="text-center">
                            <div className="position-relative d-inline-block">
                              <div className="position-absolute bottom-0 end-0">
                                <Label
                                  onClick={() => {
                                    toggleMedia();
                                  }}
                                  htmlFor="unit-logo-input"
                                  className="mb-0"
                                >
                                  <div className="avatar-xs cursor-pointer">
                                    <div className="avatar-title bg-light border rounded-circle text-muted">
                                      <i className="ri-image-fill"></i>
                                    </div>
                                  </div>
                                </Label>
                              </div>
                              <div className="avatar-lg p-1">
                                <div className="avatar-title bg-light rounded-circle">
                                  <img
                                    src={clientImg === "" ? logo : `${api.MEDIA_URL}/media/${clientImg}`}
                                    alt=""
                                    id="unitlogo-img"
                                    className="avatar-md rounded-circle object-cover"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = imgNotFound;
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <h5 className="fs-13 mt-3">Logo</h5>
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="name-field" className="form-label">
                            {t("Nama")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="name" id="name-field" className="form-control" placeholder="Enter Name" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.name || ""} invalid={validation.touched.name && validation.errors.name ? true : false} />
                          {validation.touched.name && validation.errors.name ? <FormFeedback type="invalid">{validation.errors.name}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="date-field" className="form-label">
                            {t("Subscription Date")} <span className="text-danger">*</span>
                          </Label>
                          <Flatpickr
                            name="date-picker"
                            className="form-control"
                            placeholder="Select Subscription Date"
                            options={{
                              enableTime: true,
                              time_24hr: true,
                              dateFormat: "Y/m/d H:i",
                            }}
                            value={validation.values.subscribedat || ""}
                            onChange={(o, e) => {
                              validation.setFieldValue("subscribedat", e);
                            }}
                          />
                          <Input name="subscribedat" id="subscribedat" type="hidden" validate={{ required: { value: true } }} onChange={validation.handleChange} invalid={validation.touched.subscribedat && validation.errors.subscribedat ? true : false} />
                          {validation.touched.subscribedat && validation.errors.subscribedat ? <FormFeedback type="invalid">{validation.errors.subscribedat}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="date-field" className="form-label">
                            {t("Expired Date")} <span className="text-danger">*</span>
                          </Label>
                          <Flatpickr
                            name="date-picker2"
                            className="form-control"
                            placeholder="Select Expired Date"
                            options={{
                              enableTime: true,
                              time_24hr: true,
                              dateFormat: "Y/m/d H:i",
                            }}
                            value={validation.values.expiredat || ""}
                            onChange={(o, e) => {
                              validation.setFieldValue("expiredat", e);
                            }}
                          />
                          <Input name="expiredat" id="expiredat" type="hidden" validate={{ required: { value: true } }} onChange={validation.handleChange} invalid={validation.touched.expiredat && validation.errors.expiredat ? true : false} />
                          {validation.touched.expiredat && validation.errors.expiredat ? <FormFeedback type="invalid">{validation.errors.expiredat}</FormFeedback> : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="address-field" className="form-label">
                            {t("Address")}
                          </Label>
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyD0auKcsMNas3qbqi-BgF_s3UxwtQNP74Q"
                            apiOptions={{
                              region: "IDN",
                            }}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["idn"],
                              },
                              types: ["route"],
                            }}
                            selectProps={{
                              name: "address",
                              value: undefined,
                              defaultValue: validation.values.address,
                              onChange: (value) => {
                                validation.setFieldValue("address", value);
                              },
                            }}
                          />
                          {validation.touched.address && validation.errors.address ? <div className="invalid-feedback d-block">{validation.errors.address}</div> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="code-field" className="form-label">
                            {t("Zip Code")}
                          </Label>
                          <Input name="code" id="code-field" className="form-control" placeholder="Enter Zip Code" type="number" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.code || ""} invalid={validation.touched.code && validation.errors.code ? true : false} />
                          {validation.touched.code && validation.errors.code ? <FormFeedback type="invalid">{validation.errors.code}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="street-field" className="form-label">
                            {t("Street")}
                          </Label>
                          <Input name="street" id="street-field" className="form-control" placeholder="Enter Street Name" type="textarea" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.street || ""} invalid={validation.touched.street && validation.errors.street ? true : false} style={{ minHeight: "102px" }} />
                          {validation.touched.street && validation.errors.street ? <FormFeedback type="invalid">{validation.errors.street}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="domain-field" className="form-label">
                            {t("Domain")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="domain" id="domain-field" className="form-control" placeholder="Enter Domain" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.domain || ""} invalid={validation.touched.domain && validation.errors.domain ? true : false} />
                          {validation.touched.domain && validation.errors.domain ? <FormFeedback type="invalid">{validation.errors.domain}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="active-status" className="form-label">
                            Status <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="status"
                            value={validation.values.status}
                            validate={{ required: { value: true } }}
                            placeholder="Select Status"
                            onChange={(e) => {
                              validation.setFieldValue("status", e);
                            }}
                            onInputChange={(e) => {
                              // handleUnit(e);
                            }}
                            options={statusActiveData}
                            invalid={validation.touched.status && validation.errors.status ? true : false}
                          ></Select>
                          {validation.touched.status && validation.errors.status ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.status}
                            </p>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label htmlFor="des-field" className="form-label">
                            {t("Description")}
                          </Label>
                          {/* <Input
                            name="description"
                            id="clientname-field"
                            className="form-control"
                            placeholder="Enter Keterangan"
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                          /> */}
                          <CKEditor
                            editor={ClassicEditor}
                            config={{
                              removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"],
                            }}
                            data={validation.values.description}
                            onReady={(editor) => {
                              editor.editing.view.change((writer) => {
                                writer.setStyle("height", "150px", editor.editing.view.document.getRoot());
                              });
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              validation.setFieldValue("description", data);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </ModalBody>
                  <div className="modal-footer mb3">
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          setModal(false);
                          validation.resetForm();
                          validation.setErrors({});
                          setClientImg("");
                        }}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        onClick={() => {
                          validation.handleSubmit();
                        }}
                        type="submit"
                        className="btn btn-success"
                      >
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">{t("Save")}</span>
                          {loading ? (
                            <Spinner size="sm" className="flex-shrink-0" role="status">
                              Loading...
                            </Spinner>
                          ) : (
                            ""
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </Modal>
                <ModalInputFile onSelect={handleSelectFile} loading={loading} modal={modalMedia} toggle={toggleMedia} />
                {/* <Modal id="showModalMedia" isOpen={modalMedia} centered size="xl">
                  <ModalHeader className="bg-light p-3" toggle={toggleMedia}>
                    Media File
                  </ModalHeader>
                  <ModalBody>
                    <div className="row">
                      <div className="col-lg-8">
                        <di>
                          <div className="search-box me-2 mb-3 d-inline-block col-12">
                            <input
                              onChange={(e) => {
                                // setSearchValue(e.target.value);
                                // onChange(e.target.value);
                              }}
                              id="search-bar-0"
                              type="text"
                              className="form-control search"
                              placeholder={`${199} Search...`}
                              value={""}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </di>
                        <div style={{ maxHeight: "500px", overflowY: "scroll", overflowX: "hidden" }}>
                          <Card className="mt-1 mb-0 me-2 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="py-2 px-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <div className="me-2">
                                    <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" />
                                  </div>
                                  <div>
                                    <p className="mb-0">
                                      <strong>{"f.formattedSize"}</strong>
                                    </p>
                                    <p className="text-muted font-weight-bold">{"f.name"}</p>
                                  </div>
                                </div>
                                <div>
                                  <Link
                                    to="#"
                                    className={"btn-icon btn btn-sm btn-soft-danger remove-item-btn"}
                                    onClick={() => {
                                      // const clientData = cellProps.row.original;
                                      // onClickDelete(clientData);
                                    }}
                                  >
                                    <i className=" ri-delete-bin-line fs-15"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-3">
                          <Dropzone
                            multiple={true}
                            onDrop={(acceptedFiles) => {
                              // handleAcceptedFiles(acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone dz-clickable" style={{ cursor: "pointer", height: "140px" }} {...getRootProps({ className: "dropzone" })}>
                                <div className="dz-message needsclick">
                                  <div>
                                    <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                    <input {...getInputProps()} />
                                  </div>
                                  <h5>Drop employee document here.</h5>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <div className="modal-footer">
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          toggleMedia();
                        }}
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-success" disabled={loading}>
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">{"Tambah Karyawan"}</span>
                          {loading && (
                            <Spinner size="sm" className="flex-shrink-0" role="status">
                              Loading...
                            </Spinner>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </Modal> */}
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Clients.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Clients));

// export default Clients;
