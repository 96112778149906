export const SERVICE_ORDER_PRICE_LOADING = "SERVICE_ORDER_PRICE_LOADING";
export const SERVICE_ORDER_PRICE_RESET = "SERVICE_ORDER_PRICE_RESET";
export const GET_SERVICE_ORDER_PRICE = "GET_SERVICE_ORDER_PRICE";
export const GET_SERVICE_ORDER_PRICE_SUCCESS = "GET_SERVICE_ORDER_PRICE_SUCCESS";
export const GET_SERVICE_ORDER_PRICE_FAIL = "GET_SERVICE_ORDER_PRICE_FAIL";
export const DELETE_SERVICE_ORDER_PRICE = "DELETE_SERVICE_ORDER_PRICE"
export const DELETE_SERVICE_ORDER_PRICE_SUCCESS = "DELETE_SERVICE_ORDER_PRICE_SUCCESS"
export const DELETE_SERVICE_ORDER_PRICE_FAIL = "DELETE_SERVICE_ORDER_PRICE_FAIL"
export const UPDATE_SERVICE_ORDER_PRICE = "UPDATE_SERVICE_ORDER_PRICE"
export const UPDATE_SERVICE_ORDER_PRICE_SUCCESS = "UPDATE_SERVICE_ORDER_PRICE_SUCCESS"
export const UPDATE_SERVICE_ORDER_PRICE_FAIL = "UPDATE_SERVICE_ORDER_PRICE_FAIL"
export const ADD_NEW_SERVICE_ORDER_PRICE = "ADD_NEW_SERVICE_ORDER_PRICE"
export const ADD_SERVICE_ORDER_PRICE_SUCCESS = "ADD_SERVICE_ORDER_PRICE_SUCCESS"
export const ADD_SERVICE_ORDER_PRICE_FAIL = "ADD_SERVICE_ORDER_PRICE_FAIL"

export const GET_SERVICE_ORDER_PRICE_LIST = "GET_SERVICE_ORDER_PRICE_LIST";
export const GET_SERVICE_ORDER_PRICE_LIST_SUCCESS = "GET_SERVICE_ORDER_PRICE_SUCCESS_LIST";
export const GET_SERVICE_ORDER_PRICE_LIST_FAIL = "GET_SERVICE_ORDER_PRICE_FAIL_LIST";

