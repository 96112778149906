import {
  GET_SERVICE_ORDERAPPROVAL,
  GET_SERVICE_ORDERAPPROVAL_SUCCESS,
  GET_SERVICE_ORDERAPPROVAL_FAIL,
  DELETE_SERVICE_ORDERAPPROVAL,
  DELETE_SERVICE_ORDERAPPROVAL_SUCCESS,
  DELETE_SERVICE_ORDERAPPROVAL_FAIL,
  UPDATE_SERVICE_ORDERAPPROVAL,
  UPDATE_SERVICE_ORDERAPPROVAL_SUCCESS,
  UPDATE_SERVICE_ORDERAPPROVAL_FAIL,
  ADD_NEW_SERVICE_ORDERAPPROVAL,
  ADD_SERVICE_ORDERAPPROVAL_SUCCESS,
  ADD_SERVICE_ORDERAPPROVAL_FAIL,
  SERVICE_ORDERAPPROVAL_LOADING,
  SERVICE_ORDERAPPROVAL_RESET,
} from "./actionType";

export const resetServiceOrderApprovalState = (actionType) => ({
  type: SERVICE_ORDERAPPROVAL_RESET,
  payload: { actionType },
});

export const getServiceOrderApprovalSuccess = (actionType, data) => ({
  type: GET_SERVICE_ORDERAPPROVAL_SUCCESS,
  payload: { actionType, data },
});

export const getServiceOrderApprovalFail = (actionType, error) => ({
  type: GET_SERVICE_ORDERAPPROVAL_FAIL,
  payload: { actionType, error },
});

export const getServiceOrderApproval = data => ({
  type: GET_SERVICE_ORDERAPPROVAL,
  payload: data,
});

export const getServiceOrderApprovalLoading = () => ({
  type: SERVICE_ORDERAPPROVAL_LOADING
});

export const deleteServiceOrderApproval = data => ({
  type: DELETE_SERVICE_ORDERAPPROVAL,
  payload: data,
});

export const deleteServiceOrderApprovalSuccess = data => ({
  type: DELETE_SERVICE_ORDERAPPROVAL_SUCCESS,
  payload: data,
});

export const deleteServiceOrderApprovalFail = error => ({
  type: DELETE_SERVICE_ORDERAPPROVAL_FAIL,
  payload: error,
});

export const updateServiceOrderApproval = data => ({
  type: UPDATE_SERVICE_ORDERAPPROVAL,
  payload: data,
});

export const updateServiceOrderApprovalFail = error => ({
  type: UPDATE_SERVICE_ORDERAPPROVAL_FAIL,
  payload: error,
});

export const updateServiceOrderApprovalSuccess = data => ({
  type: UPDATE_SERVICE_ORDERAPPROVAL_SUCCESS,
  payload: data,
});

export const addNewServiceOrderApproval = data => ({
  type: ADD_NEW_SERVICE_ORDERAPPROVAL,
  payload: data,
});

export const addServiceOrderApprovalSuccess = data => ({
  type: ADD_SERVICE_ORDERAPPROVAL_SUCCESS,
  payload: data,
});

export const addServiceOrderApprovalFail = error => ({
  type: ADD_SERVICE_ORDERAPPROVAL_FAIL,
  payload: error,
});