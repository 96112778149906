import { APIClient } from "./api_helper";
import { api as url } from "../config";
const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// postSocialLogin
export const postSocialLogin = (data) =>
  api.post(`${url.SSO_URL}/social-auth`, data);

// profile
export const updateImageProfile = (data) => api.patchImg("/profiles", data);

export const postLogin = (data) => api.post(`${url.SSO_URL}/auth`, data);
export const socialLogin = (data) =>
  api.post(`${url.SSO_URL}/social-auth`, data);
export const logout = (data) => api.post(`${url.SSO_URL}/logout`, data);
export const postRegister = (data) => api.post(`${url.SSO_URL}/register`, data);
export const postJwtForgetPwd = (data) => api.post("/jwt-forget-pwd", data);
export const postJwtProfile = (data) => api.post("/jwt-forget-pwd", data);
export const refreshToken = () => api.get("/refresh");

export const getMailApi = (data) => api.get("/mails", data);
export const approveMailApi = (data) => api.put("/mails", data);
export const addNewMailApi = (data) => api.postImg("/mails", data);
export const updateMailApi = (data) => api.patchImg("/mails", data);
export const deleteMailApi = (data) => api.delete("/mails", data);

//ROLE
export const getRoleApi = (data) => api.get("/roles", data);
export const addNewRoleApi = (data) => api.post("/roles", data);
export const updateRoleApi = (data) => api.patch("/roles", data);
export const deleteRoleApi = (data) => api.delete("/roles", data);
export const approveRoleApi = (data) => api.put("/roles", data);

export const getRoleListApi = (data) => api.get("/role-lists", data);
export const addNewRoleListApi = (data) => api.post("/role-lists", data);
export const updateRoleListApi = (data) => api.patch("/role-lists", data);
export const deleteRoleListApi = (data) => api.delete("/role-lists", data);
export const approveRoleListApi = (data) => api.put("/role-lists", data);

export const getUserApi = (data) => api.get("/users", data);
export const addNewUserApi = (data) => api.post("/users", data);
export const updateUserApi = (data) => api.patch("/users", data);
export const deleteUserApi = (data) => api.delete("/users", data);
export const approveUserApi = (data) => api.put("/users", data);

//
export const getServiceOrderApi = (data) => api.get("/service-orders", data);
export const approveServiceOrderApi = (data) =>
  api.put("/service-orders", data);
export const addNewServiceOrderApi = (data) =>
  api.post("/service-orders", data);
export const updateServiceOrderApi = (data) =>
  api.patch("/service-orders", data);
export const deleteServiceOrderApi = (data) =>
  api.delete("/service-orders", data);

export const getServiceOrderPriceApi = (data) =>
  api.get("/service-order-prices", data);
export const approveServiceOrderPriceApi = (data) =>
  api.put("/service-order-prices", data);
export const addNewServiceOrderPriceApi = (data) =>
  api.postImg("/service-order-prices", data);
export const updateServiceOrderPriceApi = (data) =>
  api.patchImg("/service-order-prices", data);
export const deleteServiceOrderPriceApi = (data) =>
  api.delete("/service-order-prices", data);

export const getServiceOrderDetailApi = (data) =>
  api.get("/service-orders-details", data);
export const approveServiceOrderDetailApi = (data) =>
  api.put("/service-orders-details", data);
export const addNewServiceOrderDetailApi = (data) =>
  api.post("/service-orders-details", data);
export const updateServiceOrderDetailApi = (data) =>
  api.patch("/service-orders-details", data);
export const deleteServiceOrderDetailApi = (data) =>
  api.delete("/service-orders-details", data);

export const getServiceOrderTotalApi = (data) =>
  api.get("/service-orders-totals", data);
export const approveServiceOrderTotalApi = (data) =>
  api.put("/service-orders-totals", data);
export const addNewServiceOrderTotalApi = (data) =>
  api.post("/service-orders-totals", data);
export const updateServiceOrderTotalApi = (data) =>
  api.patch("/service-orders-totals", data);
export const deleteServiceOrderTotalApi = (data) =>
  api.delete("/service-orders-totals", data);

export const getServiceOrderApprovalApi = (data) =>
  api.get("/service-orders-approvals", data);
export const approveServiceOrderApprovalApi = (data) =>
  api.put("/service-orders-approvals", data);
export const addNewServiceOrderApprovalApi = (data) =>
  api.post("/service-orders-approvals", data);
export const updateServiceOrderApprovalApi = (data) =>
  api.patch("/service-orders-approvals", data);
export const deleteServiceOrderApprovalApi = (data) =>
  api.delete("/service-orders-approvals", data);

export const getServiceOrderPaymentApi = (data) =>
  api.get("/service-orders-payments", data);
export const approveServiceOrderPaymentApi = (data) =>
  api.put("/service-orders-payments", data);
export const addNewServiceOrderPaymentApi = (data) =>
  api.post("/service-orders-payments", data);
export const updateServiceOrderPaymentApi = (data) =>
  api.patch("/service-orders-payments", data);
export const deleteServiceOrderPaymentApi = (data) =>
  api.delete("/service-orders-payments", data);

export const getServiceOrderHistoryApi = (data) =>
  api.get("/service-orders-histories", data);
export const approveServiceOrderHistoryApi = (data) =>
  api.put("/service-orders-histories", data);
export const addNewServiceOrderHistoryApi = (data) =>
  api.post("/service-orders-histories", data);
export const updateServiceOrderHistoryApi = (data) =>
  api.patch("/service-orders-histories", data);
export const deleteServiceOrderHistoryApi = (data) =>
  api.delete("/service-orders-histories", data);
//

export const getAppApi = (data) => api.get("/apps", data);
export const addNewAppApi = (data) => api.post("/apps", data);
export const updateAppApi = (data) => api.patch("/apps", data);
export const deleteAppApi = (data) => api.delete("/apps", data);
export const approveAppApi = (data) => api.put("/apps", data);

export const getCompanyApi = (data) => api.get("/companies", data);
export const addNewCompanyApi = (data) => api.post("/companies", data);
export const updateCompanyApi = (data) => api.patch("/companies", data);
export const deleteCompanyApi = (data) => api.delete("/companies", data);

export const getBranchApi = (data) => api.get("/branches", data);
export const addNewBranchApi = (data) => api.post("/branches", data);
export const updateBranchApi = (data) => api.patch("/branches", data);
export const deleteBranchApi = (data) => api.delete("/branches", data);

export const getClientApi = (data) => api.get("/clients", data);
export const addNewClientApi = (data) => api.post("/clients", data);
export const updateClientApi = (data) => api.patch("/clients", data);
export const deleteClientApi = (data) => api.delete("/clients", data);
export const approveClientApi = (data) => api.put("/clients", data);

export const getUnitApi = (data) => api.get("/units", data);
export const addNewUnitApi = (data) => api.post("/units", data);
export const updateUnitApi = (data) => api.patch("/units", data);
export const deleteUnitApi = (data) => api.delete("/units", data);
export const approveUnitApi = (data) => api.put("/units", data);

export const getServiceApi = (data) => api.get("/services", data);
export const addNewServiceApi = (data) => api.post("/services", data);
export const updateServiceApi = (data) => api.patch("/services", data);
export const deleteServiceApi = (data) => api.delete("/services", data);
export const approveServiceApi = (data) => api.put("/services", data);

export const getBillingApi = (data) => api.get("/billings", data);
export const addNewBillingApi = (data) => api.post("/billings", data);
export const approveBillingApi = (data) => api.put("/billings", data);
export const updateBillingApi = (data) => api.patch("/billings", data);
export const deleteBillingApi = (data) => api.delete("/billings", data);

export const getBillingComponentApi = (data) =>
  api.get("/billing-components", data);
export const addNewBillingComponentApi = (data) =>
  api.post("/billing-components", data);
export const approveBillingComponentApi = (data) =>
  api.put("/billing-components", data);
export const updateBillingComponentApi = (data) =>
  api.patch("/billing-components", data);
export const deleteBillingComponentApi = (data) =>
  api.delete("/billing-components", data);

export const getBillingCategoryApi = (data) =>
  api.get("/billing-categories", data);
export const addNewBillingCategoryApi = (data) =>
  api.post("/billing-categories", data);
export const approveBillingCategoryApi = (data) =>
  api.put("/billing-categories", data);
export const updateBillingCategoryApi = (data) =>
  api.patch("/billing-categories", data);
export const deleteBillingCategoryApi = (data) =>
  api.delete("/billing-categories", data);

export const getWarantyApi = (data) => api.get("/warranties", data);
export const addNewWarantyApi = (data) => api.post("/warranties", data);
export const approveWarantyApi = (data) => api.put("/warranties", data);
export const updateWarantyApi = (data) => api.patch("/warranties", data);
export const deleteWarantyApi = (data) => api.delete("/warranties", data);

export const getPromosionApi = (data) => api.get("/promotions", data);
export const addNewPromosionApi = (data) => api.post("/promotions", data);
export const approvePromosionApi = (data) => api.put("/promotions", data);
export const updatePromosionApi = (data) => api.patch("/promotions", data);
export const deletePromosionApi = (data) => api.delete("/promotions", data);

export const getPaymentMethodApi = (data) => api.get("/payment-methods", data);
export const approvePaymentMethodApi = (data) =>
  api.put("/payment-methods", data);
export const addNewPaymentMethodApi = (data) =>
  api.post("/payment-methods", data);
export const updatePaymentMethodApi = (data) =>
  api.patch("/payment-methods", data);
export const deletePaymentMethodApi = (data) =>
  api.delete("/payment-methods", data);

export const getPaymentTermApi = (data) => api.get("/payment-terms", data);
export const approvePaymentTermApi = (data) => api.put("/payment-terms", data);
export const addNewPaymentTermApi = (data) => api.post("/payment-terms", data);
export const updatePaymentTermApi = (data) => api.patch("/payment-terms", data);
export const deletePaymentTermApi = (data) =>
  api.delete("/payment-terms", data);

export const getTransactionTaxApi = (data) =>
  api.get("/transaction-taxes", data);
export const approveTransactionTaxApi = (data) =>
  api.put("/transaction-taxes", data);
export const addNewTransactionTaxApi = (data) =>
  api.post("/transaction-taxes", data);
export const updateTransactionTaxApi = (data) =>
  api.patch("/transaction-taxes", data);
export const deleteTransactionTaxApi = (data) =>
  api.delete("/transaction-taxes", data);

export const getOneStorageFileApi = (id) =>
  api.getOne(`${url.MEDIA_URL}/storage-files`, id);
export const getStorageFileApi = (data) =>
  api.get(`${url.MEDIA_URL}/storage-files`, data);
export const addNewStorageFileApi = (data) =>
  api.postImg(`${url.MEDIA_URL}/storage-files`, data);
export const approveStorageFileApi = (data) =>
  api.put(`${url.MEDIA_URL}/storage-files`, data);
export const updateStorageFileApi = (data) =>
  api.patchImg(`${url.MEDIA_URL}/storage-files`, data);
export const deleteStorageFileApi = (data) =>
  api.delete(`${url.MEDIA_URL}/storage-files`, data);

export const getDocumentApi = (data) => api.get("/documents", data);
export const approveDocumentApi = (data) => api.put("/documents", data);
export const addNewDocumentApi = (data) => api.post("/documents", data);
export const updateDocumentApi = (data) => api.patch("/documents", data);
export const deleteDocumentApi = (data) => api.delete("/documents", data);

export const getDocumentDetailApi = (data) =>
  api.get("/document-details", data);
export const approveDocumentDetailApi = (data) =>
  api.put("/document-details", data);
export const addNewDocumentDetailApi = (data) =>
  api.post("/document-details", data);
export const updateDocumentDetailApi = (data) =>
  api.patch("/document-details", data);
export const deleteDocumentDetailApi = (data) =>
  api.delete("/document-details", data);

export const getContactPersonApi = (data) => api.get("/contact-persons", data);
export const approveContactPersonApi = (data) =>
  api.put("/contact-persons", data);
export const addNewContactPersonApi = (data) =>
  api.post("/contact-persons", data);
export const updateContactPersonApi = (data) =>
  api.patch("/contact-persons", data);
export const deleteContactPersonApi = (data) =>
  api.delete("/contact-persons", data);

export const getTicketApi = (data) => api.get("/tickets", data);
export const approveTicketApi = (data) => api.put("/tickets", data);
export const addNewTicketApi = (data) => api.post("/tickets", data);
export const updateTicketApi = (data) => api.patch("/tickets", data);
export const deleteTicketApi = (data) => api.delete("/tickets", data);

export const getTicketCategoryApi = (data) =>
  api.get("/ticket-categories", data);
export const addNewTicketCategoryApi = (data) =>
  api.post("/ticket-categories", data);
export const approveTicketCategoryApi = (data) =>
  api.put("/ticket-categories", data);
export const updateTicketCategoryApi = (data) =>
  api.patch("/ticket-categories", data);
export const deleteTicketCategoryApi = (data) =>
  api.delete("/ticket-categories", data);

export const getServicePriceApi = (data) => api.get("/service-prices", data);
export const getServicePriceDetailApi = (data) =>
  api.get("/service-price-details", data);
export const getServicePriceCategoryApi = (data) =>
  api.get("/service-price-categories", data);
export const getServiceListApi = (data) => api.get("/service-lists", data);

export const getTaskApi = (data) => api.get(`${url.ERP_URL}/tasks`, data);
export const getPublicTaskApi = (data) =>
  api.get(`${url.ERP_URL}/task-publics`, data);
export const approveTaskApi = (data) => api.put(`${url.ERP_URL}/tasks`, data);
export const addNewTaskApi = (data) => api.post(`${url.ERP_URL}/tasks`, data);
export const updateTaskApi = (data) => api.patch(`${url.ERP_URL}/tasks`, data);
export const deleteTaskApi = (data) => api.delete(`${url.ERP_URL}/tasks`, data);
