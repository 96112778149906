import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Table, Button, Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, Modal, ModalHeader, Form, ModalBody, Label, Input, FormFeedback, Spinner } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import ApproveModal from "../../../Components/Common/ApproveModal";
import { isEmpty, clone } from "lodash";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { Grid, _ } from "gridjs-react";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//   Import actions
//   import { getAccAccount, resetAccAccountState } from "../../../store/accAccount/action";
import { getClient, resetClientState } from "../../../store/clients/action";
import { getWaranty, resetWarantyState } from "../../../store/waranty/action";
import { getPromosion, resetPromosionState } from "../../../store/promosion/action";
import { getService, resetServiceState } from "../../../store/service/action";
// import { getDocumentCategory, resetDocumentCategoryState } from "../../../store/documentCategory/action";
import { getDocument as onGetDocuments, addNewDocument as onAddNewDocument, updateDocument as onUpdateDocument, deleteDocument as onDeleteDocument, getDocumentLoading, resetDocumentState, addNewDocument } from "../../../store/document/action";
import { getDocumentDetail as onGetDocumentDetails, addNewDocumentDetail as onAddNewDocumentDetail, updateDocumentDetail as onUpdateDocumentDetail, deleteDocumentDetail as onDeleteDocumentDetail, getDocumentDetailLoading, resetDocumentDetailState } from "../../../store/documentDetail/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PropTypes from "prop-types";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import CopyModal from "../../../Components/Common/CopyModal";
import defaultImg from "../../../assets/images/upload.png";
import { api } from "../../../config";
import ModalInputFile from "../../../Components/ModalInputFile";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Wiki = (props) => {
  const [logo, setLogo] = useState(defaultImg);
  const [modal, setModal] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  const { promosions, loadingPromosion, waranties, loadingWaranty, documentCategoryLoading, documentCategories, documents, documentDetails, isDocumentCreated, isDocumentSuccess, loading, error, documentDetailLoading, clients, services } = useSelector((state) => ({
    documents: state.Document.documents,
    isDocumentCreated: state.Document.isDocumentCreated,
    isDocumentSuccess: state.Document.isDocumentSuccess,
    loading: state.Document.loading,
    error: state.Document.error,
    // documentCategories: state.DocumentCategory.documentCategories,
    // documentCategoryLoading: state.DocumentCategory.documentCategoryLoading,
    documentDetails: state.DocumentDetail.documentDetails,
    clients: state.Clients.clients,
    services: state.Services.services,
    waranties: state.Waranty.waranties,
    loadingWaranty: state.Waranty.loading,
    promosions: state.Promosion.promosions,
    loadingPromosion: state.Promosion.loading,
  }));

  const [isSearch, setIsSearch] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [warantyList, setWarantyList] = useState([]);
  const [document, setDocument] = useState({});
  const [componentDetailAddedId, setComponentDetailAddedId] = useState(0);
  const [info, setInfo] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);
  const [documentDetail, setDocumentDetail] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [approveModal, setApproveModal] = useState(false);
  const [componentType, setComponentType] = useState("");
  const [amountType, setAmountType] = useState("");
  const [componentTypeCategory, setComponentTypeCategory] = useState("");
  const [periodType, setPeriodType] = useState("");
  const [selectedAllowance, setSelectedAllowance] = useState([]);
  const [roles, setRoles] = useState(null);
  const [userType, setUserType] = useState("user");
  const [accountingList, setAccountingList] = useState([]);
  const [documentDetailList, setDocumentDetailList] = useState([]);
  const [documentCategoryList, setDocumentCategoryList] = useState([]);
  const [documentId, setDocumentId] = useState();
  const [clientId, setClientId] = useState();
  const [documentName, setDocumentName] = useState();
  const [clientList, setClientList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [promotionList, setPromotionList] = useState([]);

  const periodTypeData = [
    {
      options: [
        { label: "Daily", value: "Daily" },
        { label: "Monthly", value: "Monthly" },
        { label: "One Time", value: "One Time" },
      ],
    },
  ];

  const componentTypeCategoryAllowanceData = [
    {
      options: [
        { label: "Custom", value: "Custom" },
        { label: "BPJS Kesehatan", value: "BPJS Kesehatan" },
        { label: "JHT", value: "JHT" },
        { label: "JP", value: "JP" },
        { label: "JKK", value: "JKK" },
        { label: "JKM", value: "JKM" },
      ],
    },
  ];

  const componentTypeCategoryDeductionData = [
    {
      options: [
        { label: "Custom", value: "Custom" },
        { label: "Savings", value: "Savings" },
        // { label: "Loan", value: "Loan" },
        // { label: "Over Payment", value: "Over Payment" },
        { label: "Unpaid Leave", value: "Unpaid Leave" },
        { label: "Absent Compensation", value: "Absent Compensation" },
        { label: "BPJS Kesehatan", value: "BPJS Kesehatan" },
        { label: "JHT", value: "JHT" },
        { label: "JP", value: "JP" },
        { label: "JKK", value: "JKK" },
        { label: "JKM", value: "JKM" },
      ],
    },
  ];

  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: "1" },
        { label: "Activated", value: "2" },
      ],
    },
  ];

  const componentTypeData = [
    {
      options: [
        { label: "Device", value: "Device" },
        { label: "Request", value: "Request" },
        { label: "Storage", value: "Storage" },
        { label: "User", value: "User" },
        { label: "Subscription", value: "Subscription" },
      ],
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditDetail, setIsEditDetail] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [deleteModalDetail, setDeleteModalDetail] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [unitName, setUnitName] = useState({});
  const [modalMedia, setModalMedia] = useState(false);

  const defaultdate = () => {
    return moment(new Date()).tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };

  const handleSelectFile = (file) => {
    if (modalDetail) {
      validationDetail.setFieldValue("image", file.filename);
    } else {
      validation.setFieldValue("image", file.filename);
    }
    toggleMedia();
  };

  const toggleMedia = useCallback(() => {
    if (modalMedia) {
      setModalMedia(false);
    } else {
      setModalMedia(true);
    }
  }, [modalMedia]);

  const onClickDelete = (document) => {
    setDocument(document);
    setDeleteModal(true);
  };

  const onClickDeleteDetail = (documentDetail) => {
    setDocumentDetail(documentDetail);
    setDeleteModalDetail(true);
  };

  const handleDeleteDocument = () => {
    if (document) {
      dispatch(onDeleteDocument(document));
      setDeleteModal(false);
    }
  };

  const handleCopyDocument = () => {
    if (document) {
      dispatch(onAddNewDocument({ targetId: document.id }));
      setCopyModal(false);
    }
  };

  const checkHandler = () => {
    setIsChecked(!isChecked);
  };

  const handleDeleteDocumentDetail = () => {
    if (documentDetail) {
      setComponentDetailAddedId(documentDetail.componentId);
      dispatch(onDeleteDocumentDetail(documentDetail));
      setDeleteModalDetail(false);
    }
  };

  const onClickApprove = (employee) => {
    setDocument(employee);
    setApproveModal(true);
  };

  const onClickCopy = (data) => {
    setDocument(data);
    setCopyModal(true);
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredDocuments = documents;
      if (type !== "all") {
        filteredDocuments = documents.filter((document) => document.document_status.name === type);
      }
      setDocumentList(filteredDocuments);
    }
  };

  useEffect(() => {
    if (!isSearch) {
      dispatch(onGetDocuments());
      setIsSearch(true);
      const obj = JSON.parse(localStorage.getItem("authUser"));
      // if (
      //   obj.data &&
      //   obj.data.employee_position &&
      //   obj.data.employee_position.position_role
      // ) {
      //   setUnitName({
      //     label: obj.data.employee_unit.unit_name,
      //     value: obj.data.employee_unit.id,
      //   });
      //   setRoles(obj.data.employee_position.position_role.role_method);
      // } else setRoles(null);

      // if (obj.data && obj.data.userType) setUserType(obj.data.userType);
    }
  }, [dispatch, isSearch]);

  useEffect(() => {
    if (!isEmpty(documents)) {
      setIsEdit(false);
      setDocumentList(documents);
      let current = 0;
      if (componentDetailAddedId !== 0) {
        documents.forEach((obj, i) => {
          if (obj.id === componentDetailAddedId) current = i;
        });
      } else {
        setInfo(documents[current]);
        setDocumentId(documents[current].id);
        setClientId(documents[current].clientId);
        setDocumentName(documents[current].name);
        dispatch(onGetDocumentDetails({ documentId: documents[current].id }));
      }
    }
  }, [dispatch, documents, componentDetailAddedId]);

  useEffect(() => {
    // dispatch(resetClientState());
    // dispatch(getClient());
    dispatch(resetWarantyState());
    dispatch(getWaranty());
    dispatch(resetPromosionState());
    dispatch(getPromosion());
    // dispatch(resetDocumentCategoryState());
    // dispatch(getDocumentCategory());
    dispatch(resetServiceState());
    dispatch(getService());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(documentCategories)) {
      var data = [];
      var opt = [];
      documentCategories.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setDocumentCategoryList(data);
    }
  }, [documentCategories]);

  useEffect(() => {
    if (!isEmpty(services)) {
      var data = [];
      var opt = [];
      services.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setServiceList(data);
    }
  }, [services]);

  useEffect(() => {
    if (isDocumentCreated) {
      dispatch(resetDocumentState());
      dispatch(onGetDocuments());
    }
  }, [dispatch, isDocumentCreated]);

  const [filterDate, setFilterDate] = useState(defaultdate());

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setDocument({});
      validation.resetForm();
      validation.setErrors({});
    } else {
      setModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const resetList = () => {};

  const handleDocumentClick = useCallback(
    (arg) => {
      const data = arg;
      setDocument({
        id: data.id ?? 0,
        name: data.name ?? "",
        image: data.image ?? "",
        sortOrder: data.sort_order ?? 0,
        type: data.type ?? "",
        description: data.description,
        status:
          data.document_status === null
            ? ""
            : {
                label: data.document_status.name,
                value: data.document_status.id,
              },
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const toggleDetail = useCallback(() => {
    if (modalDetail) {
      setModalDetail(false);
      setSelectedAllowance([]);
      setDocumentDetail(null);
      setDocument({});
      setIsChecked(false);
      validation.resetForm();
      validation.setErrors({});
    } else {
      setModalDetail(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalDetail]);

  const handleDocumentDetailClick = useCallback(
    (arg, arginfo, isEdit) => {
      const data = arg;

      setDocumentDetail({
        id: data.id ?? 0,
        image: data.image ?? "",
        name: data.name ?? "",
        sortOrder: data.sort_order ?? 0,
        description: data.description ?? "",
        status:
          data.document_detail_status === null
            ? ""
            : {
                label: data.document_detail_status.name,
                value: data.document_detail_status.id,
              },
      });

      setIsChecked(data.isTaxable);

      setIsEditDetail(isEdit);
      toggleDetail();
    },
    [toggleDetail]
  );

  const handleCopyData = (data) => {
    dispatch(onAddNewDocument(data));
  };

  const columns = useMemo(
    () => [
      {
        Header: " ",
        maxWidth: 100,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              {cellProps.row.original.statusActiveId === 1 ? (
                <button
                  className="btn-label btn btn-sm btn-soft-success fs-12 me-0"
                  onClick={() => {
                    const data = cellProps.row.original;
                    onClickApprove(data);
                  }}
                >
                  <i className="ri-checkbox-circle-line label-icon align-middle fs-16 me-1"></i>
                  Approve
                </button>
              ) : (
                ""
              )}
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-primary"
                onClick={() => {
                  setInfo(cellProps.row.original);
                  setDocumentId(cellProps.row.original.id);
                  setClientId(cellProps.row.original.clientId);
                  setDocumentName(cellProps.row.original.name);
                  dispatch(onGetDocumentDetails({ documentId: cellProps.row.original.id }));
                }}
              >
                <i className="ri-eye-line fs-15"></i>
              </Link>
              <Link
                to="#"
                className={"btn-icon btn btn-sm btn-soft-primary edit-item-btn"}
                onClick={() => {
                  handleDocumentClick(cellProps.row.original);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </Link>
              {/* <Link
                to="#"
                className={"btn-icon btn btn-sm btn-soft-info remove-item-btn"}
                onClick={() => {
                  const documentData = cellProps.row.original;
                  onClickCopy(documentData);
                  // (documentData.id);
                  // const dataCopy = { targetId: documentData.id };
                  // handleCopyData(dataCopy);
                }}
              >
                <i className=" ri-file-copy-line fs-15"></i>
              </Link> */}
            </div>
          );
        },
      },
      {
        Header: `${props.t("Name")}`,
        accessor: "name",
        filterable: true,
        Cell: (client) => (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              {client.row.original.image !== null && client.row.original.image !== "" ? (
                <img src={api.MEDIA_URL + "/media/" + client.row.original.image} alt="" className="avatar-xs rounded-circle me-1" />
              ) : (
                <div className="flex-shrink-0 avatar-xs me-1">
                  <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">{client.row.original.image !== null ? client.row.original.name.charAt(0).toUpperCase() : "-"}</div>
                </div>
              )}
            </div>
            <div className="flex-grow-1 ms-2 name">{client.row.original.image !== null ? client.row.original.name : "-"}</div>
          </div>
        ),
      },
      {
        Header: `${props.t("Type")}`,
        accessor: "type",
        filterable: true,
      },
      {
        Header: `${props.t("Description")}`,
        accessor: "description",
        filterable: true,
      },
      {
        Header: `${props.t("Status")}`,
        accessor: "document_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.row.original.statusId) {
            case 1:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
            case 2:
              return <span className="badge text-uppercase badge-soft-info">Activated</span>;
            default:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
          }
        },
      },
    ],
    [handleDocumentClick, dispatch, props]
  );

  const columnsSecond = useMemo(
    () => [
      {
        Header: " ",
        maxWidth: 100,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              {cellProps.row.original.statusActiveId === 1 ? (
                <button
                  className="btn-label btn btn-sm btn-soft-success fs-12 me-0"
                  onClick={() => {
                    const data = cellProps.row.original;
                    onClickApprove(data);
                  }}
                >
                  <i className="ri-checkbox-circle-line label-icon align-middle fs-16 me-1"></i>
                  Approve
                </button>
              ) : (
                ""
              )}
              <Link
                to="#"
                className={"btn-icon btn btn-sm btn-soft-primary edit-item-btn"}
                onClick={() => {
                  handleDocumentDetailClick(cellProps.row.original, info, true);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </Link>
              {/* <Link
                to="#"
                className={"btn-icon btn btn-sm btn-soft-danger remove-item-btn"}
                onClick={() => {
                  const documentData = cellProps.row.original;
                  (documentData);
                  onClickDeleteDetail(documentData);
                }}
              >
                <i className=" ri-delete-bin-line fs-15"></i>
              </Link> */}
            </div>
          );
        },
      },
      {
        Header: `${props.t("Name")}`,
        accessor: "name",
        filterable: true,
        Cell: (client) => (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              {client.row.original.image !== null && client.row.original.image !== "" ? (
                <img src={api.MEDIA_URL + "/media/" + client.row.original.image} alt="" className="avatar-xs rounded-circle me-1" />
              ) : (
                <div className="flex-shrink-0 avatar-xs me-1">
                  <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">{client.row.original.image !== null ? client.row.original.name.charAt(0).toUpperCase() : "-"}</div>
                </div>
              )}
            </div>
            <div className="flex-grow-1 ms-2 name">{client.row.original.image !== null ? client.row.original.name : "-"}</div>
          </div>
        ),
      },

      {
        Header: `${props.t("Sort")}`,
        accessor: "sort_order",
        filterable: true,
        Cell: (cell) => parseFloat(cell.row.original.sort_order).toFixed(0),
      },
      {
        Header: `${props.t("Status")}`,
        accessor: "document_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.row.original.statusId) {
            case 1:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
            case 2:
              return <span className="badge text-uppercase badge-soft-info">Activated</span>;
            default:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
          }
        },
      },
    ],
    [handleDocumentDetailClick, props, info]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: documentList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (documentDetailList.length > 0) {
      setPageSizeSecond(Number(documentDetailList.length));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentDetailList]);

  const {
    getTableProps: getTablePropsSecond,
    getTableBodyProps: getTableBodyPropsSecond,
    headerGroups: headerGroupsSecond,
    page: pageSecond,
    prepareRow: prepareRowSecond,
    canPreviousPage: canPreviousPageSecond,
    canNextPage: canNextPageSecond,
    pageOptions: pageOptionsSecond,
    gotoPage: gotoPageSecond,
    nextPage: nextPageSecond,
    previousPage: previousPageSecond,
    setPageSize: setPageSizeSecond,
    preGlobalFilteredRows: preGlobalFilteredRowsSecond,
    setGlobalFilter: setGlobalFilterSecond,
    state: { pageIndex: pageIndexSecond, pageSize: pageSizeSecond },
  } = useTable(
    {
      columns: columnsSecond,
      data: documentDetailList,
      defaultColumn: { Filter: null },
      // initialState: {
      //   pageIndex: 0,
      //   // pageSize: 8,
      //   selectedRowIds: 0,
      //   sortBy: [
      //     {
      //       desc: true,
      //     },
      //   ],
      // },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable ? (column.isSorted ? (column.isSortedDesc ? " sorting_desc" : " sorting_asc") : " sort") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState([]);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const countSecond = preGlobalFilteredRowsSecond.length;
  const [searchValueSecond, setSearchValueSecond] = useState([]);
  const onChangeSecond = useAsyncDebounce((value) => {
    setGlobalFilterSecond(value || undefined);
  }, 200);

  const handleApproveComponent = () => {
    if (document) {
      document.setApprove = true;
      // dispatch(onUpdateDocument(document));
      setApproveModal(false);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: (document && document.id) || 0,
      name: (document && document.name) || "",
      sortOrder: (document && document.amount) || 0,
      status: (document && document.status) || "",
      type: (document && document.type) || "",
      image: (document && document.image) || "",
      description: (document && document.description) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      // service: Yup.object().required("Please Select Service"),
      // status: Yup.object().required("Please Select Status"),
    }),
    onSubmit: (values) => {
      // (values);
      // dispatch(getDocumentLoading());
      if (isEdit) {
        const dataEditSubmit = {
          id: values.id,
          image: values.image,
          name: values.name,
          type: values.type,
          description: values.description,
          statusId: values.status.value,
          sort_order: values.sortOrder,
        };
        dispatch(onUpdateDocument(dataEditSubmit));
      } else {
        const dataAddSubmit = {
          image: values.image,
          name: values.name,
          type: values.type,
          description: values.description,
          statusId: values.status.value,
          sort_order: values.sortOrder,
        };
        dispatch(onAddNewDocument(dataAddSubmit));
      }
      toggle();
    },
  });

  const validationDetail = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: (documentDetail && documentDetail.id) || 0,
      name: (documentDetail && documentDetail.name) || "",
      sortOrder: (documentDetail && documentDetail.amount) || 0,
      status: (documentDetail && documentDetail.status) || "",
      image: (documentDetail && documentDetail.image) || "",
      description: (documentDetail && documentDetail.description) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
    }),
    onSubmit: (values) => {
      dispatch(getDocumentDetailLoading());
      if (isEditDetail) {
        const updateDocumentDetail = {
          id: documentDetail ? documentDetail.id : 0,
          documentId: info.id,
          image: values.image,
          description: values.description,
          name: values.name,
          sort_order: values.sortOrder,
          statusId: values.status.value,
        };
        dispatch(onUpdateDocumentDetail(updateDocumentDetail));
      } else {
        const newDocumentDetail = {
          documentId: info.id,
          image: values.image,
          description: values.description,
          name: values.name,
          sort_order: values.sortOrder,
          statusId: values.status.value,
        };

        dispatch(onAddNewDocumentDetail(newDocumentDetail));
      }
      toggleDetail();
    },
  });

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      // dispatch(onDeleteDocumentDetail(element.value));
    });
    checkall.checked = false;
  };

  function handleDocumentStatus(data) {
    setStatusActive(data);
  }

  function handleFilter() {
    let startat = "";
    let endat = "";
    let datearr = filterDate.split(" to ");
    if (datearr[1]) {
      startat = datearr[0];
      endat = datearr[1];
    }
    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    //   dispatch(onGetDocuments(param));
  }

  useEffect(() => {
    var data = [];
    var opt = [];
    waranties.forEach((item, key) => {
      const name = item.name;
      const amount = item.amount;
      const id = item.id;
      var obj = { label: `${name} - ${amount}`, value: id };
      opt.push(obj);
    });
    const defaultSelect = {
      label: "No Warenty",
      value: null,
    };
    var option = { options: [defaultSelect, ...opt] };
    data.push(option);
    setWarantyList(data);
  }, [waranties]);

  useEffect(() => {
    var data = [];
    var opt = [];
    promosions.forEach((item, key) => {
      const name = item.name;
      const id = item.id;
      var obj = { label: name, value: id };
      opt.push(obj);
    });
    const defaultSelect = {
      label: "No Promotion",
      value: null,
    };
    var option = { options: [defaultSelect, ...opt] };
    data.push(option);
    setPromotionList(data);
  }, [promosions]);

  useEffect(() => {
    if (documentDetails) {
      setDocumentDetailList(documentDetails);
    }
  }, [documentDetails]);

  document.title = `${props.t("Document")} | Aptek`;
  return (
    <div className="page-content">
      <ApproveModal data={document && document.contract_employee ? document.contract_employee.employee_name : ""} show={approveModal} onApproveClick={handleApproveComponent} onCloseClick={() => setApproveModal(false)} />
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteDocument} onCloseClick={() => setDeleteModal(false)} />
      <CopyModal show={copyModal} onCopyClick={handleCopyDocument} onCloseClick={() => setCopyModal(false)} />
      <DeleteModal show={deleteModalDetail} onDeleteClick={handleDeleteDocumentDetail} onCloseClick={() => setDeleteModalDetail(false)} />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("Document")} pageTitle={props.t("Document")} />
        <Row>
          <Col xxl={5}>
            <Card>
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    {props.t("Document Data")} &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color="primary" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className={"btn btn-primary add-btn"}
                      id="create-btn"
                      onClick={() => {
                        // setDocument("");
                        // setIsEdit(false);
                        toggle();
                      }}
                    >
                      <i className="ri-booklet-line me-1 align-bottom fs-14"></i> {props.t("Add")}
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className="ri-booklet-line me-1 align-bottom fs-14"></i> All Document
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("2", "Draft");
                        }}
                        href="#"
                      >
                        <i className="ri-information-line me-1 align-bottom fs-14"></i> Draft
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("3", "Activated");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Activated{" "}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className="mb-3">
                    <CardBody className="border border-top-0 border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={1} className="col-xxl-2">
                            <div className="me-2 mb-2 col-12">
                              <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={7}>
                            <div className="search-box me-2 mb-2 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${count} Search...`}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col className="col-xxl-3">
                            <div>
                              <button
                                type="button"
                                className="btn btn-light w-100"
                                onClick={() => {
                                  handleFilter();
                                }}
                              >
                                <i className="ri-search-line me-1 align-bottom fs-14"></i> {props.t("Search")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card mb-1">
                    <Table hover {...getTableProps()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroups.map((headerGroup) => (
                          <tr className={""} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button color="light" onClick={previousPage} disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      Page
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}{" "}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
                    </Col>
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button color="light" onClick={nextPage} disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal id="showModal" isOpen={modal} centered size="md">
                  <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                      toggle();
                      validation.resetForm();
                      validation.setErrors({});
                    }}
                  >
                    {!!isEdit ? `${props.t("Edit")} ${props.t("Document")}` : `${props.t("Add")} ${props.t("Document")}`}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody>
                      <div className="row">
                        {/* <div className="col-lg-12"> */}
                        {/* <div className="mb-3">
                          <Label htmlFor="client-field" className="form-label">
                            {props.t("Client")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="client"
                            value={validation.values.client}
                            placeholder="Enter Client"
                            onChange={(e) => {
                              validation.setFieldValue("client", e);
                            }}
                            options={clientList}
                            validate={{ required: { value: true } }}
                            invalid={validation.touched.client && validation.errors.client ? true : false}
                          ></Select>
                          {validation.touched.client && validation.errors.client ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.client}
                            </p>
                          ) : null}
                        </div> */}

                        <div className="col-lg-12 mb-3">
                          <div className="text-center">
                            <div className="position-relative d-inline-block">
                              <div className="position-absolute bottom-0 end-0">
                                <Label
                                  onClick={() => {
                                    toggleMedia();
                                  }}
                                  htmlFor="unit-logo-input"
                                  className="mb-0"
                                >
                                  <div className="avatar-xs cursor-pointer">
                                    <div className="avatar-title bg-light border rounded-circle text-muted">
                                      <i className="ri-image-fill"></i>
                                    </div>
                                  </div>
                                </Label>
                                {/* <Input
                                    name="img"
                                    className="form-control d-none"
                                    id="unit-logo-input"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={(e) => {
                                      // handleLogoChange(e);
                                    }}
                                    onBlur={validation.handleBlur}
                                    invalid={validation.touched.img && validation.errors.img ? true : false}
                                  /> */}
                                {/* <button className="form-control d-none" onClick={()=>{
                                    ("clickkkk");
                                  }}>

                                  </button> */}
                              </div>
                              <div className="avatar-lg p-1">
                                <div className="avatar-title bg-light rounded-circle">
                                  <img src={validation.values.image === "" ? logo : `${api.MEDIA_URL}/media/${validation.values.image}`} alt="" id="unitlogo-img" className="avatar-md rounded-circle object-cover" />
                                </div>
                              </div>
                            </div>
                            <h5 className="fs-13 mt-3">Logo</h5>
                          </div>
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="name-field" className="form-name">
                            {props.t("Name")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="name" id="name-field" className="form-control" placeholder="Enter Name" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.name} invalid={validation.touched.name && validation.errors.name ? true : false} />
                          {validation.touched.name && validation.errors.name ? <FormFeedback type="invalid">{validation.errors.name}</FormFeedback> : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="type-field" className="form-name">
                            {props.t("Type")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="type" id="type-field" className="form-control" placeholder="Enter Type" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.type} invalid={validation.touched.type && validation.errors.type ? true : false} />
                          {validation.touched.type && validation.errors.type ? <FormFeedback type="invalid">{validation.errors.type}</FormFeedback> : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="sortOrder-field" className="form-name">
                            {props.t("Sort")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="sortOrder" id="sortOrder-field" className="form-control" placeholder="Enter Sort Order" type="number" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.sortOrder} invalid={validation.touched.sortOrder && validation.errors.sortOrder ? true : false} />
                          {validation.touched.sortOrder && validation.errors.sortOrder ? <FormFeedback type="invalid">{validation.errors.sortOrder}</FormFeedback> : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="active-status" className="form-label">
                            {props.t("Status")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="status"
                            value={validation.values.status}
                            validate={{ required: { value: true } }}
                            placeholder="Select Status"
                            onChange={(e) => {
                              validation.setFieldValue("status", e);
                            }}
                            onInputChange={(e) => {
                              // handleUnit(e);
                            }}
                            options={statusActiveData}
                            invalid={validation.touched.status && validation.errors.status ? true : false}
                          ></Select>
                          {validation.touched.status && validation.errors.status ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.status}
                            </p>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="description-field" className="form-label">
                            Description
                          </Label>
                          <Input name="description" id="description-field" className="form-control" placeholder="Enter Description" type="textarea" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.description || ""} invalid={validation.touched.description && validation.errors.description ? true : false} />
                        </div>

                        {/* <div className="mb-3 col-lg-12">
                          <Label htmlFor="amount-field" className="form-label">
                            {props.t("Amount")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="amount" id="amount-field" className="form-control" placeholder="Enter Amount" type="number" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.amount} invalid={validation.touched.amount && validation.errors.amount ? true : false} />
                          {validation.touched.amount && validation.errors.amount ? <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback> : null}
                        </div> */}
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            setModal(false);
                            validation.resetForm();
                            validation.setErrors({});
                          }}
                        >
                          {props.t("Cancel")}
                        </button>
                        <button type="submit" className="btn btn-success">
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">{props.t("Save")}</span>
                            {/* {loading && (
                                <Spinner
                                  size="sm"
                                  className="flex-shrink-0"
                                  role="status"
                                >
                                  Loading...
                                </Spinner>
                              )} */}
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <Modal id="showModalDetail" isOpen={modalDetail} centered size="xl">
                  <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                      toggleDetail();
                      validationDetail.resetForm();
                      validationDetail.setErrors({});
                    }}
                  >
                    {!!isEditDetail ? `${props.t("Edit")} ${props.t("Document Component")}` : `${props.t("Add")} ${props.t("Document Component")}`}
                  </ModalHeader>
                  <ModalBody>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <div className="text-center">
                            <div className="position-relative d-inline-block">
                              <div className="position-absolute bottom-0 end-0">
                                <Label
                                  onClick={() => {
                                    toggleMedia();
                                  }}
                                  htmlFor="unit-logo-input"
                                  className="mb-0"
                                >
                                  <div className="avatar-xs cursor-pointer">
                                    <div className="avatar-title bg-light border rounded-circle text-muted">
                                      <i className="ri-image-fill"></i>
                                    </div>
                                  </div>
                                </Label>
                                {/* <Input
                                    name="img"
                                    className="form-control d-none"
                                    id="unit-logo-input"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={(e) => {
                                      // handleLogoChange(e);
                                    }}
                                    onBlur={validation.handleBlur}
                                    invalid={validation.touched.img && validation.errors.img ? true : false}
                                  /> */}
                                {/* <button className="form-control d-none" onClick={()=>{
                                    ("clickkkk");
                                  }}>

                                  </button> */}
                              </div>
                              <div className="avatar-lg p-1">
                                <div className="avatar-title bg-light rounded-circle">
                                  <img src={validationDetail.values.image === "" ? logo : `${api.MEDIA_URL}/media/${validationDetail.values.image}`} alt="" id="unitlogo-img" className="avatar-md rounded-circle object-cover" />
                                </div>
                              </div>
                            </div>
                            <h5 className="fs-13 mt-3">Logo</h5>
                          </div>
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="name-field" className="form-name">
                            {props.t("Name")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="name" id="name-field" className="form-control" placeholder="Enter Name" type="text" validate={{ required: { value: true } }} onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.name} invalid={validationDetail.touched.name && validationDetail.errors.name ? true : false} />
                          {validationDetail.touched.name && validationDetail.errors.name ? <FormFeedback type="invalid">{validationDetail.errors.name}</FormFeedback> : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="sortOrder-field" className="form-name">
                            {props.t("Sort")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="sortOrder" id="sortOrder-field" className="form-control" placeholder="Enter Sort Order" type="number" validate={{ required: { value: true } }} onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.sortOrder} invalid={validationDetail.touched.sortOrder && validationDetail.errors.sortOrder ? true : false} />
                          {validationDetail.touched.sortOrder && validationDetail.errors.sortOrder ? <FormFeedback type="invalid">{validationDetail.errors.sortOrder}</FormFeedback> : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="active-status" className="form-label">
                            {props.t("Status")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="status"
                            value={validationDetail.values.status}
                            validate={{ required: { value: true } }}
                            placeholder="Select Status"
                            onChange={(e) => {
                              validationDetail.setFieldValue("status", e);
                            }}
                            onInputChange={(e) => {
                              // handleUnit(e);
                            }}
                            options={statusActiveData}
                            invalid={validationDetail.touched.status && validationDetail.errors.status ? true : false}
                          ></Select>
                          {validationDetail.touched.status && validationDetail.errors.status ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validationDetail.errors.status}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 ">
                        <CKEditor
                          editor={ClassicEditor}
                          data={validationDetail.values.description}
                          onReady={(editor) => {
                            editor.editing.view.change((writer) => {
                              writer.setStyle("height", "350px", editor.editing.view.document.getRoot());
                            });
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            validationDetail.setFieldValue("description", data);
                          }}
                        />
                      </div>
                    </div>
                  </ModalBody>
                  <div className="modal-footer">
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          setModalDetail(false);
                          validationDetail.resetForm();
                          validationDetail.setErrors({});
                        }}
                      >
                        {props.t("Cancel")}
                      </button>
                      <button
                        onClick={() => {
                          validationDetail.handleSubmit();
                        }}
                        type="submit"
                        className="btn btn-success"
                      >
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">{props.t("Save")}</span>
                          {/* {loading && (
                                <Spinner
                                  size="sm"
                                  className="flex-shrink-0"
                                  role="status"
                                >
                                  Loading...
                                </Spinner>
                              )} */}
                        </span>
                      </button>
                    </div>
                  </div>
                </Modal>
                <ModalInputFile onSelect={handleSelectFile} loading={loading} modal={modalMedia} toggle={toggleMedia} />
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
          <Col xxl={7}>
            <Card>
              <div className="card-body d-flex align-items-center">
                <h5 className="text-uppercase mb-2 flex-grow-1">{documentName ? documentName : " - "}</h5>
                <div className="flex-shrink-0">
                  <button
                    type="button"
                    className="btn btn-success add-btn"
                    id="create-btn"
                    onClick={() => {
                      setDocument(info);
                      setIsEditDetail(false);
                      toggleDetail();
                      var data = [];
                      var opt = [{ label: "Select All", value: "all" }];
                      info.pc_detail &&
                        info.pc_detail.forEach((item, key) => {
                          if (item.component_type === "Allowance") {
                            const detail_name = item.detail_name;
                            const id = item.id;
                            var obj = { label: detail_name, value: id };
                            opt.push(obj);
                          }
                        });
                      var option = { options: opt };
                      data.push(option);
                      setSelectedAllowance(data);
                    }}
                  >
                    <i className="ri-list-check label-icon align-middle fs-13 me-1"></i>
                    {props.t("Add")} {props.t("Document Component")}
                  </button>
                  {/* <button
                      className="btn btn-soft-danger"
                      onClick={() => setDeleteModalMulti(true)}
                    >
                      <i className="ri-delete-bin-5-line me-1 align-bottom fs-13"></i>
                    </button> */}
                </div>
              </div>
              <div className="card-body">
                {/* <Grid
                  data={documentDetailList || []}
                  columns={[
                    {
                      id: "actionchecked",
                      name: _(<input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />),
                      width: "4%",
                      sort: false,
                      data: (row) => row.id,
                      formatter: (cell) => _(<input type="checkbox" className="dataCheckbox form-check-input" value={cell} />),
                    },
                    {
                      name: "Name",
                      data: (row) => row.name,
                      width: "18%",
                    },
                    {
                      name: "Type",
                      width: "10%",
                      data: (row) => row.type,
                    },
                    {
                      name: "Period",
                      width: "14%",
                      data: (row) => row.period,
                    },
                    {
                      name: "Amount",
                      width: "14%",
                      data: (row) => row.amount,
                    },
                    {
                      name: "Status",
                      width: "10%",
                      data: (row) => row,
                      formatter: (cell) => _(<span className={cell.document_component_status.id === 1 ? "badge text-uppercase badge-soft-danger" : cell.document_component_status.id === 2 ? "badge text-uppercase badge-soft-info" : "badge text-uppercase badge-soft-warning"}>{cell.document_component_status.id === 2 ? "Activated" : cell.document_component_status.id === 1 ? "Draft" : ""}</span>),
                    },
                    {
                      name: "action",
                      sort: false,
                      width: "9%",
                      data: (row) => row,
                      formatter: (cell) =>
                        _(
                          <div className="hstack gap-2 mt-4 mt-sm-0">
                            <button
                              className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                              onClick={() => {
                                handleDocumentDetailClick(cell, info, true);
                              }}
                            >
                              <i className="ri-pencil-line fs-16"></i>
                            </button>
                            {cell.statusActiveId !== 2 ? (
                              <button
                                to="#"
                                className="btn-icon btn btn-sm btn-soft-danger remove-item-btn"
                                onClick={() => {
                                  onClickDeleteDetail(cell);
                                }}
                              >
                                <i className=" ri-delete-bin-line fs-15"></i>
                              </button>
                            ) : null}
                          </div>
                        ),
                    },
                  ]}
                  search={true}
                  sort={true}
                /> */}

                <Row>
                  <CardBody className="border border-top-0 border-end-0 border-start-0">
                    <form>
                      <Row>
                        <Col sm={4}>
                          <div className="search-box me-2 mb-2 d-inline-block col-12">
                            <input
                              onChange={(e) => {
                                setSearchValueSecond(e.target.value);
                                onChangeSecond(e.target.value);
                              }}
                              id="search-bar-0"
                              type="text"
                              className="form-control search"
                              placeholder={`${countSecond} Search...`}
                              value={searchValueSecond || ""}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Row>
                <div className="table-responsive table-card mb-1">
                  <Table hover {...getTablePropsSecond()} className="align-middle table-nowrap">
                    <thead className="table-light text-muted">
                      {headerGroupsSecond.map((headerGroup) => (
                        <tr className={""} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                              {column.render("Header")}
                              {generateSortingIndicator(column)}
                              {/* <Filter column={column} /> */}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyPropsSecond()}>
                      {pageSecond.map((row) => {
                        prepareRowSecond(row);
                        return (
                          <Fragment key={row.getRowProps().key}>
                            <tr>
                              {row.cells.map((cell) => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Wiki.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Wiki));
