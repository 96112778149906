import React, { Fragment } from "react";
import { Container } from "reactstrap";
import p1 from "../../assets/images/auth-one-bg.jpg";
import p2 from "../../assets/images/ndaa-banner-pc.jpg";
import p3 from "../../assets/images/platform.jpg";

function Column() {
  return (
    <Fragment>
      <Container>
        <div className="text-center">
          <h1 className="fs-1 fw-bold ">Make Sensing Matter</h1>
          <h6 className="fs-6 text-muted">Leveraging the incredible insights, we help people optimize their business and accelerate IoT strategies to energetically drive the world.</h6>
        </div>
        <div className="body__cl mt-5">
          <div className="gallery">
            <div className="card card__ac">
              <img src={p1} alt="" height={300} />
              <span style={{ backgroundColor: "#25292e", color: "white" }} className="p-2 fs-3">
                Smart Security
                {/* <i className='ri-arrow-right-line'></i> */}
              </span>
            </div>
            <div className="card card__ac">
              <img src={p2} alt="" height={300} />
              <span style={{ backgroundColor: "#25292e", color: "white" }} className="p-2 fs-3">
                Smart Security
                {/* <i className='ri-arrow-right-line'></i> */}
              </span>
            </div>
            <div className="card card__ac">
              <img src={p3} alt="" height={300} />
              <span style={{ backgroundColor: "#25292e", color: "white" }} className="p-2 fs-3">
                Smart Security
                {/* <i className='ri-arrow-right-line'></i> */}
              </span>
            </div>
            {/* <img src={p1} alt='' />
            <img src={p3} alt='' /> */}
          </div>
        </div>
      </Container>
    </Fragment>
  );
}

export default Column;
