export const BRANCH_LOADING = "BRANCH_LOADING";
export const BRANCH_RESET = "BRANCH_RESET";
export const GET_BRANCH = "GET_BRANCH";
export const GET_BRANCH_SUCCESS = "GET_BRANCH_SUCCESS";
export const GET_BRANCH_FAIL = "GET_BRANCH_FAIL";
export const DELETE_BRANCH = "DELETE_BRANCH"
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS"
export const DELETE_BRANCH_FAIL = "DELETE_BRANCH_FAIL"
export const UPDATE_BRANCH = "UPDATE_BRANCH"
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS"
export const UPDATE_BRANCH_FAIL = "UPDATE_BRANCH_FAIL"
export const ADD_NEW_BRANCH = "ADD_NEW_BRANCH"
export const ADD_BRANCH_SUCCESS = "ADD_BRANCH_SUCCESS"
export const ADD_BRANCH_FAIL = "ADD_BRANCH_FAIL"