import {
  GET_SERVICEPRICEDETAIL,
  GET_SERVICEPRICEDETAIL_SUCCESS,
  GET_SERVICEPRICEDETAIL_FAIL,
  SERVICEPRICEDETAIL_LOADING,
  SERVICEPRICEDETAIL_RESET,
} from "./actionType";

export const resetServicePriceDetailState = (actionType) => ({
  type: SERVICEPRICEDETAIL_RESET,
  payload: { actionType },
});

export const getServicePriceDetailSuccess = (actionType, data) => ({
  type: GET_SERVICEPRICEDETAIL_SUCCESS,
  payload: { actionType, data },
});

export const getServicePriceDetailFail = (actionType, error) => ({
  type: GET_SERVICEPRICEDETAIL_FAIL,
  payload: { actionType, error },
});

export const getServicePriceDetail = data => ({
  type: GET_SERVICEPRICEDETAIL,
  payload: data,
});

export const getServicePriceDetailLoading = () => ({
  type: SERVICEPRICEDETAIL_LOADING
});