import {
  GET_SERVICE_ORDERHISTORY,
  GET_SERVICE_ORDERHISTORY_SUCCESS,
  GET_SERVICE_ORDERHISTORY_FAIL,
  DELETE_SERVICE_ORDERHISTORY,
  DELETE_SERVICE_ORDERHISTORY_SUCCESS,
  DELETE_SERVICE_ORDERHISTORY_FAIL,
  UPDATE_SERVICE_ORDERHISTORY,
  UPDATE_SERVICE_ORDERHISTORY_SUCCESS,
  UPDATE_SERVICE_ORDERHISTORY_FAIL,
  ADD_NEW_SERVICE_ORDERHISTORY,
  ADD_SERVICE_ORDERHISTORY_SUCCESS,
  ADD_SERVICE_ORDERHISTORY_FAIL,
  SERVICE_ORDERHISTORY_LOADING,
  SERVICE_ORDERHISTORY_RESET,
} from "./actionType";

export const resetServiceOrderHistoryState = (actionType) => ({
  type: SERVICE_ORDERHISTORY_RESET,
  payload: { actionType },
});

export const getServiceOrderHistorySuccess = (actionType, data) => ({
  type: GET_SERVICE_ORDERHISTORY_SUCCESS,
  payload: { actionType, data },
});

export const getServiceOrderHistoryFail = (actionType, error) => ({
  type: GET_SERVICE_ORDERHISTORY_FAIL,
  payload: { actionType, error },
});

export const getServiceOrderHistory = data => ({
  type: GET_SERVICE_ORDERHISTORY,
  payload: data,
});

export const getServiceOrderHistoryLoading = () => ({
  type: SERVICE_ORDERHISTORY_LOADING
});

export const deleteServiceOrderHistory = data => ({
  type: DELETE_SERVICE_ORDERHISTORY,
  payload: data,
});

export const deleteServiceOrderHistorySuccess = data => ({
  type: DELETE_SERVICE_ORDERHISTORY_SUCCESS,
  payload: data,
});

export const deleteServiceOrderHistoryFail = error => ({
  type: DELETE_SERVICE_ORDERHISTORY_FAIL,
  payload: error,
});

export const updateServiceOrderHistory = data => ({
  type: UPDATE_SERVICE_ORDERHISTORY,
  payload: data,
});

export const updateServiceOrderHistoryFail = error => ({
  type: UPDATE_SERVICE_ORDERHISTORY_FAIL,
  payload: error,
});

export const updateServiceOrderHistorySuccess = data => ({
  type: UPDATE_SERVICE_ORDERHISTORY_SUCCESS,
  payload: data,
});

export const addNewServiceOrderHistory = data => ({
  type: ADD_NEW_SERVICE_ORDERHISTORY,
  payload: data,
});

export const addServiceOrderHistorySuccess = data => ({
  type: ADD_SERVICE_ORDERHISTORY_SUCCESS,
  payload: data,
});

export const addServiceOrderHistoryFail = error => ({
  type: ADD_SERVICE_ORDERHISTORY_FAIL,
  payload: error,
});