import {
  SERVICE_ORDERDETAIL_LOADING,
  GET_SERVICE_ORDERDETAIL,
  GET_SERVICE_ORDERDETAIL_SUCCESS,
  GET_SERVICE_ORDERDETAIL_FAIL,
  ADD_SERVICE_ORDERDETAIL_SUCCESS,
  ADD_SERVICE_ORDERDETAIL_FAIL,
  DELETE_SERVICE_ORDERDETAIL_SUCCESS,
  DELETE_SERVICE_ORDERDETAIL_FAIL,
  UPDATE_SERVICE_ORDERDETAIL_SUCCESS,
  UPDATE_SERVICE_ORDERDETAIL_FAIL,
  SERVICE_ORDERDETAIL_RESET
} from "./actionType";

const INIT_STATE = {
  serviceOrderDetails: [],
  error: {},
};

const ServiceOrderDetail = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_ORDERDETAIL_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDERDETAIL:
          return {
            ...state,
            serviceOrderDetails: action.payload.data,
            isServiceOrderDetailCreated: false,
            isServiceOrderDetailSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SERVICE_ORDERDETAIL_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDERDETAIL_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isServiceOrderDetailCreated: false,
            isServiceOrderDetailSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SERVICE_ORDERDETAIL: {
      return {
        ...state,
        isServiceOrderDetailCreated: false,
        loading: true,
      };
    }

    case SERVICE_ORDERDETAIL_LOADING: {
      return {
        ...state,
        isServiceOrderDetailCreated: false,
        loading: true,
      };
    }

    case ADD_SERVICE_ORDERDETAIL_SUCCESS:
      return {
        ...state,
        isServiceOrderDetailCreated: true,
        loading: false,
        serviceOrderDetails: [...state.serviceOrderDetails, action.payload.data],
      };

    case ADD_SERVICE_ORDERDETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_SERVICE_ORDERDETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderDetailCreated: true,
        serviceOrderDetails: state.serviceOrderDetails.filter(
          serviceOrderDetail => serviceOrderDetail.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_SERVICE_ORDERDETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_SERVICE_ORDERDETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderDetailCreated: true,
        serviceOrderDetails: state.serviceOrderDetails.map(serviceOrderDetail =>
          serviceOrderDetail.id.toString() === action.payload.data.id.toString() ? { ...serviceOrderDetail, ...action.payload.data } : serviceOrderDetail
        ),
      };

    case UPDATE_SERVICE_ORDERDETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SERVICE_ORDERDETAIL_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ServiceOrderDetail;