import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Register from "./auth/register/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Role from "./role/reducer";
import RoleList from "./roleList/reducer";

//Users
import Users from "./users/reducer";
import Clients from "./clients/reducer";
import Services from "./service/reducer";
import Billing from "./billing/reducer";
import BillingComponent from "./billingComponent/reducer";
import BillingCategory from "./billingCategory/reducer";
import Apps from "./app/reducer";
import StorageFile from "./storageFiles/reducer";
import Units from "./units/reducer";

import ServiceOrder from "./serviceOrder/reducer";
import ServiceOrderDetail from "./serviceOrderDetail/reducer";
import ServiceOrderTotal from "./serviceOrderTotal/reducer";
import ServiceOrderApproval from "./serviceOrderApproval/reducer";
import ServiceOrderHistory from "./serviceOrderHistory/reducer";
import ServiceOrderPayment from "./serviceOrderPayment/reducer";
import ServiceOrderPrice from "./serviceOrderPrice/reducer";

import Company from "./company/reducer";
import Branch from "./branch/reducer";

import PaymentMethod from "./paymentMethod/reducer";
import PaymentTerm from "./paymentTerm/reducer";
import TransactionTax from "./transactionTax/reducer";
import Waranty from "./waranty/reducer";
import Promosion from "./promosion/reducer";
import ContactPerson from "./contactPerson/reducer";
import Document from "./document/reducer";
import DocumentDetail from "./documentDetail/reducer";

import Ticket from "./ticket/reducer";
import TicketCategory from "./ticketCategory/reducer";

import ServicePrice from "./servicePrice/reducer";
import ServicePriceDetail from "./servicePriceDetail/reducer";
import ServicePriceCategory from "./servicePriceCategory/reducer";
import ServiceList from "./serviceList/reducer";

import Task from "./task/reducer";

const rootReducer = combineReducers({
  Layout,
  Login,
  Register,
  Account,
  ForgetPassword,
  Profile,
  Users,
  Clients,
  Units,
  Services,
  Billing,
  BillingComponent,
  BillingCategory,
  Apps,
  StorageFile,
  Role,
  RoleList,
  ServiceOrder,
  ServiceOrderDetail,
  ServiceOrderTotal,
  ServiceOrderApproval,
  ServiceOrderHistory,
  ServiceOrderPrice,
  Company,
  Branch,
  PaymentMethod,
  PaymentTerm,
  TransactionTax,
  Waranty,
  Promosion,
  ContactPerson,
  Document,
  DocumentDetail,
  Ticket,
  TicketCategory,
  ServicePrice,
  ServicePriceDetail,
  ServicePriceCategory,
  ServiceList,

  Task,
});

export default rootReducer;
