import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_STORAGE_FILE,
  DELETE_STORAGE_FILE,
  UPDATE_STORAGE_FILE,
  ADD_NEW_STORAGE_FILE,
  GET_STORAGE_FILE_ONE,
} from "./actionType";

import {
  getStorageFileSuccess,
  getStorageFileFail,
  deleteStorageFileSuccess,
  deleteStorageFileFail,
  updateStorageFileSuccess,
  updateStorageFileFail,
  addStorageFileSuccess,
  addStorageFileFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveStorageFileApi,
  getStorageFileApi,
  updateStorageFileApi,
  deleteStorageFileApi,
  addNewStorageFileApi,
  getOneStorageFileApi,
} from "../../helpers/backend_helper";

function* getStorageFile({ payload: storageFile }) {
  try {
    const response = yield call(getStorageFileApi, storageFile);
    yield put(getStorageFileSuccess(GET_STORAGE_FILE, response.data));
  } catch (error) {
    yield put(getStorageFileFail(GET_STORAGE_FILE, error));
  }
}

function* getStorageFileOne({ payload: id }) {
  try {
    const response = yield call(getOneStorageFileApi, id);
    yield put(getStorageFileSuccess(GET_STORAGE_FILE_ONE, response.data));
  } catch (error) {
    yield put(getStorageFileFail(GET_STORAGE_FILE_ONE, error));
  }
}

function* onUpdateStorageFile({ payload: storageFile }) {
  try {
    if (storageFile.setApprove) {
      const response = yield call(approveStorageFileApi, storageFile);
      yield put(updateStorageFileSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateStorageFileApi, storageFile);
      yield put(updateStorageFileSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateStorageFileFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteStorageFile({ payload: storageFile }) {
  try {
    const response = yield call(deleteStorageFileApi, storageFile);
    yield put(deleteStorageFileSuccess({ storageFile, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteStorageFileFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewStorageFile({ payload: storageFile }) {
  try {
    const response = yield call(addNewStorageFileApi, storageFile);
    yield put(addStorageFileSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addStorageFileFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetStorageFile() {
  yield takeEvery(GET_STORAGE_FILE, getStorageFile);
}

export function* watchGetStorageFileOne() {
  yield takeEvery(GET_STORAGE_FILE_ONE, getStorageFileOne);
}

export function* watchUpdateStorageFile() {
  yield takeEvery(UPDATE_STORAGE_FILE, onUpdateStorageFile);
}

export function* watchDeleteStorageFile() {
  yield takeEvery(DELETE_STORAGE_FILE, onDeleteStorageFile);
}

export function* watchAddNewStorageFile() {
  yield takeEvery(ADD_NEW_STORAGE_FILE, onAddNewStorageFile);
}

function* storageFileSaga() {
  yield all([
    fork(watchGetStorageFile),
    fork(watchGetStorageFileOne),
    fork(watchDeleteStorageFile),
    fork(watchUpdateStorageFile),
    fork(watchAddNewStorageFile),
  ]);
}

export default storageFileSaga;
