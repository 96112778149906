import {
  SERVICE_ORDERHISTORY_LOADING,
  GET_SERVICE_ORDERHISTORY,
  GET_SERVICE_ORDERHISTORY_SUCCESS,
  GET_SERVICE_ORDERHISTORY_FAIL,
  ADD_SERVICE_ORDERHISTORY_SUCCESS,
  ADD_SERVICE_ORDERHISTORY_FAIL,
  DELETE_SERVICE_ORDERHISTORY_SUCCESS,
  DELETE_SERVICE_ORDERHISTORY_FAIL,
  UPDATE_SERVICE_ORDERHISTORY_SUCCESS,
  UPDATE_SERVICE_ORDERHISTORY_FAIL,
  SERVICE_ORDERHISTORY_RESET
} from "./actionType";

const INIT_STATE = {
  serviceOrderHistories: [],
  error: {},
};

const ServiceOrderHistory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_ORDERHISTORY_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDERHISTORY:
          return {
            ...state,
            serviceOrderHistories: action.payload.data,
            isServiceOrderHistoryCreated: false,
            isServiceOrderHistorySuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SERVICE_ORDERHISTORY_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDERHISTORY_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isServiceOrderHistoryCreated: false,
            isServiceOrderHistorySuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SERVICE_ORDERHISTORY: {
      return {
        ...state,
        isServiceOrderHistoryCreated: false,
        loading: true,
      };
    }

    case SERVICE_ORDERHISTORY_LOADING: {
      return {
        ...state,
        isServiceOrderHistoryCreated: false,
        loading: true,
      };
    }

    case ADD_SERVICE_ORDERHISTORY_SUCCESS:
      return {
        ...state,
        isServiceOrderHistoryCreated: true,
        loading: false,
        serviceOrderHistories: [...state.serviceOrderHistories, action.payload.data],
      };

    case ADD_SERVICE_ORDERHISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_SERVICE_ORDERHISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderHistoryCreated: true,
        serviceOrderHistories: state.serviceOrderHistories.filter(
          serviceOrderHistory => serviceOrderHistory.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_SERVICE_ORDERHISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_SERVICE_ORDERHISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderHistoryCreated: true,
        serviceOrderHistories: state.serviceOrderHistories.map(serviceOrderHistory =>
          serviceOrderHistory.id.toString() === action.payload.data.id.toString() ? { ...serviceOrderHistory, ...action.payload.data } : serviceOrderHistory
        ),
      };

    case UPDATE_SERVICE_ORDERHISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SERVICE_ORDERHISTORY_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ServiceOrderHistory;