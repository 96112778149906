import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_SERVICE_ORDER_PRICE,
  DELETE_SERVICE_ORDER_PRICE,
  UPDATE_SERVICE_ORDER_PRICE,
  ADD_NEW_SERVICE_ORDER_PRICE,
  GET_SERVICE_ORDER_PRICE_LIST,
} from "./actionType";

import {
  getServiceOrderPriceSuccess,
  getServiceOrderPriceFail,
  deleteServiceOrderPriceSuccess,
  deleteServiceOrderPriceFail,
  updateServiceOrderPriceSuccess,
  updateServiceOrderPriceFail,
  addServiceOrderPriceSuccess,
  addServiceOrderPriceFail,
  getServiceOrderPriceListSuccess,
  getServiceOrderPriceListFail,
} from "./action";

//Include Both Helper File with needed carriers
import {
  approveServiceOrderPriceApi,
  getServiceOrderPriceApi,
  updateServiceOrderPriceApi,
  deleteServiceOrderPriceApi,
  addNewServiceOrderPriceApi
} from "../../helpers/backend_helper";

function* getServiceOrderPrice({ payload: serviceOrderPrice }) {
  try {
    const response = yield call(getServiceOrderPriceApi, serviceOrderPrice);
    yield put(getServiceOrderPriceSuccess(GET_SERVICE_ORDER_PRICE, response.data));
  } catch (error) {
    yield put(getServiceOrderPriceFail(GET_SERVICE_ORDER_PRICE, error));
  }
}

function* getServiceOrderPriceList({ payload: serviceOrderPrice }) {
  try {
    const response = yield call(getServiceOrderPriceApi, serviceOrderPrice);
    yield put(getServiceOrderPriceListSuccess(GET_SERVICE_ORDER_PRICE_LIST, response.data));
  } catch (error) {
    yield put(getServiceOrderPriceListFail(GET_SERVICE_ORDER_PRICE_LIST, error));
  }
}

function* onUpdateServiceOrderPrice({ payload: serviceOrderPrice }) {
  try {
    if (serviceOrderPrice.setApprove) {
      const response = yield call(approveServiceOrderPriceApi, serviceOrderPrice);
      yield put(updateServiceOrderPriceSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateServiceOrderPriceApi, serviceOrderPrice);
      yield put(updateServiceOrderPriceSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateServiceOrderPriceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteServiceOrderPrice({ payload: serviceOrderPrice }) {
  try {
    const response = yield call(deleteServiceOrderPriceApi, serviceOrderPrice);
    yield put(deleteServiceOrderPriceSuccess({ serviceOrderPrice, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteServiceOrderPriceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewServiceOrderPrice({ payload: serviceOrderPrice }) {
  try {
    const response = yield call(addNewServiceOrderPriceApi, serviceOrderPrice);
    yield put(addServiceOrderPriceSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addServiceOrderPriceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetServiceOrderPrice() {
  yield takeEvery(GET_SERVICE_ORDER_PRICE, getServiceOrderPrice);
}

export function* watchGetServiceOrderPriceList() {
  yield takeEvery(GET_SERVICE_ORDER_PRICE_LIST, getServiceOrderPriceList);
}

export function* watchUpdateServiceOrderPrice() {
  yield takeEvery(UPDATE_SERVICE_ORDER_PRICE, onUpdateServiceOrderPrice);
}

export function* watchDeleteServiceOrderPrice() {
  yield takeEvery(DELETE_SERVICE_ORDER_PRICE, onDeleteServiceOrderPrice);
}

export function* watchAddNewServiceOrderPrice() {
  yield takeEvery(ADD_NEW_SERVICE_ORDER_PRICE, onAddNewServiceOrderPrice);
}

function* serviceOrderPriceSaga() {
  yield all([
    fork(watchGetServiceOrderPrice),
    fork(watchGetServiceOrderPriceList),
    fork(watchDeleteServiceOrderPrice),
    fork(watchUpdateServiceOrderPrice),
    fork(watchAddNewServiceOrderPrice)
  ]);
}

export default serviceOrderPriceSaga;
