import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GET_SERVICEPRICEDETAIL, DELETE_SERVICEPRICEDETAIL, UPDATE_SERVICEPRICEDETAIL, ADD_NEW_SERVICEPRICEDETAIL } from "./actionType";

import {
  getServicePriceDetailSuccess,
  getServicePriceDetailFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getServicePriceDetailApi,
} from "../../helpers/backend_helper";

function* getServicePriceDetail({ payload: servicePriceDetail }) {
  try {
    const response = yield call(getServicePriceDetailApi, servicePriceDetail);
    yield put(getServicePriceDetailSuccess(GET_SERVICEPRICEDETAIL, response.data));
  } catch (error) {
    yield put(getServicePriceDetailFail(GET_SERVICEPRICEDETAIL, error));
  }
}

export function* watchGetServicePriceDetail() {
  yield takeEvery(GET_SERVICEPRICEDETAIL, getServicePriceDetail);
}

function* servicePriceDetailSaga() {
  yield all([
    fork(watchGetServicePriceDetail),
  ]);
}

export default servicePriceDetailSaga;
