import {
  SERVICEPRICEDETAIL_LOADING,
  GET_SERVICEPRICEDETAIL,
  GET_SERVICEPRICEDETAIL_SUCCESS,
  GET_SERVICEPRICEDETAIL_FAIL,
  SERVICEPRICEDETAIL_RESET
} from "./actionType";

const INIT_STATE = {
  servicePriceDetails: [],
  error: {},
  loading: false
};

const ServicePriceDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICEPRICEDETAIL_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICEPRICEDETAIL:
          return {
            ...state,
            servicePriceDetails: action.payload.data,
            isServicePriceDetailCreated: false,
            isServicePriceDetailSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SERVICEPRICEDETAIL_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICEPRICEDETAIL_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isServicePriceDetailsCreated: false,
            isServicePriceDetailsSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SERVICEPRICEDETAIL: {
      return {
        ...state,
        isServicePriceDetailsCreated: false,
        loading: true,
      };
    }

    case SERVICEPRICEDETAIL_LOADING: {
      return {
        ...state,
        isServicePriceDetailsCreated: false,
        loading: true,
      };
    }

    case SERVICEPRICEDETAIL_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ServicePriceDetails;