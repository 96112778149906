import {
  APP_LOADING,
  GET_APP,
  GET_APP_SUCCESS,
  GET_APP_FAIL,
  ADD_APP_SUCCESS,
  ADD_APP_FAIL,
  DELETE_APP_SUCCESS,
  DELETE_APP_FAIL,
  UPDATE_APP_SUCCESS,
  UPDATE_APP_FAIL,
  APP_RESET
} from "./actionType";

const INIT_STATE = {
  apps: [],
  error: {},
  loading: false
};

const Apps = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APP_SUCCESS:
      switch (action.payload.actionType) {
        case GET_APP:
          return {
            ...state,
            apps: action.payload.data,
            isAppCreated: false,
            isAppSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_APP_FAIL:
      switch (action.payload.actionType) {
        case GET_APP_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isAppsCreated: false,
            isAppsSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_APP: {
      return {
        ...state,
        isAppsCreated: false,
        loading: true,
      };
    }

    case APP_LOADING: {
      return {
        ...state,
        isAppsCreated: false,
        loading: true,
      };
    }

    case ADD_APP_SUCCESS:
      return {
        ...state,
        isAppsCreated: true,
        loading: false,
        apps: [...state.apps, action.payload.data],
      };

    case ADD_APP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_APP_SUCCESS:
      return {
        ...state,
        loading: false,
        isAppsCreated: true,
        apps: state.apps.filter(
          apps => apps.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_APP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_APP_SUCCESS:
      return {
        ...state,
        loading: false,
        isAppCreated: true,
        apps: state.apps.map(app =>
          app.id.toString() === action.payload.data.id.toString() ? { ...app, ...action.payload.data } : app
        ),
      };

    case UPDATE_APP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case APP_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Apps;