import { takeEvery, takeLatest, put, call } from "redux-saga/effects";

// Login Redux States
import { API_ERROR, LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

//Include Both Helper File with needed methods
import { logout, postLogin, postRefreshToken, postSocialLogin } from "../../../helpers/backend_helper";

//const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, user);
    if (response.status === "success") {
      if (response.data.userType !== "admin") {
        yield put(apiError("error_client"));
      } else {
        sessionStorage.setItem("authUser", JSON.stringify(response));
        yield put(loginSuccess(response));
        history("/dashboard");
      }
    } else {
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    yield call(logout, {});
    sessionStorage.removeItem("authUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      yield put(logoutUserSuccess(LOGOUT_USER, {}));
    } else {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    const response = yield call(postSocialLogin, data);
    console.log("Resp sosial login", response);
    // const fireBaseBackend = getFirebaseBackend();
    // const response = yield call(fireBaseBackend.socialLoginUser, data, type);
    // console.log(response, "res firebase");
    // sessionStorage.setItem("authUser", JSON.stringify(response));
    // yield put(loginSuccess(response));
    // history("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
}

export default authSaga;
