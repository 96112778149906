import {
  SERVICE_ORDER_PRICE_LOADING,
  GET_SERVICE_ORDER_PRICE,
  GET_SERVICE_ORDER_PRICE_SUCCESS,
  GET_SERVICE_ORDER_PRICE_FAIL,
  ADD_SERVICE_ORDER_PRICE_SUCCESS,
  ADD_SERVICE_ORDER_PRICE_FAIL,
  DELETE_SERVICE_ORDER_PRICE_SUCCESS,
  DELETE_SERVICE_ORDER_PRICE_FAIL,
  UPDATE_SERVICE_ORDER_PRICE_SUCCESS,
  UPDATE_SERVICE_ORDER_PRICE_FAIL,
  SERVICE_ORDER_PRICE_RESET,
  GET_SERVICE_ORDER_PRICE_LIST,
  GET_SERVICE_ORDER_PRICE_LIST_SUCCESS,
  GET_SERVICE_ORDER_PRICE_LIST_FAIL
} from "./actionType";

const INIT_STATE = {
  serviceOrderPrices: {},
  serviceOrderPricesList: [],
  error: {},
};

const ServiceOrderPrice = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_ORDER_PRICE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDER_PRICE:
          return {
            ...state,
            serviceOrderPrices: action.payload.data,
            isServiceOrderPriceCreated: false,
            isServiceOrderPriceSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SERVICE_ORDER_PRICE_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDER_PRICE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isServiceOrderPriceCreated: false,
            isServiceOrderPriceSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SERVICE_ORDER_PRICE: {
      return {
        ...state,
        isServiceOrderPriceCreated: false,
        loading: true,
      };
    }

    case GET_SERVICE_ORDER_PRICE_LIST_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDER_PRICE_LIST:
          return {
            ...state,
            serviceOrderPricesList: [...state.serviceOrderPricesList, action.payload.data],
            isServiceOrderPriceCreated: false,
            isServiceOrderPriceSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SERVICE_ORDER_PRICE_LIST_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDER_PRICE_LIST_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isServiceOrderPriceCreated: false,
            isServiceOrderPriceSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SERVICE_ORDER_PRICE_LIST: {
      return {
        ...state,
        isServiceOrderPriceCreated: false,
        loading: true,
      };
    }

    case SERVICE_ORDER_PRICE_LOADING: {
      return {
        ...state,
        isServiceOrderPriceCreated: false,
        loading: true,
      };
    }

    case ADD_SERVICE_ORDER_PRICE_SUCCESS:
      return {
        ...state,
        isServiceOrderPriceCreated: true,
        loading: false,
        serviceOrderPrices: [...state.serviceOrderPrices, action.payload.data],
      };

    case ADD_SERVICE_ORDER_PRICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_SERVICE_ORDER_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderPriceCreated: true,
        serviceOrderPrices: state.serviceOrderPrices.filter(
          serviceOrderPrice => serviceOrderPrice.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_SERVICE_ORDER_PRICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_SERVICE_ORDER_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderPriceCreated: true,
        serviceOrderPrices: state.serviceOrderPrices.map(serviceOrderPrice =>
          serviceOrderPrice.id.toString() === action.payload.data.id.toString() ? { ...serviceOrderPrice, ...action.payload.data } : serviceOrderPrice
        ),
      };

    case UPDATE_SERVICE_ORDER_PRICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SERVICE_ORDER_PRICE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ServiceOrderPrice;