import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_WARANTY,
  DELETE_WARANTY,
  UPDATE_WARANTY,
  ADD_NEW_WARANTY,
} from "./actionType";

import {
  getWarantySuccess,
  getWarantyFail,
  deleteWarantySuccess,
  deleteWarantyFail,
  updateWarantySuccess,
  updateWarantyFail,
  addWarantySuccess,
  addWarantyFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveWarantyApi,
  getWarantyApi,
  updateWarantyApi,
  deleteWarantyApi,
  addNewWarantyApi,
} from "../../helpers/backend_helper";

function* getWaranty({ payload: waranty }) {
  try {
    const response = yield call(getWarantyApi, waranty);
    yield put(getWarantySuccess(GET_WARANTY, response.data));
  } catch (error) {
    yield put(getWarantyFail(GET_WARANTY, error));
  }
}

function* onUpdateWaranty({ payload: waranty }) {
  try {
    if (waranty.setApprove) {
      const response = yield call(approveWarantyApi, waranty);
      yield put(updateWarantySuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateWarantyApi, waranty);
      yield put(updateWarantySuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateWarantyFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteWaranty({ payload: waranty }) {
  try {
    const response = yield call(deleteWarantyApi, waranty);
    yield put(deleteWarantySuccess({ waranty, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteWarantyFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewWaranty({ payload: waranty }) {
  try {
    const response = yield call(addNewWarantyApi, waranty);
    yield put(addWarantySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addWarantyFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetWaranty() {
  yield takeEvery(GET_WARANTY, getWaranty);
}

export function* watchUpdateWaranty() {
  yield takeEvery(UPDATE_WARANTY, onUpdateWaranty);
}

export function* watchDeleteWaranty() {
  yield takeEvery(DELETE_WARANTY, onDeleteWaranty);
}

export function* watchAddNewWaranty() {
  yield takeEvery(ADD_NEW_WARANTY, onAddNewWaranty);
}

function* warantySaga() {
  yield all([
    fork(watchGetWaranty),
    fork(watchDeleteWaranty),
    fork(watchUpdateWaranty),
    fork(watchAddNewWaranty),
  ]);
}

export default warantySaga;
