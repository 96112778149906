import {
  STORAGE_FILE_LOADING,
  GET_STORAGE_FILE,
  GET_STORAGE_FILE_ONE,
  GET_STORAGE_FILE_SUCCESS,
  GET_STORAGE_FILE_FAIL,
  ADD_STORAGE_FILE_SUCCESS,
  ADD_STORAGE_FILE_FAIL,
  DELETE_STORAGE_FILE_SUCCESS,
  DELETE_STORAGE_FILE_FAIL,
  UPDATE_STORAGE_FILE_SUCCESS,
  UPDATE_STORAGE_FILE_FAIL,
  STORAGE_FILE_RESET,
} from "./actionType";

const INIT_STATE = {
  storageFiles: [],
  storageFile: {},
  error: {},
  loading: false
};

const StorageFile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORAGE_FILE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_STORAGE_FILE:
          return {
            ...state,
            storageFiles: action.payload.data,
            isStorageFileCreated: false,
            isStorageFileSuccess: true,
            loading: false,
          };

        case GET_STORAGE_FILE_ONE:
          return {
            ...state,
            storageFile: action.payload.data,
            isStorageFileSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_STORAGE_FILE_FAIL:
      switch (action.payload.actionType) {
        case GET_STORAGE_FILE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isStorageFileCreated: false,
            isStorageFileSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_STORAGE_FILE: {
      return {
        ...state,
        isStorageFileCreated: false,
        loading: true,
      };
    }

    case GET_STORAGE_FILE_ONE: {
      return {
        ...state,
        loading: true,
      };
    }

    case STORAGE_FILE_LOADING: {
      return {
        ...state,
        isStorageFileCreated: false,
        loading: true,
      };
    }

    case ADD_STORAGE_FILE_SUCCESS:
      return {
        ...state,
        isStorageFileCreated: true,
        loading: false,
        storageFiles: [...state.storageFiles, ...action.payload.data],
      };

    case ADD_STORAGE_FILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_STORAGE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        storageFiles: state.storageFiles.filter(
          (storageFile) => storageFile.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_STORAGE_FILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_STORAGE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        storageFiles: state.storageFiles.map((storageFile) =>
          storageFile.id.toString() === action.payload.data.id.toString()
            ? { ...storageFile, ...action.payload.data }
            : storageFile
        ),
      };

    case UPDATE_STORAGE_FILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case STORAGE_FILE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default StorageFile;
