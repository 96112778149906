import {
  TASK_LOADING,
  GET_TASK,
  GET_TASK_SUCCESS,
  GET_TASK_FAIL,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAIL,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAIL,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAIL,
  TASK_RESET,
  GET_PUBLIC_TASK,
  GET_TASK_PUBLIC_SUCCESS,
  GET_TASK_PUBLIC_FAIL,
} from "./actionType";

const INIT_STATE = {
  tasks: [],
  error: {},
};

const Task = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TASK_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TASK:
          return {
            ...state,
            tasks: action.payload.data,
            isTaskCreated: false,
            isTaskSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_TASK_FAIL:
      switch (action.payload.actionType) {
        case GET_TASK_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isTaskCreated: false,
            isTaskSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_TASK: {
      return {
        ...state,
        isTaskCreated: false,
        loading: true,
      };
    }

    case GET_TASK_PUBLIC_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PUBLIC_TASK:
          return {
            ...state,
            tasks: action.payload.data,
            isTaskCreated: false,
            isTaskSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_TASK_PUBLIC_FAIL:
      switch (action.payload.actionType) {
        case GET_TASK_PUBLIC_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isTaskCreated: false,
            isTaskSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_PUBLIC_TASK: {
      return {
        ...state,
        isTaskCreated: false,
        loading: true,
      };
    }

    case TASK_LOADING: {
      return {
        ...state,
        isTaskCreated: false,
        loading: true,
      };
    }

    case ADD_TASK_SUCCESS:
      return {
        ...state,
        isTaskCreated: true,
        loading: false,
        tasks: [...state.tasks, action.payload.data],
      };

    case ADD_TASK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        isTaskCreated: true,
        tasks: state.tasks.filter(
          (task) => task.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_TASK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        isTaskCreated: true,
        tasks: state.tasks.map((task) =>
          task.id.toString() === action.payload.data.id.toString()
            ? { ...task, ...action.payload.data }
            : task
        ),
      };

    case UPDATE_TASK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TASK_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Task;
