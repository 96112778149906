export const BILLING_LOADING = "BILLING_LOADING";
export const BILLING_RESET = "BILLING_RESET";
export const GET_BILLING = "GET_BILLING";
export const GET_BILLING_SUCCESS = "GET_BILLING_SUCCESS";
export const GET_BILLING_FAIL = "GET_BILLING_FAIL";
export const DELETE_BILLING = "DELETE_BILLING";
export const DELETE_BILLING_SUCCESS = "DELETE_BILLING_SUCCESS";
export const DELETE_BILLING_FAIL = "DELETE_BILLING_FAIL";
export const UPDATE_BILLING = "UPDATE_BILLING";
export const UPDATE_BILLING_SUCCESS = "UPDATE_BILLING_SUCCESS";
export const UPDATE_BILLING_FAIL = "UPDATE_BILLING_FAIL";
export const ADD_NEW_BILLING = "ADD_NEW_BILLING";
export const ADD_BILLING_SUCCESS = "ADD_BILLING_SUCCESS";
export const ADD_BILLING_FAIL = "ADD_BILLING_FAIL";
