import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import p1 from "../../assets/images/small/img-2.jpg";
import p2 from "../../assets/images/small/img-1.jpg";

function Academy() {
  return (
    <Fragment>
      <Container style={{ marginTop: "100px" }}>
        <div className='text-center'>
          <h1 className='fs-1 fw-bold '>Academy</h1>
          <h6 className='fs-6 text-muted'>
            Gain deeper understanding about Aptek's latest products,
            technologies and solutions at Aptek Academy.
          </h6>
        </div>
        <Row className='mt-5'>
          <Col lg={6}>
            <div className='card-wrapper'>
              <div className='card-top'>
                <img className='image__ac' src={p1} alt='' />
              </div>
              <div className='card-bottom'>
                <span className='top-text' style={{ cursor: "pointer" }}>
                  Title
                </span>
                <span className='bottom-text' style={{ cursor: "pointer" }}>
                  Description
                </span>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className='card-wrapper'>
              <div className='card-top'>
                <img className='image__ac' src={p2} alt='' />
              </div>
              <div className='card-bottom'>
                <span className='top-text' style={{ cursor: "pointer" }}>
                  Title
                </span>
                <span className='bottom-text' style={{ cursor: "pointer" }}>
                  Description
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default Academy;
