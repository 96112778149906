import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_ROLELIST,
  DELETE_ROLELIST,
  UPDATE_ROLELIST,
  ADD_NEW_ROLELIST,
} from "./actionType";

import {
  getRoleListSuccess,
  getRoleListFail,
  deleteRoleListSuccess,
  deleteRoleListFail,
  updateRoleListSuccess,
  updateRoleListFail,
  addRoleListSuccess,
  addRoleListFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getRoleListApi,
  updateRoleListApi,
  deleteRoleListApi,
  addNewRoleListApi
} from "../../helpers/backend_helper";

function* getRoleList({ payload: roleList , path}) {
  try {
    const response = yield call(getRoleListApi, roleList, path);
    yield put(getRoleListSuccess(GET_ROLELIST, response.data));
  } catch (error) {
    yield put(getRoleListFail(GET_ROLELIST, error));
  }
}

function* onUpdateRoleList({ payload: roleList }) {
  try {
    const response = yield call(updateRoleListApi, roleList);
    yield put(updateRoleListSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateRoleListFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteRoleList({ payload: roleList }) {
  try {
    const response = yield call(deleteRoleListApi, roleList);
    yield put(deleteRoleListSuccess({ roleList, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteRoleListFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewRoleList({ payload: roleList }) {
  try {
    const response = yield call(addNewRoleListApi, roleList);
    yield put(addRoleListSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addRoleListFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetRoleList() {
  yield takeEvery(GET_ROLELIST, getRoleList);
}

export function* watchUpdateRoleList() {
  yield takeEvery(UPDATE_ROLELIST, onUpdateRoleList);
}

export function* watchDeleteRoleList() {
  yield takeEvery(DELETE_ROLELIST, onDeleteRoleList);
}

export function* watchAddNewRoleList() {
  yield takeEvery(ADD_NEW_ROLELIST, onAddNewRoleList);
}

function* roleListSaga() {
  yield all([
    fork(watchGetRoleList),
    fork(watchDeleteRoleList),
    fork(watchUpdateRoleList),
    fork(watchAddNewRoleList)
  ]);
}

export default roleListSaga;
