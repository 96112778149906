export const STORAGE_FILE_LOADING = "STORAGE_FILE_LOADING";
export const STORAGE_FILE_RESET = "STORAGE_FILE_RESET";
export const GET_STORAGE_FILE = "GET_STORAGE_FILE";
export const GET_STORAGE_FILE_ONE = "GET_STORAGE_FILE_ONE";
export const GET_STORAGE_FILE_SUCCESS = "GET_STORAGE_FILE_SUCCESS";
export const GET_STORAGE_FILE_FAIL = "GET_STORAGE_FILE_FAIL";
export const DELETE_STORAGE_FILE = "DELETE_STORAGE_FILE"
export const DELETE_STORAGE_FILE_SUCCESS = "DELETE_STORAGE_FILE_SUCCESS"
export const DELETE_STORAGE_FILE_FAIL = "DELETE_STORAGE_FILE_FAIL"
export const UPDATE_STORAGE_FILE = "UPDATE_STORAGE_FILE"
export const UPDATE_STORAGE_FILE_SUCCESS = "UPDATE_STORAGE_FILE_SUCCESS"
export const UPDATE_STORAGE_FILE_FAIL = "UPDATE_STORAGE_FILE_FAIL"
export const ADD_NEW_STORAGE_FILE = "ADD_NEW_STORAGE_FILE"
export const ADD_STORAGE_FILE_SUCCESS = "ADD_STORAGE_FILE_SUCCESS"
export const ADD_STORAGE_FILE_FAIL = "ADD_STORAGE_FILE_FAIL"