import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_SERVICE_ORDERTOTAL,
  DELETE_SERVICE_ORDERTOTAL,
  UPDATE_SERVICE_ORDERTOTAL,
  ADD_NEW_SERVICE_ORDERTOTAL,
} from "./actionType";

import {
  getServiceOrderTotalSuccess,
  getServiceOrderTotalFail,
  deleteServiceOrderTotalSuccess,
  deleteServiceOrderTotalFail,
  updateServiceOrderTotalSuccess,
  updateServiceOrderTotalFail,
  addServiceOrderTotalSuccess,
  addServiceOrderTotalFail,
} from "./action";

//Include Both Helper File with needed carriers
import {
  approveServiceOrderTotalApi,
  getServiceOrderTotalApi,
  updateServiceOrderTotalApi,
  deleteServiceOrderTotalApi,
  addNewServiceOrderTotalApi
} from "../../helpers/backend_helper";

function* getServiceOrderTotal({ payload: serviceOrderTotal }) {
  try {
    const response = yield call(getServiceOrderTotalApi, serviceOrderTotal);
    yield put(getServiceOrderTotalSuccess(GET_SERVICE_ORDERTOTAL, response.data));
  } catch (error) {
    yield put(getServiceOrderTotalFail(GET_SERVICE_ORDERTOTAL, error));
  }
}

function* onUpdateServiceOrderTotal({ payload: serviceOrderTotal }) {
  try {
    if (serviceOrderTotal.setApprove) {
      const response = yield call(approveServiceOrderTotalApi, serviceOrderTotal);
      yield put(updateServiceOrderTotalSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateServiceOrderTotalApi, serviceOrderTotal);
      yield put(updateServiceOrderTotalSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateServiceOrderTotalFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteServiceOrderTotal({ payload: serviceOrderTotal }) {
  try {
    const response = yield call(deleteServiceOrderTotalApi, serviceOrderTotal);
    yield put(deleteServiceOrderTotalSuccess({ serviceOrderTotal, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteServiceOrderTotalFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewServiceOrderTotal({ payload: serviceOrderTotal }) {
  try {
    const response = yield call(addNewServiceOrderTotalApi, serviceOrderTotal);
    yield put(addServiceOrderTotalSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addServiceOrderTotalFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetServiceOrderTotal() {
  yield takeEvery(GET_SERVICE_ORDERTOTAL, getServiceOrderTotal);
}

export function* watchUpdateServiceOrderTotal() {
  yield takeEvery(UPDATE_SERVICE_ORDERTOTAL, onUpdateServiceOrderTotal);
}

export function* watchDeleteServiceOrderTotal() {
  yield takeEvery(DELETE_SERVICE_ORDERTOTAL, onDeleteServiceOrderTotal);
}

export function* watchAddNewServiceOrderTotal() {
  yield takeEvery(ADD_NEW_SERVICE_ORDERTOTAL, onAddNewServiceOrderTotal);
}

function* serviceOrderTotalSaga() {
  yield all([
    fork(watchGetServiceOrderTotal),
    fork(watchDeleteServiceOrderTotal),
    fork(watchUpdateServiceOrderTotal),
    fork(watchAddNewServiceOrderTotal)
  ]);
}

export default serviceOrderTotalSaga;
