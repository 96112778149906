import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_DOCUMENT,
  DELETE_DOCUMENT,
  UPDATE_DOCUMENT,
  ADD_NEW_DOCUMENT,
} from "./actionType";

import {
  getDocumentSuccess,
  getDocumentFail,
  deleteDocumentSuccess,
  deleteDocumentFail,
  updateDocumentSuccess,
  updateDocumentFail,
  addDocumentSuccess,
  addDocumentFail,
} from "./action";

//Include Both Helper File with needed carriers
import {
  approveDocumentApi,
  getDocumentApi,
  updateDocumentApi,
  deleteDocumentApi,
  addNewDocumentApi
} from "../../helpers/backend_helper";

function* getDocument({ payload: document }) {
  try {
    const response = yield call(getDocumentApi, document);
    yield put(getDocumentSuccess(GET_DOCUMENT, response.data));
  } catch (error) {
    yield put(getDocumentFail(GET_DOCUMENT, error));
  }
}

function* onUpdateDocument({ payload: document }) {
  try {
    if (document.setApprove) {
      const response = yield call(approveDocumentApi, document);
      yield put(updateDocumentSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateDocumentApi, document);
      yield put(updateDocumentSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateDocumentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteDocument({ payload: document }) {
  try {
    const response = yield call(deleteDocumentApi, document);
    yield put(deleteDocumentSuccess({ document, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteDocumentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewDocument({ payload: document }) {
  try {
    const response = yield call(addNewDocumentApi, document);
    yield put(addDocumentSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addDocumentFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetDocument() {
  yield takeEvery(GET_DOCUMENT, getDocument);
}

export function* watchUpdateDocument() {
  yield takeEvery(UPDATE_DOCUMENT, onUpdateDocument);
}

export function* watchDeleteDocument() {
  yield takeEvery(DELETE_DOCUMENT, onDeleteDocument);
}

export function* watchAddNewDocument() {
  yield takeEvery(ADD_NEW_DOCUMENT, onAddNewDocument);
}

function* documentSaga() {
  yield all([
    fork(watchGetDocument),
    fork(watchDeleteDocument),
    fork(watchUpdateDocument),
    fork(watchAddNewDocument)
  ]);
}

export default documentSaga;
