import {
  GET_SERVICE_ORDER,
  GET_SERVICE_ORDER_SUCCESS,
  GET_SERVICE_ORDER_FAIL,
  DELETE_SERVICE_ORDER,
  DELETE_SERVICE_ORDER_SUCCESS,
  DELETE_SERVICE_ORDER_FAIL,
  UPDATE_SERVICE_ORDER,
  UPDATE_SERVICE_ORDER_SUCCESS,
  UPDATE_SERVICE_ORDER_FAIL,
  ADD_NEW_SERVICE_ORDER,
  ADD_SERVICE_ORDER_SUCCESS,
  ADD_SERVICE_ORDER_FAIL,
  SERVICE_ORDER_LOADING,
  SERVICE_ORDER_RESET,
} from "./actionType";

export const resetServiceOrderState = (actionType) => ({
  type: SERVICE_ORDER_RESET,
  payload: { actionType },
});

export const getServiceOrderSuccess = (actionType, data) => ({
  type: GET_SERVICE_ORDER_SUCCESS,
  payload: { actionType, data },
});

export const getServiceOrderFail = (actionType, error) => ({
  type: GET_SERVICE_ORDER_FAIL,
  payload: { actionType, error },
});

export const getServiceOrder = data => ({
  type: GET_SERVICE_ORDER,
  payload: data,
});

export const getServiceOrderLoading = () => ({
  type: SERVICE_ORDER_LOADING
});

export const deleteServiceOrder = data => ({
  type: DELETE_SERVICE_ORDER,
  payload: data,
});

export const deleteServiceOrderSuccess = data => ({
  type: DELETE_SERVICE_ORDER_SUCCESS,
  payload: data,
});

export const deleteServiceOrderFail = error => ({
  type: DELETE_SERVICE_ORDER_FAIL,
  payload: error,
});

export const updateServiceOrder = data => ({
  type: UPDATE_SERVICE_ORDER,
  payload: data,
});

export const updateServiceOrderFail = error => ({
  type: UPDATE_SERVICE_ORDER_FAIL,
  payload: error,
});

export const updateServiceOrderSuccess = data => ({
  type: UPDATE_SERVICE_ORDER_SUCCESS,
  payload: data,
});

export const addNewServiceOrder = data => ({
  type: ADD_NEW_SERVICE_ORDER,
  payload: data,
});

export const addServiceOrderSuccess = data => ({
  type: ADD_SERVICE_ORDER_SUCCESS,
  payload: data,
});

export const addServiceOrderFail = error => ({
  type: ADD_SERVICE_ORDER_FAIL,
  payload: error,
});