import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import { EffectFade, Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Images
import imgpattern from "../../assets/images/landing/img-pattern.png";

import defaultDemo from "../../assets/images/ndaa-banner-pc.jpg";
import saasDemo from "../../assets/images/platform.jpg";

const Home = () => {
  return (
    <React.Fragment>
      <section id='hero' className='bg-light'>
        <Swiper
          spaceBetween={30}
          effect={"fade"}
          loop={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          navigation={true}
          modules={[EffectFade, Autoplay, Navigation, Pagination]}
          className='mySwiper'>
          <SwiperSlide className='d-flex'>
            <img
              src={defaultDemo}
              className='position-block w-100'
              alt='...'
              height={650}
            />
            <div
              className='z-1 position-absolute'
              style={{ marginTop: "200px", marginLeft: "100px" }}>
              <Row>
                <Col lg={6}>
                  <h1 className='text-white'>Aptek Development Platform</h1>
                  <h4 className='text-white'>
                    From device deployment and management to custom integration
                    and application development, Milesight helps you get your
                    project off the ground.
                  </h4>
                </Col>
              </Row>
            </div>
          </SwiperSlide>
          <SwiperSlide className='d-flex'>
            <img
              src={saasDemo}
              className='position-block w-100'
              alt='...'
              height={650}
            />
            <div
              className='z-1 position-absolute'
              style={{ marginTop: "200px", marginLeft: "100px" }}>
              <Row>
                <Col lg={6}>
                  <h1 className='text-primary'>Aptek Development Platform</h1>
                  <h4 className='text-primary'>
                    Saving Energy in Existing Buildings This Is What IoT
                    Solutions Are Capable Of
                  </h4>
                </Col>
              </Row>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className='position-absolute start-0 end-0 bottom-0 hero-shape-svg'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox='0 0 1440 120'>
            <g mask='url("#SvgjsMask1003")' fill='none'>
              <path d='M 0,118 C 288,98.6 1152,40.4 1440,21L1440 140L0 140z'></path>
            </g>
          </svg>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
