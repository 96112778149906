export const TASK_LOADING = "TASK_LOADING";
export const TASK_RESET = "TASK_RESET";
export const GET_TASK = "GET_TASK";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_FAIL = "GET_TASK_FAIL";
export const DELETE_TASK = "DELETE_TASK";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAIL = "DELETE_TASK_FAIL";
export const UPDATE_TASK = "UPDATE_TASK";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAIL = "UPDATE_TASK_FAIL";
export const ADD_NEW_TASK = "ADD_NEW_TASK";
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
export const ADD_TASK_FAIL = "ADD_TASK_FAIL";
export const GET_PUBLIC_TASK = "GET_PUBLIC_TASK";
export const GET_TASK_PUBLIC_SUCCESS = "GET_TASK_PUBLIC_SUCCESS";
export const GET_TASK_PUBLIC_FAIL = "GET_TASK_PUBLIC_FAIL";
