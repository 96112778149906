import {
  GET_BILLING_COMPONENT,
  GET_BILLING_COMPONENT_SUCCESS,
  GET_BILLING_COMPONENT_FAIL,
  DELETE_BILLING_COMPONENT,
  DELETE_BILLING_COMPONENT_SUCCESS,
  DELETE_BILLING_COMPONENT_FAIL,
  UPDATE_BILLING_COMPONENT,
  UPDATE_BILLING_COMPONENT_SUCCESS,
  UPDATE_BILLING_COMPONENT_FAIL,
  ADD_NEW_BILLING_COMPONENT,
  ADD_BILLING_COMPONENT_SUCCESS,
  ADD_BILLING_COMPONENT_FAIL,
  BILLING_COMPONENT_LOADING,
  BILLING_COMPONENT_RESET,
} from "./actionType";

export const resetBillingComponentState = (actionType) => ({
  type: BILLING_COMPONENT_RESET,
  payload: { actionType },
});

export const getBillingComponentSuccess = (actionType, data) => ({
  type: GET_BILLING_COMPONENT_SUCCESS,
  payload: { actionType, data },
});

export const getBillingComponentFail = (actionType, error) => ({
  type: GET_BILLING_COMPONENT_FAIL,
  payload: { actionType, error },
});

export const getBillingComponent = (data) => ({
  type: GET_BILLING_COMPONENT,
  payload: data,
});

export const getBillingComponentLoading = () => ({
  type: BILLING_COMPONENT_LOADING,
});

export const deleteBillingComponent = (data) => ({
  type: DELETE_BILLING_COMPONENT,
  payload: data,
});

export const deleteBillingComponentSuccess = (data) => ({
  type: DELETE_BILLING_COMPONENT_SUCCESS,
  payload: data,
});

export const deleteBillingComponentFail = (error) => ({
  type: DELETE_BILLING_COMPONENT_FAIL,
  payload: error,
});

export const updateBillingComponent = (data) => ({
  type: UPDATE_BILLING_COMPONENT,
  payload: data,
});

export const updateBillingComponentFail = (error) => ({
  type: UPDATE_BILLING_COMPONENT_FAIL,
  payload: error,
});

export const updateBillingComponentSuccess = (data) => ({
  type: UPDATE_BILLING_COMPONENT_SUCCESS,
  payload: data,
});

export const addNewBillingComponent = (data) => ({
  type: ADD_NEW_BILLING_COMPONENT,
  payload: data,
});

export const addBillingComponentSuccess = (data) => ({
  type: ADD_BILLING_COMPONENT_SUCCESS,
  payload: data,
});

export const addBillingComponentFail = (error) => ({
  type: ADD_BILLING_COMPONENT_FAIL,
  payload: error,
});
