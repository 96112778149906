import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_TICKET,
  DELETE_TICKET,
  UPDATE_TICKET,
  ADD_NEW_TICKET,
} from "./actionType";

import {
  getTicketSuccess,
  getTicketFail,
  deleteTicketSuccess,
  deleteTicketFail,
  updateTicketSuccess,
  updateTicketFail,
  addTicketSuccess,
  addTicketFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveTicketApi,
  getTicketApi,
  updateTicketApi,
  deleteTicketApi,
  addNewTicketApi
} from "../../helpers/backend_helper";

function* getTicket({ payload: ticket }) {
  try {
    const response = yield call(getTicketApi, ticket);
    yield put(getTicketSuccess(GET_TICKET, response.data));
  } catch (error) {
    yield put(getTicketFail(GET_TICKET, error));
  }
}

function* onUpdateTicket({ payload: ticket }) {
  try {
    if (ticket.setApprove) {
      const response = yield call(approveTicketApi, ticket);
      yield put(updateTicketSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateTicketApi, ticket);
      yield put(updateTicketSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateTicketFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteTicket({ payload: ticket }) {
  try {
    const response = yield call(deleteTicketApi, ticket);
    yield put(deleteTicketSuccess({ ticket, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteTicketFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewTicket({ payload: ticket }) {
  try {
    const response = yield call(addNewTicketApi, ticket);
    yield put(addTicketSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addTicketFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetTicket() {
  yield takeEvery(GET_TICKET, getTicket);
}

export function* watchUpdateTicket() {
  yield takeEvery(UPDATE_TICKET, onUpdateTicket);
}

export function* watchDeleteTicket() {
  yield takeEvery(DELETE_TICKET, onDeleteTicket);
}

export function* watchAddNewTicket() {
  yield takeEvery(ADD_NEW_TICKET, onAddNewTicket);
}

function* ticketSaga() {
  yield all([
    fork(watchGetTicket),
    fork(watchDeleteTicket),
    fork(watchUpdateTicket),
    fork(watchAddNewTicket)
  ]);
}

export default ticketSaga;
