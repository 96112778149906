import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isCurrentState, setIsCurrentState] = useState("Dashboard");
  const [isDashboard, setIsDashboard] = useState(false);
  const [isServiceOrder, setIsServiceOrder] = useState(false);
  const [isSupportTicket, setIsSupportTicket] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [isService, setIsService] = useState(false);
  const [isBilings, setIsBilings] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isMasterData, setIsMasterData] = useState(false);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (isCurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (isCurrentState !== "Users") {
      setIsUsers(false);
    }
    if (isCurrentState !== "Service_Order") {
      setIsServiceOrder(false);
    }
    if (isCurrentState !== "Support_Ticket") {
      setIsSupportTicket(false);
    }
    if (isCurrentState !== "Client") {
      setIsClient(false);
    }
    if (isCurrentState !== "Service") {
      setIsService(false);
    }
    if (isCurrentState !== "Bilings") {
      setIsBilings(false);
    }
    if (isCurrentState !== "Report") {
      setIsReport(false);
    }
    if (isCurrentState !== "Apps") {
      setIsApps(false);
    }
    if (isCurrentState !== "MasterData") {
      setIsMasterData(false);
    }
  }, [
    history,
    isCurrentState,
    isMasterData,
    isReport,
    isUsers,
    isApps,
    isBilings,
    isClient,
    isServiceOrder,
    isSupportTicket,
    isService,
  ]);
  const menuItems = [
    {
      id: "Dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("Dashboard");
      },
      stateVariables: isDashboard,
    },
    {
      id: "Client",
      label: "Client",
      icon: "ri-building-4-line",
      link: "/#",
      stateVariables: isClient,
      click: function (e) {
        e.preventDefault();
        setIsClient(!isClient);
        setIsCurrentState("Client");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "menuClient",
          label: "Client",
          link: "/client",
          parentId: "Client",
        },
        {
          id: "menuCompany",
          label: "Company",
          link: "/company",
          parentId: "Client",
        },
        {
          id: "menuBranch",
          label: "Branch",
          link: "/branch",
          parentId: "Client",
        },
        {
          id: "menuUnit",
          label: "Unit",
          link: "/unit",
          parentId: "Client",
        },
      ],
    },
    {
      id: "MasterData",
      label: "Master Data",
      icon: "ri-archive-line",
      link: "/#",
      stateVariables: isMasterData,
      click: function (e) {
        e.preventDefault();
        setIsMasterData(!isMasterData);
        setIsCurrentState("MasterData");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "menuPaymentMethod",
          label: "Payment Method",
          link: "/payment-method",
          parentId: "MasterData",
        },
        {
          id: "menuPaymentTerm",
          label: "Payment Term",
          link: "/payment-term",
          parentId: "MasterData",
        },
        {
          id: "menuTransactionTax",
          label: "Transaction Tax",
          link: "/transaction-tax",
          parentId: "MasterData",
        },
        {
          id: "menuCategoryBilling",
          label: "Category Billing",
          link: "/category-billing",
          parentId: "MasterData",
        },
        {
          id: "menuWaranty",
          label: "Waranty",
          link: "/waranty",
          parentId: "MasterData",
        },
        {
          id: "menuPromosion",
          label: "promosion",
          link: "/promotion",
          parentId: "MasterData",
        },
        {
          id: "menuContactPerson",
          label: "Contact Person",
          link: "/contact-person",
          parentId: "MasterData",
        },
        {
          id: "menuWiki",
          label: "Wiki",
          link: "/document",
          parentId: "MasterData",
        },
      ],
    },
    {
      id: "Apps",
      label: "App",
      icon: "ri-apps-line",
      link: "/apps",
      //   visible: userType === "executive" ? "" : roles && roles.split(",").indexOf("viewUser") !== -1 ? "" : " d-none",
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("Apps");
      },
      stateVariables: isApps,
    },
    {
      id: "Service",
      label: "Service",
      icon: "ri-customer-service-2-line",
      link: "/service",
      //   visible: userType === "executive" ? "" : roles && roles.split(",").indexOf("viewUser") !== -1 ? "" : " d-none",
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("Service");
      },
      stateVariables: isService,
    },
    {
      id: "Billings",
      label: "Billing",
      icon: "ri-bill-line",
      link: "/billings",
      //   visible: userType === "executive" ? "" : roles && roles.split(",").indexOf("viewUser") !== -1 ? "" : " d-none",
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("Billings");
      },
      stateVariables: isBilings,
    },
    {
      id: "Service_Order",
      label: "Service Order",
      icon: "ri-broadcast-line",
      link: "/service-orders",
      //   visible: userType === "executive" ? "" : roles && roles.split(",").indexOf("viewUser") !== -1 ? "" : " d-none",
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("Service_Order");
      },
      stateVariables: isServiceOrder,
    },
    {
      id: "Support_Ticket",
      label: "Support Ticket",
      icon: "ri-todo-line",
      link: "/ticket",
      //   visible: userType === "executive" ? "" : roles && roles.split(",").indexOf("viewUser") !== -1 ? "" : " d-none",
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("Support_Ticket");
      },
      stateVariables: isSupportTicket,
    },
    {
      id: "Report",
      label: "Report",
      icon: "ri-file-list-2-line",
      link: "/report",
      //   visible: userType === "executive" ? "" : roles && roles.split(",").indexOf("viewUser") !== -1 ? "" : " d-none",
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("Report");
      },
      stateVariables: isReport,
    },
    {
      id: "Users",
      label: "Users",
      icon: "ri-admin-line",
      link: "/users",
      //   visible: userType === "executive" ? "" : roles && roles.split(",").indexOf("viewUser") !== -1 ? "" : " d-none",
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("Users");
      },
      stateVariables: isUsers,
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
