import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_SERVICE_ORDERDETAIL,
  DELETE_SERVICE_ORDERDETAIL,
  UPDATE_SERVICE_ORDERDETAIL,
  ADD_NEW_SERVICE_ORDERDETAIL,
} from "./actionType";

import {
  getServiceOrderDetailSuccess,
  getServiceOrderDetailFail,
  deleteServiceOrderDetailSuccess,
  deleteServiceOrderDetailFail,
  updateServiceOrderDetailSuccess,
  updateServiceOrderDetailFail,
  addServiceOrderDetailSuccess,
  addServiceOrderDetailFail,
} from "./action";

//Include Both Helper File with needed carriers
import {
  approveServiceOrderDetailApi,
  getServiceOrderDetailApi,
  updateServiceOrderDetailApi,
  deleteServiceOrderDetailApi,
  addNewServiceOrderDetailApi
} from "../../helpers/backend_helper";

function* getServiceOrderDetail({ payload: serviceOrderDetail }) {
  try {
    const response = yield call(getServiceOrderDetailApi, serviceOrderDetail);
    yield put(getServiceOrderDetailSuccess(GET_SERVICE_ORDERDETAIL, response.data));
  } catch (error) {
    yield put(getServiceOrderDetailFail(GET_SERVICE_ORDERDETAIL, error));
  }
}

function* onUpdateServiceOrderDetail({ payload: serviceOrderDetail }) {
  try {
    if (serviceOrderDetail.setApprove) {
      const response = yield call(approveServiceOrderDetailApi, serviceOrderDetail);
      yield put(updateServiceOrderDetailSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateServiceOrderDetailApi, serviceOrderDetail);
      yield put(updateServiceOrderDetailSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateServiceOrderDetailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteServiceOrderDetail({ payload: serviceOrderDetail }) {
  try {
    const response = yield call(deleteServiceOrderDetailApi, serviceOrderDetail);
    yield put(deleteServiceOrderDetailSuccess({ serviceOrderDetail, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteServiceOrderDetailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewServiceOrderDetail({ payload: serviceOrderDetail }) {
  try {
    const response = yield call(addNewServiceOrderDetailApi, serviceOrderDetail);
    yield put(addServiceOrderDetailSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addServiceOrderDetailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetServiceOrderDetail() {
  yield takeEvery(GET_SERVICE_ORDERDETAIL, getServiceOrderDetail);
}

export function* watchUpdateServiceOrderDetail() {
  yield takeEvery(UPDATE_SERVICE_ORDERDETAIL, onUpdateServiceOrderDetail);
}

export function* watchDeleteServiceOrderDetail() {
  yield takeEvery(DELETE_SERVICE_ORDERDETAIL, onDeleteServiceOrderDetail);
}

export function* watchAddNewServiceOrderDetail() {
  yield takeEvery(ADD_NEW_SERVICE_ORDERDETAIL, onAddNewServiceOrderDetail);
}

function* serviceOrderDetailSaga() {
  yield all([
    fork(watchGetServiceOrderDetail),
    fork(watchDeleteServiceOrderDetail),
    fork(watchUpdateServiceOrderDetail),
    fork(watchAddNewServiceOrderDetail)
  ]);
}

export default serviceOrderDetailSaga;
