import {
  GET_APP,
  GET_APP_SUCCESS,
  GET_APP_FAIL,
  DELETE_APP,
  DELETE_APP_SUCCESS,
  DELETE_APP_FAIL,
  UPDATE_APP,
  UPDATE_APP_SUCCESS,
  UPDATE_APP_FAIL,
  ADD_NEW_APP,
  ADD_APP_SUCCESS,
  ADD_APP_FAIL,
  APP_LOADING,
  APP_RESET,
} from "./actionType";

export const resetAppState = (actionType) => ({
  type: APP_RESET,
  payload: { actionType },
});

export const getAppSuccess = (actionType, data) => ({
  type: GET_APP_SUCCESS,
  payload: { actionType, data },
});

export const getAppFail = (actionType, error) => ({
  type: GET_APP_FAIL,
  payload: { actionType, error },
});

export const getApp = data => ({
  type: GET_APP,
  payload: data,
});

export const getAppLoading = () => ({
  type: APP_LOADING
});

export const deleteApp = data => ({
  type: DELETE_APP,
  payload: data,
});

export const deleteAppSuccess = data => ({
  type: DELETE_APP_SUCCESS,
  payload: data,
});

export const deleteAppFail = error => ({
  type: DELETE_APP_FAIL,
  payload: error,
});

export const updateApp = data => ({
  type: UPDATE_APP,
  payload: data,
});

export const updateAppFail = error => ({
  type: UPDATE_APP_FAIL,
  payload: error,
});

export const updateAppSuccess = data => ({
  type: UPDATE_APP_SUCCESS,
  payload: data,
});

export const addNewApp = data => ({
  type: ADD_NEW_APP,
  payload: data,
});

export const addAppSuccess = data => ({
  type: ADD_APP_SUCCESS,
  payload: data,
});

export const addAppFail = error => ({
  type: ADD_APP_FAIL,
  payload: error,
});