import {
  CLIENT_LOADING,
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAIL,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  CLIENT_RESET
} from "./actionType";

const INIT_STATE = {
  clients: [],
  error: {},
  loading: false
};

const Clients = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENT_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CLIENT:
          return {
            ...state,
            clients: action.payload.data,
            isClientCreated: false,
            isClientSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_CLIENT_FAIL:
      switch (action.payload.actionType) {
        case GET_CLIENT_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isClientsCreated: false,
            isClientsSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_CLIENT: {
      return {
        ...state,
        isClientsCreated: false,
        loading: true,
      };
    }

    case CLIENT_LOADING: {
      return {
        ...state,
        isClientsCreated: false,
        loading: true,
      };
    }

    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        isClientsCreated: true,
        loading: false,
        clientss: [...state.clientss, action.payload.data],
      };

    case ADD_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isClientsCreated: true,
        clientss: state.clientss.filter(
          clients => clients.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isClientCreated: true,
        clients: state.clients.map(client =>
          client.id.toString() === action.payload.data.id.toString() ? { ...client, ...action.payload.data } : client
        ),
      };

    case UPDATE_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLIENT_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Clients;