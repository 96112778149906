import {
  SERVICEPRICE_LOADING,
  GET_SERVICEPRICE,
  GET_SERVICEPRICE_SUCCESS,
  GET_SERVICEPRICE_FAIL,
  SERVICEPRICE_RESET
} from "./actionType";

const INIT_STATE = {
  servicePrices: [],
  error: {},
  loading: false
};

const ServicePrices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICEPRICE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICEPRICE:
          return {
            ...state,
            servicePrices: action.payload.data,
            isServicePriceCreated: false,
            isServicePriceSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SERVICEPRICE_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICEPRICE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isServicePricesCreated: false,
            isServicePricesSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SERVICEPRICE: {
      return {
        ...state,
        isServicePricesCreated: false,
        loading: true,
      };
    }

    case SERVICEPRICE_LOADING: {
      return {
        ...state,
        isServicePricesCreated: false,
        loading: true,
      };
    }

    case SERVICEPRICE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ServicePrices;