export const DOCUMENT_LOADING = "DOCUMENT_LOADING";
export const DOCUMENT_RESET = "DOCUMENT_RESET";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAIL = "GET_DOCUMENT_FAIL";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT"
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS"
export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL"
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT"
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS"
export const UPDATE_DOCUMENT_FAIL = "UPDATE_DOCUMENT_FAIL"
export const ADD_NEW_DOCUMENT = "ADD_NEW_DOCUMENT"
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS"
export const ADD_DOCUMENT_FAIL = "ADD_DOCUMENT_FAIL"