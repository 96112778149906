import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_BRANCH,
  DELETE_BRANCH,
  UPDATE_BRANCH,
  ADD_NEW_BRANCH,
} from "./actionType";

import {
  getBranchSuccess,
  getBranchFail,
  deleteBranchSuccess,
  deleteBranchFail,
  updateBranchSuccess,
  updateBranchFail,
  addBranchSuccess,
  addBranchFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getBranchApi,
  updateBranchApi,
  deleteBranchApi,
  addNewBranchApi
} from "../../helpers/backend_helper";

function* getBranch({ payload: branch }) {
  try {
    const response = yield call(getBranchApi, branch);
    yield put(getBranchSuccess(GET_BRANCH, response.data));
  } catch (error) {
    yield put(getBranchFail(GET_BRANCH, error));
  }
}

function* onUpdateBranch({ payload: branch }) {
  try {
    const response = yield call(updateBranchApi, branch);
    yield put(updateBranchSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateBranchFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteBranch({ payload: branch }) {
  try {
    const response = yield call(deleteBranchApi, branch);
    yield put(deleteBranchSuccess({ branch, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteBranchFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewBranch({ payload: branch }) {
  try {
    const response = yield call(addNewBranchApi, branch);
    yield put(addBranchSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addBranchFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetBranch() {
  yield takeEvery(GET_BRANCH, getBranch);
}

export function* watchUpdateBranch() {
  yield takeEvery(UPDATE_BRANCH, onUpdateBranch);
}

export function* watchDeleteBranch() {
  yield takeEvery(DELETE_BRANCH, onDeleteBranch);
}

export function* watchAddNewBranch() {
  yield takeEvery(ADD_NEW_BRANCH, onAddNewBranch);
}

function* branchSaga() {
  yield all([
    fork(watchGetBranch),
    fork(watchDeleteBranch),
    fork(watchUpdateBranch),
    fork(watchAddNewBranch)
  ]);
}

export default branchSaga;
