export const BILLING_COMPONENT_LOADING = "BILLING_COMPONENT_LOADING";
export const BILLING_COMPONENT_RESET = "BILLING_COMPONENT_RESET";
export const GET_BILLING_COMPONENT = "GET_BILLING_COMPONENT";
export const GET_BILLING_COMPONENT_SUCCESS = "GET_BILLING_COMPONENT_SUCCESS";
export const GET_BILLING_COMPONENT_FAIL = "GET_BILLING_COMPONENT_FAIL";
export const DELETE_BILLING_COMPONENT = "DELETE_BILLING_COMPONENT";
export const DELETE_BILLING_COMPONENT_SUCCESS = "DELETE_BILLING_COMPONENT_SUCCESS";
export const DELETE_BILLING_COMPONENT_FAIL = "DELETE_BILLING_COMPONENT_FAIL";
export const UPDATE_BILLING_COMPONENT = "UPDATE_BILLING_COMPONENT";
export const UPDATE_BILLING_COMPONENT_SUCCESS = "UPDATE_BILLING_COMPONENT_SUCCESS";
export const UPDATE_BILLING_COMPONENT_FAIL = "UPDATE_BILLING_COMPONENT_FAIL";
export const ADD_NEW_BILLING_COMPONENT = "ADD_NEW_BILLING_COMPONENT";
export const ADD_BILLING_COMPONENT_SUCCESS = "ADD_BILLING_COMPONENT_SUCCESS";
export const ADD_BILLING_COMPONENT_FAIL = "ADD_BILLING_COMPONENT_FAIL";
