import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GET_UNIT, DELETE_UNIT, UPDATE_UNIT, ADD_NEW_UNIT } from "./actionType";

import {
  getUnitSuccess,
  getUnitFail,
  deleteUnitSuccess,
  deleteUnitFail,
  updateUnitSuccess,
  updateUnitFail,
  addUnitSuccess,
  addUnitFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveUnitApi,
  getUnitApi,
  updateUnitApi,
  deleteUnitApi,
  addNewUnitApi,
} from "../../helpers/backend_helper";

function* getUnit({ payload: unit }) {
  try {
    const response = yield call(getUnitApi, unit);
    yield put(getUnitSuccess(GET_UNIT, response.data));
  } catch (error) {
    yield put(getUnitFail(GET_UNIT, error));
  }
}

function* onUpdateUnit({ payload: unit }) {
  try {
    if (unit.setApprove) {
      const response = yield call(approveUnitApi, unit);
      yield put(updateUnitSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateUnitApi, unit);
      yield put(updateUnitSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateUnitFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteUnit({ payload: unit }) {
  try {
    const response = yield call(deleteUnitApi, unit);
    yield put(deleteUnitSuccess({ unit, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteUnitFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewUnit({ payload: unit }) {
  try {
    const response = yield call(addNewUnitApi, unit);
    yield put(addUnitSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addUnitFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetUnit() {
  yield takeEvery(GET_UNIT, getUnit);
}

export function* watchUpdateUnit() {
  yield takeEvery(UPDATE_UNIT, onUpdateUnit);
}

export function* watchDeleteUnit() {
  yield takeEvery(DELETE_UNIT, onDeleteUnit);
}

export function* watchAddNewUnit() {
  yield takeEvery(ADD_NEW_UNIT, onAddNewUnit);
}

function* unitSaga() {
  yield all([
    fork(watchGetUnit),
    fork(watchDeleteUnit),
    fork(watchUpdateUnit),
    fork(watchAddNewUnit),
  ]);
}

export default unitSaga;
