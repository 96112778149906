import {
  PROMOSION_LOADING,
  GET_PROMOSION,
  GET_PROMOSION_SUCCESS,
  GET_PROMOSION_FAIL,
  ADD_PROMOSION_SUCCESS,
  ADD_PROMOSION_FAIL,
  DELETE_PROMOSION_SUCCESS,
  DELETE_PROMOSION_FAIL,
  UPDATE_PROMOSION_SUCCESS,
  UPDATE_PROMOSION_FAIL,
  PROMOSION_RESET
} from "./actionType";

const INIT_STATE = {
  promosions: [],
  error: {},
  loading: false
};

const Promosions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROMOSION_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PROMOSION:
          return {
            ...state,
            promosions: action.payload.data,
            isPromosionCreated: false,
            isPromosionSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_PROMOSION_FAIL:
      switch (action.payload.actionType) {
        case GET_PROMOSION_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isPromosionsCreated: false,
            isPromosionsSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_PROMOSION: {
      return {
        ...state,
        isPromosionsCreated: false,
        loading: true,
      };
    }

    case PROMOSION_LOADING: {
      return {
        ...state,
        isPromosionsCreated: false,
        loading: true,
      };
    }

    case ADD_PROMOSION_SUCCESS:
      return {
        ...state,
        isPromosionsCreated: true,
        loading: false,
        promosions: [...state.promosions, action.payload.data],
      };

    case ADD_PROMOSION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_PROMOSION_SUCCESS:
      return {
        ...state,
        loading: false,
        isPromosionsCreated: true,
        promosions: state.promosions.filter(
          promosions => promosions.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PROMOSION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PROMOSION_SUCCESS:
      return {
        ...state,
        loading: false,
        isPromosionCreated: true,
        promosions: state.promosions.map(promosion =>
          promosion.id.toString() === action.payload.data.id.toString() ? { ...promosion, ...action.payload.data } : promosion
        ),
      };

    case UPDATE_PROMOSION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PROMOSION_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Promosions;