import {
  BILLING_LOADING,
  GET_BILLING,
  GET_BILLING_SUCCESS,
  GET_BILLING_FAIL,
  ADD_BILLING_SUCCESS,
  ADD_BILLING_FAIL,
  DELETE_BILLING_SUCCESS,
  DELETE_BILLING_FAIL,
  UPDATE_BILLING_SUCCESS,
  UPDATE_BILLING_FAIL,
  BILLING_RESET,
} from "./actionType";

const INIT_STATE = {
  billings: [],
  error: {},
};

const Billing = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BILLING_SUCCESS:
      switch (action.payload.actionType) {
        case GET_BILLING:
          return {
            ...state,
            billings: action.payload.data,
            isBillingCreated: false,
            isBillingSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_BILLING_FAIL:
      switch (action.payload.actionType) {
        case GET_BILLING_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isBillingCreated: false,
            isBillingSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_BILLING: {
      return {
        ...state,
        isBillingCreated: false,
        loading: true,
      };
    }

    case BILLING_LOADING: {
      return {
        ...state,
        isBillingCreated: false,
        loading: true,
      };
    }

    case ADD_BILLING_SUCCESS:
      return {
        ...state,
        isBillingCreated: true,
        loading: false,
        billings: [...state.billings, action.payload.data],
      };

    case ADD_BILLING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        billings: state.billings.filter(
          (billing) => billing.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_BILLING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        billings: state.billings.map((billing) =>
          billing.id.toString() === action.payload.data.id.toString()
            ? { ...billing, ...action.payload.data }
            : billing
        ),
      };

    case UPDATE_BILLING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BILLING_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Billing;
