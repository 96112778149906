import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Table, Button, Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, Modal, ModalHeader, Form, ModalBody, Label, Input, FormFeedback, Spinner } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import ApproveModal from "../../Components/Common/ApproveModal";
import { isEmpty, clone } from "lodash";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { Grid, _ } from "gridjs-react";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//   Import actions
//   import { getAccAccount, resetAccAccountState } from "../../../store/accAccount/action";
import { getClient, resetClientState } from "../../store/clients/action";
import { getWaranty, resetWarantyState } from "../../store/waranty/action";
import { getPromosion, resetPromosionState } from "../../store/promosion/action";
import { getService, resetServiceState } from "../../store/service/action";
import { getBillingCategory, resetBillingCategoryState } from "../../store/billingCategory/action";
import { getBilling as onGetBillings, addNewBilling as onAddNewBilling, updateBilling as onUpdateBilling, deleteBilling as onDeleteBilling, getBillingLoading, resetBillingState, addNewBilling } from "../../store/billing/action";
import { getBillingComponent as onGetBillingComponents, addNewBillingComponent as onAddNewBillingComponent, updateBillingComponent as onUpdateBillingComponent, deleteBillingComponent as onDeleteBillingComponent, getBillingComponentLoading, resetBillingComponentState } from "../../store/billingComponent/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import CopyModal from "../../Components/Common/CopyModal";

const Billing = (props) => {
  const [modal, setModal] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  const { promosions, loadingPromosion, waranties, loadingWaranty, billingCategoryLoading, billingCategories, billings, billingComponents, isBillingCreated, isBillingSuccess, loading, error, billingComponentLoading, clients, services } = useSelector((state) => ({
    billings: state.Billing.billings,
    isBillingCreated: state.Billing.isBillingCreated,
    isBillingSuccess: state.Billing.isBillingSuccess,
    loading: state.Billing.loading,
    error: state.Billing.error,
    billingCategories: state.BillingCategory.billingCategories,
    billingCategoryLoading: state.BillingCategory.billingCategoryLoading,
    billingComponents: state.BillingComponent.billingComponents,
    clients: state.Clients.clients,
    services: state.Services.services,
    waranties: state.Waranty.waranties,
    loadingWaranty: state.Waranty.loading,
    promosions: state.Promosion.promosions,
    loadingPromosion: state.Promosion.loading,
  }));

  const [isSearch, setIsSearch] = useState(false);
  const [billingList, setBillingList] = useState([]);
  const [warantyList, setWarantyList] = useState([]);
  const [billing, setBilling] = useState([]);
  const [componentDetailAddedId, setComponentDetailAddedId] = useState(0);
  const [info, setInfo] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);
  const [billingComponent, setBillingDetail] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [approveModal, setApproveModal] = useState(false);
  const [componentType, setComponentType] = useState("");
  const [amountType, setAmountType] = useState("");
  const [componentTypeCategory, setComponentTypeCategory] = useState("");
  const [periodType, setPeriodType] = useState("");
  const [selectedAllowance, setSelectedAllowance] = useState([]);
  const [roles, setRoles] = useState(null);
  const [userType, setUserType] = useState("user");
  const [accountingList, setAccountingList] = useState([]);
  const [billingComponentList, setBillingComponentList] = useState([]);
  const [billingCategoryList, setBillingCategoryList] = useState([]);
  const [billingId, setBillingId] = useState();
  const [clientId, setClientId] = useState();
  const [billingName, setBillingName] = useState();
  const [clientList, setClientList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [promotionList, setPromotionList] = useState([]);

  const periodTypeData = [
    {
      options: [
        { label: "Daily", value: "Daily" },
        { label: "Monthly", value: "Monthly" },
        { label: "One Time", value: "One Time" },
      ],
    },
  ];

  const componentTypeCategoryAllowanceData = [
    {
      options: [
        { label: "Custom", value: "Custom" },
        { label: "BPJS Kesehatan", value: "BPJS Kesehatan" },
        { label: "JHT", value: "JHT" },
        { label: "JP", value: "JP" },
        { label: "JKK", value: "JKK" },
        { label: "JKM", value: "JKM" },
      ],
    },
  ];

  const componentTypeCategoryDeductionData = [
    {
      options: [
        { label: "Custom", value: "Custom" },
        { label: "Savings", value: "Savings" },
        // { label: "Loan", value: "Loan" },
        // { label: "Over Payment", value: "Over Payment" },
        { label: "Unpaid Leave", value: "Unpaid Leave" },
        { label: "Absent Compensation", value: "Absent Compensation" },
        { label: "BPJS Kesehatan", value: "BPJS Kesehatan" },
        { label: "JHT", value: "JHT" },
        { label: "JP", value: "JP" },
        { label: "JKK", value: "JKK" },
        { label: "JKM", value: "JKM" },
      ],
    },
  ];

  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: "1" },
        { label: "Activated", value: "2" },
      ],
    },
  ];

  const componentTypeData = [
    {
      options: [
        { label: "Device", value: "Device" },
        { label: "Request", value: "Request" },
        { label: "Storage", value: "Storage" },
        { label: "User", value: "User" },
        { label: "Subscription", value: "Subscription" },
      ],
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditDetail, setIsEditDetail] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [deleteModalDetail, setDeleteModalDetail] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [unitName, setUnitName] = useState({});

  const defaultdate = () => {
    return moment(new Date()).tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };

  const onClickDelete = (billing) => {
    setBilling(billing);
    setDeleteModal(true);
  };

  const onClickDeleteDetail = (billingComponent) => {
    setBillingDetail(billingComponent);
    setDeleteModalDetail(true);
  };

  const handleDeleteBilling = () => {
    if (billing) {
      dispatch(onDeleteBilling(billing));
      setDeleteModal(false);
    }
  };

  const handleCopyBilling = () => {
    if (billing) {
      dispatch(onAddNewBilling({ targetId: billing.id }));
      setCopyModal(false);
    }
  };

  const checkHandler = () => {
    setIsChecked(!isChecked);
  };

  const handleDeleteBillingDetail = () => {
    if (billingComponent) {
      setComponentDetailAddedId(billingComponent.componentId);
      dispatch(onDeleteBillingComponent(billingComponent));
      setDeleteModalDetail(false);
    }
  };

  const onClickApprove = (employee) => {
    setBilling(employee);
    setApproveModal(true);
  };

  const onClickCopy = (data) => {
    setBilling(data);
    setCopyModal(true);
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredBillings = billings;
      if (type !== "all") {
        filteredBillings = billings.filter((billing) => billing.billing_status.name === type);
      }
      setBillingList(filteredBillings);
    }
  };

  useEffect(() => {
    if (!isSearch) {
      dispatch(onGetBillings());
      setIsSearch(true);
      const obj = JSON.parse(localStorage.getItem("authUser"));
      // if (
      //   obj.data &&
      //   obj.data.employee_position &&
      //   obj.data.employee_position.position_role
      // ) {
      //   setUnitName({
      //     label: obj.data.employee_unit.unit_name,
      //     value: obj.data.employee_unit.id,
      //   });
      //   setRoles(obj.data.employee_position.position_role.role_method);
      // } else setRoles(null);

      // if (obj.data && obj.data.userType) setUserType(obj.data.userType);
    }
  }, [dispatch, isSearch]);

  useEffect(() => {
    if (!isEmpty(billings)) {
      setIsEdit(false);
      setBillingList(billings);
      let current = 0;
      if (componentDetailAddedId !== 0) {
        billings.forEach((obj, i) => {
          if (obj.id === componentDetailAddedId) current = i;
        });
      } else {
        setInfo(billings[current]);
        setBillingId(billings[current].id);
        setClientId(billings[current].clientId);
        setBillingName(billings[current].name);
        dispatch(onGetBillingComponents({ billingId: billings[current].id }));
      }
    }
  }, [dispatch, billings, componentDetailAddedId]);

  useEffect(() => {
    // dispatch(resetClientState());
    // dispatch(getClient());
    dispatch(resetWarantyState());
    dispatch(getWaranty());
    dispatch(resetPromosionState());
    dispatch(getPromosion());
    dispatch(resetBillingCategoryState());
    dispatch(getBillingCategory());
    dispatch(resetServiceState());
    dispatch(getService());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(billingCategories)) {
      var data = [];
      var opt = [];
      billingCategories.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setBillingCategoryList(data);
    }
  }, [billingCategories]);

  useEffect(() => {
    if (!isEmpty(services)) {
      var data = [];
      var opt = [];
      services.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setServiceList(data);
    }
  }, [services]);

  useEffect(() => {
    if (isBillingCreated) {
      dispatch(resetBillingState());
      dispatch(onGetBillings());
    }
  }, [dispatch, isBillingCreated]);

  const [filterDate, setFilterDate] = useState(defaultdate());

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setBilling(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleBillingClick = useCallback(
    (arg) => {
      const data = arg;
      setBilling({
        id: data.id,
        name: data.name,
        amount: data.amount,
        status:
          data.billing_status === null
            ? ""
            : {
                label: data.billing_status.name,
                value: data.billing_status.id,
              },
        service: {
          label: data.billing_service ? data.billing_service.name : "",
          value: data.billing_service ? data.billing_service.id : "",
        },
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const toggleDetail = useCallback(() => {
    if (modalDetail) {
      setModalDetail(false);
      setSelectedAllowance([]);
      setBillingDetail(null);
      setBilling(null);
      setAmountType("");
      setComponentType("");
      setPeriodType("");
      setIsChecked(false);
    } else {
      setModalDetail(true);
    }
  }, [modalDetail]);

  const handleBillingDetailClick = useCallback(
    (arg, arginfo, isEdit) => {
      const data = arg;

      setBillingDetail({
        id: data.id,
        name: data.name,
        type: { label: data.type, value: data.type },
        amount: data.amount,
        amountMax: data.amount_max ?? "",
        extraTitle: data.extra_title ?? "",
        extraAmount: data.extra_amount ?? "",
        extraPrice: data.extra_price ?? "",
        price: data.price ?? "",
        sortOrder: data.sort_order ?? 0,
        status:
          data.billing_component_status === null
            ? ""
            : {
                label: data.billing_component_status.name,
                value: data.billing_component_status.id,
              },
        billingCategory:
          data.billing_component_category === null
            ? ""
            : {
                label: data.billing_component_category.name,
                value: data.billing_component_category.id,
              },
        waranty:
          data.billing_component_warranty === null
            ? ""
            : {
                label: data.billing_component_warranty.name + " - " + data.billing_component_warranty.amount,
                value: data.billing_component_warranty.id,
              },
        promotion:
          data.billing_component_promotion === null
            ? ""
            : {
                label: data.billing_component_promotion.name,
                value: data.billing_component_promotion.id,
              },
        isTaxable: data.isTaxable,
        componentId: data.componentId,
        billing: data.billingId,
      });

      setIsChecked(data.isTaxable);

      setIsEditDetail(isEdit);
      toggleDetail();
    },
    [toggleDetail]
  );

  const handleCopyData = (data) => {
    dispatch(onAddNewBilling(data));
  };

  const columns = useMemo(
    () => [
      {
        Header: " ",
        maxWidth: 100,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              {cellProps.row.original.statusActiveId === 1 ? (
                <button
                  className="btn-label btn btn-sm btn-soft-success fs-12 me-0"
                  onClick={() => {
                    const data = cellProps.row.original;
                    onClickApprove(data);
                  }}
                >
                  <i className="ri-checkbox-circle-line label-icon align-middle fs-16 me-1"></i>
                  Approve
                </button>
              ) : (
                ""
              )}
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-primary"
                onClick={() => {
                  setInfo(cellProps.row.original);
                  setBillingId(cellProps.row.original.id);
                  setClientId(cellProps.row.original.clientId);
                  setBillingName(cellProps.row.original.name);
                  dispatch(onGetBillingComponents({ billingId: cellProps.row.original.id }));
                }}
              >
                <i className="ri-eye-line fs-15"></i>
              </Link>
              <Link
                to="#"
                className={"btn-icon btn btn-sm btn-soft-primary edit-item-btn"}
                onClick={() => {
                  handleBillingClick(cellProps.row.original);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </Link>
              <Link
                to="#"
                className={"btn-icon btn btn-sm btn-soft-info remove-item-btn"}
                onClick={() => {
                  const billingData = cellProps.row.original;
                  onClickCopy(billingData);
                  // (billingData.id);
                  // const dataCopy = { targetId: billingData.id };
                  // handleCopyData(dataCopy);
                }}
              >
                <i className=" ri-file-copy-line fs-15"></i>
              </Link>
            </div>
          );
        },
      },
      {
        Header: `${props.t("Name")}`,
        accessor: "name",
        filterable: true,
      },
      {
        Header: `${props.t("Service")}`,
        accessor: "billing_service.name",
        filterable: true,
      },
      {
        Header: `${props.t("Amount")}`,
        accessor: "amount",
        filterable: true,
      },
      {
        Header: `${props.t("Status")}`,
        accessor: "billing_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.row.original.statusId) {
            case 1:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
            case 2:
              return <span className="badge text-uppercase badge-soft-info">Activated</span>;
            default:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
          }
        },
      },
    ],
    [handleBillingClick, dispatch, props]
  );

  const columnsSecond = useMemo(
    () => [
      {
        Header: " ",
        maxWidth: 100,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              {cellProps.row.original.statusActiveId === 1 ? (
                <button
                  className="btn-label btn btn-sm btn-soft-success fs-12 me-0"
                  onClick={() => {
                    const data = cellProps.row.original;
                    onClickApprove(data);
                  }}
                >
                  <i className="ri-checkbox-circle-line label-icon align-middle fs-16 me-1"></i>
                  Approve
                </button>
              ) : (
                ""
              )}
              <Link
                to="#"
                className={"btn-icon btn btn-sm btn-soft-primary edit-item-btn"}
                onClick={() => {
                  handleBillingDetailClick(cellProps.row.original, info, true);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </Link>
              {/* <Link
                to="#"
                className={"btn-icon btn btn-sm btn-soft-danger remove-item-btn"}
                onClick={() => {
                  const billingData = cellProps.row.original;
                  (billingData);
                  onClickDeleteDetail(billingData);
                }}
              >
                <i className=" ri-delete-bin-line fs-15"></i>
              </Link> */}
            </div>
          );
        },
      },
      {
        Header: `${props.t("Name")}`,
        accessor: "name",
        filterable: true,
      },
      {
        Header: `${props.t("Type")}`,
        accessor: "type",
        filterable: true,
        Cell: (cell) => (
          <div className="d-flex flex-column justify-content-center">
            <p className="text-success mb-0">{cell.row.original.billing_component_category ? cell.row.original.billing_component_category.name : "-"}</p>
            <p>{cell.row.original.type}</p>
          </div>
        ),
      },

      {
        Header: `${props.t("Amount")}`,
        accessor: "amount",
        filterable: true,
      },

      {
        Header: `${props.t("Price")}`,
        accessor: "price",
        filterable: true,
        Cell: (cell) => <p>Rp.{new Intl.NumberFormat().format(cell.row.original.price)}</p>,
      },
      {
        Header: `${props.t("Sort")}`,
        accessor: "sort_order",
        filterable: true,
        Cell: (cell) => parseFloat(cell.row.original.sort_order).toFixed(0),
      },
      {
        Header: `${props.t("Status")}`,
        accessor: "billing_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.row.original.statusId) {
            case 1:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
            case 2:
              return <span className="badge text-uppercase badge-soft-info">Activated</span>;
            default:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
          }
        },
      },
    ],
    [handleBillingDetailClick, props, info]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: billingList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (billingComponentList.length > 0) {
      setPageSizeSecond(Number(billingComponentList.length));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingComponentList]);

  const {
    getTableProps: getTablePropsSecond,
    getTableBodyProps: getTableBodyPropsSecond,
    headerGroups: headerGroupsSecond,
    page: pageSecond,
    prepareRow: prepareRowSecond,
    canPreviousPage: canPreviousPageSecond,
    canNextPage: canNextPageSecond,
    pageOptions: pageOptionsSecond,
    gotoPage: gotoPageSecond,
    nextPage: nextPageSecond,
    previousPage: previousPageSecond,
    setPageSize: setPageSizeSecond,
    preGlobalFilteredRows: preGlobalFilteredRowsSecond,
    setGlobalFilter: setGlobalFilterSecond,
    state: { pageIndex: pageIndexSecond, pageSize: pageSizeSecond },
  } = useTable(
    {
      columns: columnsSecond,
      data: billingComponentList,
      defaultColumn: { Filter: null },
      // initialState: {
      //   pageIndex: 0,
      //   // pageSize: 8,
      //   selectedRowIds: 0,
      //   sortBy: [
      //     {
      //       desc: true,
      //     },
      //   ],
      // },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable ? (column.isSorted ? (column.isSortedDesc ? " sorting_desc" : " sorting_asc") : " sort") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState([]);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const countSecond = preGlobalFilteredRowsSecond.length;
  const [searchValueSecond, setSearchValueSecond] = useState([]);
  const onChangeSecond = useAsyncDebounce((value) => {
    setGlobalFilterSecond(value || undefined);
  }, 200);

  const handleApproveComponent = () => {
    if (billing) {
      billing.setApprove = true;
      // dispatch(onUpdateBilling(billing));
      setApproveModal(false);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: (billing && billing.id) || "",
      name: (billing && billing.name) || "",
      client: (billing && billing.client) || "",
      amount: (billing && billing.amount) || "",
      status: (billing && billing.status) || "",
      service: (billing && billing.service) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      service: Yup.object().required("Please Select Service"),
      status: Yup.object().required("Please Select Status"),
    }),
    onSubmit: (values) => {
      dispatch(getBillingLoading());
      if (isEdit) {
        const updateBilling = {
          id: billing ? billing.id : 0,
          clientId: values.client.value,
          name: values.name,
          statusId: values.status.value,
          amount: values.amount,
          serviceId: values.service.value,
        };
        dispatch(onUpdateBilling(updateBilling));
        validation.resetForm();
      } else {
        const newBilling = {
          clientId: values.client.value,
          name: values.name,
          statusId: values.status.value,
          amount: values.amount,
          serviceId: values.service.value,
        };
        dispatch(onAddNewBilling(newBilling));
        validation.resetForm();
      }
      toggle();
    },
  });

  const validationDetail = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: (billingComponent && billingComponent.id) || "",
      name: (billingComponent && billingComponent.name) || "",
      type: (billingComponent && billingComponent.type) || "",
      amount: (billingComponent && billingComponent.amount) || "",
      sortOrder: (billingComponent && billingComponent.sortOrder) || 0,
      amountMax: (billingComponent && billingComponent.amountMax) || "",
      price: (billingComponent && billingComponent.price) || "",
      extraTitle: (billingComponent && billingComponent.extraTitle) || "",
      extraAmount: (billingComponent && billingComponent.extraAmount) || "",
      extraPrice: (billingComponent && billingComponent.extraPrice) || "",
      isTaxable: (billingComponent && billingComponent.isTaxable) || "",
      status: (billingComponent && billingComponent.status) || "",
      billing: (billingComponent && billingComponent.billing) || "",
      billingCategory: (billingComponent && billingComponent.billingCategory) || "",
      waranty: (billingComponent && billingComponent.waranty) || "",
      promotion: (billingComponent && billingComponent.promotion) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      type: Yup.object().required("Please Select Type"),
      amount: Yup.string().required("Please Enter Amount"),
      amountMax: Yup.string().required("Please Enter Amount Max"),
      price: Yup.string().required("Please Enter Price"),
      extraAmount: Yup.string().required("Please Enter Extra Amount"),
      extraPrice: Yup.string().required("Please Enter Extra Price"),
      status: Yup.object().required("Please Select Status"),
      billingCategory: Yup.object().required("Please Select Billing Category"),
    }),
    onSubmit: (values) => {
      dispatch(getBillingComponentLoading());
      if (isEditDetail) {
        const updateBillingComponent = {
          id: billingComponent ? billingComponent.id : 0,
          name: values.name,
          type: values.type.value,
          amount: values.amount,
          isTaxable: isChecked,
          billingId: billingId,
          categoryId: values.billingCategory.value,
          statusId: values.status.value,
          amount_max: values.amountMax,
          price: values.price,
          sort_order: values.sortOrder,
          extra_title: values.extraTitle,
          extra_amount: values.extraAmount,
          extra_price: values.extraPrice,
          warrantyId: values.waranty.value,
          promotionId: values.promotion.value,
        };
        dispatch(onUpdateBillingComponent(updateBillingComponent));
        validationDetail.resetForm();
      } else {
        const newBillingComponent = {
          name: values.name,
          type: values.type.value,
          amount: values.amount,
          isTaxable: isChecked,
          billingId: billingId,
          categoryId: values.billingCategory.value,
          statusId: values.status.value,
          amount_max: values.amountMax,
          price: values.price,
          sort_order: values.sortOrder,
          extra_title: values.extraTitle,
          extra_amount: values.extraAmount,
          extra_price: values.extraPrice,
          warrantyId: values.waranty.value,
          promotionId: values.promotion.value,
        };
        dispatch(onAddNewBillingComponent(newBillingComponent));
        validationDetail.resetForm();
      }
      toggleDetail();
    },
  });

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      // dispatch(onDeleteBillingDetail(element.value));
    });
    checkall.checked = false;
  };

  function handleBillingStatus(data) {
    setStatusActive(data);
  }

  function handleFilter() {
    let startat = "";
    let endat = "";
    let datearr = filterDate.split(" to ");
    if (datearr[1]) {
      startat = datearr[0];
      endat = datearr[1];
    }
    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    //   dispatch(onGetBillings(param));
  }

  useEffect(() => {
    var data = [];
    var opt = [];
    waranties.forEach((item, key) => {
      const name = item.name;
      const amount = item.amount;
      const id = item.id;
      var obj = { label: `${name} - ${amount}`, value: id };
      opt.push(obj);
    });
    const defaultSelect = {
      label: "No Warenty",
      value: null,
    };
    var option = { options: [defaultSelect, ...opt] };
    data.push(option);
    setWarantyList(data);
  }, [waranties]);

  useEffect(() => {
    var data = [];
    var opt = [];
    promosions.forEach((item, key) => {
      const name = item.name;
      const id = item.id;
      var obj = { label: name, value: id };
      opt.push(obj);
    });
    const defaultSelect = {
      label: "No Promotion",
      value: null,
    };
    var option = { options: [defaultSelect, ...opt] };
    data.push(option);
    setPromotionList(data);
  }, [promosions]);

  useEffect(() => {
    if (billingComponents) {
      setBillingComponentList(billingComponents);
    }
  }, [billingComponents]);

  document.title = `${props.t("Billing")} | Aptek`;
  return (
    <div className="page-content">
      <ApproveModal data={billing && billing.contract_employee ? billing.contract_employee.employee_name : ""} show={approveModal} onApproveClick={handleApproveComponent} onCloseClick={() => setApproveModal(false)} />
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteBilling} onCloseClick={() => setDeleteModal(false)} />
      <CopyModal show={copyModal} onCopyClick={handleCopyBilling} onCloseClick={() => setCopyModal(false)} />
      <DeleteModal show={deleteModalDetail} onDeleteClick={handleDeleteBillingDetail} onCloseClick={() => setDeleteModalDetail(false)} />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("Billing")} pageTitle={props.t("Billing")} />
        <Row>
          <Col xxl={5}>
            <Card>
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    {props.t("Billing Data")} &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color="primary" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className={"btn btn-primary add-btn"}
                      id="create-btn"
                      onClick={() => {
                        setBilling("");
                        setIsEdit(false);
                        toggle();
                      }}
                    >
                      <i className="ri-booklet-line me-1 align-bottom fs-14"></i> {props.t("Add")}
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className="ri-booklet-line me-1 align-bottom fs-14"></i> All Billing
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("2", "Draft");
                        }}
                        href="#"
                      >
                        <i className="ri-information-line me-1 align-bottom fs-14"></i> Draft
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("3", "Activated");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Activated{" "}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className="mb-3">
                    <CardBody className="border border-top-0 border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={1} className="col-xxl-2">
                            <div className="me-2 mb-2 col-12">
                              <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={7}>
                            <div className="search-box me-2 mb-2 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${count} Search...`}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col className="col-xxl-3">
                            <div>
                              <button
                                type="button"
                                className="btn btn-light w-100"
                                onClick={() => {
                                  handleFilter();
                                }}
                              >
                                <i className="ri-search-line me-1 align-bottom fs-14"></i> {props.t("Search")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card mb-1">
                    <Table hover {...getTableProps()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroups.map((headerGroup) => (
                          <tr className={""} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button color="light" onClick={previousPage} disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      Page
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}{" "}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
                    </Col>
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button color="light" onClick={nextPage} disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal id="showModal" isOpen={modal} centered size="md">
                  <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                      toggle();
                      validation.resetForm();
                      validation.setErrors({});
                    }}
                  >
                    {!!isEdit ? `${props.t("Edit")} ${props.t("Billing")}` : `${props.t("Add")} ${props.t("Billing")}`}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody>
                      <div className="row">
                        {/* <div className="col-lg-12"> */}
                        {/* <div className="mb-3">
                          <Label htmlFor="client-field" className="form-label">
                            {props.t("Client")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="client"
                            value={validation.values.client}
                            placeholder="Enter Client"
                            onChange={(e) => {
                              validation.setFieldValue("client", e);
                            }}
                            options={clientList}
                            validate={{ required: { value: true } }}
                            invalid={validation.touched.client && validation.errors.client ? true : false}
                          ></Select>
                          {validation.touched.client && validation.errors.client ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.client}
                            </p>
                          ) : null}
                        </div> */}

                        <div className="mb-3">
                          <Label htmlFor="password-field" className="form-label">
                            {props.t("Service")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="service"
                            value={validation.values.service}
                            placeholder="Enter Service"
                            onChange={(e) => {
                              validation.setFieldValue("service", e);
                            }}
                            options={serviceList}
                            validate={{ required: { value: true } }}
                            invalid={validation.touched.service && validation.errors.service ? true : false}
                          ></Select>
                          {validation.touched.service && validation.errors.service ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.service}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="name-field" className="form-name">
                            {props.t("Name")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="name" id="name-field" className="form-control" placeholder="Enter Name" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.name} invalid={validation.touched.name && validation.errors.name ? true : false} />
                          {validation.touched.name && validation.errors.name ? <FormFeedback type="invalid">{validation.errors.name}</FormFeedback> : null}
                        </div>
                        <div className="mb-3 col-lg-12">
                          <Label htmlFor="amount-field" className="form-label">
                            {props.t("Amount")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="amount" id="amount-field" className="form-control" placeholder="Enter Amount" type="number" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.amount} invalid={validation.touched.amount && validation.errors.amount ? true : false} />
                          {validation.touched.amount && validation.errors.amount ? <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="active-status" className="form-label">
                            {props.t("Status")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="status"
                            value={validation.values.status}
                            validate={{ required: { value: true } }}
                            placeholder="Select Status"
                            onChange={(e) => {
                              validation.setFieldValue("status", e);
                            }}
                            onInputChange={(e) => {
                              // handleUnit(e);
                            }}
                            options={statusActiveData}
                            invalid={validation.touched.status && validation.errors.status ? true : false}
                          ></Select>
                          {validation.touched.status && validation.errors.status ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.status}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            setModal(false);
                            validation.resetForm();
                            validation.setErrors({});
                          }}
                        >
                          {props.t("Cancel")}
                        </button>
                        <button type="submit" className="btn btn-success">
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">{props.t("Save")}</span>
                            {/* {loading && (
                                <Spinner
                                  size="sm"
                                  className="flex-shrink-0"
                                  role="status"
                                >
                                  Loading...
                                </Spinner>
                              )} */}
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <Modal id="showModalDetail" isOpen={modalDetail} centered size="lg">
                  <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                      toggleDetail();
                      validationDetail.resetForm();
                      validationDetail.setErrors({});
                    }}
                  >
                    {!!isEditDetail ? `${props.t("Edit")} ${props.t("Billing Component")}` : `${props.t("Add")} ${props.t("Billing Component")}`}
                  </ModalHeader>
                  <ModalBody>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <Label htmlFor="name-field" className="form-label">
                            {props.t("Name")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="name" id="name-field" className="form-control" placeholder="Enter Name" type="text" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.name} invalid={validationDetail.touched.name && validationDetail.errors.name ? true : false} />
                          {validationDetail.touched.name && validationDetail.errors.name ? <FormFeedback type="invalid">{validationDetail.errors.name}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="waranty-field" className="form-label">
                            {props.t("Waranty")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="waranty"
                            value={validationDetail.values.waranty}
                            placeholder="Select Waranty"
                            onChange={(e) => {
                              validationDetail.setFieldValue("waranty", e);
                            }}
                            options={warantyList}
                            invalid={validationDetail.touched.waranty && validationDetail.errors.waranty ? true : false}
                          ></Select>
                          {validationDetail.touched.waranty && validationDetail.errors.waranty ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validationDetail.errors.waranty}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="promotion-field" className="form-label">
                            {props.t("Promotion")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="promotion"
                            value={validationDetail.values.promotion}
                            placeholder="Select Promotion"
                            onChange={(e) => {
                              validationDetail.setFieldValue("promotion", e);
                            }}
                            options={promotionList}
                            invalid={validationDetail.touched.promotion && validationDetail.errors.promotion ? true : false}
                          ></Select>
                          {validationDetail.touched.promotion && validationDetail.errors.promotion ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validationDetail.errors.promotion}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="billingCategory-field" className="form-label">
                            {props.t("Category")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="billingCategory"
                            value={validationDetail.values.billingCategory}
                            placeholder="Select Billing Category"
                            onChange={(e) => {
                              validationDetail.setFieldValue("billingCategory", e);
                            }}
                            validate={{ required: { value: true } }}
                            options={billingCategoryList}
                            invalid={validationDetail.touched.billingCategory && validationDetail.errors.billingCategory ? true : false}
                          ></Select>
                          {validationDetail.touched.billingCategory && validationDetail.errors.billingCategory ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validationDetail.errors.billingCategory}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="type" className="form-label">
                            {props.t("Type")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="type"
                            placeholder="Select Type"
                            value={validationDetail.values.type}
                            onChange={(e) => {
                              validationDetail.setFieldValue("type", e);
                              if (e.value === "THR Bonus" || e.value === "Bonus" || e.value === "KPI Bonus" || e.value === "Sales Bonus") {
                                validationDetail.setFieldValue("component_type_category", { label: e.value, value: e.value });
                              } else {
                                validationDetail.setFieldValue("component_type_category", null);
                              }
                              validationDetail.setFieldValue("amount_type", null);
                              validationDetail.setFieldValue("period_type", null);
                              validationDetail.setFieldValue("selected_allowance", null);
                              setComponentType(e.value);
                            }}
                            options={componentTypeData}
                            validate={{ required: { value: true } }}
                            invalid={validationDetail.touched.type && validationDetail.errors.type ? true : false}
                          ></Select>
                          {validationDetail.touched.type && validationDetail.errors.type ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validationDetail.errors.type}
                            </p>
                          ) : null}
                        </div>
                        {/* {(componentType === "Deduction" || componentType === "Allowance") && (
                          <div className="mb-3 col-lg-12">
                            <Label htmlFor="component_type_category-field" className="form-label">
                              {componentType} Kategori
                            </Label>
                            <Select
                              name="component_type_category"
                              placeholder="Enter Kategori"
                              value={validationDetail.values.component_type_category}
                              onChange={(e) => {
                                validationDetail.setFieldValue("component_type_category", e);
                                validationDetail.setFieldValue("period_type", null);
                                setComponentTypeCategory(e.value);
                              }}
                              options={componentType === "Deduction" ? componentTypeCategoryDeductionData : componentTypeCategoryAllowanceData}
                            ></Select>
                          </div>
                        )} */}
                        {/* <div className={periodType === "One Time" && componentType !== "Resign Compensation" ? "mb-3 col-lg-7" : "mb-3 col-lg-12"}>
                        <Label htmlFor="period" className="form-label">
                          {props.t("Period")} <span className="text-danger">*</span>
                        </Label>
                        <Select
                          name="period"
                          placeholder="Select Period"
                          value={validationDetail.values.period}
                          onChange={(e) => {
                            validationDetail.setFieldValue("period", e);
                            setPeriodType(e.value);
                          }}
                          options={periodTypeData}
                          filterOption={(option) => {
                            if (componentType === "THR Bonus" || componentType === "KPI Bonus" || componentType === "Resign Compensation") {
                              return option.value !== "Daily" && option.value !== "Monthly";
                            } else if (componentTypeCategory === "Absent Compensation" || componentTypeCategory === "Unpaid Leave" || componentTypeCategory === "BPJS Kesehatan" || componentTypeCategory === "JHT" || componentTypeCategory === "JP" || componentTypeCategory === "JKK" || componentTypeCategory === "JKM") {
                              return option.value !== "Daily" && option.value !== "One Time";
                            } else {
                              return option.value !== "";
                            }
                          }}
                          validate={{ required: { value: true } }}
                          invalid={validationDetail.touched.period && validationDetail.errors.period ? true : false}
                        ></Select>
                        {validationDetail.touched.period && validationDetail.errors.period ? (
                          <p className="invalid-feedback d-block" type="invalid">
                            {validationDetail.errors.period}
                          </p>
                        ) : null}
                      </div> */}
                        {/* {periodType === "One Time" && componentType !== "Resign Compensation" && (
                          <div className="mb-3 col-lg-5">
                            <Label htmlFor="byperiod-field" className="form-label">
                              Payroll Period Date
                            </Label>
                            <Flatpickr
                              name="byperiod"
                              id="byperiod-field"
                              className="form-control"
                              placeholder="Select One Time Date"
                              options={{
                                enableTime: false,
                                dateFormat: "Y/m/d",
                              }}
                              value={validationDetail.values.byperiod}
                              onChange={(o, e) => {
                                validationDetail.setFieldValue("byperiod", e);
                              }}
                            />
                          </div>
                        )} */}
                        <div className="mb-3">
                          <Label htmlFor="value-field" className="form-label">
                            {props.t("Amount")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="amount" id="amount-field" className="form-control" placeholder="Enter Amount" type="number" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.amount} invalid={validationDetail.touched.amount && validationDetail.errors.amount ? true : false} />
                          {validationDetail.touched.amount && validationDetail.errors.amount ? <FormFeedback type="invalid">{validationDetail.errors.amount}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="amountMax-field" className="form-label">
                            {props.t("Amount Max")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="amountMax" id="amountMax-field" className="form-control" placeholder="Enter Amount Max" type="number" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.amountMax} invalid={validationDetail.touched.amountMax && validationDetail.errors.amountMax ? true : false} />
                          {validationDetail.touched.amountMax && validationDetail.errors.amountMax ? <FormFeedback type="invalid">{validationDetail.errors.amountMax}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="sortOrder-field" className="form-label">
                            {props.t("Sort Order")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="sortOrder" id="sortOrder-field" className="form-control" placeholder="Enter Sort Order" type="number" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.sortOrder} invalid={validationDetail.touched.sortOrder && validationDetail.errors.sortOrder ? true : false} />
                          {validationDetail.touched.sortOrder && validationDetail.errors.sortOrder ? <FormFeedback type="invalid">{validationDetail.errors.sortOrder}</FormFeedback> : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <Label htmlFor="price-field" className="form-label">
                            {props.t("Price")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="price" id="price-field" className="form-control" placeholder="Enter Price" type="number" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.price} invalid={validationDetail.touched.price && validationDetail.errors.price ? true : false} />
                          {validationDetail.touched.price && validationDetail.errors.price ? <FormFeedback type="invalid">{validationDetail.errors.price}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="extraTitle-field" className="form-label">
                            {props.t("Extra Title")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="extraTitle" id="extraTitle-field" className="form-control" placeholder="Enter Extra Title" type="text" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.extraTitle} invalid={validationDetail.touched.extraTitle && validationDetail.errors.extraTitle ? true : false} />
                          {validationDetail.touched.extraTitle && validationDetail.errors.extraTitle ? <FormFeedback type="invalid">{validationDetail.errors.extraTitle}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="extraAmount-field" className="form-label">
                            {props.t("Extra Amount")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="extraAmount" id="extraAmount-field" className="form-control" placeholder="Enter Extra Amount" type="number" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.extraAmount} invalid={validationDetail.touched.extraAmount && validationDetail.errors.extraAmount ? true : false} />
                          {validationDetail.touched.extraAmount && validationDetail.errors.extraAmount ? <FormFeedback type="invalid">{validationDetail.errors.extraAmount}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="extraPrice-field" className="form-label">
                            {props.t("Extra Price")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="extraPrice" id="extraPrice-field" className="form-control" placeholder="Enter Extra Price" type="number" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.extraPrice} invalid={validationDetail.touched.extraPrice && validationDetail.errors.extraPrice ? true : false} />
                          {validationDetail.touched.extraPrice && validationDetail.errors.extraPrice ? <FormFeedback type="invalid">{validationDetail.errors.extraPrice}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="active-status" className="form-label">
                            {props.t("Status")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="status"
                            value={validationDetail.values.status}
                            placeholder="Select Status"
                            onChange={(e) => {
                              validationDetail.setFieldValue("status", e);
                            }}
                            onInputChange={(e) => {
                              // handleUnit(e);
                            }}
                            options={statusActiveData}
                            validate={{ required: { value: true } }}
                            invalid={validationDetail.touched.status && validationDetail.errors.status ? true : false}
                          ></Select>
                          {validationDetail.touched.status && validationDetail.errors.status ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validationDetail.errors.status}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="mb-3 col-lg-12">
                        <Input name="isTaxable" id="isTaxable-field" className="form-check-input" type="checkbox" checked={isChecked} onChange={checkHandler} onBlur={validationDetail.handleBlur} /> &nbsp;
                        <Label htmlFor="isTaxable-field" className="form-label">
                          {props.t("Taxable")}?
                        </Label>
                      </div>
                    </div>
                  </ModalBody>
                  <div className="modal-footer">
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          setModalDetail(false);
                          validationDetail.resetForm();
                          validationDetail.setErrors({});
                        }}
                      >
                        {props.t("Cancel")}
                      </button>
                      <button
                        onClick={() => {
                          validationDetail.handleSubmit();
                        }}
                        type="submit"
                        className="btn btn-success"
                      >
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">{props.t("Save")}</span>
                          {/* {loading && (
                                <Spinner
                                  size="sm"
                                  className="flex-shrink-0"
                                  role="status"
                                >
                                  Loading...
                                </Spinner>
                              )} */}
                        </span>
                      </button>
                    </div>
                  </div>
                </Modal>
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
          <Col xxl={7}>
            <Card>
              <div className="card-body d-flex align-items-center">
                <h5 className="text-uppercase mb-2 flex-grow-1">{billingName ? billingName : " - "}</h5>
                <div className="flex-shrink-0">
                  <button
                    type="button"
                    className="btn btn-success add-btn"
                    id="create-btn"
                    onClick={() => {
                      setBilling(info);
                      setIsEditDetail(false);
                      toggleDetail();
                      var data = [];
                      var opt = [{ label: "Select All", value: "all" }];
                      info.pc_detail &&
                        info.pc_detail.forEach((item, key) => {
                          if (item.component_type === "Allowance") {
                            const detail_name = item.detail_name;
                            const id = item.id;
                            var obj = { label: detail_name, value: id };
                            opt.push(obj);
                          }
                        });
                      var option = { options: opt };
                      data.push(option);
                      setSelectedAllowance(data);
                    }}
                  >
                    <i className="ri-list-check label-icon align-middle fs-13 me-1"></i>
                    {props.t("Add")} {props.t("Billing Component")}
                  </button>
                  {/* <button
                      className="btn btn-soft-danger"
                      onClick={() => setDeleteModalMulti(true)}
                    >
                      <i className="ri-delete-bin-5-line me-1 align-bottom fs-13"></i>
                    </button> */}
                </div>
              </div>
              <div className="card-body">
                {/* <Grid
                  data={billingComponentList || []}
                  columns={[
                    {
                      id: "actionchecked",
                      name: _(<input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />),
                      width: "4%",
                      sort: false,
                      data: (row) => row.id,
                      formatter: (cell) => _(<input type="checkbox" className="dataCheckbox form-check-input" value={cell} />),
                    },
                    {
                      name: "Name",
                      data: (row) => row.name,
                      width: "18%",
                    },
                    {
                      name: "Type",
                      width: "10%",
                      data: (row) => row.type,
                    },
                    {
                      name: "Period",
                      width: "14%",
                      data: (row) => row.period,
                    },
                    {
                      name: "Amount",
                      width: "14%",
                      data: (row) => row.amount,
                    },
                    {
                      name: "Status",
                      width: "10%",
                      data: (row) => row,
                      formatter: (cell) => _(<span className={cell.billing_component_status.id === 1 ? "badge text-uppercase badge-soft-danger" : cell.billing_component_status.id === 2 ? "badge text-uppercase badge-soft-info" : "badge text-uppercase badge-soft-warning"}>{cell.billing_component_status.id === 2 ? "Activated" : cell.billing_component_status.id === 1 ? "Draft" : ""}</span>),
                    },
                    {
                      name: "action",
                      sort: false,
                      width: "9%",
                      data: (row) => row,
                      formatter: (cell) =>
                        _(
                          <div className="hstack gap-2 mt-4 mt-sm-0">
                            <button
                              className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                              onClick={() => {
                                handleBillingDetailClick(cell, info, true);
                              }}
                            >
                              <i className="ri-pencil-line fs-16"></i>
                            </button>
                            {cell.statusActiveId !== 2 ? (
                              <button
                                to="#"
                                className="btn-icon btn btn-sm btn-soft-danger remove-item-btn"
                                onClick={() => {
                                  onClickDeleteDetail(cell);
                                }}
                              >
                                <i className=" ri-delete-bin-line fs-15"></i>
                              </button>
                            ) : null}
                          </div>
                        ),
                    },
                  ]}
                  search={true}
                  sort={true}
                /> */}

                <Row>
                  <CardBody className="border border-top-0 border-end-0 border-start-0">
                    <form>
                      <Row>
                        <Col sm={4}>
                          <div className="search-box me-2 mb-2 d-inline-block col-12">
                            <input
                              onChange={(e) => {
                                setSearchValueSecond(e.target.value);
                                onChangeSecond(e.target.value);
                              }}
                              id="search-bar-0"
                              type="text"
                              className="form-control search"
                              placeholder={`${countSecond} Search...`}
                              value={searchValueSecond || ""}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Row>
                <div className="table-responsive table-card mb-1">
                  <Table hover {...getTablePropsSecond()} className="align-middle table-nowrap">
                    <thead className="table-light text-muted">
                      {headerGroupsSecond.map((headerGroup) => (
                        <tr className={""} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                              {column.render("Header")}
                              {generateSortingIndicator(column)}
                              {/* <Filter column={column} /> */}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyPropsSecond()}>
                      {pageSecond.map((row) => {
                        prepareRowSecond(row);
                        return (
                          <Fragment key={row.getRowProps().key}>
                            <tr>
                              {row.cells.map((cell) => {
                                return (
                                  <td key={cell.id} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Billing.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Billing));
