import {
  SERVICE_ORDERAPPROVAL_LOADING,
  GET_SERVICE_ORDERAPPROVAL,
  GET_SERVICE_ORDERAPPROVAL_SUCCESS,
  GET_SERVICE_ORDERAPPROVAL_FAIL,
  ADD_SERVICE_ORDERAPPROVAL_SUCCESS,
  ADD_SERVICE_ORDERAPPROVAL_FAIL,
  DELETE_SERVICE_ORDERAPPROVAL_SUCCESS,
  DELETE_SERVICE_ORDERAPPROVAL_FAIL,
  UPDATE_SERVICE_ORDERAPPROVAL_SUCCESS,
  UPDATE_SERVICE_ORDERAPPROVAL_FAIL,
  SERVICE_ORDERAPPROVAL_RESET
} from "./actionType";

const INIT_STATE = {
  serviceOrderApprovals: [],
  error: {},
};

const ServiceOrderApproval = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_ORDERAPPROVAL_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDERAPPROVAL:
          return {
            ...state,
            serviceOrderApprovals: action.payload.data,
            isServiceOrderApprovalCreated: false,
            isServiceOrderApprovalSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SERVICE_ORDERAPPROVAL_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICE_ORDERAPPROVAL_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isServiceOrderApprovalCreated: false,
            isServiceOrderApprovalSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SERVICE_ORDERAPPROVAL: {
      return {
        ...state,
        isServiceOrderApprovalCreated: false,
        loading: true,
      };
    }

    case SERVICE_ORDERAPPROVAL_LOADING: {
      return {
        ...state,
        isServiceOrderApprovalCreated: false,
        loading: true,
      };
    }

    case ADD_SERVICE_ORDERAPPROVAL_SUCCESS:
      return {
        ...state,
        isServiceOrderApprovalCreated: true,
        loading: false,
        serviceOrderApprovals: [...state.serviceOrderApprovals, action.payload.data],
      };

    case ADD_SERVICE_ORDERAPPROVAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_SERVICE_ORDERAPPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderApprovalCreated: true,
        serviceOrderApprovals: state.serviceOrderApprovals.filter(
          serviceOrderApproval => serviceOrderApproval.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_SERVICE_ORDERAPPROVAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_SERVICE_ORDERAPPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        isServiceOrderApprovalCreated: true,
        serviceOrderApprovals: state.serviceOrderApprovals.map(serviceOrderApproval =>
          serviceOrderApproval.id.toString() === action.payload.data.id.toString() ? { ...serviceOrderApproval, ...action.payload.data } : serviceOrderApproval
        ),
      };

    case UPDATE_SERVICE_ORDERAPPROVAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SERVICE_ORDERAPPROVAL_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ServiceOrderApproval;