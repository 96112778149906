import {
  COMPANY_LOADING,
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  COMPANY_RESET
} from "./actionType";

const INIT_STATE = {
  companies: [],
  error: {},
};

const Company = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_SUCCESS:
      switch (action.payload.actionType) {
        case GET_COMPANY:
          return {
            ...state,
            companies: action.payload.data,
            isCompanyCreated: false,
            isCompanySuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_COMPANY_FAIL:
      switch (action.payload.actionType) {
        case GET_COMPANY_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isCompanyCreated: false,
            isCompanySuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_COMPANY: {
      return {
        ...state,
        isCompanyCreated: false,
        loading: true,
      };
    }

    case COMPANY_LOADING: {
      return {
        ...state,
        isCompanyCreated: false,
        loading: true,
      };
    }

    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        isCompanyCreated: true,
        loading: false,
        companies: [...state.companies, action.payload.data],
      };

    case ADD_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: state.companies.filter(
          company => company.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: state.companies.map(company =>
          company.id.toString() === action.payload.data.id.toString() ? { ...company, ...action.payload.data } : company
        ),
      };

    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case COMPANY_RESET:
      return INIT_STATE;


    default:
      return { ...state };
  }
};

export default Company;