import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GET_SERVICE, DELETE_SERVICE, UPDATE_SERVICE, ADD_NEW_SERVICE } from "./actionType";

import {
  getServiceSuccess,
  getServiceFail,
  deleteServiceSuccess,
  deleteServiceFail,
  updateServiceSuccess,
  updateServiceFail,
  addServiceSuccess,
  addServiceFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveServiceApi,
  getServiceApi,
  updateServiceApi,
  deleteServiceApi,
  addNewServiceApi,
} from "../../helpers/backend_helper";

function* getService({ payload: service }) {
  try {
    const response = yield call(getServiceApi, service);
    yield put(getServiceSuccess(GET_SERVICE, response.data));
  } catch (error) {
    yield put(getServiceFail(GET_SERVICE, error));
  }
}

function* onUpdateService({ payload: service }) {
  try {
    if (service.setApprove) {
      const response = yield call(approveServiceApi, service);
      yield put(updateServiceSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateServiceApi, service);
      yield put(updateServiceSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateServiceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteService({ payload: service }) {
  try {
    const response = yield call(deleteServiceApi, service);
    yield put(deleteServiceSuccess({ service, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteServiceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewService({ payload: service }) {
  try {
    const response = yield call(addNewServiceApi, service);
    yield put(addServiceSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addServiceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetService() {
  yield takeEvery(GET_SERVICE, getService);
}

export function* watchUpdateService() {
  yield takeEvery(UPDATE_SERVICE, onUpdateService);
}

export function* watchDeleteService() {
  yield takeEvery(DELETE_SERVICE, onDeleteService);
}

export function* watchAddNewService() {
  yield takeEvery(ADD_NEW_SERVICE, onAddNewService);
}

function* serviceSaga() {
  yield all([
    fork(watchGetService),
    fork(watchDeleteService),
    fork(watchUpdateService),
    fork(watchAddNewService),
  ]);
}

export default serviceSaga;
