import {
  GET_PROMOSION,
  GET_PROMOSION_SUCCESS,
  GET_PROMOSION_FAIL,
  DELETE_PROMOSION,
  DELETE_PROMOSION_SUCCESS,
  DELETE_PROMOSION_FAIL,
  UPDATE_PROMOSION,
  UPDATE_PROMOSION_SUCCESS,
  UPDATE_PROMOSION_FAIL,
  ADD_NEW_PROMOSION,
  ADD_PROMOSION_SUCCESS,
  ADD_PROMOSION_FAIL,
  PROMOSION_LOADING,
  PROMOSION_RESET,
} from "./actionType";

export const resetPromosionState = (actionType) => ({
  type: PROMOSION_RESET,
  payload: { actionType },
});

export const getPromosionSuccess = (actionType, data) => ({
  type: GET_PROMOSION_SUCCESS,
  payload: { actionType, data },
});

export const getPromosionFail = (actionType, error) => ({
  type: GET_PROMOSION_FAIL,
  payload: { actionType, error },
});

export const getPromosion = data => ({
  type: GET_PROMOSION,
  payload: data,
});

export const getPromosionLoading = () => ({
  type: PROMOSION_LOADING
});

export const deletePromosion = data => ({
  type: DELETE_PROMOSION,
  payload: data,
});

export const deletePromosionSuccess = data => ({
  type: DELETE_PROMOSION_SUCCESS,
  payload: data,
});

export const deletePromosionFail = error => ({
  type: DELETE_PROMOSION_FAIL,
  payload: error,
});

export const updatePromosion = data => ({
  type: UPDATE_PROMOSION,
  payload: data,
});

export const updatePromosionFail = error => ({
  type: UPDATE_PROMOSION_FAIL,
  payload: error,
});

export const updatePromosionSuccess = data => ({
  type: UPDATE_PROMOSION_SUCCESS,
  payload: data,
});

export const addNewPromosion = data => ({
  type: ADD_NEW_PROMOSION,
  payload: data,
});

export const addPromosionSuccess = data => ({
  type: ADD_PROMOSION_SUCCESS,
  payload: data,
});

export const addPromosionFail = error => ({
  type: ADD_PROMOSION_FAIL,
  payload: error,
});