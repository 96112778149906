import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_TICKETCATEGORY,
  DELETE_TICKETCATEGORY,
  UPDATE_TICKETCATEGORY,
  ADD_NEW_TICKETCATEGORY,
} from "./actionType";

import {
  getTicketCategorySuccess,
  getTicketCategoryFail,
  deleteTicketCategorySuccess,
  deleteTicketCategoryFail,
  updateTicketCategorySuccess,
  updateTicketCategoryFail,
  addTicketCategorySuccess,
  addTicketCategoryFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveTicketCategoryApi,
  getTicketCategoryApi,
  updateTicketCategoryApi,
  deleteTicketCategoryApi,
  addNewTicketCategoryApi,
} from "../../helpers/backend_helper";

function* getTicketCategory({ payload: ticketCategory }) {
  try {
    const response = yield call(getTicketCategoryApi, ticketCategory);
    yield put(getTicketCategorySuccess(GET_TICKETCATEGORY, response.data));
  } catch (error) {
    yield put(getTicketCategoryFail(GET_TICKETCATEGORY, error));
  }
}

function* onUpdateTicketCategory({ payload: ticketCategory }) {
  try {
    if (ticketCategory.setApprove) {
      const response = yield call(approveTicketCategoryApi, ticketCategory);
      yield put(updateTicketCategorySuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateTicketCategoryApi, ticketCategory);
      yield put(updateTicketCategorySuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateTicketCategoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteTicketCategory({ payload: ticketCategory }) {
  try {
    const response = yield call(deleteTicketCategoryApi, ticketCategory);
    yield put(deleteTicketCategorySuccess({ ticketCategory, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteTicketCategoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewTicketCategory({ payload: ticketCategory }) {
  try {
    const response = yield call(addNewTicketCategoryApi, ticketCategory);
    yield put(addTicketCategorySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addTicketCategoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetTicketCategory() {
  yield takeEvery(GET_TICKETCATEGORY, getTicketCategory);
}

export function* watchUpdateTicketCategory() {
  yield takeEvery(UPDATE_TICKETCATEGORY, onUpdateTicketCategory);
}

export function* watchDeleteTicketCategory() {
  yield takeEvery(DELETE_TICKETCATEGORY, onDeleteTicketCategory);
}

export function* watchAddNewTicketCategory() {
  yield takeEvery(ADD_NEW_TICKETCATEGORY, onAddNewTicketCategory);
}

function* ticketCategorySaga() {
  yield all([
    fork(watchGetTicketCategory),
    fork(watchDeleteTicketCategory),
    fork(watchUpdateTicketCategory),
    fork(watchAddNewTicketCategory),
  ]);
}

export default ticketCategorySaga;
