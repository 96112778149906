import React from 'react';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
//import { ecomWidgets } from "../../common/data";

const Widgets = () => {
    return (
        <React.Fragment>
        
        </React.Fragment>
    );
};

export default Widgets;