import {
  DOCUMENT_DETAIL_LOADING,
  GET_DOCUMENT_DETAIL,
  GET_DOCUMENT_DETAIL_SUCCESS,
  GET_DOCUMENT_DETAIL_FAIL,
  ADD_DOCUMENT_DETAIL_SUCCESS,
  ADD_DOCUMENT_DETAIL_FAIL,
  DELETE_DOCUMENT_DETAIL_SUCCESS,
  DELETE_DOCUMENT_DETAIL_FAIL,
  UPDATE_DOCUMENT_DETAIL_SUCCESS,
  UPDATE_DOCUMENT_DETAIL_FAIL,
  DOCUMENT_DETAIL_RESET
} from "./actionType";

const INIT_STATE = {
  documentDetails: [],
  error: {},
};

const DocumentDetail = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOCUMENT_DETAIL_SUCCESS:
      switch (action.payload.actionType) {
        case GET_DOCUMENT_DETAIL:
          return {
            ...state,
            documentDetails: action.payload.data,
            isDocumentDetailCreated: false,
            isDocumentDetailSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_DOCUMENT_DETAIL_FAIL:
      switch (action.payload.actionType) {
        case GET_DOCUMENT_DETAIL_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isDocumentDetailCreated: false,
            isDocumentDetailSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_DOCUMENT_DETAIL: {
      return {
        ...state,
        isDocumentDetailCreated: false,
        loading: true,
      };
    }

    case DOCUMENT_DETAIL_LOADING: {
      return {
        ...state,
        isDocumentDetailCreated: false,
        loading: true,
      };
    }

    case ADD_DOCUMENT_DETAIL_SUCCESS:
      return {
        ...state,
        isDocumentDetailCreated: true,
        loading: false,
        documentDetails: [...state.documentDetails, action.payload.data],
      };

    case ADD_DOCUMENT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_DOCUMENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        isDocumentDetailCreated: true,
        documentDetails: state.documentDetails.filter(
          documentDetail => documentDetail.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_DOCUMENT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_DOCUMENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        isDocumentDetailCreated: true,
        documentDetails: state.documentDetails.map(documentDetail =>
          documentDetail.id.toString() === action.payload.data.id.toString() ? { ...documentDetail, ...action.payload.data } : documentDetail
        ),
      };

    case UPDATE_DOCUMENT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DOCUMENT_DETAIL_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default DocumentDetail;