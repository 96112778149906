import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";
import lottie from "lottie-web";
import { defineElement } from "lord-icon-element";
defineElement(lottie.loadAnimation);

const ApproveModal = ({ show, onApproveClick, onCloseClick, data }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size="md">
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <lord-icon src="https://cdn.lordicon.com/puvaffet.json" trigger="loop" colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">
              Are you sure you want to approve <b>{data == null ? "" : data.name} </b>
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onCloseClick}>
            Close
          </button>
          <button type="button" className="btn w-sm btn-success " id="delete-record" onClick={onApproveClick}>
            Yes, Approve!
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

ApproveModal.propTypes = {
  onCloseClick: PropTypes.func,
  onApproveClick: PropTypes.func,
  show: PropTypes.any,
};

export default ApproveModal;
