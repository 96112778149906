import {
  WARANTY_LOADING,
  GET_WARANTY,
  GET_WARANTY_SUCCESS,
  GET_WARANTY_FAIL,
  ADD_WARANTY_SUCCESS,
  ADD_WARANTY_FAIL,
  DELETE_WARANTY_SUCCESS,
  DELETE_WARANTY_FAIL,
  UPDATE_WARANTY_SUCCESS,
  UPDATE_WARANTY_FAIL,
  WARANTY_RESET,
} from "./actionType";

const INIT_STATE = {
  waranties: [],
  error: {},
};

const Waranty = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WARANTY_SUCCESS:
      switch (action.payload.actionType) {
        case GET_WARANTY:
          return {
            ...state,
            waranties: action.payload.data,
            isWarantyCreated: false,
            isWarantySuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_WARANTY_FAIL:
      switch (action.payload.actionType) {
        case GET_WARANTY_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isWarantyCreated: false,
            isWarantySuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_WARANTY: {
      return {
        ...state,
        isWarantyCreated: false,
        loading: true,
      };
    }

    case WARANTY_LOADING: {
      return {
        ...state,
        isWarantyCreated: false,
        loading: true,
      };
    }

    case ADD_WARANTY_SUCCESS:
      return {
        ...state,
        isWarantyCreated: true,
        loading: false,
        waranties: [...state.waranties, action.payload.data],
      };

    case ADD_WARANTY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_WARANTY_SUCCESS:
      return {
        ...state,
        loading: false,
        waranties: state.waranties.filter(
          (waranty) => waranty.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_WARANTY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_WARANTY_SUCCESS:
      return {
        ...state,
        loading: false,
        waranties: state.waranties.map((waranty) =>
          waranty.id.toString() === action.payload.data.id.toString()
            ? { ...waranty, ...action.payload.data }
            : waranty
        ),
      };

    case UPDATE_WARANTY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case WARANTY_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Waranty;
