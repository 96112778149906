import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_BILLING_CATEGORY,
  DELETE_BILLING_CATEGORY,
  UPDATE_BILLING_CATEGORY,
  ADD_NEW_BILLING_CATEGORY,
} from "./actionType";

import {
  getBillingCategorySuccess,
  getBillingCategoryFail,
  deleteBillingCategorySuccess,
  deleteBillingCategoryFail,
  updateBillingCategorySuccess,
  updateBillingCategoryFail,
  addBillingCategorySuccess,
  addBillingCategoryFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveBillingCategoryApi,
  getBillingCategoryApi,
  updateBillingCategoryApi,
  deleteBillingCategoryApi,
  addNewBillingCategoryApi,
} from "../../helpers/backend_helper";

function* getBillingCategory({ payload: billingCategory }) {
  try {
    const response = yield call(getBillingCategoryApi, billingCategory);
    yield put(getBillingCategorySuccess(GET_BILLING_CATEGORY, response.data));
  } catch (error) {
    yield put(getBillingCategoryFail(GET_BILLING_CATEGORY, error));
  }
}

function* onUpdateBillingCategory({ payload: billingCategory }) {
  try {
    if (billingCategory.setApprove) {
      const response = yield call(approveBillingCategoryApi, billingCategory);
      yield put(updateBillingCategorySuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateBillingCategoryApi, billingCategory);
      yield put(updateBillingCategorySuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateBillingCategoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteBillingCategory({ payload: billingCategory }) {
  try {
    const response = yield call(deleteBillingCategoryApi, billingCategory);
    yield put(deleteBillingCategorySuccess({ billingCategory, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteBillingCategoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewBillingCategory({ payload: billingCategory }) {
  try {
    const response = yield call(addNewBillingCategoryApi, billingCategory);
    yield put(addBillingCategorySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addBillingCategoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetBillingCategory() {
  yield takeEvery(GET_BILLING_CATEGORY, getBillingCategory);
}

export function* watchUpdateBillingCategory() {
  yield takeEvery(UPDATE_BILLING_CATEGORY, onUpdateBillingCategory);
}

export function* watchDeleteBillingCategory() {
  yield takeEvery(DELETE_BILLING_CATEGORY, onDeleteBillingCategory);
}

export function* watchAddNewBillingCategory() {
  yield takeEvery(ADD_NEW_BILLING_CATEGORY, onAddNewBillingCategory);
}

function* billingCategorySaga() {
  yield all([
    fork(watchGetBillingCategory),
    fork(watchDeleteBillingCategory),
    fork(watchUpdateBillingCategory),
    fork(watchAddNewBillingCategory),
  ]);
}

export default billingCategorySaga;
