import {
  GET_SERVICEPRICE,
  GET_SERVICEPRICE_SUCCESS,
  GET_SERVICEPRICE_FAIL,
  SERVICEPRICE_LOADING,
  SERVICEPRICE_RESET,
} from "./actionType";

export const resetServicePriceState = (actionType) => ({
  type: SERVICEPRICE_RESET,
  payload: { actionType },
});

export const getServicePriceSuccess = (actionType, data) => ({
  type: GET_SERVICEPRICE_SUCCESS,
  payload: { actionType, data },
});

export const getServicePriceFail = (actionType, error) => ({
  type: GET_SERVICEPRICE_FAIL,
  payload: { actionType, error },
});

export const getServicePrice = data => ({
  type: GET_SERVICEPRICE,
  payload: data,
});

export const getServicePriceLoading = () => ({
  type: SERVICEPRICE_LOADING
});