export const SERVICE_ORDERTOTAL_LOADING = "SERVICE_ORDERTOTAL_LOADING";
export const SERVICE_ORDERTOTAL_RESET = "SERVICE_ORDERTOTAL_RESET";
export const GET_SERVICE_ORDERTOTAL = "GET_SERVICE_ORDERTOTAL";
export const GET_SERVICE_ORDERTOTAL_SUCCESS = "GET_SERVICE_ORDERTOTAL_SUCCESS";
export const GET_SERVICE_ORDERTOTAL_FAIL = "GET_SERVICE_ORDERTOTAL_FAIL";
export const DELETE_SERVICE_ORDERTOTAL = "DELETE_SERVICE_ORDERTOTAL"
export const DELETE_SERVICE_ORDERTOTAL_SUCCESS = "DELETE_SERVICE_ORDERTOTAL_SUCCESS"
export const DELETE_SERVICE_ORDERTOTAL_FAIL = "DELETE_SERVICE_ORDERTOTAL_FAIL"
export const UPDATE_SERVICE_ORDERTOTAL = "UPDATE_SERVICE_ORDERTOTAL"
export const UPDATE_SERVICE_ORDERTOTAL_SUCCESS = "UPDATE_SERVICE_ORDERTOTAL_SUCCESS"
export const UPDATE_SERVICE_ORDERTOTAL_FAIL = "UPDATE_SERVICE_ORDERTOTAL_FAIL"
export const ADD_NEW_SERVICE_ORDERTOTAL = "ADD_NEW_SERVICE_ORDERTOTAL"
export const ADD_SERVICE_ORDERTOTAL_SUCCESS = "ADD_SERVICE_ORDERTOTAL_SUCCESS"
export const ADD_SERVICE_ORDERTOTAL_FAIL = "ADD_SERVICE_ORDERTOTAL_FAIL"