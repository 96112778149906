import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  getServiceList,
  resetServiceListState,
} from "../../store/serviceList/action";

const Services = () => {
  const dispatch = useDispatch();

  const { serviceListLoading, serviceLists } = useSelector((state) => ({
    serviceListLoading: state.ServiceList.loading,
    serviceLists: state.ServiceList.serviceLists,
  }));

  useEffect(() => {
    dispatch(resetServiceListState());
    dispatch(getServiceList());
  }, [dispatch]);

  return (
    <React.Fragment>
      <section className='section' id='services'>
        <Container>
          <Row className='justify-content-center'>
            <Col lg={8}>
              <div className='text-center mb-5'>
                <h1 className='mb-3 ff-secondary fw-semibold lh-base'>
                  The Best Solution For Your Business
                </h1>
                <p className='text-muted'>
                  Simplify and integrate your business into one powerful
                  application
                </p>
              </div>
            </Col>
          </Row>

          <Row className='g-3'>
            {serviceLists.map((service, idx) => {
              return (
                <Col lg={4} key={idx}>
                  <div className='d-flex p-3'>
                    <div className='flex-shrink-0 me-3 d-none'>
                      <div className='avatar-sm icon-effect'>
                        <div className='avatar-title bg-transparent text-success rounded-circle'>
                          <i className='ri-pencil-ruler-2-line fs-36'></i>
                        </div>
                      </div>
                    </div>
                    <div className='flex-grow-1'>
                      <h5 className='fs-18'>{service.name}</h5>
                      <p className='text-muted my-3 ff-secondary'>
                        {service.desciption ? service.desciption : "-"}
                      </p>
                      <div>
                        <Link to='#' className='fs-13 fw-medium'>
                          Learn More{" "}
                          <i className='ri-arrow-right-s-line align-bottom'></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Services;
