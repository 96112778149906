import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_BILLING,
  DELETE_BILLING,
  UPDATE_BILLING,
  ADD_NEW_BILLING,
} from "./actionType";

import {
  getBillingSuccess,
  getBillingFail,
  deleteBillingSuccess,
  deleteBillingFail,
  updateBillingSuccess,
  updateBillingFail,
  addBillingSuccess,
  addBillingFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveBillingApi,
  getBillingApi,
  updateBillingApi,
  deleteBillingApi,
  addNewBillingApi,
} from "../../helpers/backend_helper";

function* getBilling({ payload: billing }) {
  try {
    const response = yield call(getBillingApi, billing);
    yield put(getBillingSuccess(GET_BILLING, response.data));
  } catch (error) {
    yield put(getBillingFail(GET_BILLING, error));
  }
}

function* onUpdateBilling({ payload: billing }) {
  try {
    if (billing.setApprove) {
      const response = yield call(approveBillingApi, billing);
      yield put(updateBillingSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateBillingApi, billing);
      yield put(updateBillingSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateBillingFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteBilling({ payload: billing }) {
  try {
    const response = yield call(deleteBillingApi, billing);
    yield put(deleteBillingSuccess({ billing, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteBillingFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewBilling({ payload: billing }) {
  try {
    const response = yield call(addNewBillingApi, billing);
    yield put(addBillingSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addBillingFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetBilling() {
  yield takeEvery(GET_BILLING, getBilling);
}

export function* watchUpdateBilling() {
  yield takeEvery(UPDATE_BILLING, onUpdateBilling);
}

export function* watchDeleteBilling() {
  yield takeEvery(DELETE_BILLING, onDeleteBilling);
}

export function* watchAddNewBilling() {
  yield takeEvery(ADD_NEW_BILLING, onAddNewBilling);
}

function* billingSaga() {
  yield all([
    fork(watchGetBilling),
    fork(watchDeleteBilling),
    fork(watchUpdateBilling),
    fork(watchAddNewBilling),
  ]);
}

export default billingSaga;
