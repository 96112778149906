import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Table, Button, Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, Modal, ModalHeader, Form, ModalBody, Label, Input, FormFeedback, Spinner } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import ApproveModal from "../../Components/Common/ApproveModal";
import { isEmpty, clone } from "lodash";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { Grid, _ } from "gridjs-react";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { api } from "../../config";
import defaultImg from "../../assets/images/upload.png";

//Import actions
import { resetTicketCategoryState, getTicketCategory } from "../../store/ticketCategory/action";
import { getTicket as onGetTickets, addNewTicket as onAddNewTicket, updateTicket as onUpdateTicket, deleteTicket as onDeleteTicket, getTicketLoading, resetTicketState } from "../../store/ticket/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ModalInputFile from "../../Components/ModalInputFile";

import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const Ticket = (props) => {
  const { t } = props;
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();

  const { tickets, isTicketCreated, isTicketSuccess, loading, error, ticketCategories, ticketCategoryLoading } = useSelector((state) => ({
    tickets: state.Ticket.tickets,
    isTicketCreated: state.Ticket.isTicketCreated,
    isTicketSuccess: state.Ticket.isTicketSuccess,
    loading: state.Ticket.loading,
    error: state.Ticket.error,
    ticketCategories: state.TicketCategory.ticketCategories,
    ticketCategoryLoading: state.TicketCategory.loading,
  }));

  const [isSearch, setIsSearch] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const [ticket, setTicket] = useState([]);
  const [info, setInfo] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);
  const [ticketDetail, setTicketDetail] = useState([]);
  const [approveModal, setApproveModal] = useState(false);
  const [positionList, setPositionList] = useState([]);
  const [roles, setRoles] = useState(null);
  const [userType, setUserType] = useState("user");
  const [companyName, setCompanyName] = useState({});
  const [logo, setLogo] = useState(defaultImg);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditDetail, setIsEditDetail] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalDetail, setDeleteModalDetail] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [ticketCategoryList, setTicketCategoryList] = useState([]);
  const [otherDocsImg, setOtherDocsImg] = useState([]);
  const [modalMedia, setModalMedia] = useState(false);
  const [originalFiles, setOriginalFiles] = useState([]);
  const ref = useRef(null);

  const defaultdate = () => {
    return moment(new Date()).tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };

  const onClickDelete = (ticket) => {
    setTicket(ticket);
    setDeleteModal(true);
  };

  const onClickDeleteDetail = (ticketDetail) => {
    setTicketDetail(ticketDetail);
    setDeleteModalDetail(true);
  };

  const handleDeleteTicket = () => {
    if (ticket) {
      dispatch(onDeleteTicket(ticket));
      setDeleteModal(false);
    }
  };

  const onClickApprove = (employee) => {
    setTicket(employee);
    setApproveModal(true);
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredTickets = tickets;
      if (type !== "all") {
        filteredTickets = tickets.filter((ticket) => ticket.ticket_status.status_name === type);
      }
      setTicketList(filteredTickets);
    }
  };

  // useEffect(() => {

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetTicketState());
      dispatch(onGetTickets());
      setIsSearch(true);
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      if (obj.data && obj.data.employee_position && obj.data.employee_position.position_role) {
        setCompanyName({
          label: obj.data.employee_company.name,
          value: obj.data.employee_company.id,
        });
        setRoles(obj.data.employee_position.position_role.role_method);
      } else setRoles(null);

      if (obj.data) setUserType(obj.data.userType);
    }
  }, [dispatch, tickets, isSearch]);

  useEffect(() => {
    if (!isEmpty(tickets)) {
      setIsEdit(false);
      setTicketList(tickets);
      let current = 0;
      setInfo(tickets[current]);
    }
  }, [tickets]);

  useEffect(() => {
    if (!isEmpty(ticketCategories)) {
      var data = [];
      var opt = [];
      ticketCategories.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setTicketCategoryList(data);
    }
  }, [ticketCategories]);

  const [filterDate, setFilterDate] = useState(defaultdate());

  const handleValidDate = (date) => {
    return moment(new Date(date), "YYYY/MM/DD").tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  const handleSelectFile = (file) => {
    setOtherDocsImg((val) => [...val, { filename: file.filename }]);

    toggleMedia();
  };

  const handleDeleteFile = (file) => {
    if (isEdit) {
      const newState = otherDocsImg.map((obj) => {
        if (obj.id === file.id) {
          return { id: file.id, filename: file.filename, isDeleted: true };
        }
        return obj;
      });
      setOtherDocsImg(newState);
    } else {
      const index = otherDocsImg.findIndex((fl) => fl.filename === file.filename);

      setOtherDocsImg((val) => [...val.slice(0, index), ...val.slice(index + 1)]);
      // setOtherDocsImg(val =>
      //   val.filter(fl => {
      //     return fl.id !== file.id;
      //   }),
      // );
    }
  };

  const toggleMedia = useCallback(() => {
    if (modalMedia) {
      setModalMedia(false);
    } else {
      setModalMedia(true);
    }
  }, [modalMedia]);

  const toggle = useCallback(() => {
    if (modal) {
      dispatch(resetTicketCategoryState());
      setModal(false);
      setCompanyList([]);
      setPositionList([]);
      setOriginalFiles([]);
      setOtherDocsImg([]);
      validation.resetForm();
      validation.setErrors({});
    } else {
      dispatch(getTicketCategory());
      setModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const handleTicketClick = useCallback(
    (arg) => {
      const data = arg;

      let files = [];

      if (data.ticket_file.length > 0) {
        data.ticket_file.forEach((element) => {
          files.push({
            id: element.id,
            filename: element.filename,
          });
        });
      }

      setOtherDocsImg(files);

      setTicket({
        id: data.id,
        name: data.name,
        description: data.description,
        category:
          data.ticket_category === null
            ? ""
            : {
                label: data.ticket_category ? data.ticket_category.name : "",
                value: data.ticket_category ? data.ticket_category.id : "",
              },
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        maxWidth: 100,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              <Link
                to="#"
                className={userType === "admin" ? "btn-icon btn btn-sm btn-soft-primary edit-item-btn" : roles && roles.split(",").indexOf("updateTicket") !== -1 ? "btn-icon btn btn-sm btn-soft-primary edit-item-btn" : "btn-icon btn btn-sm btn-soft-primary edit-item-btn d-none"}
                onClick={() => {
                  handleTicketClick(cellProps.row.original);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </Link>
              <Link
                to="#"
                className={userType === "admin" ? "btn-icon btn btn-sm btn-soft-danger remove-item-btn" : roles && roles.split(",").indexOf("deleteTicket") !== -1 ? "btn-icon btn btn-sm btn-soft-danger remove-item-btn" : "btn-icon btn btn-sm btn-soft-danger remove-item-btn d-none"}
                onClick={() => {
                  const ticketData = cellProps.row.original;
                  onClickDelete(ticketData);
                }}
              >
                <i className=" ri-delete-bin-line fs-15"></i>
              </Link>
            </div>
          );
        },
      },
      {
        Header: "Ticket By",
        accessor: "ticket_requestedby.employee_name",
        filterable: true,
        Cell: (cell) => (
          <span>
            {cell.row.original.ticket_requestedby.employee_name} -<small>{cell.row.original.ticket_requestedby.employee_position && cell.row.original.ticket_requestedby.employee_position.position_name}</small>
          </span>
        ),
      },
      {
        Header: "Title",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "To Position",
        accessor: "ticket_position.position_name",
        filterable: true,
      },
      {
        Header: "Company",
        accessor: "ticket_company.name",
        filterable: true,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: true,
      },
      {
        Header: `Status`,
        accessor: "billing_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.row.original.statusId) {
            case 1:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
            case 2:
              return <span className="badge text-uppercase badge-soft-info">Activated</span>;
            default:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
          }
        },
      },
    ],
    [handleTicketClick, userType, roles]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: ticketList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 8,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable ? (column.isSorted ? (column.isSortedDesc ? " sorting_desc" : " sorting_asc") : " sort") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState([]);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (ticket && ticket.name) || "",
      category: (ticket && ticket.category) || "",
      description: (ticket && ticket.description) || "",
      file: (ticket && ticket.file) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Ticket Name").nullable(true),
      // position: Yup.object().required("Please Enter Position Name").nullable(true),
      // company: Yup.object().required("Please Enter Company").nullable(true),
      // description: Yup.string().required("Please Enter Percentage Value").nullable(true),
    }),
    onSubmit: (values) => {
      dispatch(getTicketLoading());
      if (isEdit) {
        const updateTicket = {
          id: ticket ? ticket.id : 0,
          name: values.name,
          description: values.description,
          ticketCategoryId: values.category.value,
          statusId: 2,
          files: otherDocsImg.length > 0 ? otherDocsImg : [],
        };
        dispatch(onUpdateTicket(updateTicket));
        validation.resetForm();
      } else {
        const newTicket = {
          name: values.name,
          description: values.description,
          ticketCategoryId: values.category.value,
          statusId: 2,
          files: otherDocsImg.length > 0 ? otherDocsImg : [],
        };
        dispatch(onAddNewTicket(newTicket));
        validation.resetForm();
      }
      toggle();
    },
  });

  function handleFilter() {
    let startat = "";
    let endat = "";
    let datearr = filterDate.split(" to ");
    if (datearr[1]) {
      startat = datearr[0];
      endat = datearr[1];
    }
    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetTickets(param));
  }

  document.title = "Ticket | Aptek";

  return (
    <div className="page-content">
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteTicket} onCloseClick={() => setDeleteModal(false)} />
      <Container fluid>
        <BreadCrumb title="Tickets" pageTitle="Task" />
        <Row>
          <Col xxl={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Ticket History &nbsp;{" "}
                    {isLoading ? (
                      <>
                        <Spinner color="primary" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className={userType === "admin" ? "btn btn-primary add-btn" : roles && roles.split(",").indexOf("createTicket") !== -1 ? "btn btn-primary add-btn" : "btn btn-primary add-btn d-none"}
                      id="create-btn"
                      onClick={() => {
                        setTicket("");
                        setIsEdit(false);
                        toggle();
                      }}
                    >
                      <i className="ri-coupon-line me-1 align-bottom fs-14"></i> Add New
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className="ri-coupon-line me-1 align-bottom fs-14"></i> All Ticket
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("2", "Draft");
                        }}
                        href="#"
                      >
                        <i className="ri-information-line me-1 align-bottom fs-14"></i> Open
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("3", "Activated");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Processed
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("4", "Deactivated");
                        }}
                        href="#"
                      >
                        <i className="ri-indeterminate-circle-line me-1 align-bottom fs-14"></i> Closed
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className="mb-3">
                    <CardBody className="border border-top-0 border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={1} className="col-xxl-2">
                            <div className="me-2 mb-2 col-12">
                              <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={7}>
                            <div className="search-box me-2 mb-2 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${count} Search...`}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col className="col-xxl-3">
                            <div>
                              <button
                                type="button"
                                className="btn btn-light w-100"
                                onClick={() => {
                                  handleFilter();
                                }}
                              >
                                <i className="ri-search-line me-1 align-bottom fs-14"></i> Search
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card mb-1">
                    <Table hover {...getTableProps()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroups.map((headerGroup) => (
                          <tr className={""} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button color="light" onClick={previousPage} disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      Page
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}{" "}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
                    </Col>
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button color="light" onClick={nextPage} disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal id="showModal" isOpen={modal} centered size="md">
                  <ModalHeader className="bg-light p-3" toggle={toggle}>
                    {!!isEdit ? "Edit Ticket" : "Add Ticket"}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody>
                      <div className="row">
                        <div className="col-lg-12 ">
                          <div className="mb-3">
                            <Label htmlFor="category-field" className="form-label">
                              Category&nbsp;
                              {ticketCategoryLoading ? (
                                <Spinner color="primary" size="sm">
                                  Loading...
                                </Spinner>
                              ) : (
                                ""
                              )}
                            </Label>
                            <Select
                              name="category"
                              value={validation.values.category}
                              placeholder="Select Category"
                              onChange={(e) => {
                                validation.setFieldValue("category", e);
                              }}
                              options={ticketCategoryList}
                            ></Select>
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="name-field" className="form-label">
                              Ticket Title
                            </Label>
                            <Input name="name" id="name-field" className="form-control" placeholder="Enter ticket title" type="text" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.name} invalid={validation.touched.name && validation.errors.name ? true : false} />
                            {validation.touched.name && validation.errors.name ? <FormFeedback type="invalid">{validation.errors.name}</FormFeedback> : null}
                          </div>

                          <div className="mb-3 d-flex justify-content-between">
                            <Label htmlFor="education" className="form-label">
                              Other Document
                              {/* {educationLevelLoading && (
                                <Spinner color="primary" size="sm">
                                  Loading...
                                </Spinner>
                              )} */}
                            </Label>
                            <button
                              type="button"
                              className="btn btn-sm btn-label btn-soft-success"
                              onClick={() => {
                                toggleMedia();
                              }}
                            >
                              <i className="ri-file-cloud-fill label-icon align-middle fs-16 me-1"></i> Media
                            </button>
                          </div>
                          {otherDocsImg.map((otherDocFile, i) => {
                            if (otherDocFile.isDeleted) {
                              return null;
                            } else {
                              return (
                                <div key={i} className="border-bottom mb-3">
                                  <div className="d-flex justify-content-between mb-3 align-items-center">
                                    <div className="me-2">
                                      <img src={otherDocFile.filename === "" ? logo : `${api.MEDIA_URL}/media/${otherDocFile.filename}`} alt="" data-dz-thumbnail="" height="30" className="avatar-sm rounded bg-light" />
                                    </div>

                                    <div className="text-truncate">{otherDocFile.filename}</div>
                                    <div>
                                      <Link
                                        to="#"
                                        className={"btn-icon btn btn-sm btn-soft-danger remove-item-btn mx-2"}
                                        onClick={(e) => {
                                          // e.stopPropagation();
                                          handleDeleteFile(otherDocFile);
                                        }}
                                      >
                                        <i className=" ri-delete-bin-line fs-15"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                          <div className="mb-3">
                            <Label htmlFor="description-field" className="form-label">
                              Description
                            </Label>
                            <Input name="description" id="description-field" rows="7" className="form-control" placeholder="Enter Ticket Description" type="textarea" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.description} />
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            setModal(false);
                          }}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-success">
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">{!!isEdit ? "Update" : "Add Ticket"}</span>
                            {loading && (
                              <Spinner size="sm" className="flex-shrink-0" role="status">
                                Loading...
                              </Spinner>
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <ModalInputFile onSelect={handleSelectFile} loading={loading} modal={modalMedia} toggle={toggleMedia} />
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Ticket.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Ticket));
