export const PROMOSION_LOADING = "PROMOSION_LOADING";
export const PROMOSION_RESET = "PROMOSION_RESET";
export const GET_PROMOSION = "GET_PROMOSION";
export const GET_PROMOSION_SUCCESS = "GET_PROMOSION_SUCCESS";
export const GET_PROMOSION_FAIL = "GET_PROMOSION_FAIL";
export const DELETE_PROMOSION = "DELETE_PROMOSION"
export const DELETE_PROMOSION_SUCCESS = "DELETE_PROMOSION_SUCCESS"
export const DELETE_PROMOSION_FAIL = "DELETE_PROMOSION_FAIL"
export const UPDATE_PROMOSION = "UPDATE_PROMOSION"
export const UPDATE_PROMOSION_SUCCESS = "UPDATE_PROMOSION_SUCCESS"
export const UPDATE_PROMOSION_FAIL = "UPDATE_PROMOSION_FAIL"
export const ADD_NEW_PROMOSION = "ADD_NEW_PROMOSION"
export const ADD_PROMOSION_SUCCESS = "ADD_PROMOSION_SUCCESS"
export const ADD_PROMOSION_FAIL = "ADD_PROMOSION_FAIL"