export const SERVICE_ORDERPAYMENT_LOADING = "SERVICE_ORDERPAYMENT_LOADING";
export const SERVICE_ORDERPAYMENT_RESET = "SERVICE_ORDERPAYMENT_RESET";
export const GET_SERVICE_ORDERPAYMENT = "GET_SERVICE_ORDERPAYMENT";
export const GET_SERVICE_ORDERPAYMENT_SUCCESS = "GET_SERVICE_ORDERPAYMENT_SUCCESS";
export const GET_SERVICE_ORDERPAYMENT_FAIL = "GET_SERVICE_ORDERPAYMENT_FAIL";
export const DELETE_SERVICE_ORDERPAYMENT = "DELETE_SERVICE_ORDERPAYMENT"
export const DELETE_SERVICE_ORDERPAYMENT_SUCCESS = "DELETE_SERVICE_ORDERPAYMENT_SUCCESS"
export const DELETE_SERVICE_ORDERPAYMENT_FAIL = "DELETE_SERVICE_ORDERPAYMENT_FAIL"
export const UPDATE_SERVICE_ORDERPAYMENT = "UPDATE_SERVICE_ORDERPAYMENT"
export const UPDATE_SERVICE_ORDERPAYMENT_SUCCESS = "UPDATE_SERVICE_ORDERPAYMENT_SUCCESS"
export const UPDATE_SERVICE_ORDERPAYMENT_FAIL = "UPDATE_SERVICE_ORDERPAYMENT_FAIL"
export const ADD_NEW_SERVICE_ORDERPAYMENT = "ADD_NEW_SERVICE_ORDERPAYMENT"
export const ADD_SERVICE_ORDERPAYMENT_SUCCESS = "ADD_SERVICE_ORDERPAYMENT_SUCCESS"
export const ADD_SERVICE_ORDERPAYMENT_FAIL = "ADD_SERVICE_ORDERPAYMENT_FAIL"