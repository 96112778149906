import {
  TICKETCATEGORY_LOADING,
  GET_TICKETCATEGORY,
  GET_TICKETCATEGORY_SUCCESS,
  GET_TICKETCATEGORY_FAIL,
  ADD_TICKETCATEGORY_SUCCESS,
  ADD_TICKETCATEGORY_FAIL,
  DELETE_TICKETCATEGORY_SUCCESS,
  DELETE_TICKETCATEGORY_FAIL,
  UPDATE_TICKETCATEGORY_SUCCESS,
  UPDATE_TICKETCATEGORY_FAIL,
  TICKETCATEGORY_RESET,
} from "./actionType";

const INIT_STATE = {
  ticketCategories: [],
  error: {},
};

const TicketCategory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TICKETCATEGORY_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TICKETCATEGORY:
          return {
            ...state,
            ticketCategories: action.payload.data,
            isTicketCategoryCreated: false,
            isTicketCategorySuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_TICKETCATEGORY_FAIL:
      switch (action.payload.actionType) {
        case GET_TICKETCATEGORY_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isTicketCategoryCreated: false,
            isTicketCategorySuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_TICKETCATEGORY: {
      return {
        ...state,
        isTicketCategoryCreated: false,
        loading: true,
      };
    }

    case TICKETCATEGORY_LOADING: {
      return {
        ...state,
        isTicketCategoryCreated: false,
        loading: true,
      };
    }

    case ADD_TICKETCATEGORY_SUCCESS:
      return {
        ...state,
        isTicketCategoryCreated: true,
        loading: false,
        ticketCategories: [...state.ticketCategories, action.payload.data],
      };

    case ADD_TICKETCATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_TICKETCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketCategories: state.ticketCategories.filter(
          (ticketCategory) => ticketCategory.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_TICKETCATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_TICKETCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketCategories: state.ticketCategories.map((ticketCategory) =>
          ticketCategory.id.toString() === action.payload.data.id.toString()
            ? { ...ticketCategory, ...action.payload.data }
            : ticketCategory
        ),
      };

    case UPDATE_TICKETCATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TICKETCATEGORY_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default TicketCategory;
