import {
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  ADD_NEW_COMPANY,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  COMPANY_LOADING,
  COMPANY_RESET,
} from "./actionType";

export const resetCompanyState = (actionType) => ({
  type: COMPANY_RESET,
  payload: { actionType },
});

export const getCompanySuccess = (actionType, data) => ({
  type: GET_COMPANY_SUCCESS,
  payload: { actionType, data },
});

export const getCompanyFail = (actionType, error) => ({
  type: GET_COMPANY_FAIL,
  payload: { actionType, error },
});

export const getCompany = data => ({
  type: GET_COMPANY,
  payload: data,
});

export const getCompanyLoading = () => ({
  type: COMPANY_LOADING
});

export const deleteCompany = data => ({
  type: DELETE_COMPANY,
  payload: data,
});

export const deleteCompanySuccess = data => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: data,
});

export const deleteCompanyFail = error => ({
  type: DELETE_COMPANY_FAIL,
  payload: error,
});

export const updateCompany = data => ({
  type: UPDATE_COMPANY,
  payload: data,
});

export const updateCompanyFail = error => ({
  type: UPDATE_COMPANY_FAIL,
  payload: error,
});

export const updateCompanySuccess = data => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: data,
});

export const addNewCompany = data => ({
  type: ADD_NEW_COMPANY,
  payload: data,
});

export const addCompanySuccess = data => ({
  type: ADD_COMPANY_SUCCESS,
  payload: data,
});

export const addCompanyFail = error => ({
  type: ADD_COMPANY_FAIL,
  payload: error,
});