import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Table, Button, Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, Modal, ModalHeader, Form, ModalBody, Label, Input, FormFeedback, Spinner, Collapse } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import ApproveModal from "../../../Components/Common/ApproveModal";
import { isEmpty } from "lodash";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";

import Dropzone from "react-dropzone";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import AsyncSelect from "react-select/async";
// Formik
import * as Yup from "yup";
import { useFormik, Field, FieldArray } from "formik";
import FormData from "form-data";
//redux
import { useSelector, useDispatch } from "react-redux";

import { getClient, resetClientState } from "../../../store/clients/action";
import { resetCompanyState, getCompany } from "../../../store/company/action";
import { resetBranchState, getBranch } from "../../../store/branch/action";
import { resetUnitState, getUnit } from "../../../store/units/action";
//Import actions
import { resetContactPersonState, getContactPerson as onGetContactPersons, addNewContactPerson as onAddNewContactPerson, updateContactPerson as onUpdateContactPerson, deleteContactPerson as onDeleteContactPerson, getContactPersonLoading } from "../../../store/contactPerson/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultImg from "../../../assets/images/upload.png";

import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import ModalInputFile from "../../../Components/ModalInputFile";
import { api } from "../../../config";

import PropTypes from "prop-types";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const ContactPersons = (props) => {
  const [value, setValue] = useState();
  const [logo, setLogo] = useState(defaultImg);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);
  const [modalContactPersonnamePass, setModalContactPersonnamePass] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();

  const { companies, clients, contactPersons, isContactPersonCreated, isContactPersonSuccess, loading, error, branches, units, companyLoading, branchLoading, unitLoading } = useSelector((state) => ({
    contactPersons: state.ContactPerson.contactPersons,
    isContactPersonCreated: state.ContactPerson.isContactPersonCreated,
    isContactPersonSuccess: state.ContactPerson.isContactPersonSuccess,
    loading: state.ContactPerson.loading,
    error: state.ContactPerson.error,
    clients: state.Clients.clients,
    companies: state.Company.companies,
    companyLoading: state.Company.loading,
    branches: state.Branch.branches,
    branchLoading: state.Branch.loading,
    units: state.Units.units,
    unitLoading: state.Units.loading,
  }));

  const contactPersonTypes = [
    {
      options: [
        { label: "Client", value: "client" },
        { label: "Admin", value: "admin" },
        { label: "ContactPerson", value: "contactPerson" },
        { label: "Company", value: "company" },
      ],
    },
  ];

  const defaultdate = () => {
    return moment(new Date()).tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };
  const [filterDate, setFilterDate] = useState(defaultdate());
  const [contactPersonList, setContactPersonList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [contactPerson, setContactPerson] = useState([]);
  const [info, setInfo] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isContactPersonname, setIsContactPersonname] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [unitList, setUnitList] = useState([]);

  const [isExecutive, setIsExecutive] = useState(false);

  const [open, setOpen] = useState("2");
  const toggleAccordion = (id) => {
    if (open === id) {
      setOpen(open);
    } else {
      setOpen(id);
    }
  };

  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: "1" },
        { label: "Activated", value: "2" },
      ],
    },
  ];

  const onClickDelete = (contactPerson) => {
    setContactPerson(contactPerson);
    setDeleteModal(true);
  };

  const onClickApprove = (contactPerson) => {
    setContactPerson(contactPerson);
    setApproveModal(true);
  };

  const handleDeleteContactPerson = () => {
    if (contactPerson) {
      dispatch(onDeleteContactPerson(contactPerson));
      setDeleteModal(false);
    }
  };

  const handleApproveContactPerson = () => {
    if (contactPerson) {
      dispatch(
        onUpdateContactPerson({
          id: contactPerson.id,
          statusId: 2,
          isApproved: true,
        })
      );
      setApproveModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setContactPersonList(contactPersons);
  }, [contactPersons]);

  useEffect(() => {
    //if (contactPersons && !contactPersons.length && !isSearch) {
    if (!isSearch) {
      dispatch(resetContactPersonState());
      dispatch(onGetContactPersons());
      setIsSearch(true);
      const emp = JSON.parse(sessionStorage.getItem("authContactPerson"));
      // if (emp.data && emp.data.password && emp.data.password.contactPersonType === "executive") {
      //   setIsExecutive(true);
      // }
    }
  }, [dispatch, contactPersons, isSearch]);

  useEffect(() => {
    if (!isEmpty(contactPersons)) {
      setContactPerson(contactPersons);
      setContactPersonList(contactPersons);
      setInfo(contactPersons[contactPersons.length - 1]);
      setIsEdit(false);
    }
  }, [contactPersons]);

  const handleValidDate = (date) => {
    return moment(new Date(date), "YYYY/MM/DD").tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredContactPersons = contactPersons;
      if (type !== "all") {
        filteredContactPersons = contactPersons.filter((contactPerson) => contactPerson.contactPerson_status.name === type);
      }
      setContactPersonList(filteredContactPersons);
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContactPerson(null);
    } else {
      setModal(true);
    }
    if (!isExecutive) {
      //validation.setva
    }
  }, [modal, isExecutive]);

  const toggleMedia = useCallback(() => {
    if (modalMedia) {
      setModalMedia(false);
    } else {
      setModalMedia(true);
    }
  }, [modalMedia]);

  const toggleContactPersonnamePass = useCallback(() => {
    if (modalContactPersonnamePass) {
      setModalContactPersonnamePass(false);
      setContactPerson(null);
    } else {
      setModalContactPersonnamePass(true);
    }
  }, [modalContactPersonnamePass]);

  const handleContactPersonClick = useCallback(
    (arg, type) => {
      const contactPerson = arg;

      setContactPerson({
        id: contactPerson.id,
        name: contactPerson.name ?? "",
        email: contactPerson.email ?? "",
        phone: contactPerson.phone ?? "",
        description: contactPerson.description == null ? "" : contactPerson.description,
        client: {
          label: contactPerson.contact_person_client ? contactPerson.contact_person_client.name : "",
          value: contactPerson.contact_person_client ? contactPerson.contact_person_client.id : "",
        },
        company: {
          label: contactPerson.contact_person_company ? contactPerson.contact_person_company.name : "",
          value: contactPerson.contact_person_company ? contactPerson.contact_person_company.id : "",
        },
        branch: {
          label: contactPerson.contact_person_branch ? contactPerson.contact_person_branch.name : "",
          value: contactPerson.contact_person_branch ? contactPerson.contact_person_branch.id : "",
        },
        unit: {
          label: contactPerson.contact_person_unit ? contactPerson.contact_person_unit.name : "",
          value: contactPerson.contact_person_unit ? contactPerson.contact_person_unit.id : "",
        },
      });
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="dataCheckbox form-check-input" value={cellProps.row.original.id} />;
        },
        id: "#",
      },
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleContactPersonClick(data);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </Link>

              {cellProps.row.original.statusId === 1 ? (
                <button
                  className="btn-label btn btn-sm btn-soft-success fs-12 me-0"
                  onClick={() => {
                    const data = cellProps.row.original;
                    onClickApprove(data);
                  }}
                >
                  <i className="ri-checkbox-circle-line label-icon align-middle fs-16 me-1"></i>
                  Approve
                </button>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        Header: "Name",
        filterable: true,
        accessor: "name",
        Cell: (client) => (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <div className="flex-shrink-0 avatar-xs me-1">
                <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">{client.row.original.image !== null ? client.row.original.name.charAt(0).toUpperCase() : "-"}</div>
              </div>
            </div>
            <div className="flex-grow-1 ms-2 name">{client.row.original.image !== null ? client.row.original.name : "-"}</div>
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
      },
      {
        Header: "Phone",
        accessor: "phone",
        filterable: true,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: true,
      },
    ],
    [handleContactPersonClick]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: contactPersonList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable ? (column.isSorted ? (column.isSortedDesc ? " sorting_desc" : " sorting_asc") : " sort") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleFilter() {
    let startat = "";
    let endat = "";
    let datearr = filterDate.split(" to ");
    if (datearr[1]) {
      startat = datearr[0];
      endat = datearr[1];
    }

    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetContactPersons(param));
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (contactPerson && contactPerson.name) || "",
      email: (contactPerson && contactPerson.email) || "",
      phone: (contactPerson && contactPerson.phone) || "",
      description: (contactPerson && contactPerson.description) || "",
      client: (contactPerson && contactPerson.client) || "",
      company: (contactPerson && contactPerson.company) || "",
      branch: (contactPerson && contactPerson.branch) || "",
      unit: (contactPerson && contactPerson.unit) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      client: Yup.object().required("Please Enter Client"),
      company: Yup.object().required("Please Enter Company"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        let editDataSubmit = {
          id: contactPerson.id,
          name: values.name,
          email: values.email,
          phone: values.phone,
          description: values.description,
          clientId: values.client.value,
          companyId: values.company.value,
          branchId: values.branch.value,
          unitId: values.branch.value,
        };

        dispatch(onUpdateContactPerson(editDataSubmit));
        validation.resetForm();
        toggle();
      } else {
        let addDataSubmit = {
          name: values.name,
          email: values.email,
          phone: values.phone,
          description: values.description,
          clientId: values.client.value,
          companyId: values.company.value,
          branchId: values.branch.value,
          unitId: values.branch.value,
        };

        dispatch(onAddNewContactPerson(addDataSubmit));
        validation.resetForm();
        toggle();
      }
    },
  });
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteContactPerson(element.value));
    });
    checkall.checked = false;
  };

  useEffect(() => {
    if (!isEmpty(clients)) {
      var data = [];
      var opt = [];
      clients.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setClientList(data);
    }
  }, [clients]);

  useEffect(() => {
    dispatch(resetClientState());
    dispatch(getClient());
  }, [dispatch]);

  const handleSelectFile = (file) => {
    validation.setFieldValue("image", file.filename);
    toggleMedia();
  };

  useEffect(() => {
    var data = [];
    var opt = [];
    companies.forEach((item, key) => {
      const name = item.name;
      const id = item.id;
      var obj = { label: name, value: id };
      opt.push(obj);
    });
    var option = { options: opt };
    data.push(option);
    setCompanyList(data);
  }, [companies]);

  useEffect(() => {
    var data = [];
    var opt = [];
    branches.forEach((item, key) => {
      const name = item.name;
      const id = item.id;
      var obj = { label: name, value: id };
      opt.push(obj);
    });
    var option = { options: opt };
    data.push(option);
    setBranchList(data);
  }, [branches]);

  useEffect(() => {
    var data = [];
    var opt = [];
    units.forEach((item, key) => {
      const name = item.name;
      const id = item.id;
      var obj = { label: name, value: id };
      opt.push(obj);
    });
    var option = { options: opt };
    data.push(option);
    setUnitList(data);
  }, [units]);

  function handleCompany(e) {
    validation.setFieldValue("company", "");
    const param = { clientId: e };
    dispatch(resetCompanyState());
    dispatch(getCompany(param));
  }

  function handleBranch(e) {
    validation.setFieldValue("branch", "");
    const param = { companyId: e };
    dispatch(resetBranchState());
    dispatch(getBranch(param));
  }

  function handleUnit(e) {
    validation.setFieldValue("unit", "");
    const param = { branchId: e };
    dispatch(resetUnitState());
    dispatch(getUnit(param));
  }

  document.title = "Contact Persons | Aptek";

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={contactPersonList} />
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteContactPerson} onCloseClick={() => setDeleteModal(false)} />
      <ApproveModal data={contactPerson} show={approveModal} onApproveClick={handleApproveContactPerson} onCloseClick={() => setApproveModal(false)} />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title="Contact Persons" pageTitle="Contact Person" />
        <Row>
          <Col xxl={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Contact Person History &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color="primary" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className={"btn btn-primary add-btn"}
                      id="create-btn"
                      onClick={() => {
                        setIsEdit(false);
                        toggle();
                      }}
                    >
                      <i className="ri-building-line me-1 align-bottom fs-14"></i> Tambah
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className=" ri-group-line me-1 align-bottom fs-14"></i> All Contact Persons
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className="mb-3">
                    <CardBody className="border border-top-0 border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={1} className="col-xxl-1">
                            <div className="me-2 mb-3 col-12">
                              <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="search-box me-2 mb-3 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${count} Search...`}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col sm={6} className="col-xxl-3">
                            <div className="search-box me-2 mb-3 col-12">
                              <Flatpickr
                                className="form-control"
                                id="datepicker-publish-input"
                                placeholder="Select join date"
                                options={{
                                  mode: "range",
                                  dateFormat: "Y/m/d",
                                }}
                                onChange={(selectedDates, dateStr) => {
                                  setFilterDate(dateStr);
                                }}
                              />
                              <i className="bx bx-calendar-alt search-icon"></i>
                            </div>
                          </Col>

                          <Col className="col-xxl-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-light w-100"
                                onClick={() => {
                                  handleFilter();
                                }}
                              >
                                <i className="ri-search-line me-1 align-bottom fs-14"></i> Search
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card mb-1">
                    <Table hover {...getTableProps()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroups.map((headerGroup) => (
                          <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={previousPage} disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    go
                    <Col className="col-md-auto d-none d-md-block">
                      {" "}
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
                    </Col>
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={nextPage} disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal id="showModal" isOpen={modal} centered fullscreen={false} size="lg">
                  <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                      toggle();
                      validation.resetForm();
                      validation.setErrors({});
                    }}
                  >
                    {!!isEdit ? "Edit Data Contact Person" : "Tambah Data Contact Person"}
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="password-field" className="form-label">
                            {" "}
                            Client{" "}
                          </Label>
                          <Select
                            name="client"
                            value={validation.values.client}
                            placeholder="Enter Client"
                            onChange={(e) => {
                              validation.setFieldValue("client", e);
                              handleCompany(e.value);
                            }}
                            validate={{ required: { value: true } }}
                            options={clientList}
                            invalid={validation.touched.client && validation.errors.client ? true : false}
                          ></Select>
                          {validation.touched.client && validation.errors.client ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.client}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="company" className="form-label">
                            {props.t("Company")}{" "}
                            {companyLoading && (
                              <Spinner color="primary" size="sm">
                                Loading...
                              </Spinner>
                            )}
                          </Label>
                          <Select
                            name="company"
                            isDisabled={validation.values.client !== "" ? false : true}
                            value={validation.values.company}
                            validate={{ required: { value: true } }}
                            placeholder="Select Company"
                            onChange={(e) => {
                              validation.setFieldValue("company", e);
                              handleBranch(e.value);
                            }}
                            options={companyList}
                            invalid={validation.touched.company && validation.errors.company ? true : false}
                          ></Select>
                          {validation.touched.company && validation.errors.company ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.company}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="branch" className="form-label">
                            {props.t("Branch")}{" "}
                            {branchLoading && (
                              <Spinner color="primary" size="sm">
                                Loading...
                              </Spinner>
                            )}
                          </Label>
                          <Select
                            name="company"
                            isDisabled={validation.values.company !== "" ? false : true}
                            value={validation.values.branch}
                            validate={{ required: { value: true } }}
                            placeholder="Select Branch"
                            onChange={(e) => {
                              validation.setFieldValue("branch", e);
                              handleUnit(e.value);
                            }}
                            options={branchList}
                            invalid={validation.touched.branch && validation.errors.branch ? true : false}
                          ></Select>
                          {validation.touched.branch && validation.errors.branch ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.branch}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="unit" className="form-label">
                            {props.t("Unit")}{" "}
                            {unitLoading && (
                              <Spinner color="primary" size="sm">
                                Loading...
                              </Spinner>
                            )}
                          </Label>
                          <Select
                            name="unit"
                            isDisabled={validation.values.branch !== "" ? false : true}
                            value={validation.values.unit}
                            validate={{ required: { value: true } }}
                            placeholder="Select Unit"
                            onChange={(e) => {
                              validation.setFieldValue("unit", e);
                            }}
                            options={unitList}
                            invalid={validation.touched.unit && validation.errors.unit ? true : false}
                          ></Select>
                          {validation.touched.unit && validation.errors.unit ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.unit}
                            </p>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="name-field" className="form-label">
                            Nama <span className="text-danger">*</span>
                          </Label>
                          <Input name="name" id="name-field" className="form-control" placeholder="Enter Name" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.name || ""} invalid={validation.touched.name && validation.errors.name ? true : false} />
                          {validation.touched.name && validation.errors.name ? <FormFeedback type="invalid">{validation.errors.name}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="email-field" className="form-label">
                            Email <span className="text-danger">*</span>
                          </Label>
                          <Input name="email" id="email-field" className="form-control" placeholder="Enter Email" type="email" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.email || ""} invalid={validation.touched.email && validation.errors.email ? true : false} />
                          {validation.touched.email && validation.errors.email ? <FormFeedback type="invalid">{validation.errors.email}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="phone-field" className="form-label">
                            Phone Number <span className="text-danger">*</span>
                          </Label>
                          <Input name="phone" id="phone-field" className="form-control" placeholder="Enter Phone" type="nu" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.phone || ""} invalid={validation.touched.phone && validation.errors.phone ? true : false} />
                          {validation.touched.phone && validation.errors.phone ? <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="description-field" className="form-label">
                            Description
                          </Label>
                          <Input name="description" id="description-field" className="form-control" placeholder="Enter Description" type="textarea" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.description || ""} invalid={validation.touched.description && validation.errors.description ? true : false} style={isEdit ? { minHeight: "100px" } : { minHeight: " 100px" }} />
                        </div>
                      </Col>
                    </Row>
                  </ModalBody>
                  <div className="modal-footer mb3">
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          setModal(false);
                          validation.resetForm();
                          validation.setErrors({});
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          validation.handleSubmit();
                        }}
                        type="submit"
                        className="btn btn-success"
                      >
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">Save</span>
                          {loading ? (
                            <Spinner size="sm" className="flex-shrink-0" role="status">
                              Loading...
                            </Spinner>
                          ) : (
                            ""
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </Modal>
                <Modal id="showModal" isOpen={modalContactPersonnamePass} centered fullscreen={false} size="md">
                  <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                      toggleContactPersonnamePass();
                      validation.resetForm();
                      validation.setErrors({});
                    }}
                  >
                    {!!isContactPersonname ? "Edit ContactPersonname" : "Edit Password"}
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col lg={12}>
                        {isContactPersonname ? (
                          <div className="mb-3">
                            <Label htmlFor="name-field" className="form-label">
                              ContactPersonname <span className="text-danger">*</span>
                            </Label>
                            <Input name="name" id="name-field" className="form-control" placeholder="Enter ContactPersonname" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.name || ""} invalid={validation.touched.name && validation.errors.name ? true : false} />
                            {validation.touched.name && validation.errors.name ? <FormFeedback type="invalid">{validation.errors.name}</FormFeedback> : null}
                          </div>
                        ) : (
                          <div className="mb-3">
                            <Label htmlFor="password-field" className="form-label">
                              Password <span className="text-danger">*</span>
                            </Label>
                            <Input name="password" id="password-field" className="form-control" placeholder="Enter Password" type="password" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.password || ""} invalid={validation.touched.password && validation.errors.password ? true : false} />
                            {validation.touched.password && validation.errors.password ? <FormFeedback type="invalid">{validation.errors.password}</FormFeedback> : null}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </ModalBody>
                  <div className="modal-footer mb3">
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          setModal(false);
                          validation.resetForm();
                          validation.setErrors({});
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          validation.handleSubmit();
                        }}
                        type="submit"
                        className="btn btn-success"
                      >
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">Save</span>
                          {loading ? (
                            <Spinner size="sm" className="flex-shrink-0" role="status">
                              Loading...
                            </Spinner>
                          ) : (
                            ""
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </Modal>
                <ModalInputFile onSelect={handleSelectFile} loading={loading} modal={modalMedia} toggle={toggleMedia} />
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ContactPersons.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ContactPersons));
