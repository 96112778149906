import {
  GET_TASK,
  GET_TASK_SUCCESS,
  GET_TASK_FAIL,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAIL,
  UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAIL,
  ADD_NEW_TASK,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAIL,
  TASK_LOADING,
  TASK_RESET,
  GET_PUBLIC_TASK,
  GET_TASK_PUBLIC_SUCCESS,
  GET_TASK_PUBLIC_FAIL,
} from "./actionType";

export const resetTaskState = (actionType) => ({
  type: TASK_RESET,
  payload: { actionType },
});

export const getTaskSuccess = (actionType, data) => ({
  type: GET_TASK_SUCCESS,
  payload: { actionType, data },
});

export const getTaskFail = (actionType, error) => ({
  type: GET_TASK_FAIL,
  payload: { actionType, error },
});

export const getTask = (data) => ({
  type: GET_TASK,
  payload: data,
});

export const getPublicTask = (data) => ({
  type: GET_PUBLIC_TASK,
  payload: data,
});

export const getTaskPublicSuccess = (actionType, data) => ({
  type: GET_TASK_PUBLIC_SUCCESS,
  payload: { actionType, data },
});

export const getTaskPublicFail = (actionType, error) => ({
  type: GET_TASK_PUBLIC_FAIL,
  payload: { actionType, error },
});

export const getTaskLoading = () => ({
  type: TASK_LOADING,
});

export const deleteTask = (data) => ({
  type: DELETE_TASK,
  payload: data,
});

export const deleteTaskSuccess = (data) => ({
  type: DELETE_TASK_SUCCESS,
  payload: data,
});

export const deleteTaskFail = (error) => ({
  type: DELETE_TASK_FAIL,
  payload: error,
});

export const updateTask = (data) => ({
  type: UPDATE_TASK,
  payload: data,
});

export const updateTaskFail = (error) => ({
  type: UPDATE_TASK_FAIL,
  payload: error,
});

export const updateTaskSuccess = (data) => ({
  type: UPDATE_TASK_SUCCESS,
  payload: data,
});

export const addNewTask = (data) => ({
  type: ADD_NEW_TASK,
  payload: data,
});

export const addTaskSuccess = (data) => ({
  type: ADD_TASK_SUCCESS,
  payload: data,
});

export const addTaskFail = (error) => ({
  type: ADD_TASK_FAIL,
  payload: error,
});
