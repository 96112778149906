import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY,
  ADD_NEW_COMPANY,
} from "./actionType";

import {
  getCompanySuccess,
  getCompanyFail,
  deleteCompanySuccess,
  deleteCompanyFail,
  updateCompanySuccess,
  updateCompanyFail,
  addCompanySuccess,
  addCompanyFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getCompanyApi,
  updateCompanyApi,
  deleteCompanyApi,
  addNewCompanyApi
} from "../../helpers/backend_helper";

function* getCompany({ payload: company }) {
  try {
    const response = yield call(getCompanyApi, company);
    yield put(getCompanySuccess(GET_COMPANY, response.data));
  } catch (error) {
    yield put(getCompanyFail(GET_COMPANY, error));
  }
}

function* onUpdateCompany({ payload: company }) {
  try {
    const response = yield call(updateCompanyApi, company);
    yield put(updateCompanySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateCompanyFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteCompany({ payload: company }) {
  try {
    const response = yield call(deleteCompanyApi, company);
    yield put(deleteCompanySuccess({ company, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteCompanyFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewCompany({ payload: company }) {
  try {
    const response = yield call(addNewCompanyApi, company);
    yield put(addCompanySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addCompanyFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetCompany() {
  yield takeEvery(GET_COMPANY, getCompany);
}

export function* watchUpdateCompany() {
  yield takeEvery(UPDATE_COMPANY, onUpdateCompany);
}

export function* watchDeleteCompany() {
  yield takeEvery(DELETE_COMPANY, onDeleteCompany);
}

export function* watchAddNewCompany() {
  yield takeEvery(ADD_NEW_COMPANY, onAddNewCompany);
}

function* companySaga() {
  yield all([
    fork(watchGetCompany),
    fork(watchDeleteCompany),
    fork(watchUpdateCompany),
    fork(watchAddNewCompany)
  ]);
}

export default companySaga;
