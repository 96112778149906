/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Table,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback,
  Spinner,
  Collapse,
} from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import ApproveModal from "../../Components/Common/ApproveModal";
import { isEmpty } from "lodash";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Dropzone from "react-dropzone";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// Formik
import * as Yup from "yup";
import { useFormik, Field, FieldArray } from "formik";
import FormData from "form-data";
//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import {
  resetAppState,
  getApp as onGetApps,
  addNewApp as onAddNewApp,
  updateApp as onUpdateApp,
  deleteApp as onDeleteApp,
  getAppLoading,
} from "../../store/app/action";
import { getClient, resetClientState } from "../../store/clients/action";
import { getService, resetServiceState } from "../../store/service/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import defaultImg from "../../assets/images/upload.png";
import ModalInputFile from "./ModalInputFile";
import { api } from "../../config";

import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const authUser = JSON.parse(sessionStorage.getItem("authUser"));

const Apps = (props) => {
  const [logo, setLogo] = useState(defaultImg);
  const [appImg, setAppImg] = useState("");
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [clientList, setClientList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const dispatch = useDispatch();

  const {
    apps,
    clients,
    services,
    isAppCreated,
    isAppSuccess,
    loading,
    error,
  } = useSelector((state) => ({
    apps: state.Apps.apps,
    isAppCreated: state.Apps.isAppCreated,
    isAppSuccess: state.Apps.isAppSuccess,
    loading: state.Apps.loading,
    error: state.Apps.error,
    clients: state.Clients.clients,
    services: state.Services.services,
  }));

  const appTypes = [
    {
      options: [
        { label: "App", value: "app" },
        { label: "Executive", value: "executive" },
      ],
    },
  ];

  const defaultdate = () => {
    return moment(new Date())
      .tz("Asia/Jakarta")
      .format("YYYY/MM/DD HH:mm:ss")
      .toString();
  };

  const [filterDate, setFilterDate] = useState(defaultdate());
  const [appList, setAppList] = useState([]);
  const [app, setApp] = useState([]);
  const [info, setInfo] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [isExecutive, setIsExecutive] = useState(false);

  const [open, setOpen] = useState("2");
  const toggleAccordion = (id) => {
    if (open === id) {
      setOpen(open);
    } else {
      setOpen(id);
    }
  };

  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: "1" },
        { label: "Activated", value: "2" },
      ],
    },
  ];

  const onClickDelete = (app) => {
    setApp(app);
    setDeleteModal(true);
  };

  const onClickApprove = (app) => {
    setApp(app);
    setApproveModal(true);
  };

  const handleDeleteApp = () => {
    if (app) {
      dispatch(onDeleteApp(app));
      setDeleteModal(false);
    }
  };

  const handleApproveApp = () => {
    if (app) {
      app.setApprove = true;
      dispatch(onUpdateApp(app));
      setApproveModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setAppList(apps);
  }, [apps]);

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetAppState());
      dispatch(onGetApps());
      setIsSearch(true);
    }
  }, [dispatch, apps, isSearch]);

  useEffect(() => {
    if (!isEmpty(apps)) {
      setApp(apps);
      setAppList(apps);
      setInfo(apps[apps.length - 1]);
      setIsEdit(false);
    }
  }, [apps]);

  useEffect(() => {
    appImg, "appImg";
  });

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredApps = apps;
      if (type !== "all") {
        filteredApps = apps.filter((app) => app.app_status.name === type);
      }
      setAppList(filteredApps);
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setApp(null);
    } else {
      setModal(true);
    }
    if (!isExecutive) {
      //validation.setva
    }
  }, [modal, isExecutive]);

  const toggleMedia = useCallback(() => {
    if (modalMedia) {
      setModalMedia(false);
    } else {
      setModalMedia(true);
    }
  }, [modalMedia]);

  const handleAppClick = useCallback(
    (arg) => {
      const app = arg;

      setApp({
        id: app.id,
        name: app.name ?? "",
        redirect: app.redirect ?? "",
        status:
          app.app_status === null
            ? ""
            : {
                label: app.app_status.name,
                value: app.app_status.id,
              },
        service:
          app.app_service === null
            ? ""
            : {
                label: app.app_service.name,
                value: app.app_service.id,
              },
        client:
          app.app_client === null
            ? ""
            : {
                label: app.app_client.name,
                value: app.app_client.id,
              },
        description: app.description ?? "",
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type='checkbox'
            id='checkBoxAll'
            className='form-check-input'
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type='checkbox'
              className='dataCheckbox form-check-input'
              value={cellProps.row.original.id}
            />
          );
        },
        id: "#",
      },
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className='hstack gap-2 mt-4 mt-sm-0'>
              <Link
                to='#'
                className='btn-icon btn btn-sm btn-soft-primary edit-item-btn'
                onClick={() => {
                  const data = cellProps.row.original;
                  handleAppClick(data);
                }}>
                <i className='ri-pencil-line fs-16'></i>
              </Link>
              {cellProps.row.original.statusActiveId === 1 ? (
                <button
                  className='btn-label btn btn-sm btn-soft-success fs-12 me-0'
                  onClick={() => {
                    const data = cellProps.row.original;
                    onClickApprove(data);
                  }}>
                  <i className='ri-checkbox-circle-line label-icon align-middle fs-16 me-1'></i>
                  Approve
                </button>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        Header: `${props.t("Name")}`,
        filterable: true,
        accessor: "name",
      },
      {
        Header: `${props.t("Redirect")}`,
        filterable: true,
        accessor: "redirect",
      },
      {
        Header: "Status",
        accessor: "app_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.row.original.statusId) {
            case 1:
              return (
                <span className='badge text-uppercase badge-soft-warning'>
                  Draft
                </span>
              );
            case 2:
              return (
                <span className='badge text-uppercase badge-soft-info'>
                  Activated
                </span>
              );
            default:
              return (
                <span className='badge text-uppercase badge-soft-warning'>
                  Draft
                </span>
              );
          }
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleAppClick]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: appList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable
      ? column.isSorted
        ? column.isSortedDesc
          ? " sorting_desc"
          : " sorting_asc"
        : " sort"
      : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleFilter() {
    let startat = "";
    let endat = "";
    let datearr = filterDate.split(" to ");
    if (datearr[1]) {
      startat = datearr[0];
      endat = datearr[1];
    }

    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetApps(param));
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (app && app.name) || "",
      client: (app && app.client) || "",
      status: (app && app.status) || "",
      service: (app && app.service) || "",
      description: (app && app.description) || "",
      redirect: (app && app.redirect) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      status: Yup.object().required("Please Enter Status"),
      // client: Yup.object().required("Please Select Client"),
      // service: Yup.object().required("Please Select Service"),
      redirect: Yup.string().required("Please Select Redirect"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        let editDataSubmit = {
          id: app.id,
          name: values.name,
          serviceId: values.service.value,
          statusId: values.status.value,
          clientId: values.client.value,
          description: values.description,
          redirect: values.redirect,
        };

        editDataSubmit;

        dispatch(onUpdateApp(editDataSubmit));
      } else {
        let addDataSubmit = {
          name: values.name,
          serviceId: values.service.value,
          statusId: values.status.value,
          clientId: values.client.value,
          description: values.description,
          redirect: values.redirect,
        };

        addDataSubmit;

        dispatch(onAddNewApp(addDataSubmit));
      }
      toggle();
      validation.resetForm();
    },
  });
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteApp(element.value));
    });
    checkall.checked = false;
  };

  const handleSelectFile = (file) => {
    file.filename;
    setAppImg(file.filename);
    toggleMedia();
  };

  useEffect(() => {
    dispatch(resetClientState());
    dispatch(getClient());
    dispatch(resetServiceState());
    dispatch(getService());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(clients)) {
      var data = [];
      var opt = [];
      clients.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setClientList(data);
    }
  }, [clients]);

  useEffect(() => {
    if (!isEmpty(services)) {
      var data = [];
      var opt = [];
      services.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setServiceList(data);
    }
  }, [services]);

  document.title = `${props.t("App")} | Aptek`;

  return (
    <div className='page-content'>
      <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={appList}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteApp}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ApproveModal
        data={app && app.appname ? app.appname : ""}
        show={approveModal}
        onApproveClick={handleApproveApp}
        onCloseClick={() => setApproveModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={props.t("App")} pageTitle={props.t("App")} />
        <Row>
          <Col xxl={12}>
            <Card>
              <CardHeader className='card-header border-0'>
                <div className='d-flex align-items-center'>
                  <h5 className='card-title mb-0 flex-grow-1'>
                    {props.t("App History")} &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color='primary' type='grow' size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color='success' type='grow' size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className='flex-shrink-0'>
                    <button
                      type='button'
                      className={"btn btn-primary add-btn"}
                      id='create-btn'
                      onClick={() => {
                        setIsEdit(false);
                        toggle();
                      }}>
                      <i className='ri-building-line me-1 align-bottom fs-14'></i>{" "}
                      Tambah
                    </button>
                    {/* <button type="button" className="btn btn-success" onClick={() => setIsExportCSV(true)}>
                      <i className="ri-file-download-line me-1 align-bottom fs-14"></i> Export
                    </button>{" "}
                    <button className={userType === "executive" ? "btn btn-soft-danger" : roles && roles.split(",").indexOf("deleteApp") !== -1 ? "btn btn-soft-danger" : "btn btn-soft-danger d-none"} onClick={() => setDeleteModalMulti(true)}>
                      <i className="ri-delete-bin-5-line me-1 align-bottom fs-15"></i> Hapus
                    </button> */}
                  </div>
                </div>
              </CardHeader>
              <CardBody className='pt-0'>
                <div>
                  <Nav
                    className='nav-tabs nav-tabs-custom nav-success'
                    role='tablist'>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href='#'>
                        <i className=' ri-group-line me-1 align-bottom fs-14'></i>{" "}
                        All Apps
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "2" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("2", "Draft");
                        }}
                        href='#'>
                        <i className='ri-information-line me-1 align-bottom fs-14'></i>{" "}
                        Draft
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "2" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("3", "Activated");
                        }}
                        href='#'>
                        <i className='ri-checkbox-circle-line me-1 align-bottom fs-14'></i>{" "}
                        Activated
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className='mb-3'>
                    <CardBody className='border border-top-0 border-end-0 border-start-0'>
                      <form>
                        <Row>
                          <Col sm={1} className='col-xxl-1'>
                            <div className='me-2 mb-3 col-12'>
                              <select
                                className='form-select'
                                value={pageSize}
                                onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className='search-box me-2 mb-3 d-inline-block col-12'>
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id='search-bar-0'
                                type='text'
                                className='form-control search'
                                placeholder={`${count} Search...`}
                                value={searchValue || ""}
                              />
                              <i className='bx bx-search-alt search-icon'></i>
                            </div>
                          </Col>
                          <Col sm={6} className='col-xxl-3'>
                            <div className='search-box me-2 mb-3 col-12'>
                              <Flatpickr
                                className='form-control'
                                id='datepicker-publish-input'
                                placeholder='Select join date'
                                options={{
                                  mode: "range",
                                  dateFormat: "Y/m/d",
                                }}
                                onChange={(selectedDates, dateStr) => {
                                  setFilterDate(dateStr);
                                }}
                              />
                              <i className='bx bx-calendar-alt search-icon'></i>
                            </div>
                          </Col>

                          <Col className='col-xxl-2'>
                            <div>
                              <button
                                type='button'
                                className='btn btn-light w-100'
                                onClick={() => {
                                  handleFilter();
                                }}>
                                <i className='ri-search-line me-1 align-bottom fs-14'></i>{" "}
                                {props.t("Search")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className='table-responsive table-card mb-1'>
                    <Table
                      hover
                      {...getTableProps()}
                      className='align-middle table-nowrap'>
                      <thead className='table-light text-muted'>
                        {headerGroups.map((headerGroup) => (
                          <tr
                            key={headerGroup.id}
                            {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                key={column.id}
                                className={"" + generateFilterable(column)}
                                {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                    <Col className='col-md-auto'>
                      <div className='d-flex gap-1'>
                        <Button
                          className='btn btn-light'
                          onClick={previousPage}
                          disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className='col-md-auto d-none d-md-block'>
                      {" "}
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className='col-md-auto'>
                      <Input
                        type='number'
                        min={1}
                        style={{ width: 70 }}
                        max={pageOptions.length}
                        defaultValue={pageIndex + 1}
                        onChange={onChangeInInput}
                      />
                    </Col>
                    <Col className='col-md-auto'>
                      <div className='d-flex gap-1'>
                        <Button
                          className='btn btn-light'
                          onClick={nextPage}
                          disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal
                  id='showModal'
                  isOpen={modal}
                  centered
                  fullscreen={false}
                  size='md'>
                  <ModalHeader
                    className='bg-light p-3'
                    toggle={() => {
                      toggle();
                      validation.resetForm();
                      setAppImg("");
                      validation.setErrors({});
                    }}>
                    {!!isEdit
                      ? `${props.t("Edit")} ${props.t("App")}`
                      : `${props.t("Add")} ${props.t("App")}`}
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col lg={12}>
                        <div className='mb-3'>
                          <Label htmlFor='name-field' className='form-label'>
                            {props.t("Nama")}{" "}
                            <span className='text-danger'>*</span>
                          </Label>
                          <Input
                            name='name'
                            id='name-field'
                            className='form-control'
                            placeholder='Enter Name'
                            type='text'
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                        {/* <div className="mb-3">
                          <Label htmlFor="password-field" className="form-label">
                            {props.t("Client")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="client"
                            value={validation.values.client}
                            placeholder="Enter Client"
                            onChange={(e) => {
                              validation.setFieldValue("client", e);
                            }}
                            options={clientList}
                            validate={{ required: { value: true } }}
                            invalid={validation.touched.client && validation.errors.client ? true : false}
                          ></Select>
                          {validation.touched.client && validation.errors.client ? (
                            <p className="text-danger" type="invalid">
                              {validation.errors.client}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="password-field" className="form-label">
                            {props.t("Service")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="service"
                            value={validation.values.service}
                            placeholder="Enter Service"
                            onChange={(e) => {
                              validation.setFieldValue("service", e);
                            }}
                            validate={{ required: { value: true } }}
                            options={serviceList}
                            invalid={validation.touched.service && validation.errors.service ? true : false}
                          ></Select>
                          {validation.touched.service && validation.errors.service ? (
                            <p className="text-danger" type="invalid">
                              {validation.errors.service}
                            </p>
                          ) : null}
                        </div> */}
                        <div className='mb-3'>
                          <Label
                            htmlFor='redirect-field'
                            className='form-label'>
                            {props.t("Redirect")}{" "}
                            <span className='text-danger'>*</span>
                          </Label>
                          <Input
                            name='redirect'
                            id='redirect-field'
                            className='form-control'
                            placeholder='Enter Redirect'
                            type='text'
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.redirect || ""}
                            invalid={
                              validation.touched.redirect &&
                              validation.errors.redirect
                                ? true
                                : false
                            }
                          />
                          <small className='text-success' type='invalid'>
                            * eg. http://localhost:port
                          </small>
                          {validation.touched.redirect &&
                          validation.errors.redirect ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.redirect}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className='mb-3'>
                          <Label htmlFor='active-status' className='form-label'>
                            {props.t("Status")}{" "}
                            <span className='text-danger'>*</span>
                          </Label>
                          <Select
                            name='status'
                            value={validation.values.status}
                            validate={{ required: { value: true } }}
                            placeholder='Select Status'
                            onChange={(e) => {
                              validation.setFieldValue("status", e);
                            }}
                            options={statusActiveData}
                            invalid={
                              validation.touched.status &&
                              validation.errors.status
                                ? true
                                : false
                            }></Select>
                          {validation.touched.status &&
                          validation.errors.status ? (
                            <p className='text-danger' type='invalid'>
                              {validation.errors.status}
                            </p>
                          ) : null}
                        </div>
                        <div className='mb-3'>
                          <Label
                            htmlFor='description-field'
                            className='form-label'>
                            {props.t("Description")}
                          </Label>
                          <Input
                            name='description'
                            id='description-field'
                            className='form-control'
                            placeholder='Enter Description'
                            type='textarea'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={
                              validation.touched.description &&
                              validation.errors.description
                                ? true
                                : false
                            }
                          />
                          {/* {validation.touched.description && validation.errors.description ? <FormFeedback type="invalid">{validation.errors.description}</FormFeedback> : null} */}
                        </div>
                      </Col>
                    </Row>
                  </ModalBody>
                  <div className='modal-footer mb3'>
                    <div className='hstack gap-2 justify-content-end'>
                      <button
                        type='button'
                        className='btn btn-light'
                        onClick={() => {
                          setModal(false);
                          validation.resetForm();
                          validation.setErrors({});
                          setAppImg("");
                        }}>
                        {props.t("Cancel")}
                      </button>
                      <button
                        onClick={() => {
                          validation.handleSubmit();
                        }}
                        type='submit'
                        className='btn btn-success'>
                        <span className='d-flex align-items-center'>
                          <span className='flex-grow-1 me-2'>
                            {props.t("Save")}
                          </span>
                          {loading ? (
                            <Spinner
                              size='sm'
                              className='flex-shrink-0'
                              role='status'>
                              Loading...
                            </Spinner>
                          ) : (
                            ""
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </Modal>
                <ModalInputFile
                  onSelect={handleSelectFile}
                  loading={loading}
                  modal={modalMedia}
                  toggle={toggleMedia}
                />
                {/* <Modal id="showModalMedia" isOpen={modalMedia} centered size="xl">
                  <ModalHeader className="bg-light p-3" toggle={toggleMedia}>
                    Media File
                  </ModalHeader>
                  <ModalBody>
                    <div className="row">
                      <div className="col-lg-8">
                        <di>
                          <div className="search-box me-2 mb-3 d-inline-block col-12">
                            <input
                              onChange={(e) => {
                                // setSearchValue(e.target.value);
                                // onChange(e.target.value);
                              }}
                              id="search-bar-0"
                              type="text"
                              className="form-control search"
                              placeholder={`${199} Search...`}
                              value={""}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </di>
                        <div style={{ maxHeight: "500px", overflowY: "scroll", overflowX: "hidden" }}>
                          <Card className="mt-1 mb-0 me-2 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="py-2 px-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <div className="me-2">
                                    <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" />
                                  </div>
                                  <div>
                                    <p className="mb-0">
                                      <strong>{"f.formattedSize"}</strong>
                                    </p>
                                    <p className="text-muted font-weight-bold">{"f.name"}</p>
                                  </div>
                                </div>
                                <div>
                                  <Link
                                    to="#"
                                    className={"btn-icon btn btn-sm btn-soft-danger remove-item-btn"}
                                    onClick={() => {
                                      // const appData = cellProps.row.original;
                                      // onClickDelete(appData);
                                    }}
                                  >
                                    <i className=" ri-delete-bin-line fs-15"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-3">
                          <Dropzone
                            multiple={true}
                            onDrop={(acceptedFiles) => {
                              // handleAcceptedFiles(acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone dz-clickable" style={{ cursor: "pointer", height: "140px" }} {...getRootProps({ className: "dropzone" })}>
                                <div className="dz-message needsclick">
                                  <div>
                                    <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                    <input {...getInputProps()} />
                                  </div>
                                  <h5>Drop employee document here.</h5>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <div className="modal-footer">
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          toggleMedia();
                        }}
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-success" disabled={loading}>
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">{"Tambah Karyawan"}</span>
                          {loading && (
                            <Spinner size="sm" className="flex-shrink-0" role="status">
                              Loading...
                            </Spinner>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </Modal> */}
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Apps.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Apps));
