import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { Table, Button, Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, Modal, ModalHeader, Form, ModalBody, Label, Input, FormFeedback, Spinner } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";

import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

import Flatpickr from "react-flatpickr";
import Select from "react-select";
import AsyncSelect from "react-select/async";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import { getClient, resetClientState } from "../../store/clients/action";
import { resetCompanyState, getCompany } from "../../store/company/action";
// import { getVillage, resetVillageState } from "../../store/village/action";
import { resetBranchState, getBranch as onGetBranches, addNewBranch as onAddNewBranch, updateBranch as onUpdateBranch, deleteBranch as onDeleteBranch, getBranchLoading, getBranch } from "../../store/branch/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const Branch = (props) => {
  const { t } = props;
  const [placeValue, setPlaceValue] = useState("");
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();

  const { clients, branches, isBranchCreated, isBranchSuccess, loading, error, companies, companyLoading } = useSelector((state) => ({
    branches: state.Branch.branches,
    isBranchCreated: state.Branch.isBranchCreated,
    isBranchSuccess: state.Branch.isBranchSuccess,
    loading: state.Branch.loading,
    companies: state.Company.companies,
    clients: state.Clients.clients,
    companyLoading: state.Company.loading,
    error: state.Branch.error,
  }));

  const [branchList, setBranchList] = useState([]);
  const [branch, setBranch] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);

  const [companyList, setCompanyList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [parentList, setParentList] = useState([]);
  const [companyName, setCompanyName] = useState({});

  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: "1" },
        { label: "Activated", value: "2" },
      ],
    },
  ];

  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [roles, setRoles] = useState(null);
  const [userType, setUserType] = useState("user");

  const onClickDelete = (branch) => {
    setBranch(branch);
    setDeleteModal(true);
  };

  const handleDeleteBranch = () => {
    if (branch) {
      dispatch(onDeleteBranch(branch));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setBranchList(branches);
  }, [branches]);

  useEffect(() => {
    if (!isEmpty(branches)) {
      setBranchList(branches);
    }
  }, [branches]);

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredBranches = branches;
      if (type !== "all") {
        filteredBranches = branches.filter((branch) => branch.branch_status.name === type);
      }
      setBranchList(filteredBranches);
    }
  };

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetBranchState());
      dispatch(onGetBranches());
      setIsSearch(true);
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      if (obj.data && obj.data.employee_position && obj.data.employee_position.position_role) {
        setCompanyName({
          label: obj.data.employee_company.name,
          value: obj.data.employee_company.id,
        });
        setRoles(obj.data.employee_position.position_role.role_method);
      } else {
        setRoles(null);
      }
      if (obj.data && obj.data.employee_user) setUserType(obj.data.employee_user.userType);
    }
  }, [dispatch, branches, isSearch]);

  useEffect(() => {
    setBranch(branches);
  }, [branches]);

  useEffect(() => {
    if (!isEmpty(branches)) {
      setBranch(branches);
      setIsEdit(false);
    }
  }, [branches]);

  useEffect(() => {
    setBranchList(branches);
  }, [branches]);

  useEffect(() => {
    if (!isEmpty(branches)) setBranchList(branches);
  }, [branches]);

  // useEffect(() => {
  //   if (companies && !companies.length) {
  //     dispatch(resetCompanyState());
  //     dispatch(getCompany());
  //   }
  // }, [dispatch, companies]);

  function handleCompany(e) {
    const param = { clientId: e };
    dispatch(getCompany(param));
  }

  const handleGetParentBranch = (id) => {
    var data = [];
    var opt = [];
    branches.forEach((item, key) => {
      if (item.companyId === id) {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      }
    });
    var option = { options: opt };
    data.push(option);

    if (opt.length > 0) {
      setParentList(data);
    } else {
      setParentList([]);
    }
  };

  useEffect(() => {
    if (!isEmpty(companies)) {
      var data = [];
      var opt = [];
      companies.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setCompanyList(data);
    }
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(clients)) {
      var data = [];
      var opt = [];
      clients.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setClientList(data);
    }
  }, [clients]);

  //   const promiseOptions = (e) => {
  //     if (!villageLoading) {
  //       dispatch(resetVillageState());
  //       setVillageList([]);
  //       if (e.length > 3) {
  //         dispatch(getVillage({ title: e }));
  //       }
  //       return new Promise((resolve, reject) => {
  //         while (villageLoading);
  //         resolve(villageList);
  //       });
  //     }
  //   };

  //   useEffect(() => {
  //     if (!isEmpty(villages)) {
  //       var villageData = [];
  //       var opt = [];
  //       villages.forEach((item, key) => {
  //         const village_name = item.village_name;
  //         const id = item.id;
  //         const city_name = item.vc.city_name;
  //         const district_name = item.vd.district_name;
  //         const province_name = item.vp.province_name;
  //         var obj = {
  //           label:
  //             village_name +
  //             ", " +
  //             district_name +
  //             ", " +
  //             city_name +
  //             " - " +
  //             province_name,
  //           value: id,
  //         };
  //         opt.push(obj);
  //       });
  //       var option = { options: opt };
  //       villageData.push(option);
  //       setVillageList(villageData);
  //     }
  //   }, [villages]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setBranch(null);
      resetList();
      validation.resetForm();
      validation.setErrors({});
    } else {
      dispatch(getClient());
      setModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const handleBranchClick = useCallback(
    (arg) => {
      const branch = arg;

      let address_branch = {};
      for (const address of branch.branch_address) {
        if (address.isPrimary) {
          address_branch = address;
        }
      }

      setPlaceValue(
        branch.branch_address.length > 0
          ? {
              label: `${address_branch.village} ${address_branch.district} ${address_branch.city} ${address_branch.province} ${address_branch.country}`,
              value: `${address_branch.village} ${address_branch.district} ${address_branch.city} ${address_branch.province} ${address_branch.country}`,
            }
          : ""
      );

      setBranch({
        id: branch.id,
        name: branch.name,
        branch_code: branch.branch_code,
        isWarehouse: branch.isWarehouse,
        isSellingOffline: branch.isSellingOffline,
        isSellingOnline: branch.isSellingOnline,
        attendance_distance: branch.attendance_distance,
        sinceat: branch.sinceat,
        description: branch.description,
        latitude: branch.latitude,
        longitude: branch.longitude,
        company: {
          label: branch.branch_company ? branch.branch_company.name : "",
          value: branch.branch_company ? branch.branch_company.id : "",
        },
        client: {
          label: branch.branch_client ? branch.branch_client.name : "",
          value: branch.branch_client ? branch.branch_client.id : "",
        },
        parent:
          branch.branch_parent === null
            ? ""
            : {
                label: branch.branch_parent ? branch.branch_parent.name : "",
                value: branch.branch_parent ? branch.branch_parent.id : "",
              },
        status:
          branch.branch_status === null
            ? ""
            : {
                label: branch.branch_status.name,
                value: branch.branch_status.id,
              },
        zip: address_branch.code ?? "",
        address:
          branch.branch_address.length < 1
            ? ""
            : {
              label: `${address_branch.village} ${address_branch.district} ${address_branch.city} ${address_branch.province} ${address_branch.country}`,
              value: {
                description: `${address_branch.village} ${address_branch.district} ${address_branch.city} ${address_branch.province} ${address_branch.country}`,
                matched_substrings: [],
                place_id: "",
                reference: "",
                structured_formatting: {},
                terms: [],
                types: [],
              },
            },
        street: address_branch.description ?? "",
      });
      // if (branch.branch_client !== null) {
      //   handleCompany(branch.branch_client.id);
      // }

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      branch_name: (branch && branch.name) || "",
      attendance_distance: (branch && branch.attendance_distance) || "",
      branch_code: (branch && branch.branch_code) || "",
      longitude: (branch && branch.longitude) || "",
      latitude: (branch && branch.latitude) || "",
      status: (branch && branch.status) || "",
      description: (branch && branch.description) || "",
      company: (branch && branch.company) || "",
      client: (branch && branch.client) || "",
      isWarehouse: (branch && branch.isWarehouse) || false,
      isSellingOffline: (branch && branch.isSellingOffline) || false,
      isSellingOnline: (branch && branch.isSellingOnline) || false,
      parent: branch && branch.parent,
      zip: (branch && branch.zip) || "",
      address: (branch && branch.address) || "",
      street: (branch && branch.street) || "",
    },
    validationSchema: Yup.object({
      branch_name: Yup.string().required("Please Enter Branch Name"),
      branch_code: Yup.string().required("Please Enter Branch Code"),
      company: Yup.object().required("Please Select Company"),
      client: Yup.object().required("Please Select Client"),
      // zip: Yup.string().required("Please Enter Zip Code"),
      // street: Yup.string().required("Please Enter Street"),
      // address: Yup.object().required("Please Select Adress"),
      // latitude: Yup.string().required("Please Enter Latitude"),
      // longitude: Yup.string().required("Please Enter Longitude"),
      // attendance_distance: Yup.string().required("Please Enter Attendance Distance"),
      status: Yup.object().required("Please Select Status"),
    }),
    onSubmit: (values) => {
      dispatch(getBranchLoading());
      if (isEdit) {
        let editDataSubmit = {};

        if (values.address.value && values.address.value.terms.length > 0) {
          editDataSubmit = {
            id: branch.id,
            name: values.branch_name,
            branch_code: values.branch_code,
            longitude: values.longitude,
            latitude: values.latitude,
            statusId: values.status.value,
            description: values.description,
            parentId: values.parent.value,
            companyId: values.company.value,
            isWarehouse: values.isWarehouse,
            isSellingOffline: values.isSellingOffline,
            isSellingOnline: values.isSellingOnline,
            attendance_distance: values.attendance_distance,
            clientId: values.client.value,
            zip: values.zip,
            street: values.street,
          };

          if (values.address.value) {
            editDataSubmit = {
              ...editDataSubmit,
              village: values.address.value.terms[0].value,
              district: values.address.value.terms[values.address.value.terms.length - 4].value,
              city: values.address.value.terms[values.address.value.terms.length - 3].value,
              province: values.address.value.terms[values.address.value.terms.length - 2].value,
              country: values.address.value.terms[values.address.value.terms.length - 1].value,
            };
          }
        } else {
          editDataSubmit = {
            id: branch.id,
            name: values.branch_name,
            branch_code: values.branch_code,
            longitude: values.longitude,
            latitude: values.latitude,
            statusId: values.status.value,
            description: values.description,
            parentId: values.parent.value,
            companyId: values.company.value,
            isWarehouse: values.isWarehouse,
            isSellingOffline: values.isSellingOffline,
            isSellingOnline: values.isSellingOnline,
            attendance_distance: values.attendance_distance,
            clientId: values.client.value,
            zip: values.zip,
            street: values.street,
          };

          if (values.address.value) {
            editDataSubmit = {
              ...editDataSubmit,
              village: branch.village,
              district: branch.district,
              city: branch.city,
              province: branch.province,
              country: branch.country,
            };
          }
        }

        dispatch(onUpdateBranch(editDataSubmit));
        validation.resetForm();
      } else {
        let newBranch = {
          name: values.branch_name,
          branch_code: values.branch_code,
          longitude: values.longitude,
          latitude: values.latitude,
          statusId: values.status.value,
          description: values.description,
          parentId: values.parent.value,
          companyId: values.company.value,
          isWarehouse: values.isWarehouse,
          isSellingOffline: values.isSellingOffline,
          isSellingOnline: values.isSellingOnline,
          attendance_distance: values.attendance_distance,
          clientId: values.client.value,
          zip: values.zip,
          street: values.street,
        };

        if (values.address.value) {
          newBranch = {
            ...newBranch,
            village: values.address.value.terms[0].value,
            district: values.address.value.terms[values.address.value.terms.length - 4].value,
            city: values.address.value.terms[values.address.value.terms.length - 3].value,
            province: values.address.value.terms[values.address.value.terms.length - 2].value,
            country: values.address.value.terms[values.address.value.terms.length - 1].value,
          };
        }

        dispatch(onAddNewBranch(newBranch));
        validation.resetForm();
      }
      toggle();
    },
  });

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteBranch(element.value));
    });
    checkall.checked = false;
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="dataCheckbox form-check-input" value={cellProps.row.original.id} />;
        },
        id: "#",
      },
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleBranchClick(data);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </Link>
              {/* {cellProps.row.original.statusActiveId === 1 ? (
                <button
                  className="btn-label btn btn-sm btn-soft-success fs-12 me-0"
                  onClick={() => {
                    const data = cellProps.row.original;
                    onClickApprove(data);
                  }}
                >
                  <i className="ri-checkbox-circle-line label-icon align-middle fs-16 me-1"></i>Approve
                </button>
              ) : (
                ""
              )} */}
            </div>
          );
        },
      },
      {
        Header: `${t("Name")}`,
        accessor: "name",
        filterable: true,
      },
      {
        Header: `${t("Code")}`,
        accessor: "branch_code",
        filterable: true,
      },
      {
        Header: `${t("Branch")} ${t("Parent")}`,
        accessor: "branch_parent.name",
        filterable: true,
      },
      {
        Header: `${t("Company")}`,
        accessor: "branch_company.name",
        filterable: true,
      },
      {
        Header: `${t("Client")}`,
        accessor: "branch_client.name",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "unit_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.row.original.statusId) {
            case 1:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
            case 2:
              return <span className="badge text-uppercase badge-soft-info">Activated</span>;
            default:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
          }
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleBranchClick, userType, roles, t]
  );

  const defaultdate = () => {
    return moment(new Date()).tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };

  const [filterDate, setFilterDate] = useState(defaultdate());

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  const handleValidTime = (time) => {
    return moment(new Date(time), "YYYY/MM/DD").tz("Asia/Jakarta").format("HH:mm");
  };

  //TABLE
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: branchList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable ? (column.isSorted ? (column.isSortedDesc ? " sorting_desc" : " sorting_asc") : " sort") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState([]);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleFilter() {
    let startat = "";
    let endat = "";
    let datearr = filterDate.split(" to ");
    if (datearr[1]) {
      startat = datearr[0];
      endat = datearr[1];
    }

    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetBranches(param));
  }

  const resetList = () => {
    setPlaceValue("");
    setCompanyList([]);
    setParentList([]);
    dispatch(resetClientState());
    dispatch(resetCompanyState());
  };

  useEffect(() => {
    resetList();
    return () => {
      dispatch(resetBranchState());
      resetList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = `${t("Branch")} | Aptek`;
  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={branchList} />
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteBranch} onCloseClick={() => setDeleteModal(false)} />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={t("Branch")} pageTitle={t("Branch")} />
        <Row>
          <Col lg={12}>
            <Card id="branchList">
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    {t("Branch History")} &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color="primary" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className={"btn btn-primary add-btn"}
                      id="create-btn"
                      onClick={() => {
                        setBranch("");
                        setIsEdit(false);
                        toggle();
                      }}
                    >
                      <i className="ri-hotel-line me-1 align-bottom fs-14"></i> {t("Add")}
                    </button>{" "}
                    <button type="button" className="btn btn-success" onClick={() => setIsExportCSV(true)}>
                      <i className="ri-file-download-line me-1 align-bottom fs-14"></i> {t("Export")}
                    </button>{" "}
                    <button className={userType === "executive" ? "btn btn-soft-danger" : roles && roles.split(",").indexOf("deleteBranch") !== -1 ? "btn btn-soft-danger" : "btn btn-soft-danger d-none"} onClick={() => setDeleteModalMulti(true)}>
                      <i className="ri-delete-bin-5-line me-1 align-bottom fs-15"></i> {t("Delete")}
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className=" ri-hotel-line me-1 align-bottom fs-14"></i> All Branches
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("2", "Draft");
                        }}
                        href="#"
                      >
                        <i className="ri-information-line me-1 align-bottom fs-14"></i> Draft
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("3", "Activated");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Activated{" "}
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("4", "Deactivated");
                        }}
                        href="#"
                      >
                        <i className="ri-indeterminate-circle-line me-1 align-bottom fs-14"></i> Deactivated
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                  <Row className="mb-3">
                    <CardBody className="border border-top-0 border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={1} className="col-xxl-1">
                            <div className="me-2 mb-2 col-12">
                              <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="search-box me-2 mb-2 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${count} Search...`}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col sm={6} className="col-xxl-3">
                            <div className="search-box me-2 mb-2 col-12">
                              <Flatpickr
                                className="form-control"
                                id="datepicker-publish-input"
                                placeholder="Select created date"
                                options={{
                                  mode: "range",
                                  dateFormat: "Y/m/d",
                                }}
                                onChange={(selectedDates, dateStr) => {
                                  setFilterDate(dateStr);
                                }}
                              />
                              <i className="bx bx-calendar-alt search-icon"></i>
                            </div>
                          </Col>

                          <Col sm={4} className="col-xxl-2">
                            <div className="me-2 mb-2 col-12">
                              <Select
                                value={statusActive}
                                placeholder="Select Status"
                                onChange={(e) => {
                                  setStatusActive(e);
                                }}
                                options={statusActiveData}
                                name="choices-single-default"
                              ></Select>
                            </div>
                          </Col>

                          <Col className="col-xxl-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-light w-100"
                                onClick={() => {
                                  handleFilter();
                                }}
                              >
                                <i className="ri-search-line me-1 align-bottom fs-14"></i> {t("Search")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card mb-1">
                    <Table hover {...getTableProps()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroups.map((headerGroup) => (
                          <tr className={""} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button color="light" onClick={previousPage} disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      page
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
                    </Col>
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={nextPage} disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal id="showModal" isOpen={modal} centered size="lg">
                  <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                      toggle();
                      validation.resetForm();
                      validation.setErrors({});
                      setCompanyList([]);
                    }}
                  >
                    {!!isEdit ? `${t("Edit")} ${t("Branch")}` : `${t("Add")} ${t("Branch")}`}
                  </ModalHeader>
                  <ModalBody>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <Label htmlFor="password-field" className="form-label">
                            {t("Client")} <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="client"
                            value={validation.values.client}
                            placeholder="Select Client"
                            onChange={(e) => {
                              setCompanyList([]);
                              validation.setFieldValue("company", "");
                              setParentList([]);
                              validation.setFieldValue("parent", "");
                              validation.setFieldValue("client", e);
                              handleCompany(e.value);
                            }}
                            validate={{ required: { value: true } }}
                            options={clientList}
                            invalid={validation.touched.client && validation.errors.client ? true : false}
                          ></Select>
                          {validation.touched.client && validation.errors.client ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.client}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="company" className="form-label">
                            Company <span className="text-danger">*</span>&nbsp;
                            {companyLoading ? (
                              <Spinner color="primary" size="sm">
                                Loading...
                              </Spinner>
                            ) : (
                              ""
                            )}
                            {/* {companyLoading && (
                              <Spinner color="primary" size="sm">
                                Loading...
                              </Spinner>
                            )} */}
                          </Label>
                          <Select
                            name="company"
                            isDisabled={companyList.length > 0 ? false : true}
                            value={validation.values.company}
                            validate={{ required: { value: true } }}
                            placeholder="Select Company"
                            onChange={(e) => {
                              setParentList([]);
                              validation.setFieldValue("parent", "");
                              validation.setFieldValue("company", e);
                              handleGetParentBranch(e.value);
                            }}
                            // onInputChange={(e) => {
                            //   handleCompany(e);
                            // }}
                            options={companyList}
                            invalid={validation.touched.company && validation.errors.company ? true : false}
                          ></Select>
                          {validation.touched.company && validation.errors.company ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.company}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="parent" className="form-label">
                            {t("Branch")} {t("Parent")} ({t("if any")})`
                          </Label>
                          <Select
                            name="parent"
                            isDisabled={parentList.length > 0 ? false : true}
                            value={validation.values.parent}
                            placeholder="Enter Branch"
                            onChange={(e) => {
                              validation.setFieldValue("parent", e);
                            }}
                            options={parentList}
                          ></Select>
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="branchname-field" className="form-label">
                            {t("Name")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="branch_name" id="branchname-field" className="form-control" placeholder="Enter Name" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.branch_name || ""} invalid={validation.touched.branch_name && validation.errors.branch_name ? true : false} />
                          {validation.touched.branch_name && validation.errors.branch_name ? <FormFeedback type="invalid">{validation.errors.branch_name}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="branchcode-field" className="form-label">
                            {t("Code")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="branch_code" id="branchcode-field" className="form-control" placeholder="Enter Name" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.branch_code || ""} invalid={validation.touched.branch_code && validation.errors.branch_code ? true : false} />
                          {validation.touched.branch_code && validation.errors.branch_code ? <FormFeedback type="invalid">{validation.errors.branch_code}</FormFeedback> : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="latitude-field" className="form-label">
                            Latitude
                          </Label>
                          <Input name="latitude" id="latitude-field" className="form-control" placeholder="Enter Latitude Name" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.latitude || ""} invalid={validation.touched.latitude && validation.errors.latitude ? true : false} />
                          {validation.touched.latitude && validation.errors.latitude ? <FormFeedback type="invalid">{validation.errors.latitude}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="longitude-field" className="form-label">
                            Longitude
                          </Label>
                          <Input name="longitude" id="longitude-field" className="form-control" placeholder="Enter Longitude Name" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.longitude || ""} invalid={validation.touched.longitude && validation.errors.longitude ? true : false} />
                          {validation.touched.longitude && validation.errors.longitude ? <FormFeedback type="invalid">{validation.errors.longitude}</FormFeedback> : null}
                        </div>

                        {/* <div className="mb-1">
                          <Input name="isWarehouse" id="isWarehouse-field" className="form-check-input" type="checkbox" onChange={validation.handleChange} onBlur={validation.handleBlur} checked={validation.values.isWarehouse} /> &nbsp;
                          <Label htmlFor="isWarehouse-field" className="form-label">
                            is Warehouse ?
                          </Label>
                        </div>
                        <div className="mb-1">
                          <Input name="isSellingOffline" id="isSellingOffline-field" className="form-check-input" type="checkbox" onChange={validation.handleChange} onBlur={validation.handleBlur} checked={validation.values.isSellingOffline} /> &nbsp;
                          <Label htmlFor="isSellingOffline-field" className="form-label">
                            Selling Offline
                          </Label>
                        </div>
                        <div className="mb-3">
                          <Input name="isSellingOnline" id="isSellingOnline-field" className="form-check-input" type="checkbox" onChange={validation.handleChange} onBlur={validation.handleBlur} checked={validation.values.isSellingOnline} />
                          &nbsp;
                          <Label htmlFor="isSellingOnline-field" className="form-label">
                            Selling Online
                          </Label>
                        </div> */}
                      </div>
                      <div className="col-lg-6">
                        {/* <div className="mb-3">
                          <Label htmlFor="attendance_distance-field" className="form-label">
                            {t("Attendance Distance")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="attendance_distance" id="attendance_distance-field" className="form-control" placeholder="Enter Distance" type="number" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.attendance_distance || ""} invalid={validation.touched.attendance_distance && validation.errors.attendance_distance ? true : false} />
                          {validation.touched.attendance_distance && validation.errors.attendance_distance ? <FormFeedback type="invalid">{validation.errors.attendance_distance}</FormFeedback> : null}
                        </div> */}
                        <div className="mb-3">
                          <Label htmlFor="address-field" className="form-label">
                            {t("Address")}
                          </Label>
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyD0auKcsMNas3qbqi-BgF_s3UxwtQNP74Q"
                            apiOptions={{
                              region: "IDN",
                            }}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["idn"],
                              },
                              types: ["route"],
                            }}
                            selectProps={{
                              name: "address",
                              value: placeValue,
                              onChange: (value) => {
                                validation.setFieldValue("address", value);
                                setPlaceValue(value);
                              },
                            }}
                          />
                          {validation.touched.address && validation.errors.address ? <div className="invalid-feedback d-block">{validation.errors.address}</div> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="zip-field" className="form-label">
                            {t("Zip Code")}
                          </Label>
                          <Input name="zip" id="zip-field" className="form-control" placeholder="Enter Zip Code" type="number" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.zip || ""} invalid={validation.touched.zip && validation.errors.zip ? true : false} />
                          {validation.touched.zip && validation.errors.zip ? <FormFeedback type="invalid">{validation.errors.zip}</FormFeedback> : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="street-field" className="form-label">
                            {t("Street")} <span className="text-danger">*</span>
                          </Label>
                          <Input name="street" id="street-field" className="form-control" placeholder="Enter Street Name" type="textarea" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.street || ""} invalid={validation.touched.street && validation.errors.street ? true : false} />
                          {validation.touched.street && validation.errors.street ? <FormFeedback type="invalid">{validation.errors.street}</FormFeedback> : null}
                        </div>
                        {/* <div className="mb-3">
                            <Label htmlFor="village" className="form-label">
                              City            
                              {villageLoading && (<Spinner color="primary" size="sm">Loading...</Spinner>)}
                            </Label>
                            <AsyncSelect
                              name="village"
                              value={validation.values.village}
                              placeholder="Enter City"
                              onChange={(e) => {
                                validation.setFieldValue("village", e);
                              }}
                              cacheOptions
                              loadOptions={promiseOptions}
                              invalid={
                                validation.touched.village &&
                                validation.errors.village
                                  ? true
                                  : false
                              }
                            ></AsyncSelect>
                            {validation.touched.village &&
                            validation.errors.village ? (
                              <p className="text-danger" type="invalid">
                                {validation.errors.village}
                              </p>
                            ) : (
                              ""
                            )}
                          </div> */}

                        <div className="mb-3">
                          <Label htmlFor="active-status" className="form-label">
                            {t("Status")}
                          </Label>
                          <Select
                            name="status"
                            value={validation.values.status}
                            validate={{ required: { value: true } }}
                            placeholder="Select Status"
                            onChange={(e) => {
                              validation.setFieldValue("status", e);
                            }}
                            onInputChange={(e) => {
                              // handleUnit(e);
                            }}
                            options={statusActiveData}
                            invalid={validation.touched.status && validation.errors.status ? true : false}
                          ></Select>
                          {validation.touched.status && validation.errors.status ? (
                            <p className="invalid-feedback d-block" type="invalid">
                              {validation.errors.status}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="des-field" className="form-label">
                            {t("Description")}
                          </Label>
                          <Input name="description" id="des-field" className="form-control" placeholder="Enter Description" type="textarea" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.description || ""} />
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <div className="modal-footer">
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          setModal(false);
                          validation.resetForm();
                          validation.setErrors({});
                          setCompanyList([]);
                        }}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        onClick={() => {
                          validation.handleSubmit();
                        }}
                        type="submit"
                        className="btn btn-success"
                      >
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">{t("Save")}</span>
                          {loading && (
                            <Spinner size="sm" className="flex-shrink-0" role="status">
                              Loading...
                            </Spinner>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </Modal>
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Branch.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Branch));
