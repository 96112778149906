export const SERVICE_ORDER_LOADING = "SERVICE_ORDER_LOADING";
export const SERVICE_ORDER_RESET = "SERVICE_ORDER_RESET";
export const GET_SERVICE_ORDER = "GET_SERVICE_ORDER";
export const GET_SERVICE_ORDER_SUCCESS = "GET_SERVICE_ORDER_SUCCESS";
export const GET_SERVICE_ORDER_FAIL = "GET_SERVICE_ORDER_FAIL";
export const DELETE_SERVICE_ORDER = "DELETE_SERVICE_ORDER"
export const DELETE_SERVICE_ORDER_SUCCESS = "DELETE_SERVICE_ORDER_SUCCESS"
export const DELETE_SERVICE_ORDER_FAIL = "DELETE_SERVICE_ORDER_FAIL"
export const UPDATE_SERVICE_ORDER = "UPDATE_SERVICE_ORDER"
export const UPDATE_SERVICE_ORDER_SUCCESS = "UPDATE_SERVICE_ORDER_SUCCESS"
export const UPDATE_SERVICE_ORDER_FAIL = "UPDATE_SERVICE_ORDER_FAIL"
export const ADD_NEW_SERVICE_ORDER = "ADD_NEW_SERVICE_ORDER"
export const ADD_SERVICE_ORDER_SUCCESS = "ADD_SERVICE_ORDER_SUCCESS"
export const ADD_SERVICE_ORDER_FAIL = "ADD_SERVICE_ORDER_FAIL"