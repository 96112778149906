import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GET_PROMOSION, DELETE_PROMOSION, UPDATE_PROMOSION, ADD_NEW_PROMOSION } from "./actionType";

import {
  getPromosionSuccess,
  getPromosionFail,
  deletePromosionSuccess,
  deletePromosionFail,
  updatePromosionSuccess,
  updatePromosionFail,
  addPromosionSuccess,
  addPromosionFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approvePromosionApi,
  getPromosionApi,
  updatePromosionApi,
  deletePromosionApi,
  addNewPromosionApi,
} from "../../helpers/backend_helper";

function* getPromosion({ payload: promosion }) {
  try {
    const response = yield call(getPromosionApi, promosion);
    yield put(getPromosionSuccess(GET_PROMOSION, response.data));
  } catch (error) {
    yield put(getPromosionFail(GET_PROMOSION, error));
  }
}

function* onUpdatePromosion({ payload: promosion }) {
  try {
    if (promosion.setApprove) {
      const response = yield call(approvePromosionApi, promosion);
      yield put(updatePromosionSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updatePromosionApi, promosion);
      yield put(updatePromosionSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updatePromosionFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeletePromosion({ payload: promosion }) {
  try {
    const response = yield call(deletePromosionApi, promosion);
    yield put(deletePromosionSuccess({ promosion, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deletePromosionFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewPromosion({ payload: promosion }) {
  try {
    const response = yield call(addNewPromosionApi, promosion);
    yield put(addPromosionSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addPromosionFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetPromosion() {
  yield takeEvery(GET_PROMOSION, getPromosion);
}

export function* watchUpdatePromosion() {
  yield takeEvery(UPDATE_PROMOSION, onUpdatePromosion);
}

export function* watchDeletePromosion() {
  yield takeEvery(DELETE_PROMOSION, onDeletePromosion);
}

export function* watchAddNewPromosion() {
  yield takeEvery(ADD_NEW_PROMOSION, onAddNewPromosion);
}

function* promosionSaga() {
  yield all([
    fork(watchGetPromosion),
    fork(watchDeletePromosion),
    fork(watchUpdatePromosion),
    fork(watchAddNewPromosion),
  ]);
}

export default promosionSaga;
