import {
  SERVICEPRICECATEGORY_LOADING,
  GET_SERVICEPRICECATEGORY,
  GET_SERVICEPRICECATEGORY_SUCCESS,
  GET_SERVICEPRICECATEGORY_FAIL,
  SERVICEPRICECATEGORY_RESET
} from "./actionType";

const INIT_STATE = {
  servicePriceCategorys: [],
  error: {},
  loading: false
};

const ServicePriceCategorys = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICEPRICECATEGORY_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICEPRICECATEGORY:
          return {
            ...state,
            servicePriceCategorys: action.payload.data,
            isServicePriceCategoryCreated: false,
            isServicePriceCategorySuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SERVICEPRICECATEGORY_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICEPRICECATEGORY_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isServicePriceCategorysCreated: false,
            isServicePriceCategorysSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SERVICEPRICECATEGORY: {
      return {
        ...state,
        isServicePriceCategorysCreated: false,
        loading: true,
      };
    }

    case SERVICEPRICECATEGORY_LOADING: {
      return {
        ...state,
        isServicePriceCategorysCreated: false,
        loading: true,
      };
    }

    case SERVICEPRICECATEGORY_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ServicePriceCategorys;