import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button, CardHeader, Progress, Nav, NavItem, NavLink, TabContent, TabPane, Spinner } from "reactstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty } from "lodash";

// action
import { registerUser, resetRegisterFlag } from "../../store/auth/register/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

//import images
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import classnames from "classnames";

const Register = () => {
  const { error, registrationError, success, loading } = useSelector((state) => ({
    success: state.Register.success,
    error: state.Register.error,
    registrationError: state.Register.registrationError,
    loading: state.Register.loading,
  }));
  const history = useNavigate();
  const dispatch = useDispatch();
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);

  const [successTes, setSuccessTes] = useState(false);

  function toggleTab(tab, value) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }

  const handleSubscribedAt = (date) => {
    validation1.setFieldValue("subscribedat", date);
  };

  const handleExpirededAt = (date) => {
    validation1.setFieldValue("expiredat", date);
  };

  const statusActiveData = [
    {
      options: [
        { label: "Activated", value: "1" },
        { label: "Deactivated", value: "2" },
      ],
    },
  ];

  const validation1 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      username: "",
      password: "",
      phone: "",
      retype_password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
      email: Yup.string().required("Please Enter Your Email"),
      phone: Yup.string().required("Please Enter Your Phone"),
      retype_password: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords does not match")
        .required("Please Enter Your Retype Password"),
    }),
    onSubmit: (values) => {
      toggleTab(activeTab + 1, 50);
    },
  });

  const validation2 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      client_name: "",
      zip: "",
      street: "",
      address: "",
    },
    validationSchema: Yup.object({
      client_name: Yup.string().required("Please Enter Your Company Name"),
      zip: Yup.string().required("Please Enter Your Zip Code"),
      street: Yup.string().required("Please Enter Your Street"),
      address: Yup.object().required("Please Enter Your Address"),
    }),
    onSubmit: (values) => {
      const dataSumbit = {
        ...validation1.values,
        client_name: values.client_name,
        zip: values.zip,
        street: values.street,
        village: values.address.value.terms[0].value,
        district: values.address.value.terms[values.address.value.terms.length - 4].value,
        city: values.address.value.terms[values.address.value.terms.length - 3].value,
        province: values.address.value.terms[values.address.value.terms.length - 2].value,
        country: values.address.value.terms[values.address.value.terms.length - 1].value,
      };
      dispatch(registerUser(dataSumbit));
    },
  });

  useEffect(() => {
    if (success) {
      history("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    return () => {
      dispatch(resetRegisterFlag());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = "Register | TELKOMSEL ";

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="90" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={8} xl={8}>
                <Card>
                  <CardBody>
                    <div className="text-center pt-3 pb-4 mb-1">
                      <h5>Signup Your Account</h5>
                    </div>

                    <div className="progress-nav mb-4">
                      <Progress value={progressbarvalue} style={{ height: "1px" }} />

                      <Nav className="nav-pills progress-bar-tab custom-nav" role="tablist">
                        <NavItem>
                          <NavLink
                            //   to="#"
                            id="pills-gen-info-tab"
                            className={classnames(
                              {
                                active: activeTab === 1,
                                done: activeTab <= 4 && activeTab >= 0,
                              },
                              "rounded-pill d-flex justify-content-center"
                            )}
                            style={{
                              paddingTop: "0.4rem",
                            }}
                            // onClick={() => {
                            //   //   toggleTab(1, 0);
                            //   validation1.handleSubmit();
                            // }}
                            //   tag="button"
                          >
                            1
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            //   to="#"
                            id="pills-gen-info-tab"
                            className={classnames(
                              {
                                active: activeTab === 2,
                                done: activeTab <= 4 && activeTab > 1,
                              },
                              "rounded-pill d-flex justify-content-center"
                            )}
                            style={{
                              paddingTop: "0.4rem",
                            }}
                            // onClick={() => {
                            //   //   toggleTab(2, 50);
                            //   validation2.handleSubmit();
                            // }}
                            //   tag="button"
                          >
                            2
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={1}>
                        <div>
                          <div className="mb-4">
                            <div>
                              <h5 className="mb-1">General Information</h5>
                              <p className="text-muted">Fill all Information as below</p>
                            </div>
                          </div>
                          <Row>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label htmlFor="name" className="form-label">
                                  Name <span className="text-danger">*</span>
                                </Label>
                                <Input name="name" type="text" placeholder="Enter name" onChange={validation1.handleChange} onBlur={validation1.handleBlur} value={validation1.values.name || ""} invalid={validation1.touched.name && validation1.errors.name ? true : false} />
                                {validation1.touched.name && validation1.errors.name ? (
                                  <FormFeedback type="invalid">
                                    <div>{validation1.errors.name}</div>
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label htmlFor="useremail" className="form-label">
                                  Email <span className="text-danger">*</span>
                                </Label>
                                <Input id="email" name="email" className="form-control" placeholder="Enter email address" type="email" onChange={validation1.handleChange} onBlur={validation1.handleBlur} value={validation1.values.email || ""} invalid={validation1.touched.email && validation1.errors.email ? true : false} />
                                {validation1.touched.email && validation1.errors.email ? (
                                  <FormFeedback type="invalid">
                                    <div>{validation1.errors.email}</div>
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-2">
                                <Label htmlFor="phone" className="form-label">
                                  Phone <span className="text-danger">*</span>
                                </Label>
                                <Input name="phone" type="number" placeholder="Phone" onChange={validation1.handleChange} onBlur={validation1.handleBlur} value={validation1.values.phone || ""} invalid={validation1.touched.phone && validation1.errors.phone ? true : false} />
                                {validation1.touched.phone && validation1.errors.phone ? (
                                  <FormFeedback type="invalid">
                                    <div>{validation1.errors.phone}</div>
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label htmlFor="username" className="form-label">
                                  Username <span className="text-danger">*</span>
                                </Label>
                                <Input name="username" type="text" placeholder="Enter username" onChange={validation1.handleChange} onBlur={validation1.handleBlur} value={validation1.values.username || ""} invalid={validation1.touched.username && validation1.errors.username ? true : false} />
                                {validation1.touched.username && validation1.errors.username ? (
                                  <FormFeedback type="invalid">
                                    <div>{validation1.errors.username}</div>
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label htmlFor="userpassword" className="form-label">
                                  Password <span className="text-danger">*</span>
                                </Label>
                                <Input name="password" type="password" placeholder="Enter Password" onChange={validation1.handleChange} onBlur={validation1.handleBlur} value={validation1.values.password || ""} invalid={validation1.touched.password && validation1.errors.password ? true : false} />
                                {validation1.touched.password && validation1.errors.password ? (
                                  <FormFeedback type="invalid">
                                    <div>{validation1.errors.password}</div>
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label htmlFor="retype_password" className="form-label">
                                  Retype Password <span className="text-danger">*</span>
                                </Label>
                                <Input name="retype_password" type="password" placeholder="Enter Retype Password" onChange={validation1.handleChange} onBlur={validation1.handleBlur} invalid={validation1.touched.retype_password && validation1.errors.retype_password ? true : false} />
                                {validation1.touched.retype_password && validation1.errors.retype_password ? (
                                  <FormFeedback type="invalid">
                                    <div>{validation1.errors.retype_password}</div>
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="d-flex align-items-start gap-3 mt-4">
                          <button
                            type="button"
                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                            onClick={() => {
                              validation1.handleSubmit();
                            }}
                          >
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            Next
                          </button>
                        </div>
                      </TabPane>

                      <TabPane tabId={2}>
                        {/* {!success && !success ? (
                            <Alert color="danger">
                              <div>Please complete your data first before submit....</div>
                            </Alert>
                          ) : null} */}
                        <div>
                          <Row>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label htmlFor="clientname-field" className="form-label">
                                  Company Name <span className="text-danger">*</span>
                                </Label>
                                <Input name="client_name" id="clientclient_name-field" className="form-control" placeholder="Enter Name" type="text" validate={{ required: { value: true } }} onChange={validation2.handleChange} onBlur={validation2.handleBlur} value={validation2.values.client_name || ""} invalid={validation2.touched.client_name && validation2.errors.client_name ? true : false} />
                                {validation2.touched.client_name && validation2.errors.client_name ? <FormFeedback type="invalid">{validation2.errors.client_name}</FormFeedback> : null}
                              </div>
                              <div className="mb-3">
                                <Label htmlFor="address-field" className="form-label">
                                  Address <span className="text-danger">*</span>
                                </Label>
                                <GooglePlacesAutocomplete
                                  apiKey="AIzaSyD0auKcsMNas3qbqi-BgF_s3UxwtQNP74Q"
                                  apiOptions={{
                                    region: "IDN",
                                  }}
                                  autocompletionRequest={{
                                    componentRestrictions: {
                                      country: ["idn"],
                                    },
                                    types: ["route"],
                                  }}
                                  selectProps={{
                                    name: "address",
                                    value: validation2.values.address || "",
                                    onChange: (value) => {
                                      validation2.setFieldValue("address", value);
                                    },
                                  }}
                                />
                                {validation2.touched.address && validation2.errors.address ? <div className="invalid-feedback d-block">{validation2.errors.address}</div> : null}
                              </div>
                              <div className="mb-3">
                                <Label htmlFor="zip_code-field" className="form-label">
                                  Zip Code <span className="text-danger">*</span>
                                </Label>
                                <Input name="zip" id="zip_code-field" className="form-control" placeholder="Enter Zip Code" type="number" validate={{ required: { value: true } }} onChange={validation2.handleChange} onBlur={validation2.handleBlur} value={validation2.values.zip || ""} invalid={validation2.touched.zip && validation2.errors.zip ? true : false} />
                                {validation2.touched.zip && validation2.errors.zip ? <FormFeedback type="invalid">{validation2.errors.zip}</FormFeedback> : null}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label htmlFor="street-field" className="form-label">
                                  Street <span className="text-danger">*</span>
                                </Label>
                                <Input name="street" id="street-field" className="form-control" placeholder="Enter Street Name" type="textarea" onChange={validation2.handleChange} onBlur={validation2.handleBlur} value={validation2.values.street || ""} invalid={validation2.touched.street && validation2.errors.street ? true : false} style={{ minHeight: "202px" }} />
                                {validation2.touched.street && validation2.errors.street ? <FormFeedback type="invalid">{validation2.errors.street}</FormFeedback> : null}
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="d-flex align-items-start gap-3 mt-4">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none btn-label previestab"
                            onClick={() => {
                              toggleTab(activeTab - 1, 0);
                            }}
                          >
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back to General
                          </button>
                          <button
                            type="button"
                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                            onClick={() => {
                              validation2.handleSubmit();
                              // if (success) {
                              //   toggleTab(activeTab + 1, 100);
                              // }

                              //   toggleTab(activeTab + 1, 100);
                            }}
                          >
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            Submit
                            {loading ? <Spinner size="sm" className="flex-shrink-0 ms-2" role="status"></Spinner> : ""}
                          </button>
                        </div>
                      </TabPane>
                    </TabContent>
                    <ToastContainer closeButton={false} />
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Already have an account ?{" "}
                    <Link to="/login" className="fw-semibold text-primary text-decoration-underline">
                      {" "}
                      Signin{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Register;
