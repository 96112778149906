import {
  GET_TICKETCATEGORY,
  GET_TICKETCATEGORY_SUCCESS,
  GET_TICKETCATEGORY_FAIL,
  DELETE_TICKETCATEGORY,
  DELETE_TICKETCATEGORY_SUCCESS,
  DELETE_TICKETCATEGORY_FAIL,
  UPDATE_TICKETCATEGORY,
  UPDATE_TICKETCATEGORY_SUCCESS,
  UPDATE_TICKETCATEGORY_FAIL,
  ADD_NEW_TICKETCATEGORY,
  ADD_TICKETCATEGORY_SUCCESS,
  ADD_TICKETCATEGORY_FAIL,
  TICKETCATEGORY_LOADING,
  TICKETCATEGORY_RESET,
} from "./actionType";

export const resetTicketCategoryState = (actionType) => ({
  type: TICKETCATEGORY_RESET,
  payload: { actionType },
});

export const getTicketCategorySuccess = (actionType, data) => ({
  type: GET_TICKETCATEGORY_SUCCESS,
  payload: { actionType, data },
});

export const getTicketCategoryFail = (actionType, error) => ({
  type: GET_TICKETCATEGORY_FAIL,
  payload: { actionType, error },
});

export const getTicketCategory = (data) => ({
  type: GET_TICKETCATEGORY,
  payload: data,
});

export const getTicketCategoryLoading = () => ({
  type: TICKETCATEGORY_LOADING,
});

export const deleteTicketCategory = (data) => ({
  type: DELETE_TICKETCATEGORY,
  payload: data,
});

export const deleteTicketCategorySuccess = (data) => ({
  type: DELETE_TICKETCATEGORY_SUCCESS,
  payload: data,
});

export const deleteTicketCategoryFail = (error) => ({
  type: DELETE_TICKETCATEGORY_FAIL,
  payload: error,
});

export const updateTicketCategory = (data) => ({
  type: UPDATE_TICKETCATEGORY,
  payload: data,
});

export const updateTicketCategoryFail = (error) => ({
  type: UPDATE_TICKETCATEGORY_FAIL,
  payload: error,
});

export const updateTicketCategorySuccess = (data) => ({
  type: UPDATE_TICKETCATEGORY_SUCCESS,
  payload: data,
});

export const addNewTicketCategory = (data) => ({
  type: ADD_NEW_TICKETCATEGORY,
  payload: data,
});

export const addTicketCategorySuccess = (data) => ({
  type: ADD_TICKETCATEGORY_SUCCESS,
  payload: data,
});

export const addTicketCategoryFail = (error) => ({
  type: ADD_TICKETCATEGORY_FAIL,
  payload: error,
});
