import {
  GET_BILLING_CATEGORY,
  GET_BILLING_CATEGORY_SUCCESS,
  GET_BILLING_CATEGORY_FAIL,
  DELETE_BILLING_CATEGORY,
  DELETE_BILLING_CATEGORY_SUCCESS,
  DELETE_BILLING_CATEGORY_FAIL,
  UPDATE_BILLING_CATEGORY,
  UPDATE_BILLING_CATEGORY_SUCCESS,
  UPDATE_BILLING_CATEGORY_FAIL,
  ADD_NEW_BILLING_CATEGORY,
  ADD_BILLING_CATEGORY_SUCCESS,
  ADD_BILLING_CATEGORY_FAIL,
  BILLING_CATEGORY_LOADING,
  BILLING_CATEGORY_RESET,
} from "./actionType";

export const resetBillingCategoryState = (actionType) => ({
  type: BILLING_CATEGORY_RESET,
  payload: { actionType },
});

export const getBillingCategorySuccess = (actionType, data) => ({
  type: GET_BILLING_CATEGORY_SUCCESS,
  payload: { actionType, data },
});

export const getBillingCategoryFail = (actionType, error) => ({
  type: GET_BILLING_CATEGORY_FAIL,
  payload: { actionType, error },
});

export const getBillingCategory = (data) => ({
  type: GET_BILLING_CATEGORY,
  payload: data,
});

export const getBillingCategoryLoading = () => ({
  type: BILLING_CATEGORY_LOADING,
});

export const deleteBillingCategory = (data) => ({
  type: DELETE_BILLING_CATEGORY,
  payload: data,
});

export const deleteBillingCategorySuccess = (data) => ({
  type: DELETE_BILLING_CATEGORY_SUCCESS,
  payload: data,
});

export const deleteBillingCategoryFail = (error) => ({
  type: DELETE_BILLING_CATEGORY_FAIL,
  payload: error,
});

export const updateBillingCategory = (data) => ({
  type: UPDATE_BILLING_CATEGORY,
  payload: data,
});

export const updateBillingCategoryFail = (error) => ({
  type: UPDATE_BILLING_CATEGORY_FAIL,
  payload: error,
});

export const updateBillingCategorySuccess = (data) => ({
  type: UPDATE_BILLING_CATEGORY_SUCCESS,
  payload: data,
});

export const addNewBillingCategory = (data) => ({
  type: ADD_NEW_BILLING_CATEGORY,
  payload: data,
});

export const addBillingCategorySuccess = (data) => ({
  type: ADD_BILLING_CATEGORY_SUCCESS,
  payload: data,
});

export const addBillingCategoryFail = (error) => ({
  type: ADD_BILLING_CATEGORY_FAIL,
  payload: error,
});
