import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { Table, Button, Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, Modal, ModalHeader, Form, ModalBody, Label, Input, FormFeedback, Spinner } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import AsyncSelect from "react-select/async";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
// import { getVillage, resetVillageState } from "../../store/village/action";
import { getClient, resetClientState } from "../../store/clients/action";
import { resetCompanyState, getCompany as onGetCompanies, addNewCompany as onAddNewCompany, updateCompany as onUpdateCompany, deleteCompany as onDeleteCompany, getCompanyLoading, resetState } from "../../store/company/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import defaultImg from "../../assets/images/upload.png";
import { api } from "../../config";
import ModalInputFile from "../../Components/ModalInputFile";
import imgNotFound from "../../assets/images/users/img-remove.png";

const Company = (props) => {
  const { t } = props;
  const [logo, setLogo] = useState(defaultImg);
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();

  const { clients, companies, isCompanyCreated, isCompanySuccess, loading, error } = useSelector((state) => ({
    companies: state.Company.companies,
    isCompanyCreated: state.Company.isCompanyCreated,
    isCompanySuccess: state.Company.isCompanySuccess,
    loading: state.Company.loading,
    error: state.Company.error,
    clients: state.Clients.clients,
  }));
  const [modalMedia, setModalMedia] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [company, setCompany] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);
  const [villageList, setVillageList] = useState([]);
  const [roles, setRoles] = useState(null);
  const [userType, setUserType] = useState("user");

  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: "1" },
        { label: "Activated", value: "2" },
      ],
    },
  ];

  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  useEffect(() => {
    if (!isEmpty(clients)) {
      var data = [];
      var opt = [];
      clients.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setClientList(data);
    }
  }, [clients]);

  const onClickDelete = (company) => {
    setCompany(company);
    setDeleteModal(true);
  };

  const handleDeleteCompany = () => {
    if (company) {
      dispatch(onDeleteCompany(company));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setCompanyList(companies);
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(companies)) setCompanyList(companies);
  }, [companies]);

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredCompanies = companies;
      if (type !== "all") {
        filteredCompanies = companies.filter((company) => company.company_status.name === type);
      }
      setCompanyList(filteredCompanies);
    }
  };

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetCompanyState());
      dispatch(onGetCompanies());
      setIsSearch(true);
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      if (obj.data && obj.data.employee_position && obj.data.employee_position.position_role) setRoles(obj.data.employee_position.position_role.role_method);
      else setRoles(null);

      if (obj.data && obj.data.employee_user) setUserType(obj.data.employee_user.userType);
    }
  }, [dispatch, companies, isSearch]);

  // const promiseOptions = (e) => {
  //   if (!villageLoading) {
  //     dispatch(resetVillageState());
  //     setVillageList([]);
  //     if (e.length > 3) {
  //       dispatch(getVillage({ title: e }));
  //     }
  //     return new Promise((resolve, reject) => {
  //       while (villageLoading);
  //       resolve(villageList);
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (!isEmpty(villages)) {
  //     var villageData = [];
  //     var opt = [];
  //     villages.forEach((item, key) => {
  //       const village_name = item.village_name;
  //       const id = item.id;
  //       const city_name = item.vc.city_name;
  //       const district_name = item.vd.district_name;
  //       const province_name = item.vp.province_name;
  //       var obj = {
  //         label: village_name + ", " + district_name + ", " + city_name + " - " + province_name,
  //         value: id,
  //       };
  //       opt.push(obj);
  //     });
  //     var option = { options: opt };
  //     villageData.push(option);
  //     setVillageList(villageData);
  //   }
  // }, [villages]);

  useEffect(() => {
    setCompany(companies);
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(companies)) {
      setCompany(companies);
      setIsEdit(false);
    }
  }, [companies]);

  useEffect(() => {
    setCompanyList(companies);
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(companies)) setCompanyList(companies);
  }, [companies]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCompany(null);
      resetList();
    } else {
      setModal(true);
      dispatch(getClient());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const handleCompanyClick = useCallback(
    (arg) => {
      const company = arg;

      let address_company = {};
      for (const address of company.company_address) {
        if (address.isPrimary) {
          address_company = address;
        }
      }

      setCompany({
        image: company.image ?? "",
        id: company.id,
        name: company.name,
        foundedat: company.foundedat,
        zip: address_company.code ?? "",
        village: address_company.village ?? "",
        district: address_company.district ?? "",
        city: address_company.city ?? "",
        province: address_company.province ?? "",
        country: address_company.country ?? "",
        client:
          company.company_status === null
            ? ""
            : {
                label: company.company_client.name,
                value: company.company_client.id,
              },
        status:
          company.company_status === null
            ? ""
            : {
                label: company.company_status.name,
                value: company.company_status.id,
              },
        description: company.description ?? "",
        address:
          company.company_address.length < 1
            ? ""
            : {
                label: `${address_company.village} ${address_company.district} ${address_company.city} ${address_company.province} ${address_company.country}`,
                value: {
                  description: `${address_company.village} ${address_company.district} ${address_company.city} ${address_company.province} ${address_company.country}`,
                  matched_substrings: [],
                  place_id: "",
                  reference: "",
                  structured_formatting: {},
                  terms: [],
                  types: [],
                },
              },
        street: address_company.description ?? "",
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      image: (company && company.image) || "",
      name: (company && company.name) || "",
      foundedat: (company && company.foundedat) || "",
      description: (company && company.description) || "",
      status: (company && company.status) || "",
      zip: (company && company.zip) || "",
      address: (company && company.address) || "",
      street: (company && company.street) || "",
      client: (company && company.client) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Company Name"),
      foundedat: Yup.string().required("Please Enter Founding Date"),
      status: Yup.object().required("Please Enter Status"),
      client: Yup.object().required("Please Select Client"),
    }),
    onSubmit: (values) => {
      dispatch(getCompanyLoading());
      if (isEdit) {
        let editDataSubmit = {};

        if (values.address.value && values.address.value.terms.length > 0) {
          editDataSubmit = {
            id: company.id,
            image: values.image,
            clientId: values.client.value,
            name: values.name,
            foundedat: values.foundedat,
            description: values.description,
            statusId: values.status.value,
            zip: values.zip,
            street: values.street,
          };

          if (values.address.value) {
            editDataSubmit = {
              ...editDataSubmit,
              village: values.address.value.terms[0].value,
              district: values.address.value.terms[values.address.value.terms.length - 4].value,
              city: values.address.value.terms[values.address.value.terms.length - 3].value,
              province: values.address.value.terms[values.address.value.terms.length - 2].value,
              country: values.address.value.terms[values.address.value.terms.length - 1].value,
            };
          }
        } else {
          editDataSubmit = {
            id: company.id,
            image: values.image,
            clientId: values.client.value,
            name: values.name,
            foundedat: values.foundedat,
            description: values.description,
            statusId: values.status.value,
            // brand_name: values.brand_name,
            // brand_description: values.brand_description,

            zip: values.zip,
            street: values.street,
          };

          if (values.address.value) {
            editDataSubmit = {
              ...editDataSubmit,
              village: company.village,
              district: company.district,
              city: company.city,
              province: company.province,
              country: company.country,
            };
          }
        }

        dispatch(onUpdateCompany(editDataSubmit));
      } else {
        let newCompany = {
          name: values.name,
          clientId: values.client.value,
          foundedat: values.foundedat,
          description: values.description,
          statusId: values.status.value,
          // brand_name: values.brand_name,
          // brand_description: values.brand_description,
          zip: values.zip,

          street: values.street,
        };

        if (values.address.value) {
          newCompany = {
            ...newCompany,
            village: values.address.value.terms[0].value,
            district: values.address.value.terms[values.address.value.terms.length - 4].value,
            city: values.address.value.terms[values.address.value.terms.length - 3].value,
            province: values.address.value.terms[values.address.value.terms.length - 2].value,
            country: values.address.value.terms[values.address.value.terms.length - 1].value,
          };
        }
        dispatch(onAddNewCompany(newCompany));
      }
      validation.resetForm();
      toggle();
    },

    //   const newData = {
    //     name: req.body.name,
    //     foundedat: req.body.foundedat,
    //     statusId: req.body.statusId,
    //     description: req.body.description,
    //     clientId: req.clientId,
    //     createdby: req.userId,
    //     createdat: moment().format('YYYY/MM/DD HH:mm:ss')
    // };
  });

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteCompany(element.value));
    });
    checkall.checked = false;
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="dataCheckbox form-check-input" value={cellProps.row.original.id} />;
        },
        id: "#",
      },
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              {/* <Link to="apps-ecommerce-company-details" className="btn-icon btn btn-sm btn-soft-primary">
                <i className="ri-eye-line fs-15"></i>
              </Link> */}
              <Link
                to="#"
                className={`btn-icon btn btn-sm btn-soft-primary edit-item-btn`}
                onClick={() => {
                  const data = cellProps.row.original;
                  handleCompanyClick(data);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </Link>
              {/* <Link
                to="#"
                className={`btn-icon btn btn-sm btn-soft-danger remove-item-btn`}
                onClick={() => {
                  const data = cellProps.row.original;
                  onClickDelete(data);
                }}
              >
                <i className=" ri-delete-bin-line fs-15"></i>
              </Link> */}
            </div>
          );
        },
      },
      {
        Header: "Name",
        filterable: true,
        accessor: "name",
        Cell: (client) => (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              {client.row.original.image !== null && client.row.original.image !== "" ? (
                <img
                  src={api.MEDIA_URL + "/media/" + client.row.original.image}
                  alt=""
                  className="avatar-xs rounded-circle me-1"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = imgNotFound;
                  }}
                />
              ) : (
                <div className="flex-shrink-0 avatar-xs me-1">
                  <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">{client.row.original.name !== null ? client.row.original.name.charAt(0).toUpperCase() : "-"}</div>
                </div>
              )}
            </div>
            <div className="flex-grow-1 ms-2 name">{client.row.original.name !== null ? client.row.original.name : "-"}</div>
          </div>
        ),
      },
      {
        Header: `${t("Founded Date")}`,
        accessor: "foundedat",
        filterable: true,
        Cell: (company) => <> {handleValidDate(company.row.original.foundedat)}</>,
      },
      {
        Header: `${t("Client")}`,
        accessor: "company_client.name",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "company_status.status_name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.row.original.statusId) {
            case 1:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
            case 2:
              return <span className="badge text-uppercase badge-soft-info">Activated</span>;
            default:
              return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
          }
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleCompanyClick, userType, roles, t]
  );

  const defaultdate = () => {
    return moment(new Date()).tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };
  const [filterDate, setFilterDate] = useState(defaultdate());

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  const handleValidTime = (time) => {
    return moment(new Date(time), "YYYY/MM/DD").tz("Asia/Jakarta").format("HH:mm");
  };

  //TABLE
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: companyList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable ? (column.isSorted ? (column.isSortedDesc ? " sorting_desc" : " sorting_asc") : " sort") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState([]);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleFilter() {
    let startat = "";
    let endat = "";
    let datearr = filterDate.split(" to ");
    if (datearr[1]) {
      startat = datearr[0];
      endat = datearr[1];
    }

    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetCompanies(param));
  }

  const toggleMedia = useCallback(() => {
    if (modalMedia) {
      setModalMedia(false);
    } else {
      setModalMedia(true);
    }
  }, [modalMedia]);

  const handleSelectFile = (file) => {
    validation.setFieldValue("image", file.filename);
    toggleMedia();
  };

  const resetList = () => {
    dispatch(resetClientState());
  };

  useEffect(() => {
    return () => {
      dispatch(resetCompanyState());
      resetList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = `${t("Company")} | Aptek`;
  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={companyList} />
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteCompany} onCloseClick={() => setDeleteModal(false)} />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={t("Company")} pageTitle={t("Company")} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    {t("Company History")} &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color="primary" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className={`btn btn-primary add-btn`}
                      id="create-btn"
                      onClick={() => {
                        setCompany("");
                        setIsEdit(false);
                        toggle();
                      }}
                    >
                      <i className="ri-building-4-line me-1 align-bottom fs-14"></i> {t("Add")}
                    </button>{" "}
                    <button type="button" className="btn btn-success" onClick={() => setIsExportCSV(true)}>
                      <i className="ri-file-download-line me-1 align-bottom fs-14"></i> {t("Export")}
                    </button>{" "}
                    <button className={userType === "executive" ? "btn btn-soft-danger" : roles && roles.split(",").indexOf("deleteCompany") !== -1 ? "btn btn-soft-danger" : "btn btn-soft-danger d-none"} onClick={() => setDeleteModalMulti(true)}>
                      <i className="ri-delete-bin-5-line me-1 align-bottom fs-15"></i> {t("Delete")}
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className="ri-building-4-line me-1 align-bottom fs-14"></i> All Companies
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("2", "Draft");
                        }}
                        href="#"
                      >
                        <i className="ri-information-line me-1 align-bottom fs-14"></i> Draft
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("3", "Activated");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Activated{" "}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className="mb-3">
                    <CardBody className="border border-top-0 border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={1} className="col-xxl-1">
                            <div className="me-2 mb-2 col-12">
                              <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="search-box me-2 mb-2 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${count} Search...`}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col sm={6} className="col-xxl-3">
                            <div className="search-box me-2 mb-2 col-12">
                              <Flatpickr
                                className="form-control"
                                id="datepicker-publish-input"
                                placeholder="Select created date"
                                options={{
                                  mode: "range",
                                  dateFormat: "Y/m/d",
                                }}
                                onChange={(selectedDates, dateStr) => {
                                  setFilterDate(dateStr);
                                }}
                              />
                              <i className="bx bx-calendar-alt search-icon"></i>
                            </div>
                          </Col>

                          <Col sm={4} className="col-xxl-2">
                            <div className="me-2 mb-2 col-12">
                              <Select
                                value={statusActive}
                                placeholder="Select Status"
                                onChange={(e) => {
                                  setStatusActive(e);
                                }}
                                options={statusActiveData}
                                name="choices-single-default"
                              ></Select>
                            </div>
                          </Col>

                          <Col className="col-xxl-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-light w-100"
                                onClick={() => {
                                  handleFilter();
                                }}
                              >
                                <i className="ri-search-line me-1 align-bottom fs-14"></i> {t("Search")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card mb-1">
                    <Table hover {...getTableProps()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroups.map((headerGroup) => (
                          <tr className={""} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={previousPage} disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      {" "}
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
                    </Col>

                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={nextPage} disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal id="showModal" isOpen={modal} centered size="lg">
                  <ModalHeader
                    className="bg-light p-3"
                    toggle={() => {
                      toggle();
                      validation.resetForm();
                      validation.setErrors({});
                    }}
                  >
                    {!!isEdit ? `${t("Edit")} ${t("Company")}` : `${t("Add")} ${t("Company")}`}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="col-lg-12 mb-3">
                            <div className="text-center">
                              <div className="position-relative d-inline-block">
                                <div className="position-absolute bottom-0 end-0">
                                  <Label
                                    onClick={() => {
                                      toggleMedia();
                                    }}
                                    htmlFor="unit-logo-input"
                                    className="mb-0"
                                  >
                                    <div className="avatar-xs cursor-pointer">
                                      <div className="avatar-title bg-light border rounded-circle text-muted">
                                        <i className="ri-image-fill"></i>
                                      </div>
                                    </div>
                                  </Label>
                                  {/* <Input
                                    name="img"
                                    className="form-control d-none"
                                    id="unit-logo-input"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={(e) => {
                                      // handleLogoChange(e);
                                    }}
                                    onBlur={validation.handleBlur}
                                    invalid={validation.touched.img && validation.errors.img ? true : false}
                                  /> */}
                                  {/* <button className="form-control d-none" onClick={()=>{
                                    ("clickkkk");
                                  }}>

                                  </button> */}
                                </div>
                                <div className="avatar-lg p-1">
                                  <div className="avatar-title bg-light rounded-circle">
                                    <img
                                      src={validation.values.image === "" ? logo : `${api.MEDIA_URL}/media/${validation.values.image}`}
                                      alt=""
                                      id="unitlogo-img"
                                      className="avatar-md rounded-circle object-cover"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = imgNotFound;
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <h5 className="fs-13 mt-3">Logo</h5>
                            </div>
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="password-field" className="form-label">
                              {t("Client")} <span className="text-danger">*</span>
                            </Label>
                            <Select
                              name="client"
                              value={validation.values.client}
                              placeholder="Select Client"
                              onChange={(e) => {
                                validation.setFieldValue("client", e);
                              }}
                              validate={{ required: { value: true } }}
                              options={clientList}
                              invalid={validation.touched.client && validation.errors.client ? true : false}
                            ></Select>
                            {validation.touched.client && validation.errors.client ? (
                              <p className="invalid-feedback d-block" type="invalid">
                                {validation.errors.client}
                              </p>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="name-field" className="form-label">
                              {t("Name")} <span className="text-danger">*</span>
                            </Label>
                            <Input name="name" id="name-field" className="form-control" placeholder="Enter Name" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.name || ""} invalid={validation.touched.name && validation.errors.name ? true : false} />
                            {validation.touched.name && validation.errors.name ? <FormFeedback type="invalid">{validation.errors.name}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="date-field" className="form-label">
                              {t("Founded Date")} <span className="text-danger">*</span>
                            </Label>
                            <Flatpickr
                              name="foundedat"
                              className="form-control"
                              placeholder="Select founded date"
                              options={{
                                enableTime: false,
                                altInput: true,
                                altFormat: "Y/m/d",
                                dateFormat: "Y/m/d",
                              }}
                              value={validation.values.foundedat || ""}
                              onChange={(obj, str) => {
                                validation.setFieldValue("foundedat", str);
                              }}
                            />
                            {validation.touched.foundedat && validation.errors.foundedat ? <div className="invalid-feedback d-block">{validation.errors.foundedat}</div> : null}
                          </div>

                          <div className="mb-3">
                            <Label htmlFor="address-field" className="form-label">
                              {t("Address")}
                            </Label>
                            <GooglePlacesAutocomplete
                              apiKey="AIzaSyD0auKcsMNas3qbqi-BgF_s3UxwtQNP74Q"
                              apiOptions={{
                                region: "IDN",
                              }}
                              autocompletionRequest={{
                                componentRestrictions: {
                                  country: ["idn"],
                                },
                                types: ["route"],
                              }}
                              selectProps={{
                                name: "address",
                                value: undefined,
                                defaultValue: validation.values.address,
                                onChange: (value) => {
                                  validation.setFieldValue("address", value);
                                },
                              }}
                            />
                            {validation.touched.address && validation.errors.address ? <div className="invalid-feedback d-block">{validation.errors.address}</div> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="zip-field" className="form-label">
                              {t("Zip Code")}
                            </Label>
                            <Input name="zip" id="zip-field" className="form-control" placeholder="Enter Zip Code" type="number" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.zip || ""} invalid={validation.touched.zip && validation.errors.zip ? true : false} />
                            {validation.touched.zip && validation.errors.zip ? <FormFeedback type="invalid">{validation.errors.zip}</FormFeedback> : null}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-3">
                            <Label htmlFor="street-field" className="form-label">
                              {t("Street")}
                            </Label>
                            <Input name="street" id="street-field" className="form-control" placeholder="Enter Street Name" type="textarea" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.street || ""} invalid={validation.touched.street && validation.errors.street ? true : false} />
                            {validation.touched.street && validation.errors.street ? <FormFeedback type="invalid">{validation.errors.street}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="active-status" className="form-label">
                              Status <span className="text-danger">*</span>
                            </Label>
                            <Select
                              name="status"
                              value={validation.values.status}
                              validate={{ required: { value: true } }}
                              placeholder="Select Status"
                              onChange={(e) => {
                                validation.setFieldValue("status", e);
                              }}
                              onInputChange={(e) => {
                                // handleUnit(e);
                              }}
                              options={statusActiveData}
                              invalid={validation.touched.status && validation.errors.status ? true : false}
                            ></Select>
                            {validation.touched.status && validation.errors.status ? (
                              <p className="invalid-feedback d-block" type="invalid">
                                {validation.errors.status}
                              </p>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="des-field" className="form-label">
                              {t("Description")}
                            </Label>
                            <Input name="description" id="des-field" className="form-control" placeholder="Enter Description" type="textarea" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.description || ""} style={{ minHeight: "103px" }} />
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            toggle();
                            validation.resetForm();
                            validation.setErrors({});
                          }}
                        >
                          {t("Cancel")}
                        </button>
                        <button type="submit" className="btn btn-success">
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">{t("Save")}</span>
                            {loading ? (
                              <Spinner size="sm" className="flex-shrink-0" role="status">
                                Loading...
                              </Spinner>
                            ) : (
                              ""
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <ModalInputFile onSelect={handleSelectFile} loading={loading} modal={modalMedia} toggle={toggleMedia} />
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Company.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Company));
