import {
  GET_BRANCH,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_FAIL,
  DELETE_BRANCH,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAIL,
  UPDATE_BRANCH,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAIL,
  ADD_NEW_BRANCH,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAIL,
  BRANCH_LOADING,
  BRANCH_RESET,
} from "./actionType";

export const resetBranchState = (actionType) => ({
  type: BRANCH_RESET,
  payload: { actionType },
});

export const getBranchSuccess = (actionType, data) => ({
  type: GET_BRANCH_SUCCESS,
  payload: { actionType, data },
});

export const getBranchFail = (actionType, error) => ({
  type: GET_BRANCH_FAIL,
  payload: { actionType, error },
});

export const getBranch = data => ({
  type: GET_BRANCH,
  payload: data,
});

export const getBranchLoading = () => ({
  type: BRANCH_LOADING
});

export const deleteBranch = data => ({
  type: DELETE_BRANCH,
  payload: data,
});

export const deleteBranchSuccess = data => ({
  type: DELETE_BRANCH_SUCCESS,
  payload: data,
});

export const deleteBranchFail = error => ({
  type: DELETE_BRANCH_FAIL,
  payload: error,
});

export const updateBranch = data => ({
  type: UPDATE_BRANCH,
  payload: data,
});

export const updateBranchFail = error => ({
  type: UPDATE_BRANCH_FAIL,
  payload: error,
});

export const updateBranchSuccess = data => ({
  type: UPDATE_BRANCH_SUCCESS,
  payload: data,
});

export const addNewBranch = data => ({
  type: ADD_NEW_BRANCH,
  payload: data,
});

export const addBranchSuccess = data => ({
  type: ADD_BRANCH_SUCCESS,
  payload: data,
});

export const addBranchFail = error => ({
  type: ADD_BRANCH_FAIL,
  payload: error,
});