import {
  UNIT_LOADING,
  GET_UNIT,
  GET_UNIT_SUCCESS,
  GET_UNIT_FAIL,
  ADD_UNIT_SUCCESS,
  ADD_UNIT_FAIL,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAIL,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_FAIL,
  UNIT_RESET
} from "./actionType";

const INIT_STATE = {
  units: [],
  error: {},
  loading: false
};

const Units = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_UNIT_SUCCESS:
      switch (action.payload.actionType) {
        case GET_UNIT:
          return {
            ...state,
            units: action.payload.data,
            isUnitCreated: false,
            isUnitSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_UNIT_FAIL:
      switch (action.payload.actionType) {
        case GET_UNIT_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isUnitsCreated: false,
            isUnitsSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_UNIT: {
      return {
        ...state,
        isUnitsCreated: false,
        loading: true,
      };
    }

    case UNIT_LOADING: {
      return {
        ...state,
        isUnitsCreated: false,
        loading: true,
      };
    }

    case ADD_UNIT_SUCCESS:
      return {
        ...state,
        isUnitsCreated: true,
        loading: false,
        units: [...state.units, action.payload.data],
      };

    case ADD_UNIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUnitsCreated: true,
        units: state.units.filter(
          units => units.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_UNIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUnitCreated: true,
        units: state.units.map(unit =>
          unit.id.toString() === action.payload.data.id.toString() ? { ...unit, ...action.payload.data } : unit
        ),
      };

    case UPDATE_UNIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UNIT_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Units;