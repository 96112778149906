import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import CryptoJS from "crypto-js";
import moment from "moment";
import "moment-timezone";

import {
  getPublicTask,
  getTaskLoading,
  resetTaskState,
} from "../../store/task/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Starter = () => {
  const { taskId } = useParams();
  const dispatch = useDispatch();
  const {
    tasks,
    isTaskCreated,
    isTaskSuccess,
    loading,
    error,
    taskDetails,
    taskDetailLoading,
    isTaskDetailCreated,
    isTaskDetailSuccess,
    users,
    userLoading,
    isMediaDeleted,
    files,
  } = useSelector((state) => ({
    tasks: state.Task.tasks,
    isTaskCreated: state.Task.isTaskCreated,
    isTaskSuccess: state.Task.isTaskSuccess,
    loading: state.Task.loading,
    error: state.Task.error,
    taskDetails: state.TaskDetail.taskDetails,
    taskDetailLoading: state.TaskDetail.loading,
    isTaskDetailCreated: state.TaskDetail.isTaskDetailCreated,
    isTaskDetailSuccess: state.TaskDetail.isTaskDetailSuccess,
    users: state.User.users,
    userLoading: state.User.loading,
    files: state.File.files,
  }));

  const [isSearch, setIsSearch] = useState(false);
  const [task, setTask] = useState();

  useEffect(() => {
    if (!isSearch) {
      var decrypted = CryptoJS.AES.decrypt(taskId.replace(/[|]+/g, '/'), "@ptek4321").toString(CryptoJS.enc.Utf8);
      console.log(taskId.replace(/[|]+/g, '/'));
      dispatch(resetTaskState());
      dispatch(getPublicTask({ task: decrypted }));
      setIsSearch(true);
    }
  }, [dispatch, isSearch, taskId]);

  useEffect(() => {
    if (tasks) setTask(tasks[0])
  }, [tasks]);

  const handleValidDate = (date) => {
    return moment(new Date(date), "YYYY/MM/DD")
      .tz("Asia/Jakarta")
      .format("MMM D, YYYY");
  };

  const numRoman = (num) => {
    console.log(num);
    if (num === "1") {
      return "I"
    } else if (num === "2") {
      return "II"
    }
    else if (num === "3") {
      return "III"
    }
    else if (num === "4") {
      return "IV"
    }
    else if (num === "5") {
      return "V"
    }
    else if (num === "6") {
      return "VI"
    } else if (num === "7") {
      return "VII"
    }
    else if (num === "8") {
      return "VIII"
    }
    else if (num === "9") {
      return "IX"
    }
    else if (num === "10") {
      return "X"
    }
    else if (num === "11") {
      return "XI"
    } else if (num === "12") {
      return "XII"
    }
  }

  document.title = "Document | Document Verification";
  return (
    <React.Fragment>
      <div className="layout-wrapper landing">
        <section className="section" id="services">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8}>
                <div className="text-center mb-5">
                  <img
                    alt=''
                    src={task && 
                      process.env.REACT_APP_API_URL +
                      "/media/" +
                      task.data?.task_client.client_image?.file_folder.name +
                      "/" +
                      task.data?.task_client.client_image?.filename
                    }
                    style={{ maxWidth: "150px", maxHeight: "50px" }}
                  />
                  <h1 className="mb-3 ff-secondary fw-semibold lh-base">
                    Informasi Dokumen
                  </h1>
                  <p className="mb-1">Tanggal Dokumen: {task && handleValidDate(task.startat)}</p>
                  <p className="mb-1">No Dokumen: {task && task.id + "/" + task.document_prefix + "/" + numRoman(moment(task.documentat).format('M')) + "/" + moment(task.documentat).format('YYYY')}</p>
                  <p className="mb-1">Perihal: {task && task.name}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Starter; 