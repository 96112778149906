import {
  ROLELIST_LOADING,
  GET_ROLELIST,
  GET_ROLELIST_SUCCESS,
  GET_ROLELIST_FAIL,
  ADD_ROLELIST_SUCCESS,
  ADD_ROLELIST_FAIL,
  DELETE_ROLELIST_SUCCESS,
  DELETE_ROLELIST_FAIL,
  UPDATE_ROLELIST_SUCCESS,
  UPDATE_ROLELIST_FAIL,
  ROLELIST_RESET
} from "./actionType";

const INIT_STATE = {
  roleLists: [],
  structures: [],
  error: {},
};

const RoleList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLELIST_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ROLELIST:
          return {
            ...state,
            roleLists: action.payload.data,
            isRoleListCreated: false,
            isRoleListSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_ROLELIST_FAIL:
      switch (action.payload.actionType) {
        case GET_ROLELIST_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isRoleListCreated: false,
            isRoleListSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_ROLELIST: {
      return {
        ...state,
        isRoleListCreated: false,
        loading: true,
      };
    }

    case ROLELIST_LOADING: {
      return {
        ...state,
        isRoleListCreated: false,
        loading: true,
      };
    }

    case ADD_ROLELIST_SUCCESS:
      return {
        ...state,
        isRoleListCreated: true,
        loading: false,
        roleLists: [...state.roleLists, action.payload.data],
      };

    case ADD_ROLELIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_ROLELIST_SUCCESS:
      return {
        ...state,
        loading: false,
        roleLists: state.roleLists.filter(
          roleList => roleList.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ROLELIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_ROLELIST_SUCCESS:
      return {
        ...state,
        loading: false,
        roleLists: state.roleLists.map(roleList =>
          roleList.id.toString() === action.payload.data.id.toString() ? { ...roleList, ...action.payload.data } : roleList
        ),
      };

    case UPDATE_ROLELIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ROLELIST_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default RoleList;