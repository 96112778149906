import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

// Import Images
import avatar2 from "../../assets/images/users/user-dummy-img.jpg";


const Team = () => {
    return (
        <React.Fragment>
            <section className="section bg-light" id="team">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="text-center mb-5">
                                <h3 className="mb-3 fw-semibold">Our <span className="text-danger">Team</span></h3>
                                <p className="text-muted mb-4 ff-secondary">To achieve this, it would be necessary to have uniform grammar,
                                    pronunciation and more common words. If several languages coalesce the grammar.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3} sm={6}>
                            <Card>
                                <CardBody className="text-center p-4">
                                    <div className="avatar-xl mx-auto mb-4 position-relative">
                                        <img src={avatar2} alt="" className="img-fluid rounded-circle" />
                                        <Link to="/apps-mail"
                                            className="btn btn-success btn-sm position-absolute bottom-0 end-0 rounded-circle avatar-xs">
                                            <div className="avatar-title bg-transparent">
                                                <i className="ri-mail-fill align-bottom"></i>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* <!-- end card body --> */}
                                    <h5 className="mb-1"><Link to="/pages-profile" className="text-body"></Link></h5>
                                    <p className="text-muted mb-0 ff-secondary"></p>
                                </CardBody>
                            </Card>

                            {/* <!-- end card --> */}
                        </Col>

                        {/* <!-- end col --> */}
                        <Col lg={3} sm={6}>
                            <Card>
                                <CardBody className="text-center p-4">
                                    <div className="avatar-xl mx-auto mb-4 position-relative">
                                        <img src={avatar2} alt="" className="img-fluid rounded-circle" />
                                        <Link to="/apps-mail"
                                            className="btn btn-success btn-sm position-absolute bottom-0 end-0 rounded-circle avatar-xs">
                                            <div className="avatar-title bg-transparent">
                                                <i className="ri-mail-fill align-bottom"></i>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* <!-- end card body --> */}
                                    <h5 className="mb-1"><Link to="/pages-profile" className="text-body"></Link></h5>
                                    <p className="text-muted mb-0 ff-secondary"></p>
                                </CardBody>
                            </Card>

                            {/* <!-- end card --> */}
                        </Col>
                        {/* <!-- end col --> */}
                    </Row>

                    {/* <!-- end row --> */}
                    <Row>
                        <div className="col-lg-12">
                            <div className="text-center mt-2">
                                <Link to="/pages-team" className="btn btn-primary">View All Members <i
                                    className="ri-arrow-right-line ms-1 align-bottom"></i></Link>
                            </div>
                        </div>
                    </Row>

                    {/* <!-- end row --> */}
                </Container>
            </section>
        </React.Fragment>
    );
};

export default Team;