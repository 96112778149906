import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_SERVICE_ORDERAPPROVAL,
  DELETE_SERVICE_ORDERAPPROVAL,
  UPDATE_SERVICE_ORDERAPPROVAL,
  ADD_NEW_SERVICE_ORDERAPPROVAL,
} from "./actionType";

import {
  getServiceOrderApprovalSuccess,
  getServiceOrderApprovalFail,
  deleteServiceOrderApprovalSuccess,
  deleteServiceOrderApprovalFail,
  updateServiceOrderApprovalSuccess,
  updateServiceOrderApprovalFail,
  addServiceOrderApprovalSuccess,
  addServiceOrderApprovalFail,
} from "./action";

//Include Both Helper File with needed carriers
import {
  approveServiceOrderApprovalApi,
  getServiceOrderApprovalApi,
  updateServiceOrderApprovalApi,
  deleteServiceOrderApprovalApi,
  addNewServiceOrderApprovalApi
} from "../../helpers/backend_helper";

function* getServiceOrderApproval({ payload: serviceOrderApproval }) {
  try {
    const response = yield call(getServiceOrderApprovalApi, serviceOrderApproval);
    yield put(getServiceOrderApprovalSuccess(GET_SERVICE_ORDERAPPROVAL, response.data));
  } catch (error) {
    yield put(getServiceOrderApprovalFail(GET_SERVICE_ORDERAPPROVAL, error));
  }
}

function* onUpdateServiceOrderApproval({ payload: serviceOrderApproval }) {
  try {
    if (serviceOrderApproval.setApprove) {
      const response = yield call(approveServiceOrderApprovalApi, serviceOrderApproval);
      yield put(updateServiceOrderApprovalSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
    else {
      const response = yield call(updateServiceOrderApprovalApi, serviceOrderApproval);
      yield put(updateServiceOrderApprovalSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateServiceOrderApprovalFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteServiceOrderApproval({ payload: serviceOrderApproval }) {
  try {
    const response = yield call(deleteServiceOrderApprovalApi, serviceOrderApproval);
    yield put(deleteServiceOrderApprovalSuccess({ serviceOrderApproval, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteServiceOrderApprovalFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewServiceOrderApproval({ payload: serviceOrderApproval }) {
  try {
    const response = yield call(addNewServiceOrderApprovalApi, serviceOrderApproval);
    yield put(addServiceOrderApprovalSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addServiceOrderApprovalFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetServiceOrderApproval() {
  yield takeEvery(GET_SERVICE_ORDERAPPROVAL, getServiceOrderApproval);
}

export function* watchUpdateServiceOrderApproval() {
  yield takeEvery(UPDATE_SERVICE_ORDERAPPROVAL, onUpdateServiceOrderApproval);
}

export function* watchDeleteServiceOrderApproval() {
  yield takeEvery(DELETE_SERVICE_ORDERAPPROVAL, onDeleteServiceOrderApproval);
}

export function* watchAddNewServiceOrderApproval() {
  yield takeEvery(ADD_NEW_SERVICE_ORDERAPPROVAL, onAddNewServiceOrderApproval);
}

function* serviceOrderApprovalSaga() {
  yield all([
    fork(watchGetServiceOrderApproval),
    fork(watchDeleteServiceOrderApproval),
    fork(watchUpdateServiceOrderApproval),
    fork(watchAddNewServiceOrderApproval)
  ]);
}

export default serviceOrderApprovalSaga;
