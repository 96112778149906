module.exports = {
  google: {
    API_KEY: "AIzaSyD0auKcsMNas3qbqi-BgF_s3UxwtQNP74Q",
    CLIENT_ID: "729313900237-21ccou6h50l4df8hsqo4t0rnc6s0gf85.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: "https://api-sms.atapteknologi.id",
    SSO_URL: "https://sso.atapteknologi.id",
    MEDIA_URL: "https://drive.atapteknologi.id",
  },
};
