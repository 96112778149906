import {
  GET_SERVICE_ORDERPAYMENT,
  GET_SERVICE_ORDERPAYMENT_SUCCESS,
  GET_SERVICE_ORDERPAYMENT_FAIL,
  DELETE_SERVICE_ORDERPAYMENT,
  DELETE_SERVICE_ORDERPAYMENT_SUCCESS,
  DELETE_SERVICE_ORDERPAYMENT_FAIL,
  UPDATE_SERVICE_ORDERPAYMENT,
  UPDATE_SERVICE_ORDERPAYMENT_SUCCESS,
  UPDATE_SERVICE_ORDERPAYMENT_FAIL,
  ADD_NEW_SERVICE_ORDERPAYMENT,
  ADD_SERVICE_ORDERPAYMENT_SUCCESS,
  ADD_SERVICE_ORDERPAYMENT_FAIL,
  SERVICE_ORDERPAYMENT_LOADING,
  SERVICE_ORDERPAYMENT_RESET,
} from "./actionType";

export const resetServiceOrderPaymentState = (actionType) => ({
  type: SERVICE_ORDERPAYMENT_RESET,
  payload: { actionType },
});

export const getServiceOrderPaymentSuccess = (actionType, data) => ({
  type: GET_SERVICE_ORDERPAYMENT_SUCCESS,
  payload: { actionType, data },
});

export const getServiceOrderPaymentFail = (actionType, error) => ({
  type: GET_SERVICE_ORDERPAYMENT_FAIL,
  payload: { actionType, error },
});

export const getServiceOrderPayment = data => ({
  type: GET_SERVICE_ORDERPAYMENT,
  payload: data,
});

export const getServiceOrderPaymentLoading = () => ({
  type: SERVICE_ORDERPAYMENT_LOADING
});

export const deleteServiceOrderPayment = data => ({
  type: DELETE_SERVICE_ORDERPAYMENT,
  payload: data,
});

export const deleteServiceOrderPaymentSuccess = data => ({
  type: DELETE_SERVICE_ORDERPAYMENT_SUCCESS,
  payload: data,
});

export const deleteServiceOrderPaymentFail = error => ({
  type: DELETE_SERVICE_ORDERPAYMENT_FAIL,
  payload: error,
});

export const updateServiceOrderPayment = data => ({
  type: UPDATE_SERVICE_ORDERPAYMENT,
  payload: data,
});

export const updateServiceOrderPaymentFail = error => ({
  type: UPDATE_SERVICE_ORDERPAYMENT_FAIL,
  payload: error,
});

export const updateServiceOrderPaymentSuccess = data => ({
  type: UPDATE_SERVICE_ORDERPAYMENT_SUCCESS,
  payload: data,
});

export const addNewServiceOrderPayment = data => ({
  type: ADD_NEW_SERVICE_ORDERPAYMENT,
  payload: data,
});

export const addServiceOrderPaymentSuccess = data => ({
  type: ADD_SERVICE_ORDERPAYMENT_SUCCESS,
  payload: data,
});

export const addServiceOrderPaymentFail = error => ({
  type: ADD_SERVICE_ORDERPAYMENT_FAIL,
  payload: error,
});