import {
  CONTACT_PERSON_LOADING,
  GET_CONTACT_PERSON,
  GET_CONTACT_PERSON_SUCCESS,
  GET_CONTACT_PERSON_FAIL,
  ADD_CONTACT_PERSON_SUCCESS,
  ADD_CONTACT_PERSON_FAIL,
  DELETE_CONTACT_PERSON_SUCCESS,
  DELETE_CONTACT_PERSON_FAIL,
  UPDATE_CONTACT_PERSON_SUCCESS,
  UPDATE_CONTACT_PERSON_FAIL,
  CONTACT_PERSON_RESET,
} from "./actionType";

const INIT_STATE = {
  contactPersons: [],
  error: {},
};

const ContactPerson = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONTACT_PERSON_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CONTACT_PERSON:
          return {
            ...state,
            contactPersons: action.payload.data,
            isContactPersonCreated: false,
            isContactPersonSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_CONTACT_PERSON_FAIL:
      switch (action.payload.actionType) {
        case GET_CONTACT_PERSON_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isContactPersonCreated: false,
            isContactPersonSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_CONTACT_PERSON: {
      return {
        ...state,
        isContactPersonCreated: false,
        loading: true,
      };
    }

    case CONTACT_PERSON_LOADING: {
      return {
        ...state,
        isContactPersonCreated: false,
        loading: true,
      };
    }

    case ADD_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        isContactPersonCreated: true,
        loading: false,
        contactPersons: [...state.contactPersons, action.payload.data],
      };

    case ADD_CONTACT_PERSON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        isContactPersonCreated: true,
        contactPersons: state.contactPersons.filter(
          (contactPerson) =>
            contactPerson.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_CONTACT_PERSON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        isContactPersonCreated: true,
        contactPersons: state.contactPersons.map((contactPerson) =>
          contactPerson.id.toString() === action.payload.data.id.toString()
            ? { ...contactPerson, ...action.payload.data }
            : contactPerson
        ),
      };

    case UPDATE_CONTACT_PERSON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CONTACT_PERSON_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ContactPerson;
